import { Icon, IconProps } from '@chakra-ui/react';

export function HideInPodiumIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.36 23.597a1.374 1.374 0 1 1-1.944-1.946L10.062 12H2.757C1.234 12 0 10.945 0 9.643c0-.16.019-.32.057-.476L2.75 1.88C3.014.786 4.142 0 5.45 0h13.1c.99 0 1.876.45 2.363 1.143l.74-.74a1.374 1.374 0 1 1 1.944 1.946L22.01 3.937l1.933 5.23c.307 1.275-.652 2.522-2.144 2.784-.183.033-.37.049-.556.049H13.95l-.576.576v8.674h3.25a1.375 1.375 0 1 1 0 2.75h-9.25a1.375 1.375 0 1 1 0-2.75h3.25v-5.922L2.36 23.597ZM16.7 9.25h4.341l-1.172-3.171-3.17 3.171Zm2.119-6.012-.177-.479-.034-.006-.058-.003H5.42l-.063.009L2.958 9.25h9.852l6.009-6.012Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default HideInPodiumIcon;
