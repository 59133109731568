import { ReactNode, memo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import { TextEditor } from '@sermonary/components';

const MemomizedTextEditor = memo(TextEditor);

export function ContentBlockForm({
  children,
  register,
  control,
  formState,
  setValue,
}: Pick<UseFormReturn<any>, 'register' | 'formState' | 'control' | 'setValue'> & {
  children?: ReactNode;
}) {
  return (
    <Grid gridTemplateColumns="100%" gap="4">
      <MemomizedTextEditor label="Content" name="content" control={control} />
      {children}
    </Grid>
  );
}
