import { Icon, IconProps } from '@chakra-ui/react';

export function CaretRightIcon({ height = 4, width = 4, ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 13 13" height={height} width={width} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.218 2.209a.77.77 0 0 1 .993-.052l.06.052 3.543 3.393a.693.693 0 0 1 .054.953l-.063.066-3.534 3.384a.768.768 0 0 1-1.053 0 .692.692 0 0 1-.054-.951l.054-.058 3.015-2.89-3.015-2.888a.692.692 0 0 1-.054-.951l.054-.058Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default CaretRightIcon;
