import { memo, useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
} from '@chakra-ui/react';
import { SermonBlockInlineAdd, SermonBlockCard } from '@sermonary/components';
import { Block } from '@sermonary/types';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';
import { atomWithDefault } from 'jotai/utils';
import { DataStore } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import striptags from 'striptags';
import { track } from '../../analytics/Analytics';
import { Sermon } from '../../models';
import { SermonBlock } from './SermonBlock';
import { isSermonBlockDrawerOpenAtom, sermonAtom } from '../../pages/sermons/SingleSermon';
import { focusBlockInput } from '../../utilities/focusBlockInput';

function getBlockWordCount(blockContent: any) {
  const getWordCount = (input: string) => {
    const regexSplitWords = /\b\S+\b/g;
    const text = striptags(input);
    return text?.match(regexSplitWords)?.length ?? 0;
  };

  switch (typeof blockContent) {
    case 'object':
      return Object.values(blockContent).reduce((sum: number, verse) => {
        const content = Array.isArray(verse) ? verse.join(' ') : verse;
        return typeof content === 'string' ? sum + getWordCount(content) : sum;
      }, 0);

    case 'string':
      return getWordCount(blockContent);

    default:
      return 0;
  }
}

function convertBlocksForStorage(updatedBlocks: Block[]) {
  return JSON.stringify(
    updatedBlocks?.reduce(
      (acc, curr, index) => ({
        ...acc,
        [index]: curr,
      }),
      {},
    ),
  );
}

export function getSermonWordCount(sermon: Sermon): number {
  const sermonBlocks = sermon?.blocks ?? {};

  return Object.values(sermonBlocks).reduce((sum: number, block: any) => {
    // eslint-disable-next-line no-prototype-builtins
    const count = block?.hasOwnProperty('wordCount') ? block?.wordCount : getBlockWordCount(block?.content);
    return sum + (count ?? 0);
  }, 0) as number;
}

export function transformBlocksToArray(blocksObj: string | Block[]): Block[] {
  if (!blocksObj) {
    return [] as Block[];
  }

  const blocks = blocksObj && typeof blocksObj === 'string' ? JSON.parse(blocksObj) : blocksObj;

  if (Object.keys(blocks)?.length === 0) {
    return [];
  }

  return Object.keys(blocks)?.map((block) => blocks?.[block]);
}

const lastAddedBlockIdAtom = atom('');

const updatedBlocksAtom = atom<Block[]>([]);

export const blocksAtom = atom(
  (get) => get(updatedBlocksAtom),
  (_get, set, args: Block[]) => set(updatedBlocksAtom, args),
);

const blocksInsertionPointAtom = atomWithDefault((get) => get(blocksAtom)?.length);

const getItemStyle = (_isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  userSelect: 'none',
  ...draggableStyle,
});

const reorder = (list: Block[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const SermonBlockEditor = memo(() => {
  const [sermon, setSermon] = useAtom(sermonAtom);
  const [blocks, updateBlocks] = useState(transformBlocksToArray(sermon.blocks ?? ''));

  const [lastAddedBlockId, setLastAddedBlockId] = useAtom(lastAddedBlockIdAtom);

  const [blocksInsertionPoint, setBlocksInsertionPoint] = useAtom(blocksInsertionPointAtom);
  const [isSermonBlockDrawerOpen, setIsSermonBlockDrawerOpen] = useAtom(isSermonBlockDrawerOpenAtom);

  useEffect(() => {
    updateBlocks(transformBlocksToArray(sermon.blocks ?? ''));
  }, [sermon, sermon.blocks]);

  useEffect(() => {
    if (lastAddedBlockId) {
      const scrollToBlock = document.getElementById(lastAddedBlockId);
      const blockType = blocks.find((element) => element.id === lastAddedBlockId).type as string;

      setTimeout(() => {
        focusBlockInput(scrollToBlock, blockType);

        scrollToBlock?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 300);
    }
  }, [lastAddedBlockId]);

  async function handleUpdate(updatedBlocks: Block[]) {
    try {
      const updatedSermon = await DataStore.save(
        Sermon.copyOf(sermon, (updated) => {
          updated.blocks = convertBlocksForStorage(updatedBlocks);
        }),
      );

      setSermon(updatedSermon);
    } catch (err) {
      console.log(err);
    }
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(blocks, result.source.index, result.destination.index);

    updateBlocks(items);
    handleUpdate(items);
  };

  const handleOnSave = (id: string, values: any) => {
    values.wordCount = getBlockWordCount(values.content);

    const updatedBlocks = blocks?.map((block) => (block?.id === id ? { ...block, ...values } : block));

    updateBlocks(updatedBlocks);
    handleUpdate(updatedBlocks);
  };

  const handleOnAdd = (index: number) => {
    setBlocksInsertionPoint(index);
    setIsSermonBlockDrawerOpen(true);
  };

  const handleOnDelete = (block: Block) => {
    const filteredBlocks = blocks?.filter((b) => b?.id !== block?.id);
    updateBlocks(filteredBlocks);
    handleUpdate(filteredBlocks);
  };

  const handleOnAddBlock = async (type: any) => {
    const block = {
      id: uuid(),
      type,
    };
    const index = blocksInsertionPoint;
    const newBlocks = index !== null ? [...blocks.slice(0, index), block, ...blocks.slice(index)] : [...blocks, block];

    setLastAddedBlockId(block.id);
    updateBlocks(newBlocks);
    setBlocksInsertionPoint(newBlocks?.length);
    setIsSermonBlockDrawerOpen(false);
    await track('Add Block', { blockType: type, sermonId: sermon?.id });
  };

  const pointBlocks = blocks
    ?.map((block) => (block?.type === 'point' ? block : undefined))
    ?.filter((b): b is Block => !!b);

  useEffect(
    () =>
      function cleanup() {
        setLastAddedBlockId('');
      },
  );

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Divider size="heavy" mt="6" />
        <Box mt="6">
          <Droppable droppableId="sermonEditorBlocks">
            {(provided) => (
              <Grid gridTemplateColumns="100%" ref={provided.innerRef} {...provided.droppableProps}>
                {blocks?.map((block, index) => (
                  <Draggable key={block?.id} draggableId={block?.id} index={index}>
                    {(provDrag, snapshot) => (
                      <Box
                        id={block?.id}
                        ref={provDrag.innerRef}
                        {...provDrag.draggableProps}
                        {...provDrag.dragHandleProps}
                        sx={getItemStyle(snapshot.isDragging, provDrag.draggableProps.style)}
                      >
                        <SermonBlock
                          key={`sermon-block-${block?.id}`}
                          index={index}
                          isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                          block={block}
                          lastAddedBlockId={lastAddedBlockId}
                          pointBlocks={pointBlocks}
                          onAdd={handleOnAdd}
                          onRemove={handleOnDelete}
                          onSave={handleOnSave}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </Box>
      </DragDropContext>
      <SermonBlockInlineAdd
        isDragging={false}
        onAdd={() => handleOnAdd(blocks?.length)}
        noBlocks={!(blocks?.length > 0)}
      />
      <Drawer isOpen={isSermonBlockDrawerOpen} placement="right" onClose={() => setIsSermonBlockDrawerOpen(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add a block</DrawerHeader>

          <DrawerBody>
            <Grid gap="4">
              <SermonBlockCard type="bible" onAddBlock={() => handleOnAddBlock('bible')} />
              <SermonBlockCard type="point" onAddBlock={() => handleOnAddBlock('point')} />
              <SermonBlockCard type="illustration" onAddBlock={() => handleOnAddBlock('illustration')} />
              <SermonBlockCard type="application" onAddBlock={() => handleOnAddBlock('application')} />
              <SermonBlockCard type="quote" onAddBlock={() => handleOnAddBlock('quote')} />
              <SermonBlockCard type="custom" onAddBlock={() => handleOnAddBlock('custom')} />
              <SermonBlockCard type="media" onAddBlock={() => handleOnAddBlock('media')} />
            </Grid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
});
