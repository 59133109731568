import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const theMeWeGodYouWeMethod = {
  id: 'theMeWeGodYouWeMethod',
  title: 'The ME-WE-GOD-YOU-WE Method',
  description:
    "Created by Andy Stanley, this preaching outline uses a simple formula to communicate the text's big idea in a conversational manner.",
  sermonType: SermonType.SERMON,
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      type: 'point',
      title: 'ME',
      number: '1',
    },
    '1': {
      id: uuid(),
      type: 'custom',
      title: 'HOW DO I STRUGGLE WITH THIS?',
      content: 'Here, the communicator introduces a dilemma they have faced or are currently facing.',
    },
    '2': {
      id: uuid(),
      type: 'point',
      title: 'WE',
      number: '2',
    },
    '3': {
      id: uuid(),
      type: 'custom',
      title: 'HOW DO WE ALL STRUGGLE WITH THIS?',
      content: 'Find common ground with your audience around the same or a similar dilemma.',
    },
    '4': {
      id: uuid(),
      type: 'point',
      title: 'GOD',
      number: '3',
    },
    '5': {
      id: uuid(),
      type: 'custom',
      title: 'WHAT DOES THE BIBLE SAY ABOUT THIS?',
      content: 'Transition to the text to discover what God says about the tension or question you’ve introduced.',
    },
    '6': {
      id: uuid(),
      type: 'point',
      title: 'YOU',
      number: '4',
    },
    '7': {
      id: uuid(),
      type: 'custom',
      title: 'WHAT SHOULD YOU DO ABOUT THIS?',
      content: 'Challenge your audience to act on what they’ve just heard.',
    },
    '8': {
      id: uuid(),
      type: 'point',
      title: 'WE',
      number: '5',
    },
    '9': {
      id: uuid(),
      type: 'custom',
      title: 'HOW CAN WE ALL LIVE THIS OUT TOGETHER?',
      content:
        'Close with several statements about what could happen in your community, your church, or the world if everybody embraced that particular truth.',
    },
    '10': {
      id: uuid(),
      type: 'custom',
      color: 'transparent',
      title: 'CITATION',
      content:
        'Excerpt(s) from COMMUNICATING FOR A CHANGE: SEVEN KEYS TO IRRESISTIBLE COMMUNICATION by Andy Stanley and Ronald Lane Jones, copyright © 2006 by Andy Stanley and Ronald Lane Jones. Used by permission of WaterBrook Multnomah, an imprint of the Crown Publishing Group, a division of Penguin Random House LLC. All rights reserved.',
    },
  }),
};
