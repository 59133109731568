import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import deepEqual from 'fast-deep-equal';
import isEqual from 'lodash/isEqual';
import { FieldValues, FormState, UseFormReturn, useWatch } from 'react-hook-form';

type UseAutoSaveProps<T extends FieldValues> = {
  control: UseFormReturn<T>['control'];
  isDirty: FormState<T>['isDirty'];
  onSave: (values: T) => void;
};

export function useAutoSave<T extends FieldValues>({ control, isDirty, onSave }: UseAutoSaveProps<T>) {
  const [debouncedValues, setDebouncedValues] = useState<T>();

  const values = useWatch({
    control,
  });

  const debounce = useDebouncedCallback((newValues) => {
    if (isDirty && !deepEqual(newValues, debouncedValues)) {
      setDebouncedValues(newValues);
    }
  }, 2500);

  useEffect(() => {
    debounce.cancel();
    debounce(values);
  }, [debounce, values]);

  useEffect(() => {
    if (isEqual(values, debouncedValues)) {
      onSave(debouncedValues as T);
    }
  }, [debouncedValues]);
}
