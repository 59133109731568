import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { atom, useAtom } from 'jotai';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
} from '@chakra-ui/react';
import { Label } from '@sermonary/components';
import { useMutation } from '@sermonary/hooks';
import { DataStore } from 'aws-amplify';
import { track } from '../analytics/Analytics';
import { Sermon, SermonType } from '../models';
import {
  useCustomTemplateSelectionDrawer,
  useFavoriteTemplateSelectionDrawer,
  usePreBuiltTemplateSelectionDrawer,
} from '.';
import { isSermonSettingsDrawerOpenAtom, sermonAtom } from '../pages/sermons/SingleSermon';
import { providedSermonTemplateGroupsAtom } from 'src/libs/atoms';

export const createSermonDrawerStateAtom = atom<'open' | 'closed'>('closed');

interface CreateSermonDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

function CreateSermonDrawer({ isOpen, onClose }: CreateSermonDrawerProps) {
  const navigate = useNavigate();
  const [, setSermon] = useAtom(sermonAtom);
  const [, setSermonDrawerState] = useAtom(createSermonDrawerStateAtom);
  const [isSermonSettingsDrawerOpen, setIsSermonSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);
  const [providedSermonTemplateGroups] = useAtom(providedSermonTemplateGroupsAtom);

  const { handleOnOpen: handleFavoriteTemplateOnOpen } = useFavoriteTemplateSelectionDrawer();
  const { handleOnOpen: handleCustomTemplateOnOpen } = useCustomTemplateSelectionDrawer();
  const { handleOnOpen: handlePreBuiltTemplateOnOpen } = usePreBuiltTemplateSelectionDrawer();

  const { handler } = useMutation<Sermon>(async () => {
    try {
      const sermon = await DataStore.save(
        new Sermon({
          title: 'Untitled',
          sermonType: SermonType.SERMON,
        }),
      );

      await track('Sermon Created', { sermonId: sermon.id });

      setSermon(sermon);
      return sermon;
    } catch (err) {
      console.log(err);
      return null;
    }
  });

  const createNewSermon = async () => {
    setSermonDrawerState('closed');
    setIsSermonSettingsDrawerOpen(true);

    const { id: sermonId } = await handler({});

    navigate(`/sermons/${sermonId}`);
  };

  useEffect(() => setIsSermonSettingsDrawerOpen(false), []);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader mt="12" />

        <DrawerBody>
          <Box>
            <Label>Start writing</Label>
            <Text as="p" fontStyle="italic" color="gray.700" fontSize="sm">
              Use our block-based editor to begin writing your sermon from scratch.
            </Text>
            <Button mt="6" w="100%" colorScheme="blue" disabled={isSermonSettingsDrawerOpen} onClick={createNewSermon}>
              Open Editor
            </Button>
          </Box>
          <Box mt="6">
            <Label mb={0}>Use a template</Label>
            <Button mt="6" w="100%" colorScheme="grayButton" color="gray.900" onClick={handleFavoriteTemplateOnOpen}>
              Favorites
            </Button>
            <Button mt="6" w="100%" colorScheme="grayButton" color="gray.900" onClick={handleCustomTemplateOnOpen}>
              Custom Templates
            </Button>
            {Object.keys(providedSermonTemplateGroups).map((group) => {
              return (
                <Button
                  mt="6"
                  w="100%"
                  colorScheme="grayButton"
                  color="gray.900"
                  onClick={() => handlePreBuiltTemplateOnOpen(group)}
                  key={`template_${group}`}
                >
                  {providedSermonTemplateGroups[group].title}
                </Button>
              );
            })}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function useCreateSermonDrawer() {
  const [state, setState] = useAtom(createSermonDrawerStateAtom);
  const isOpen = state === 'open';

  const handleOnClose = useCallback(() => {
    setState('closed');
  }, [setState]);

  return {
    isOpen,
    handleOnOpen: () => {
      setState('open');
    },
    handleOnClose,
    handleOnToggle: () => {
      if (state === 'closed') {
        setState('open');
      } else {
        setState('closed');
      }
    },

    CreateSermonDrawer: useCallback(
      () => (isOpen ? <CreateSermonDrawer isOpen={isOpen} onClose={handleOnClose} /> : null),
      [handleOnClose, isOpen],
    ),
  };
}
