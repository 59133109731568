/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _FieldSet: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: any;
  join__FieldSet: any;
};

export type Attachable = File | Sermon | SermonSeries;

export enum AttachableEnum {
  file = 'file',
  sermon = 'sermon',
  sermonSeries = 'sermonSeries',
}

export type Attachment = {
  __typename?: 'Attachment';
  attachable?: Maybe<Attachable>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resource: Resource;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count?: Maybe<Scalars['Int']>;
};

export type Consumer = {
  __typename?: 'Consumer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateAttachmentInput = {
  attachableId: Scalars['String'];
  attachableType?: InputMaybe<AttachableEnum>;
  description?: InputMaybe<Scalars['String']>;
  resourceId: Scalars['String'];
  title: Scalars['String'];
};

export type CreateFacetInput = {
  description?: InputMaybe<Scalars['String']>;
  facetTypeId: Scalars['String'];
  name: Scalars['String'];
  resources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug: Scalars['String'];
};

export type CreateFacetTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
};

export type CreateFileInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byteSize?: InputMaybe<Scalars['UnsignedInt']>;
  contentType?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  path: Scalars['String'];
};

export type CreateIllustrationInput = {
  content?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image?: InputMaybe<Scalars['URL']>;
  legacyCreatedAt?: InputMaybe<Scalars['DateTime']>;
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  slug: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateResourceInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredImage?: InputMaybe<Scalars['URL']>;
  initialFreeResource?: InputMaybe<Scalars['Boolean']>;
  legacyCreatedAt?: InputMaybe<Scalars['DateTime']>;
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  resourceType?: InputMaybe<ResourceType>;
  seriesLength?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  whatsIncluded?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateSermonInput = {
  attachableType?: InputMaybe<Scalars['String']>;
  bigIdea?: InputMaybe<Scalars['String']>;
  blocks?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  legacySeriesId?: InputMaybe<Scalars['UnsignedInt']>;
  order?: InputMaybe<Scalars['UnsignedInt']>;
  referenceVerses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seriesId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateSermonSeriesInput = {
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  sermons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type DeleteAttachmentInput = {
  id: Scalars['ID'];
};

export type DeleteFacetInput = {
  id: Scalars['ID'];
};

export type DeleteFacetTypeInput = {
  id: Scalars['ID'];
};

export type DeleteFileInput = {
  id: Scalars['ID'];
};

export type DeleteIllustrationInput = {
  id: Scalars['ID'];
};

export type DeleteResourceInput = {
  id: Scalars['ID'];
};

export type DeleteSermonInput = {
  id: Scalars['ID'];
};

export type DeleteSermonSeriesInput = {
  id: Scalars['ID'];
};

export type Facet = {
  __typename?: 'Facet';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  facetType: FacetType;
  featured?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resources?: Maybe<Array<Maybe<Resource>>>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FacetType = {
  __typename?: 'FacetType';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  facets?: Maybe<Array<Maybe<Facet>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type File = {
  __typename?: 'File';
  attachableType?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  byteSize?: Maybe<Scalars['UnsignedInt']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FileDownloadLink = {
  __typename?: 'FileDownloadLink';
  url: Scalars['String'];
};

export type FilterIllustrationInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type FilterResourceInput = {
  audienceFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bookCategoryFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  favoritesFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularTopicsFilter?: InputMaybe<Scalars['String']>;
  resourceTypeFilter?: InputMaybe<Scalars['String']>;
  sermonSeriesLengthFilter?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tierFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Illustration = {
  __typename?: 'Illustration';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  facets?: Maybe<Array<Maybe<Facet>>>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['URL']>;
  legacyCreatedAt?: Maybe<Scalars['DateTime']>;
  legacyId?: Maybe<Scalars['UnsignedInt']>;
  slug: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type IllustrationInput = {
  cursor?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type IllustrationResponse = {
  __typename?: 'IllustrationResponse';
  illustrationCount?: Maybe<Scalars['Int']>;
  illustrationList?: Maybe<Array<Maybe<Illustration>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createAttachment?: Maybe<Attachment>;
  createFacet?: Maybe<Facet>;
  createFacetType?: Maybe<FacetType>;
  createFile?: Maybe<File>;
  createIllustration?: Maybe<Illustration>;
  createResource?: Maybe<Resource>;
  createSermon?: Maybe<Sermon>;
  createSermonSeries?: Maybe<SermonSeries>;
  deleteAttachment?: Maybe<Attachment>;
  deleteFacet?: Maybe<Facet>;
  deleteFacetType?: Maybe<FacetType>;
  deleteFile?: Maybe<File>;
  deleteIllustration?: Maybe<Illustration>;
  deleteResource?: Maybe<Resource>;
  deleteSermon?: Maybe<Sermon>;
  deleteSermonSeries?: Maybe<SermonSeries>;
  setFeaturedFacets?: Maybe<Array<Maybe<BatchPayload>>>;
  updateAttachment?: Maybe<Attachment>;
  updateFacet?: Maybe<Facet>;
  updateFacetType?: Maybe<FacetType>;
  updateFile?: Maybe<File>;
  updateIllustration?: Maybe<Illustration>;
  updateResource?: Maybe<Resource>;
  updateSermon?: Maybe<Sermon>;
  updateSermonSeries?: Maybe<SermonSeries>;
};

export type MutationCreateAttachmentArgs = {
  input: CreateAttachmentInput;
};

export type MutationCreateFacetArgs = {
  input: CreateFacetInput;
};

export type MutationCreateFacetTypeArgs = {
  input: CreateFacetTypeInput;
};

export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

export type MutationCreateIllustrationArgs = {
  input: CreateIllustrationInput;
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateSermonArgs = {
  input: CreateSermonInput;
};

export type MutationCreateSermonSeriesArgs = {
  input: CreateSermonSeriesInput;
};

export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
};

export type MutationDeleteFacetArgs = {
  input: DeleteFacetInput;
};

export type MutationDeleteFacetTypeArgs = {
  input: DeleteFacetTypeInput;
};

export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};

export type MutationDeleteIllustrationArgs = {
  input: DeleteIllustrationInput;
};

export type MutationDeleteResourceArgs = {
  input: DeleteResourceInput;
};

export type MutationDeleteSermonArgs = {
  input: DeleteSermonInput;
};

export type MutationDeleteSermonSeriesArgs = {
  input: DeleteSermonSeriesInput;
};

export type MutationSetFeaturedFacetsArgs = {
  input: SetFeaturedFacetsInput;
};

export type MutationUpdateAttachmentArgs = {
  input: UpdateAttachmentInput;
};

export type MutationUpdateFacetArgs = {
  input: UpdateFacetInput;
};

export type MutationUpdateFacetTypeArgs = {
  input: UpdateFacetTypeInput;
};

export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};

export type MutationUpdateIllustrationArgs = {
  input: UpdateIllustrationInput;
};

export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type MutationUpdateSermonArgs = {
  input: UpdateSermonInput;
};

export type MutationUpdateSermonSeriesArgs = {
  input: UpdateSermonSeriesInput;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  attachment?: Maybe<Attachment>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  consumer?: Maybe<Consumer>;
  consumers?: Maybe<Array<Maybe<Consumer>>>;
  facet?: Maybe<Facet>;
  facetType?: Maybe<FacetType>;
  facetTypes?: Maybe<Array<Maybe<FacetType>>>;
  facets?: Maybe<Array<Maybe<Facet>>>;
  file?: Maybe<File>;
  files?: Maybe<Array<Maybe<File>>>;
  getDownloadLink?: Maybe<FileDownloadLink>;
  getFeaturedTopics?: Maybe<Array<Maybe<Facet>>>;
  illustration?: Maybe<Illustration>;
  illustrations?: Maybe<IllustrationResponse>;
  initialUserResources?: Maybe<Array<Maybe<Resource>>>;
  resource?: Maybe<Resource>;
  resources?: Maybe<ResourceResponse>;
  sermon?: Maybe<Sermon>;
  sermonSeries?: Maybe<SermonSeries>;
  sermonSerieses?: Maybe<Array<Maybe<SermonSeries>>>;
  sermons?: Maybe<Array<Maybe<Sermon>>>;
};

export type QueryAttachmentArgs = {
  id: Scalars['ID'];
};

export type QueryConsumerArgs = {
  id: Scalars['ID'];
};

export type QueryFacetArgs = {
  id: Scalars['ID'];
};

export type QueryFacetTypeArgs = {
  id: Scalars['ID'];
};

export type QueryFileArgs = {
  id: Scalars['ID'];
};

export type QueryGetDownloadLinkArgs = {
  id: Scalars['ID'];
};

export type QueryIllustrationArgs = {
  id: Scalars['ID'];
};

export type QueryIllustrationsArgs = {
  input?: InputMaybe<IllustrationInput>;
};

export type QueryResourceArgs = {
  id: Scalars['ID'];
};

export type QueryResourcesArgs = {
  input: ResourceInput;
};

export type QuerySermonArgs = {
  id: Scalars['ID'];
};

export type QuerySermonSeriesArgs = {
  id: Scalars['ID'];
};

export type Resource = {
  __typename?: 'Resource';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  authorized?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  facets?: Maybe<Array<Maybe<Facet>>>;
  featuredImage?: Maybe<Scalars['URL']>;
  groupsWithAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  initialFreeResource?: Maybe<Scalars['Boolean']>;
  legacyCreatedAt?: Maybe<Scalars['DateTime']>;
  legacyId?: Maybe<Scalars['UnsignedInt']>;
  resourceType: ResourceType;
  seriesLength?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  whatsIncluded?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResourceInput = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterResourceInput>;
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ResourceResponse = {
  __typename?: 'ResourceResponse';
  resourceCount?: Maybe<Scalars['Int']>;
  resourceList?: Maybe<Array<Maybe<Resource>>>;
};

export enum ResourceType {
  COMMENTARY = 'COMMENTARY',
  OTHER = 'OTHER',
  SERIES = 'SERIES',
  SERMON = 'SERMON',
  SUMMARY = 'SUMMARY',
  TEMPLATE = 'TEMPLATE',
}

export type Sermon = {
  __typename?: 'Sermon';
  attachableType?: Maybe<Scalars['String']>;
  bigIdea?: Maybe<Scalars['String']>;
  blocks?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['UnsignedInt']>;
  legacySeriesId?: Maybe<Scalars['UnsignedInt']>;
  order?: Maybe<Scalars['UnsignedInt']>;
  referenceVerses?: Maybe<Array<Maybe<Scalars['String']>>>;
  series?: Maybe<SermonSeries>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SermonSeries = {
  __typename?: 'SermonSeries';
  attachableType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['UnsignedInt']>;
  sermons?: Maybe<Array<Maybe<Sermon>>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SetFeaturedFacetsInput = {
  featuredFacets: Array<Scalars['String']>;
};

export type UpdateAttachmentInput = {
  attachableId: Scalars['String'];
  attachableType?: InputMaybe<AttachableEnum>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceId: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateFacetInput = {
  description?: InputMaybe<Scalars['String']>;
  facetTypeId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  resources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug: Scalars['String'];
};

export type UpdateFacetTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateFileInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byteSize?: InputMaybe<Scalars['UnsignedInt']>;
  contentType?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
  path: Scalars['String'];
};

export type UpdateIllustrationInput = {
  content?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  image?: InputMaybe<Scalars['URL']>;
  legacyCreatedAt?: InputMaybe<Scalars['DateTime']>;
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateResourceInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredImage?: InputMaybe<Scalars['URL']>;
  id: Scalars['ID'];
  initialFreeResource?: InputMaybe<Scalars['Boolean']>;
  legacyCreatedAt?: InputMaybe<Scalars['DateTime']>;
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  resourceType?: InputMaybe<ResourceType>;
  seriesLength?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  whatsIncluded?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateSermonInput = {
  attachableType?: InputMaybe<Scalars['String']>;
  bigIdea?: InputMaybe<Scalars['String']>;
  blocks?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  legacySeriesId?: InputMaybe<Scalars['UnsignedInt']>;
  order?: InputMaybe<Scalars['UnsignedInt']>;
  referenceVerses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seriesId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateSermonSeriesInput = {
  id: Scalars['ID'];
  legacyId?: InputMaybe<Scalars['UnsignedInt']>;
  sermons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export enum Join__Graph {
  AUTH = 'AUTH',
  ILLUSTRATIONS = 'ILLUSTRATIONS',
  RESOURCES = 'RESOURCES',
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Attachable: ResolversTypes['File'] | ResolversTypes['Sermon'] | ResolversTypes['SermonSeries'];
  AttachableEnum: AttachableEnum;
  Attachment: ResolverTypeWrapper<
    Omit<Attachment, 'attachable'> & { attachable?: Maybe<ResolversTypes['Attachable']> }
  >;
  String: ResolverTypeWrapper<Scalars['String']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  BatchPayload: ResolverTypeWrapper<BatchPayload>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Consumer: ResolverTypeWrapper<Consumer>;
  CreateAttachmentInput: CreateAttachmentInput;
  CreateFacetInput: CreateFacetInput;
  CreateFacetTypeInput: CreateFacetTypeInput;
  CreateFileInput: CreateFileInput;
  CreateIllustrationInput: CreateIllustrationInput;
  CreateResourceInput: CreateResourceInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CreateSermonInput: CreateSermonInput;
  CreateSermonSeriesInput: CreateSermonSeriesInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteAttachmentInput: DeleteAttachmentInput;
  DeleteFacetInput: DeleteFacetInput;
  DeleteFacetTypeInput: DeleteFacetTypeInput;
  DeleteFileInput: DeleteFileInput;
  DeleteIllustrationInput: DeleteIllustrationInput;
  DeleteResourceInput: DeleteResourceInput;
  DeleteSermonInput: DeleteSermonInput;
  DeleteSermonSeriesInput: DeleteSermonSeriesInput;
  Facet: ResolverTypeWrapper<Facet>;
  FacetType: ResolverTypeWrapper<FacetType>;
  File: ResolverTypeWrapper<File>;
  FileDownloadLink: ResolverTypeWrapper<FileDownloadLink>;
  FilterIllustrationInput: FilterIllustrationInput;
  FilterResourceInput: FilterResourceInput;
  Illustration: ResolverTypeWrapper<Illustration>;
  IllustrationInput: IllustrationInput;
  IllustrationResponse: ResolverTypeWrapper<IllustrationResponse>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Mutation: ResolverTypeWrapper<{}>;
  Query: ResolverTypeWrapper<{}>;
  Resource: ResolverTypeWrapper<Resource>;
  ResourceInput: ResourceInput;
  ResourceResponse: ResolverTypeWrapper<ResourceResponse>;
  ResourceType: ResourceType;
  Sermon: ResolverTypeWrapper<Sermon>;
  SermonSeries: ResolverTypeWrapper<SermonSeries>;
  SetFeaturedFacetsInput: SetFeaturedFacetsInput;
  URL: ResolverTypeWrapper<Scalars['URL']>;
  UnsignedInt: ResolverTypeWrapper<Scalars['UnsignedInt']>;
  UpdateAttachmentInput: UpdateAttachmentInput;
  UpdateFacetInput: UpdateFacetInput;
  UpdateFacetTypeInput: UpdateFacetTypeInput;
  UpdateFileInput: UpdateFileInput;
  UpdateIllustrationInput: UpdateIllustrationInput;
  UpdateResourceInput: UpdateResourceInput;
  UpdateSermonInput: UpdateSermonInput;
  UpdateSermonSeriesInput: UpdateSermonSeriesInput;
  join__FieldSet: ResolverTypeWrapper<Scalars['join__FieldSet']>;
  join__Graph: Join__Graph;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Attachable: ResolversParentTypes['File'] | ResolversParentTypes['Sermon'] | ResolversParentTypes['SermonSeries'];
  Attachment: Omit<Attachment, 'attachable'> & { attachable?: Maybe<ResolversParentTypes['Attachable']> };
  String: Scalars['String'];
  ID: Scalars['ID'];
  BatchPayload: BatchPayload;
  Int: Scalars['Int'];
  Consumer: Consumer;
  CreateAttachmentInput: CreateAttachmentInput;
  CreateFacetInput: CreateFacetInput;
  CreateFacetTypeInput: CreateFacetTypeInput;
  CreateFileInput: CreateFileInput;
  CreateIllustrationInput: CreateIllustrationInput;
  CreateResourceInput: CreateResourceInput;
  Boolean: Scalars['Boolean'];
  CreateSermonInput: CreateSermonInput;
  CreateSermonSeriesInput: CreateSermonSeriesInput;
  DateTime: Scalars['DateTime'];
  DeleteAttachmentInput: DeleteAttachmentInput;
  DeleteFacetInput: DeleteFacetInput;
  DeleteFacetTypeInput: DeleteFacetTypeInput;
  DeleteFileInput: DeleteFileInput;
  DeleteIllustrationInput: DeleteIllustrationInput;
  DeleteResourceInput: DeleteResourceInput;
  DeleteSermonInput: DeleteSermonInput;
  DeleteSermonSeriesInput: DeleteSermonSeriesInput;
  Facet: Facet;
  FacetType: FacetType;
  File: File;
  FileDownloadLink: FileDownloadLink;
  FilterIllustrationInput: FilterIllustrationInput;
  FilterResourceInput: FilterResourceInput;
  Illustration: Illustration;
  IllustrationInput: IllustrationInput;
  IllustrationResponse: IllustrationResponse;
  JSON: Scalars['JSON'];
  Mutation: {};
  Query: {};
  Resource: Resource;
  ResourceInput: ResourceInput;
  ResourceResponse: ResourceResponse;
  Sermon: Sermon;
  SermonSeries: SermonSeries;
  SetFeaturedFacetsInput: SetFeaturedFacetsInput;
  URL: Scalars['URL'];
  UnsignedInt: Scalars['UnsignedInt'];
  UpdateAttachmentInput: UpdateAttachmentInput;
  UpdateFacetInput: UpdateFacetInput;
  UpdateFacetTypeInput: UpdateFacetTypeInput;
  UpdateFileInput: UpdateFileInput;
  UpdateIllustrationInput: UpdateIllustrationInput;
  UpdateResourceInput: UpdateResourceInput;
  UpdateSermonInput: UpdateSermonInput;
  UpdateSermonSeriesInput: UpdateSermonSeriesInput;
  join__FieldSet: Scalars['join__FieldSet'];
};

export type Apollo_Studio_MetadataDirectiveArgs = {
  buildId?: Maybe<Scalars['String']>;
  checkId?: Maybe<Scalars['String']>;
  launchId?: Maybe<Scalars['String']>;
};

export type Apollo_Studio_MetadataDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Apollo_Studio_MetadataDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CoreDirectiveArgs = {
  feature: Scalars['String'];
};

export type CoreDirectiveResolver<Result, Parent, ContextType = any, Args = CoreDirectiveArgs> = DirectiveResolverFn<
  Result,
  Parent,
  ContextType,
  Args
>;

export type Join__FieldDirectiveArgs = {
  graph?: Maybe<Join__Graph>;
  provides?: Maybe<Scalars['join__FieldSet']>;
  requires?: Maybe<Scalars['join__FieldSet']>;
};

export type Join__FieldDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Join__FieldDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Join__GraphDirectiveArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type Join__GraphDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Join__GraphDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Join__OwnerDirectiveArgs = {
  graph: Join__Graph;
};

export type Join__OwnerDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Join__OwnerDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Join__TypeDirectiveArgs = {
  graph: Join__Graph;
  key?: Maybe<Scalars['join__FieldSet']>;
};

export type Join__TypeDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Join__TypeDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AttachableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Attachable'] = ResolversParentTypes['Attachable'],
> = {
  __resolveType: TypeResolveFn<'File' | 'Sermon' | 'SermonSeries', ParentType, ContextType>;
};

export type AttachmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment'],
> = {
  attachable?: Resolver<Maybe<ResolversTypes['Attachable']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['Resource'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BatchPayload'] = ResolversParentTypes['BatchPayload'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Consumer'] = ResolversParentTypes['Consumer'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type FacetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Facet'] = ResolversParentTypes['Facet'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facetType?: Resolver<ResolversTypes['FacetType'], ParentType, ContextType>;
  featured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resources?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resource']>>>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacetTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacetType'] = ResolversParentTypes['FacetType'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Facet']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File'],
> = {
  attachableType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType>;
  byteSize?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileDownloadLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FileDownloadLink'] = ResolversParentTypes['FileDownloadLink'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Illustration'] = ResolversParentTypes['Illustration'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  facets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Facet']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  legacyCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationResponse'] = ResolversParentTypes['IllustrationResponse'],
> = {
  illustrationCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  illustrationList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Illustration']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createAttachment?: Resolver<
    Maybe<ResolversTypes['Attachment']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttachmentArgs, 'input'>
  >;
  createFacet?: Resolver<
    Maybe<ResolversTypes['Facet']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFacetArgs, 'input'>
  >;
  createFacetType?: Resolver<
    Maybe<ResolversTypes['FacetType']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFacetTypeArgs, 'input'>
  >;
  createFile?: Resolver<
    Maybe<ResolversTypes['File']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileArgs, 'input'>
  >;
  createIllustration?: Resolver<
    Maybe<ResolversTypes['Illustration']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateIllustrationArgs, 'input'>
  >;
  createResource?: Resolver<
    Maybe<ResolversTypes['Resource']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateResourceArgs, 'input'>
  >;
  createSermon?: Resolver<
    Maybe<ResolversTypes['Sermon']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSermonArgs, 'input'>
  >;
  createSermonSeries?: Resolver<
    Maybe<ResolversTypes['SermonSeries']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSermonSeriesArgs, 'input'>
  >;
  deleteAttachment?: Resolver<
    Maybe<ResolversTypes['Attachment']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttachmentArgs, 'input'>
  >;
  deleteFacet?: Resolver<
    Maybe<ResolversTypes['Facet']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFacetArgs, 'input'>
  >;
  deleteFacetType?: Resolver<
    Maybe<ResolversTypes['FacetType']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFacetTypeArgs, 'input'>
  >;
  deleteFile?: Resolver<
    Maybe<ResolversTypes['File']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileArgs, 'input'>
  >;
  deleteIllustration?: Resolver<
    Maybe<ResolversTypes['Illustration']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteIllustrationArgs, 'input'>
  >;
  deleteResource?: Resolver<
    Maybe<ResolversTypes['Resource']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteResourceArgs, 'input'>
  >;
  deleteSermon?: Resolver<
    Maybe<ResolversTypes['Sermon']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSermonArgs, 'input'>
  >;
  deleteSermonSeries?: Resolver<
    Maybe<ResolversTypes['SermonSeries']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSermonSeriesArgs, 'input'>
  >;
  setFeaturedFacets?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BatchPayload']>>>,
    ParentType,
    ContextType,
    RequireFields<MutationSetFeaturedFacetsArgs, 'input'>
  >;
  updateAttachment?: Resolver<
    Maybe<ResolversTypes['Attachment']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttachmentArgs, 'input'>
  >;
  updateFacet?: Resolver<
    Maybe<ResolversTypes['Facet']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFacetArgs, 'input'>
  >;
  updateFacetType?: Resolver<
    Maybe<ResolversTypes['FacetType']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFacetTypeArgs, 'input'>
  >;
  updateFile?: Resolver<
    Maybe<ResolversTypes['File']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileArgs, 'input'>
  >;
  updateIllustration?: Resolver<
    Maybe<ResolversTypes['Illustration']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateIllustrationArgs, 'input'>
  >;
  updateResource?: Resolver<
    Maybe<ResolversTypes['Resource']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateResourceArgs, 'input'>
  >;
  updateSermon?: Resolver<
    Maybe<ResolversTypes['Sermon']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSermonArgs, 'input'>
  >;
  updateSermonSeries?: Resolver<
    Maybe<ResolversTypes['SermonSeries']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSermonSeriesArgs, 'input'>
  >;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attachment?: Resolver<
    Maybe<ResolversTypes['Attachment']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttachmentArgs, 'id'>
  >;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType>;
  consumer?: Resolver<
    Maybe<ResolversTypes['Consumer']>,
    ParentType,
    ContextType,
    RequireFields<QueryConsumerArgs, 'id'>
  >;
  consumers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Consumer']>>>, ParentType, ContextType>;
  facet?: Resolver<Maybe<ResolversTypes['Facet']>, ParentType, ContextType, RequireFields<QueryFacetArgs, 'id'>>;
  facetType?: Resolver<
    Maybe<ResolversTypes['FacetType']>,
    ParentType,
    ContextType,
    RequireFields<QueryFacetTypeArgs, 'id'>
  >;
  facetTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['FacetType']>>>, ParentType, ContextType>;
  facets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Facet']>>>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType, RequireFields<QueryFileArgs, 'id'>>;
  files?: Resolver<Maybe<Array<Maybe<ResolversTypes['File']>>>, ParentType, ContextType>;
  getDownloadLink?: Resolver<
    Maybe<ResolversTypes['FileDownloadLink']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDownloadLinkArgs, 'id'>
  >;
  getFeaturedTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['Facet']>>>, ParentType, ContextType>;
  illustration?: Resolver<
    Maybe<ResolversTypes['Illustration']>,
    ParentType,
    ContextType,
    RequireFields<QueryIllustrationArgs, 'id'>
  >;
  illustrations?: Resolver<
    Maybe<ResolversTypes['IllustrationResponse']>,
    ParentType,
    ContextType,
    Partial<QueryIllustrationsArgs>
  >;
  initialUserResources?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resource']>>>, ParentType, ContextType>;
  resource?: Resolver<
    Maybe<ResolversTypes['Resource']>,
    ParentType,
    ContextType,
    RequireFields<QueryResourceArgs, 'id'>
  >;
  resources?: Resolver<
    Maybe<ResolversTypes['ResourceResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryResourcesArgs, 'input'>
  >;
  sermon?: Resolver<Maybe<ResolversTypes['Sermon']>, ParentType, ContextType, RequireFields<QuerySermonArgs, 'id'>>;
  sermonSeries?: Resolver<
    Maybe<ResolversTypes['SermonSeries']>,
    ParentType,
    ContextType,
    RequireFields<QuerySermonSeriesArgs, 'id'>
  >;
  sermonSerieses?: Resolver<Maybe<Array<Maybe<ResolversTypes['SermonSeries']>>>, ParentType, ContextType>;
  sermons?: Resolver<Maybe<Array<Maybe<ResolversTypes['Sermon']>>>, ParentType, ContextType>;
};

export type ResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Resource'] = ResolversParentTypes['Resource'],
> = {
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType>;
  authorized?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Facet']>>>, ParentType, ContextType>;
  featuredImage?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  groupsWithAccess?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  initialFreeResource?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  legacyCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  resourceType?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType>;
  seriesLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  whatsIncluded?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceResponse'] = ResolversParentTypes['ResourceResponse'],
> = {
  resourceCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  resourceList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resource']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SermonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Sermon'] = ResolversParentTypes['Sermon'],
> = {
  attachableType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bigIdea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blocks?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  legacySeriesId?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  referenceVerses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['SermonSeries']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SermonSeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SermonSeries'] = ResolversParentTypes['SermonSeries'],
> = {
  attachableType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['UnsignedInt']>, ParentType, ContextType>;
  sermons?: Resolver<Maybe<Array<Maybe<ResolversTypes['Sermon']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export interface UnsignedIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedInt'], any> {
  name: 'UnsignedInt';
}

export interface Join__FieldSetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['join__FieldSet'], any> {
  name: 'join__FieldSet';
}

export type Resolvers<ContextType = any> = {
  Attachable?: AttachableResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  BatchPayload?: BatchPayloadResolvers<ContextType>;
  Consumer?: ConsumerResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Facet?: FacetResolvers<ContextType>;
  FacetType?: FacetTypeResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  FileDownloadLink?: FileDownloadLinkResolvers<ContextType>;
  Illustration?: IllustrationResolvers<ContextType>;
  IllustrationResponse?: IllustrationResponseResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Resource?: ResourceResolvers<ContextType>;
  ResourceResponse?: ResourceResponseResolvers<ContextType>;
  Sermon?: SermonResolvers<ContextType>;
  SermonSeries?: SermonSeriesResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UnsignedInt?: GraphQLScalarType;
  join__FieldSet?: GraphQLScalarType;
};

export type DirectiveResolvers<ContextType = any> = {
  apollo_studio_metadata?: Apollo_Studio_MetadataDirectiveResolver<any, any, ContextType>;
  core?: CoreDirectiveResolver<any, any, ContextType>;
  join__field?: Join__FieldDirectiveResolver<any, any, ContextType>;
  join__graph?: Join__GraphDirectiveResolver<any, any, ContextType>;
  join__owner?: Join__OwnerDirectiveResolver<any, any, ContextType>;
  join__type?: Join__TypeDirectiveResolver<any, any, ContextType>;
};

export const GetDownloadLink = gql`
  query getDownloadLink($fileId: ID!) {
    getDownloadLink(id: $fileId) {
      url
    }
  }
`;
export const GetFeaturedTopicsQuery = gql`
  query getFeaturedTopicsQuery {
    getFeaturedTopics {
      id
      name
      slug
      facetType {
        name
      }
    }
  }
`;
export const GetFilteredResources = gql`
  query GetFilteredResources($input: ResourceInput!) {
    resources(input: $input) {
      resourceCount
      resourceList {
        id
        title
        slug
        shortDescription
        content
        resourceType
        seriesLength
        featuredImage
        groupsWithAccess
        authorized
      }
    }
  }
`;
export const InitialUserResources = gql`
  query initialUserResources {
    initialUserResources {
      id
      title
      slug
      description
      shortDescription
      whatsIncluded
      content
      resourceType
      seriesLength
      featuredImage
      groupsWithAccess
      authorized
      attachments {
        id
        title
        attachable {
          ... on File {
            id
            byteSize
            contentType
            filename
            path
            key
            attachableType
          }
          ... on Sermon {
            id
            bigIdea
            description
            title
            referenceVerses
            blocks
            attachableType
          }
          ... on SermonSeries {
            id
            title
            sermons {
              id
              bigIdea
              description
              title
              referenceVerses
              blocks
            }
            attachableType
          }
        }
      }
      facets {
        id
        name
        facetType {
          id
          name
        }
      }
    }
  }
`;
export const ResourceQuery = gql`
  query resourceQuery($id: ID!) {
    resource(id: $id) {
      id
      title
      slug
      description
      shortDescription
      whatsIncluded
      content
      resourceType
      seriesLength
      featuredImage
      groupsWithAccess
      authorized
      attachments {
        id
        title
        attachable {
          ... on File {
            id
            byteSize
            contentType
            filename
            path
            key
            attachableType
          }
          ... on Sermon {
            id
            bigIdea
            description
            title
            referenceVerses
            blocks
            attachableType
          }
          ... on SermonSeries {
            id
            title
            sermons {
              id
              bigIdea
              description
              title
              referenceVerses
              blocks
            }
            attachableType
          }
        }
      }
      facets {
        id
        name
        slug
        facetType {
          id
          name
        }
      }
    }
  }
`;
