import { Icon, IconProps } from '@chakra-ui/react';

export function IdeasIcon({ height = '6', width = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fill="currentColor"
        d="M18.572 2.706c1.742 1.74 2.58 4.014 2.561 6.543-.02 2.522-.662 4.495-2.416 6.936l-.187.257-.356.475c-.693.898-1.263 1.488-1.78 1.808l-.018.013c-.036.026-.073.05-.111.073.01.062.015.127.015.193v1.197c0 .361-.14.69-.368.936a1.325 1.325 0 01-.114.129l-2.335 2.332c-.398.397-.98.5-1.473.308a1.376 1.376 0 01-1.472-.308l-2.335-2.332a1.368 1.368 0 01-.402-1.021.54.54 0 01-.001-.044v-1.197c0-.048.002-.095.007-.142a2.154 2.154 0 01-.196-.112c-.554-.353-1.162-1.03-1.912-2.081l-.356-.513c-1.93-2.834-2.434-4.35-2.456-6.901-.022-2.495.86-4.792 2.62-6.549 3.614-3.608 9.472-3.608 13.085 0zM13.53 19.002h-3v.723l.783.782h1.356l.861-.86v-.645zM7.432 4.648C6.21 5.868 5.602 7.452 5.617 9.23c.016 1.838.297 2.825 1.625 4.852l.354.529.276.396.255.352.232.305.255.314.213.238.037.039h6.246l.091-.083.223-.238.266-.311.241-.302.13-.168.348-.468.074-.102c1.422-1.98 1.885-3.401 1.9-5.356.014-1.826-.562-3.389-1.755-4.58a6.51 6.51 0 00-9.196 0zm5.598-.49c2.05 0 4.125 1.92 4.125 4.118a1.374 1.374 0 01-2.744.132l-.006-.132c0-.597-.707-1.286-1.271-1.365l-.104-.008a1.374 1.374 0 110-2.746z"
      />
    </Icon>
  );
}

export default IdeasIcon;
