import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Image, Box, Spinner, ImageProps } from '@chakra-ui/react';

type StoredImageProps = ImageProps & {
  imageKey: string;
  legacyImage?: string;
  isDownloadable?: boolean;
};

export function StoredImage({ isDownloadable, imageKey, legacyImage, ...props }: StoredImageProps) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [imageSrc, setImageSrc] = useState<Object | string>('');

  useEffect(() => {
    async function getImageSrc() {
      try {
        const result = await Storage.get(imageKey);

        if (result) {
          setImageSrc(result);
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }

    if (imageKey) {
      getImageSrc();
    } else if (legacyImage) {
      setImageSrc(legacyImage);
    }
  }, [imageKey, legacyImage]);

  if (!imageKey && !legacyImage) {
    return null;
  }

  return (
    <Box>
      <Image
        src={imageSrc as string}
        fallback={
          <Box textAlign={'center'}>
            <Spinner color="blue.500" size="lg" />
          </Box>
        }
        margin={'auto'}
        borderRadius="md"
        maxH={'500px'}
        {...props}
      />
    </Box>
  );
}
