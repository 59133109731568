/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../types';

const defaultOptions = {} as const;
export type GetFilteredResourcesQueryVariables = Types.Exact<{
  input: Types.ResourceInput;
}>;

export type GetFilteredResourcesQuery = {
  __typename?: 'Query';
  resources?: {
    __typename?: 'ResourceResponse';
    resourceCount?: number | null;
    resourceList?: Array<{
      __typename?: 'Resource';
      id: string;
      title: string;
      slug: string;
      shortDescription?: string | null;
      content?: string | null;
      resourceType: Types.ResourceType;
      seriesLength?: number | null;
      featuredImage?: any | null;
      groupsWithAccess?: Array<string | null> | null;
      authorized?: boolean | null;
      updatedAt: any;
    } | null> | null;
  } | null;
};

export const GetFilteredResourcesDocument = gql`
  query getFilteredResources($input: ResourceInput!) {
    resources(input: $input) {
      resourceCount
      resourceList {
        id
        title
        slug
        shortDescription
        content
        resourceType
        seriesLength
        featuredImage
        groupsWithAccess
        authorized
        updatedAt
      }
    }
  }
`;

/**
 * __useGetFilteredResourcesQuery__
 *
 * To run a query within a React component, call `useGetFilteredResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFilteredResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFilteredResourcesQuery, GetFilteredResourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilteredResourcesQuery, GetFilteredResourcesQueryVariables>(
    GetFilteredResourcesDocument,
    options,
  );
}
export function useGetFilteredResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredResourcesQuery, GetFilteredResourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilteredResourcesQuery, GetFilteredResourcesQueryVariables>(
    GetFilteredResourcesDocument,
    options,
  );
}
export type GetFilteredResourcesQueryHookResult = ReturnType<typeof useGetFilteredResourcesQuery>;
export type GetFilteredResourcesLazyQueryHookResult = ReturnType<typeof useGetFilteredResourcesLazyQuery>;
export type GetFilteredResourcesQueryResult = Apollo.QueryResult<
  GetFilteredResourcesQuery,
  GetFilteredResourcesQueryVariables
>;
