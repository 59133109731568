import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { MutableRefObject } from 'react';
import pptxgen from 'pptxgenjs';
import JSZip from 'jszip';

import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

async function convertHtmlToDocx(isAuthenticated: boolean, targetRef: MutableRefObject<HTMLDivElement>, title: string) {
  const exportToDocx = `
    query exportToDocx($input: String!) {
      exportToDocx(input: $input) {
        jsonBuffer
      }
    }
  `;

  const html = `<!DOCTYPE html>
    <html lang="en">
      <head>
          <meta charset="UTF-8" />
          <title>${title}</title>
      </head>
      <body>
        ${targetRef.current.innerHTML}
      </body>
    </html>`;

  async function getResponseByAuthLevel() {
    if (isAuthenticated) {
      return await API.graphql<any>(
        graphqlOperation(exportToDocx, {
          input: html,
        }),
      );
    } else {
      return await API.graphql<any>({
        query: exportToDocx,
        variables: { input: html },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      });
    }
  }

  const response = await getResponseByAuthLevel();

  const input = JSON.parse(response.data.exportToDocx.jsonBuffer);

  const uint8 = new Uint8Array(input);
  const blob = new Blob([uint8], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  return blob;
}

async function generatePdfImage(targetRef: MutableRefObject<HTMLDivElement>) {
  const width = targetRef.current.offsetWidth;
  const height = targetRef.current.offsetHeight;
  const canvasElement = await html2canvas(targetRef.current, {
    logging: false,
    useCORS: true,
  });

  const imgData = canvasElement.toDataURL('image/png', 1);
  const pdf = new JsPdf('p', 'px', [width, height], true);

  pdf.addImage(imgData, 'PNG', 0, 0, width, height);

  return pdf;
}

export async function toPdf(targetRef: MutableRefObject<HTMLDivElement>, title: string) {
  const targetComponent = targetRef.current;

  if (!targetComponent) return;

  const pdf = await generatePdfImage(targetRef);

  pdf.save(`${title}.pdf`);
}

export async function toWord(isAuthenticated: boolean, targetRef: MutableRefObject<HTMLDivElement>, title: string) {
  const blob = await convertHtmlToDocx(isAuthenticated, targetRef, title);
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.style.display = 'none';
  link.href = url;
  link.download = title;

  document.body.appendChild(link);
  link.click();

  URL.revokeObjectURL(link.href);
}

export async function toPowerPoint(pptx: pptxgen, sermonTitle: string) {
  await pptx.writeFile({ fileName: sermonTitle });
}

export async function toProPresenter(
  isAuthenticated: boolean,
  pptx: pptxgen,
  targetRef: MutableRefObject<HTMLDivElement>,
  sermonTitle: string,
) {
  const wordBlob = await convertHtmlToDocx(isAuthenticated, targetRef, sermonTitle);
  const powerPointBlob = await pptx.write();
  const pdf = await generatePdfImage(targetRef);
  const pdfBlob = pdf.output('blob');

  const zip = new JSZip();
  const folder = zip.folder(sermonTitle);

  folder?.file(`${sermonTitle}.docx`, wordBlob);
  folder?.file(`${sermonTitle}.pptx`, powerPointBlob);
  folder?.file(`${sermonTitle}.pdf`, pdfBlob);
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${sermonTitle}.zip`);
  });
}

export async function getImageBase64(element: HTMLImageElement) {
  const canvasElement = await html2canvas(element, {
    logging: false,
    useCORS: true,
  });
  const imgData = canvasElement.toDataURL();

  return imgData;
}
