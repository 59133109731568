import { getUnsyncedSermons } from '../utilities/getUnsyncedSermons';
import { backupSermon } from '../utilities/backupSermon';
import { DataStore, Auth } from 'aws-amplify';
import { track } from '../analytics/Analytics';
import { useAtom } from 'jotai';
import {
  dataStoreIsSyncedAtom,
  fetchCognitoUserAtom,
  hasSubscriptionAtom,
  isCanceledUserAtom,
  subscriptionInfoFetchedAtom,
} from 'src/App';
import { deleteFromStorage } from '@rehooks/local-storage';

export const useHandleSignOut = () => {
  const [, fetchCognitoUser] = useAtom(fetchCognitoUserAtom);
  const [, setDataStoreIsSynced] = useAtom(dataStoreIsSyncedAtom);
  const [, setSubscriptionInfoFetched] = useAtom(subscriptionInfoFetchedAtom);
  const [, setIsCanceledUser] = useAtom(isCanceledUserAtom);
  const [, setHasSubscription] = useAtom(hasSubscriptionAtom);
  const handleSignOut = async () => {
    try {
      const unsyncedSermons = await getUnsyncedSermons();
      await Promise.all(
        unsyncedSermons.map((us) =>
          backupSermon({
            sermon: us,
            source: 'Unsynced At Logout',
          }),
        ),
      );

      await DataStore.stop();
      await DataStore.clear();
      await Auth.signOut();
      await track('User Logged Out');
      await fetchCognitoUser();
      sessionStorage.removeItem('confirmationWasResent');
      sessionStorage.removeItem('emailWasVerified');
      setDataStoreIsSynced(null);
      deleteFromStorage('returnPathname');
      deleteFromStorage('algoliaSermonarySecuredKey');
      setSubscriptionInfoFetched(false);
      setIsCanceledUser(false);
      setHasSubscription(false);
    } catch (err) {
      console.log(err);
      fetchCognitoUser();
    }
  };

  return {
    handleSignOut,
  };
};
