import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerContent, Text, Box } from '@chakra-ui/react';

import { Label } from '@sermonary/components';
import { ArrowLeftIcon } from '@sermonary/icons';
import { DataStore } from 'aws-amplify';
import { useMutation } from '@sermonary/hooks';
import { useNavigate } from 'react-router-dom';
import { sermonAtom, isSermonSettingsDrawerOpenAtom } from '../pages/sermons/SingleSermon';
import { track } from '../analytics/Analytics';
import { Sermon } from '../models';
import { createSermonDrawerStateAtom } from '.';
import { providedSermonTemplateGroupsAtom, providedSermonTemplatesAtom } from 'src/libs/atoms';

const preBuiltDrawerStateAtom = atom<'open' | 'closed'>('closed');
const preBuiltDrawerGroupAtom = atom('');

type SermonTemplate = Pick<Sermon, 'sermonType' | 'title' | 'blocks'>;

interface PreBuiltTemplateSelectionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  group: string;
}

function PreBuiltTemplateSelectionDrawer({ isOpen, onClose, group }: PreBuiltTemplateSelectionDrawerProps) {
  const navigate = useNavigate();
  const [, setPreBuiltDrawerState] = useAtom(preBuiltDrawerStateAtom);
  const [, setSermonDrawerState] = useAtom(createSermonDrawerStateAtom);
  const [, setIsSermonSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);
  const [, setSermon] = useAtom(sermonAtom);
  const [providedSermonTemplates] = useAtom(providedSermonTemplatesAtom);
  const [providedSermonTemplateGroups] = useAtom(providedSermonTemplateGroupsAtom);

  const { handler } = useMutation<Sermon>(async (template: SermonTemplate) => {
    try {
      const sermon = await DataStore.save(new Sermon(template));
      setSermon(sermon);
      return sermon;
    } catch (err) {
      console.log({ err });
      return null;
    }
  });

  async function createNewSermon(template: SermonTemplate) {
    const { id: sermonId } = await handler(template);

    setPreBuiltDrawerState('closed');
    setSermonDrawerState('closed');

    await track('Create from Pre-built Template', { templateTitle: template?.title });

    navigate(`/sermons/${sermonId}`);
    setIsSermonSettingsDrawerOpen(true);
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerContent p={4}>
        <DrawerHeader>
          <Button
            backgroundColor="transparent"
            onClick={onClose}
            textTransform="uppercase"
            color="gray.700"
            leftIcon={<ArrowLeftIcon height={4} width={4} />}
          >
            <Text fontSize="md">Back</Text>
          </Button>
        </DrawerHeader>

        <DrawerBody>
          <Box>
            <Label mb={0}>{providedSermonTemplateGroups[group]?.title}</Label>
            {providedSermonTemplateGroups[group].templates.map((templateId: string) => {
              const template = providedSermonTemplates[templateId];
              return (
                <Button
                  key={templateId}
                  mt="6"
                  w="100%"
                  backgroundColor="gray.300"
                  color="gray.900"
                  textAlign="left"
                  justifyContent="left"
                  onClick={() => createNewSermon(template)}
                  whiteSpace="normal"
                  height="auto"
                  p={4}
                  _hover={{ backgroundColor: 'gray.400' }}
                >
                  {template?.title}
                </Button>
              );
            })}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function usePreBuiltTemplateSelectionDrawer() {
  const [state, setState] = useAtom(preBuiltDrawerStateAtom);
  const [templateGroup, setTemplateGroup] = useAtom(preBuiltDrawerGroupAtom);

  const isOpen = state === 'open';

  const handleOnClose = useCallback(() => {
    setState('closed');
  }, [setState]);

  return {
    isOpen,
    handleOnOpen: (group?: string) => {
      setTemplateGroup(group || 'general');
      setState('open');
    },
    handleOnClose,
    handleOnToggle: () => {
      if (state === 'closed') {
        setState('open');
      } else {
        setState('closed');
      }
    },

    PreBuiltTemplateSelectionDrawer: useCallback(
      () =>
        isOpen && templateGroup ? (
          <PreBuiltTemplateSelectionDrawer group={templateGroup} isOpen={isOpen} onClose={handleOnClose} />
        ) : null,
      [handleOnClose, isOpen],
    ),
  };
}
