import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const vowRenewalOutline = {
  id: 'vowRenewalOutline',
  bigIdea: 'The Vow Renewal of (Groom) & (Bride)',
  sermonType: SermonType.SERMON,
  description: 'The Vow Renewal of (Groom) & (Bride)',
  title: 'Vow Renewal Outline',
  blocks: JSON.stringify({
    '0': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'PROCESSION',
    },
    '1': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Order of Entrance',
      content:
        'Option 1: <em><span style="font-weight: 400;">Pastor and groom, enter. (Can also include groomsmen, bridesmaids, a ring bearer, and a flower girl enter if desired.) Then, all stand, and bride walks the aisle.</span></em>\r\n\r\nOption 2: <em>Pastor at the front. Bride and groom walk down the aisle together. (Can also include bridesmaids, groomsmen, a flower girl, and a ring bearer if desired.)</em>',
    },
    '2': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'WELCOME',
    },
    '3': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Bride, Groom, and Audience',
      content:
        '<span style="font-weight: 400;"><strong>(Bride)</strong> and <strong>(Groom)</strong>, it is our pleasure to be here and share this special day with you as you renew your marriage vows.</span>\r\n\r\n<span style="font-weight: 400;">When we think about love, we tend to think about marriage like it is the finish line of the relationship. First, you meet that special person, and one of you works up the courage to ask them on a date. Then, if the date goes well, you have a second date, third date, and so on until you come to the realization that you are truly, madly, and deeply in love with this person next to you. So, if all goes well, you marry this person and “The End.” The movie credits roll, and you live happily ever after.</span>\r\n\r\n<span style="font-weight: 400;">But anyone who has ever been married knows that the story does not end at the wedding. Marriage is just the beginning of your long, happy, and sometimes difficult journey together.</span>\r\n\r\n<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong> have been on this journey for <strong>(#)</strong> years now. Their love for one another has been tried and tested, and it is stronger today than ever before. And so, today they would like to renew the vows they made all those years ago, and continue their devotion to one another for many more years to come.</span>',
    },
    '4': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'PRAYER',
    },
    '5': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Opening Prayer',
      content:
        '<span style="font-weight: 400;">Please join with me as we begin in prayer:</span>\r\n\r\n<span style="font-weight: 400;">Father God, we are so grateful that you, the creator of heaven and earth, love us, care about us, and listen to our prayers. Lord, thank you for the commitment to one another that <strong>(Groom)</strong> and <strong>(Bride)</strong> have shown through the years. We ask that you, Lord, would continue to prove faithful in their married. Continue to bless them and guide them for many more years. May their love for one another only continue to grow. We ask all of this in Jesus’ name. Amen.</span>',
    },
    '6': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'SCRIPTURE READING',
    },
    '7': {
      chapter: '',
      book: '',
      translation: '',
      inputType: 'manual',
      id: uuid(),
      type: 'bible',
      verses: '',
      content:
        '<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong> have asked me to read 1 Corinthians 13 from the Bible. This is the Word of God as written by the Apostle Paul:</span>\r\n\r\n<span style="font-weight: 400;">Love is patient, love is kind. It does not envy, it does not boast, it is not proud. </span><span style="font-weight: 400;">It is not rude, it is not self-seeking, it is not easily angered, it keeps no record of wrongs. </span><span style="font-weight: 400;">Love does not delight in evil but rejoices with the truth. </span><span style="font-weight: 400;">It always protects, always trusts, always hopes, always perseveres. </span><span style="font-weight: 400;">Love never fails. ...</span><span style="font-weight: 400;">And now these three remain: faith, hope and love. But the greatest of these is love. </span><span style="font-weight: 400;">(1 Corinthians 13:4-7, 13 NIV)</span>',
    },
    '8': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'SERMON',
    },
    '9': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Bride, Groom, and Audience',
      content:
        '<span style="font-weight: 400;">Over the years, you have undoubtedly had many opportunities to experience all of these aspects of love. You would not be here today if you had not learned to be patient, kind, selfless, forgiving, protecting and more.</span>\r\n\r\n<span style="font-weight: 400;"><strong>(Bride)</strong> and <strong>(Groom)</strong>, your <strong>(#)</strong> years of marriage are a testimony to the unfailing nature and perseverance of true love.</span>\r\n\r\n<span style="font-weight: 400;">As you stand here today and think back over the years, I am sure that there are pivotal moments that stand out in your relationship: The day you first met, your first kiss, your wedding day,<strong> (if parents: the birth of (first child’s name), (second child), etc.)</strong>, those moments where you wonder how you ever survived, and those moments when you felt on top of the world.</span>\r\n\r\n<span style="font-weight: 400;">And through it all, one thing has held it all together: love.</span>\r\n\r\n<span style="font-weight: 400;">First, God’s love for you. He loved you so much that he sent his only Son, Jesus, to die for you so that you could live in Him. </span>\r\n\r\n<span style="font-weight: 400;">Second, your love for God. Over the years you have both been tested again and again, but have continued to place your trust and faith in Christ to sustain you. </span>\r\n\r\n<span style="font-weight: 400;">And finally, your love for each other that you have built a life, a marriage, and a family upon.</span>\r\n\r\n<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong>, may your commitment to loving God and loving one another continue as an inspiration to us all. </span>\r\n\r\n&nbsp;',
    },
    '10': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'DECLARATION OF INTENT',
    },
    '11': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To The Bride & Groom',
      content:
        '<span style="font-weight: 400;">So I will ask you now <strong>(Bride)</strong>, do you continue to take <strong>(Groom)</strong> to be your husband, to live together in holy marriage? Do you promise to keep loving him, comforting him, honoring and keeping him, in sickness and in health, and remain faithful to him as long as you both live? If so, please say, "I do."</span>\r\n\r\n<b><i>*(Bride):  I do.*</i></b>\r\n\r\n<span style="font-weight: 400;"><strong>(Groom)</strong>, do you continue to take <strong>(Bride)</strong> to be your wife, to live together in holy marriage? Do you promise to keep loving her, comforting her, honoring and keeping her, in sickness and in health, and remain faithful to her as long as you both live? If so, please say, "I do."</span>\r\n\r\n<b><i>*(Groom): I do.*</i></b>',
    },
    '12': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'VOWS',
    },
    '13': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Option 1:',
      content:
        '<h2><b>Traditional Vows</b></h2>\r\n<span style="font-weight: 400;">Now <strong>(Bride)</strong> and <strong>(Groom)</strong> will exchange their renewed vows. Please face and take each other’s hands. We will start with you <strong>(Groom)</strong>. Repeat after me.</span>\r\n\r\n<em><strong>*(Groom): I (Groom) – years ago — chose you (Bride) –  to be my wife – and today — I choose you again —To have and to hold – For better, or worse – For richer, or poorer – In sickness, and in health – To love and to cherish –  For the rest of our lives.*</strong></em>\r\n\r\n<span style="font-weight: 400;"><strong>(Bride)</strong>, please repeat after me.</span>\r\n\r\n<strong><em>*(Bride): I (Bride) – years ago — chose you (Groom) –  to be my husband – and today — I choose you again —To have and to hold – For better, or worse – For richer, or poorer – In sickness, and in health – To love and to cherish –  For the rest of our lives.*</em></strong>',
    },
    '14': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Option 2:',
      content:
        '<h2><b>Written Vows</b></h2>\r\n<span style="font-weight: 400;">Now, to renew their commitment to one another, <strong>(Bride)</strong> and <strong>(Groom)</strong> are going to exchange vows. <strong>(Groom)</strong> and <strong>(Bride)</strong> have chosen to write their own, renewed vows.</span>\r\n\r\n<span style="font-weight: 400;">Face one another and take each other’s hands, please.  We will start with you <strong>(Groom)</strong>. </span>\r\n\r\n<em><strong>*(Groom says vows)*</strong></em>\r\n\r\n<span style="font-weight: 400;">Thank you <strong>(Groom)</strong>.  And now<strong> (Bride)</strong> would you share your renewed vows for <strong>(Groom)</strong>. </span>\r\n\r\n<em><strong>*(Bride says vows)*</strong></em>',
    },
    '15': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'RING EXCHANGE (Optional)',
    },
    '16': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To The Bride & Groom',
      content:
        '<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong>, you exchanged rings on your wedding day as a symbol of your commitment to each other.</span>\r\n\r\n<span style="font-weight: 400;">May these rings continue to serve as a daily reminder of the promise you have made. The circle of the ring has no beginning and no end. It is an eternal circle meant to represent your life-long vow to love each other. The ring is also made from purified precious metals. This is symbolic of the value and purity of your promise to remain faithful to each other.</span>\r\n\r\n<span style="font-weight: 400;"><strong>(Bride)</strong> please repeat after me:</span>\r\n\r\n<em><strong>*I (Bride) – wear this ring – as a symbol — of my love for you – and my continued promise — to love you — always and forever.*</strong></em>\r\n\r\n<span style="font-weight: 400;"><strong>(Groom)</strong> please repeat after me:</span>\r\n\r\n<em><strong>*I (Groom) – wear this ring – as a symbol — of my love for you – and my continued promise — to love you — always and forever.*</strong></em>',
    },
    '17': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'PRONOUNCEMENT',
    },
    '18': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Bride, Groom, and Audience',
      content:
        '<span style="font-weight: 400;">With your vows renewed, may your marriage continue to grow stronger with each passing day. May God continue to bless your marriage, and may your joy be full, and your worries be few. </span>\r\n\r\n<span style="font-weight: 400;">And by the power vested in me, I now pronounce that<strong> (Groom)</strong> and<strong> (Bride)</strong> are still happily married!</span>',
    },
    '19': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'KISS',
    },
    '20': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Bride, Groom, and Audience',
      content:
        'Please seal your renewed vows with a kiss.\r\n\r\n<em><strong>*Bride &amp; Groom kiss*</strong></em>\r\n\r\n<span style="font-weight: 400;">Ladies and Gentlemen, it is my joy to present to you</span>\r\n\r\nMr. &amp; Mrs.<b> (Groom) &amp; (Bride) (Last Name)!</b>',
    },
    '21': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'RECESSIONAL',
    },
    '22': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Order of Exit',
      content:
        '<em><span style="font-weight: 400;">The Bride and Groom exit first, followed by the wedding party.</span></em>',
    },
    '23': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'CLOSING COMMENTS',
    },
    '24': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Audience',
      content:
        '<span style="font-weight: 400;">On behalf of <strong>(Bride)</strong> and <strong>(Groom)</strong>, I would like to thank you again for coming, and invite you to celebrate their marriage with them…<strong>(details or directions to reception)</strong>. </span>\r\n\r\n<span style="font-weight: 400;">Thank you.</span>',
    },
  }),
};
