import { Icon, IconProps } from '@chakra-ui/react';

export function OpenEyeIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon height={height} width={width} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C15.3 2 17.4823 3.26879 19.63 5C22.26 7.12 24 10.4973 24 12C24 14.2625 22.2623 16.8819 19.63 19C17.48 20.73 15.1596 22 12 22C8.89592 22 6.23907 20.73 4.37 19L4.11764 18.764L3.62598 18.289C1.53612 16.2226 0 14.0564 0 12C0 9.80529 1.74458 7.12357 4.37236 5C6.35682 3.39631 8.7 2 12 2ZM12 4.75C9.82722 4.75 8.13204 5.49743 6.10084 7.13889C4.00546 8.83221 2.75 10.8193 2.75 12C2.75 13.0805 3.91844 14.8348 6.23801 16.9818C7.77618 18.4055 9.81055 19.25 12 19.25C14.138 19.25 15.8408 18.5193 17.906 16.8575C20.0285 15.1496 21.25 13.2258 21.25 12C21.25 11.7488 21.0902 11.3069 20.8135 10.7747L20.6122 10.4076C19.9956 9.33578 18.9962 8.02131 17.9042 7.14102C15.7748 5.42455 14.2242 4.75 12 4.75ZM12 7.1875C14.6579 7.1875 16.8125 9.34213 16.8125 12C16.8125 14.6579 14.6579 16.8125 12 16.8125C9.34213 16.8125 7.1875 14.6579 7.1875 12C7.1875 9.34213 9.34213 7.1875 12 7.1875ZM12 9.9375C10.8609 9.9375 9.9375 10.8609 9.9375 12C9.9375 13.1391 10.8609 14.0625 12 14.0625C13.1391 14.0625 14.0625 13.1391 14.0625 12C14.0625 10.8609 13.1391 9.9375 12 9.9375Z"
        fill="#168EEA"
      />
    </Icon>
  );
}

export default OpenEyeIcon;
