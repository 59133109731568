import { useInfiniteHits, useInstantSearch, useSearchBox } from 'react-instantsearch';
import {
  Box,
  BoxProps,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ActionsIcon, MagnifyingGlassIcon } from '@sermonary/icons';
import { memo, useEffect, useRef, useState } from 'react';
import { Sermon } from 'src/models';
import { Breadcrumbs, Header, isOnlineAtom, SermonCard } from '@sermonary/components';
import { DataStore } from 'aws-amplify';
import { atom, useAtom } from 'jotai';
import { useGetAlgoliaSecuredKey } from 'src/hooks/useGetAlgoliaSecuredKey';
import { useCreateSermonDrawer, useCustomTemplateSelectionDrawer, usePreBuiltTemplateSelectionDrawer } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeleteSermonDialog, useMutation, useSermonShareModal } from '@sermonary/hooks';
import { track } from 'src/analytics/Analytics';
import { BaseHit, Hit } from 'instantsearch.js';

type SermonIndex = {
  title: string;
  blockTitles?: string[];
  blockContents?: string[];
  referenceVerses?: string;
  bigIdea?: string;
  description?: string;
};

export type SearchSermonHit = {
  sermon: Sermon;
  hit: Hit<BaseHit> & SermonIndex;
};

const searchStringAtom = atom('');
const sermonSearchResultsAtom = atom<SearchSermonHit[]>([]);

export const CustomSearchBox = (props: BoxProps) => {
  const navigate = useNavigate();
  const [shouldCheckKey, setShouldCheckKey] = useState(false);
  const [queryInput, setQueryInput] = useAtom(searchStringAtom);
  const [isOnline] = useAtom(isOnlineAtom);

  useSearchBox();
  useGetAlgoliaSecuredKey(shouldCheckKey);

  function setQuery(query: string) {
    setQueryInput(query);
  }

  function handleSubmit() {
    navigate(`/search/${queryInput}`);
    setQueryInput('');
  }

  useEffect(() => {
    setShouldCheckKey(true);
  }, []);

  return (
    <Box maxW={'420px'} {...props}>
      <InputGroup>
        <Input
          placeholder="Search for a Sermon..."
          variant="filled"
          disabled={!isOnline}
          _placeholder={{
            color: 'gray.700',
            fontWeight: '600',
          }}
          value={queryInput}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          backgroundColor="white"
          borderBottomColor="rgba(125,142,153, 0.2)"
          borderBottomWidth="2px"
        />
        <InputRightElement
          pointerEvents="none"
          top="5px"
          right="5px"
          children={<MagnifyingGlassIcon height="6" width="6" />}
        />
      </InputGroup>
    </Box>
  );
};

const CustomSearchHits = () => {
  const { hits, results, isLastPage, showMore } = useInfiniteHits();
  const [loadingMore, showLoadingMore] = useState<Boolean>();
  const [sermons, setSermons] = useAtom(sermonSearchResultsAtom);
  const infiniteScrollRef = useRef(null);
  const sermonToDeleteRef = useRef<Sermon | null>(null);
  const toast = useToast();

  const { handleOpenModal, SermonShareModal } = useSermonShareModal();

  const { handler: archiveSermon } = useMutation(async (sermon: Sermon) => {
    try {
      await DataStore.save(
        Sermon.copyOf(sermon, (updated) => {
          updated.isArchived = true;
        }),
      );
      toast({
        title: 'Sermon archived.',
        status: 'success',
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
    }
  });

  const {
    isError,
    isLoading,
    error,
    handler: deleteSermon,
  } = useMutation(async (sermon: Sermon) => {
    try {
      await DataStore.delete(Sermon, sermon?.id);
    } catch (err) {
      console.log(err);
    }
  });

  const { handleOpenDialog, DeleteSermonDialog } = useDeleteSermonDialog({
    isError,
    isLoading,
    error,
    onDelete: async () => deleteSermon(sermonToDeleteRef?.current),
    onSuccess: () => {
      toast({
        title: 'Sermon deleted.',
        status: 'success',
        isClosable: true,
      });
      sermonToDeleteRef.current = null;
    },
  });

  const handleOnDelete = async (sermon: Sermon) => {
    sermonToDeleteRef.current = sermon;
    handleOpenDialog();
  };

  const { handler: duplicateSermon } = useMutation(
    async (sermon: Sermon) => {
      function stripTitle(title: string) {
        return title?.replace(/\(([[0-9])\)/, '').trim();
      }

      const duplicates = sermons?.filter((x) => stripTitle(x.sermon?.title) === stripTitle(sermon?.title));

      try {
        await DataStore.save(
          new Sermon({
            bigIdea: sermon?.bigIdea,
            blocks: sermon?.blocks,
            sermonType: sermon?.sermonType,
            wordCount: sermon?.wordCount,
            title: `${stripTitle(sermon?.title)} (${duplicates?.length ? Number(duplicates?.length) + 1 : 1})`,
            ...(sermon?.seriesId && { seriesId: sermon?.seriesId }),
          }),
        );

        await track('Duplicate Sermon');
      } catch (err) {
        console.log(err);
      }
    },
    { deps: [sermons] },
  );

  async function fetchSermons() {
    return Promise.all(
      hits.map(async (hit: Hit<BaseHit> & SermonIndex) => {
        const sermon = await DataStore.query(Sermon, hit.objectID);
        return {
          sermon: sermon,
          hit,
        };
      }),
    );
  }

  useEffect(() => {
    showLoadingMore(!isLastPage);
    fetchSermons().then((sermons) => {
      // handle search index and datastore are out of sync
      sermons = sermons.filter((sermon) => {
        return sermon.sermon !== undefined;
      });

      setSermons(sermons);
    });
  }, [hits]);

  useEffect(() => {
    if (infiniteScrollRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(infiniteScrollRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <>
      <Text marginTop={8}>
        Searched for{' '}
        <Text as={'span'} color={'blue.500'} fontWeight={'bold'} fontStyle={'italic'}>
          {decodeURIComponent(results.query)}
        </Text>{' '}
        and found {sermons.length} result{sermons.length > 1 && 's'}
      </Text>
      <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} spacing="4" mt="10">
        {sermons?.map((sermonHit) => {
          return (
            <SermonCard
              key={sermonHit.sermon.id}
              sermon={sermonHit.sermon}
              onExport={undefined}
              onShare={handleOpenModal}
            >
              <Menu>
                <MenuButton as={Button} variant="shadow" h="auto" p="0" minW="none">
                  <ActionsIcon color="white" />
                  <VisuallyHidden>Sermon block actions</VisuallyHidden>
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => duplicateSermon?.(sermonHit.sermon)}>Duplicate</MenuItem>
                  <MenuItem onClick={() => archiveSermon(sermonHit.sermon)}>Archive</MenuItem>
                  <MenuItem onClick={() => handleOnDelete?.(sermonHit.sermon)}>Delete</MenuItem>
                </MenuList>
              </Menu>
            </SermonCard>
          );
        })}
      </SimpleGrid>
      <span ref={infiniteScrollRef} aria-hidden="true" />
      {loadingMore && (
        <Box textAlign={'center'} marginTop={8}>
          <Spinner color="blue.500" size="lg" />
        </Box>
      )}
      <SermonShareModal />
      <DeleteSermonDialog />
    </>
  );
};

export function SearchResults() {
  const { pathname } = useLocation();
  const [isOnline] = useAtom(isOnlineAtom);
  const query = decodeURIComponent(
    pathname.includes('/search') && pathname.split('/search/').length === 2 ? pathname.split('/search/')[1] : '',
  );

  const { indexUiState, setUiState, setIndexUiState } = useInstantSearch();
  if (isOnline && query && query !== indexUiState.query) {
    setUiState({ [import.meta.env.VITE_ALGOLIA_SERMONARY_ENV_INDEX]: { query } });
    setIndexUiState({ query });
  }

  const { CreateSermonDrawer, handleOnOpen } = useCreateSermonDrawer();
  const { CustomTemplateSelectionDrawer } = useCustomTemplateSelectionDrawer();
  const { PreBuiltTemplateSelectionDrawer } = usePreBuiltTemplateSelectionDrawer();

  return (
    <>
      <Header title="Sermons">
        <Heading as="h1">Sermons</Heading>
        <>
          <CustomSearchBox mr={6} />
          <Button colorScheme="blue" onClick={handleOnOpen}>
            Write New
          </Button>
        </>
      </Header>
      <Breadcrumbs title={'Search'} to={'/sermons'} toLabel={'Sermons'} />
      <CustomSearchHits />
      <CreateSermonDrawer />
      <CustomTemplateSelectionDrawer />
      <PreBuiltTemplateSelectionDrawer />
    </>
  );
}

export default memo(SearchResults);
