import { memo, useState, useEffect, ReactNode, useRef } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { atom, useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Container, Flex, useMediaQuery } from '@chakra-ui/react';
import { Bottombar, FreeUserUpgradeBanner, OfflineBanner, Sidebar } from '.';
import ConfirmEmailBanner from './ConfirmEmailBanner';
import { isFreeUserAtom } from 'src/App';

interface UserType extends CognitoUser {
  attributes: {
    email_verified: boolean;
  };
}

interface AccountType {
  createdAt?: string | null;
  [key: string]: any;
}

interface LayoutProps {
  handleSignOut: () => Promise<void>;
  renderSubscribeButton: () => ReactNode;
  user: UserType;
  account: AccountType;
  externalLinks: Record<string, string>;
  isCanceledUser: boolean;
  subscriptionInfoFetched: boolean;
  hasSubscription: boolean;
}

export const emailWasVerifiedAtom = atomWithStorage<boolean>(
  'emailWasVerified',
  true,
  createJSONStorage<boolean>(() => sessionStorage),
);

export const isOnlineAtom = atom(navigator.onLine);

export const Layout = memo(
  ({
    handleSignOut,
    renderSubscribeButton,
    user,
    account,
    externalLinks,
    isCanceledUser,
    subscriptionInfoFetched,
    hasSubscription,
  }: LayoutProps) => {
    const accountCreatedAt = account && account.createdAt ? Date.parse(account.createdAt) : Date.now();

    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const { pathname } = useLocation();
    const mainAppWindowRef = useRef<HTMLDivElement>(null);

    const [isLoggedIn, setIsLoggedIn] = useState(!!(user && Object.keys(user).length));

    const [online] = useAtom(isOnlineAtom);
    const [isFreeUser] = useAtom(isFreeUserAtom);
    const [emailWasVerified, setEmailWasVerified] = useAtom(emailWasVerifiedAtom);

    useEffect(() => {
      setIsLoggedIn(!!(user && Object.keys(user).length));
    }, [user]);

    useEffect(() => {
      mainAppWindowRef.current.scrollIntoView();
    }, [pathname]);

    useEffect(() => {
      if (isLoggedIn) {
        setEmailWasVerified(user?.attributes?.email_verified);
      }

      return () => {
        setEmailWasVerified(true);
      };
    }, [user, emailWasVerified]);

    return (
      <Flex position="relative" overflow="hidden" bg="gray.100">
        {isLoggedIn ? (
          isMobile ? (
            <Bottombar
              handleSignOut={handleSignOut}
              renderSubscribeButton={renderSubscribeButton}
              externalLinks={externalLinks}
              isCanceledUser={isCanceledUser}
              isFreeUser={isFreeUser}
              subscriptionInfoFetched={subscriptionInfoFetched}
              hasSubscription={hasSubscription}
            />
          ) : (
            <Sidebar
              handleSignOut={handleSignOut}
              renderSubscribeButton={renderSubscribeButton}
              externalLinks={externalLinks}
              isCanceledUser={isCanceledUser}
              isFreeUser={isFreeUser}
              subscriptionInfoFetched={subscriptionInfoFetched}
              hasSubscription={hasSubscription}
            />
          )
        ) : null}

        <Box
          w="100%"
          minHeight={isMobile && !isLoggedIn ? '100vh' : isMobile ? undefined : '100vh'}
          maxHeight={isMobile && !isLoggedIn ? '100vh' : isMobile ? undefined : '100vh'}
          overflowX="hidden"
          position="relative"
        >
          <Box ref={mainAppWindowRef} />
          {online ? (
            isLoggedIn ? (
              <>
                <ConfirmEmailBanner
                  emailWasVerified={emailWasVerified}
                  isMobile={isMobile}
                  accountCreatedAt={accountCreatedAt}
                />
                {isFreeUser && pathname !== '/tier-upgrade' && <FreeUserUpgradeBanner />}
              </>
            ) : null
          ) : (
            <OfflineBanner />
          )}
          <Box p="16px" w="100%" min-height="-webkit-fill-available">
            <Container maxW="container.xl" pb="125px">
              <Outlet />
            </Container>
          </Box>
        </Box>
      </Flex>
    );
  },
);
