import { Box } from '@chakra-ui/react';

export function ThinTopBanner({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <Box
      background="white"
      padding={2}
      borderBottom="1px solid"
      borderBottomColor="gray.300"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
}
