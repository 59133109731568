export const BibleBooks = [
  {
    label: 'Genesis',
    value: 'GEN',
  },
  {
    label: 'Exodus',
    value: 'EXO',
  },
  {
    label: 'Leviticus',
    value: 'LEV',
  },
  {
    label: 'Numbers',
    value: 'NUM',
  },
  {
    label: 'Deuteronomy',
    value: 'DEU',
  },
  {
    label: 'Joshua',
    value: 'JOS',
  },
  {
    label: 'Judges',
    value: 'JDG',
  },
  {
    label: 'Ruth',
    value: 'RUT',
  },
  {
    label: '1 Samuel',
    value: '1SA',
  },
  {
    label: '2 Samuel',
    value: '2SA',
  },
  {
    label: '1 Kings',
    value: '1KI',
  },
  {
    label: '2 Kings',
    value: '2KI',
  },
  {
    label: '1 Chronicles',
    value: '1CH',
  },
  {
    label: '2 Chronicles',
    value: '2CH',
  },
  {
    label: 'Ezra',
    value: 'EZR',
  },
  {
    label: 'Nehemiah',
    value: 'NEH',
  },
  {
    label: 'Esther',
    value: 'EST',
  },
  {
    label: 'Job',
    value: 'JOB',
  },
  {
    label: 'Psalms',
    value: 'PSA',
  },
  {
    label: 'Proverbs',
    value: 'PRO',
  },
  {
    label: 'Ecclesiastes',
    value: 'ECC',
  },
  {
    label: 'Song of Songs',
    value: 'SNG',
  },
  {
    label: 'Isaiah',
    value: 'ISA',
  },
  {
    label: 'Jeremiah',
    value: 'JER',
  },
  {
    label: 'Lamentations',
    value: 'LAM',
  },
  {
    label: 'Ezekiel',
    value: 'EZK',
  },
  {
    label: 'Daniel',
    value: 'DAN',
  },
  {
    label: 'Hosea',
    value: 'HOS',
  },
  {
    label: 'Joel',
    value: 'JOL',
  },
  {
    label: 'Amos',
    value: 'AMO',
  },
  {
    label: 'Obadiah',
    value: 'OBA',
  },
  {
    label: 'Jonah',
    value: 'JON',
  },
  {
    label: 'Micah',
    value: 'MIC',
  },
  {
    label: 'Nahum',
    value: 'NAM',
  },
  {
    label: 'Habakkuk',
    value: 'HAB',
  },
  {
    label: 'Zephaniah',
    value: 'ZEP',
  },
  {
    label: 'Haggai',
    value: 'HAG',
  },
  {
    label: 'Zechariah',
    value: 'ZEC',
  },
  {
    label: 'Malachi',
    value: 'MAL',
  },
  {
    label: 'Matthew',
    value: 'MAT',
  },
  {
    label: 'Mark',
    value: 'MRK',
  },
  {
    label: 'Luke',
    value: 'LUK',
  },
  {
    label: 'John',
    value: 'JHN',
  },
  {
    label: 'Acts',
    value: 'ACT',
  },
  {
    label: 'Romans',
    value: 'ROM',
  },
  {
    label: '1 Corinthians',
    value: '1CO',
  },
  {
    label: '2 Corinthians',
    value: '2CO',
  },
  {
    label: 'Galatians',
    value: 'GAL',
  },
  {
    label: 'Ephesians',
    value: 'EPH',
  },
  {
    label: 'Philippians',
    value: 'PHP',
  },
  {
    label: 'Colossians',
    value: 'COL',
  },
  {
    label: '1 Thessalonians',
    value: '1TH',
  },
  {
    label: '2 Thessalonians',
    value: '2TH',
  },
  {
    label: '1 Timothy',
    value: '1TI',
  },
  {
    label: '2 Timothy',
    value: '2TI',
  },
  {
    label: 'Titus',
    value: 'TIT',
  },
  {
    label: 'Philemon',
    value: 'PHM',
  },
  {
    label: 'Hebrews',
    value: 'HEB',
  },
  {
    label: 'James',
    value: 'JAS',
  },
  {
    label: '1 Peter',
    value: '1PE',
  },
  {
    label: '2 Peter',
    value: '2PE',
  },
  {
    label: '1 John',
    value: '1JN',
  },
  {
    label: '2 John',
    value: '2JN',
  },
  {
    label: '3 John',
    value: '3JN',
  },
  {
    label: 'Jude',
    value: 'JUD',
  },
  {
    label: 'Revelation',
    value: 'REV',
  },
];

export const BibleTranslations = [
  {
    label: 'NIV - New International Version',
    value: 'NIV',
    copyright: `
      Holy Bible, New International Version ®, NIV ®<br>
      Copyright © 1973, 1978, 1984, 2011 by <a href="https://www.biblica.com/" target="_blank">Biblica</a>, Inc.<br>
      Used with permission. All rights reserved worldwide.
    `,
  },
  {
    label: 'NVI - Nueva Versión Internacional',
    value: 'NVI',
    copyright: `
      La Santa Biblia, Nueva Versión Internacional ®, NVI ®<br>
      Copyright © 1999, 2015, 2024 by <a href="https://www.biblica.com/" target="_blank">Biblica</a>, Inc.<br>
      Used with permission. All rights reserved worldwide.
    `,
  },
  {
    label: 'ASV - American Standard Version',
    value: 'ASV',
  },
  {
    label: 'CSB - Christian Standard Bible',
    value: 'CSB',
    copyright: `
      Christian Standard Bible® Copyright© 2017 by Holman Bible Publishers.<br>
      Used with permission. All rights reserved worldwide.
    `,
  },
  {
    label: 'ESV - English Standard Version',
    value: 'ESV',
    copyright: `
      Scripture quotations are from the ESV® Bible (The Holy Bible, English Standard Version®)<br>
      Copyright© 2001 by Crossway Bibles, a publishing ministry of Good News Publishers.
      Used by permission. All rights reserved.
    `,
  },
  {
    label: 'KJV - King James Version',
    value: 'KJV',
  },
  {
    label: 'NASB - New American Standard Bible',
    value: 'NASB',
    copyright: `
      New American Standard Bible<br>
      Copyright © 1960, 1971, 1977, 1995, 2020 by <a href="https://www.lockman.org/" target="_blank">The Lockman Foundation</a>, La Habra, Calif. All rights reserved.
    `,
  },
  {
    label: 'NBLA - Nueva Biblia de las Américas',
    value: 'NBLA',
    copyright: `
      Nueva Biblia de las Américas (NBLA)<br>
      Copyright © 2005 by <a href="https://www.lockman.org/" target="_blank">The Lockman Foundation</a>, La Habra, Calif. All rights reserved.
    `,
  },
  {
    label: 'NKJV - New King James Version',
    value: 'NKJV',
    copyright: `
      New King James Version®<br>
      Copyright© 1982, Thomas Nelson. All rights reserved.
    `,
  },
];
