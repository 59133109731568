import { Box, Heading, Text } from '@chakra-ui/react';

export function verseKeySorter(a: string, b: string) {
  try {
    const verseA = parseInt(a.split('v')[1], 10);
    const verseB = parseInt(b.split('v')[1], 10);

    return verseA > verseB ? 1 : -1;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return 0;
}

export function BibleReferenceDisplay({
  title,
  verses,
}: {
  title: string;
  verses: { [key: string]: string[] } | null;
}) {
  if (!verses || !Object.keys(verses)?.length) {
    return null;
  }

  return (
    <Box>
      <Heading as="h4" fontSize={24} mb={4}>
        {title}
      </Heading>
      {Object.keys(verses)
        ?.sort(verseKeySorter)
        .map((ref, index) => {
          const line = verses?.[ref];

          return (
            <Text key={`bref_${index + 1}`} as="p" mb={4}>
              <Text as="sup" display="inline-block" mr="1" color="gray">
                {ref?.replace('v', '')}
              </Text>
              <Text
                as="span"
                dangerouslySetInnerHTML={{
                  __html: Array.isArray(line) ? line?.join(' ') : line,
                }}
              />
            </Text>
          );
        })}
    </Box>
  );
}
