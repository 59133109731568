export function focusBlockInput(targetElement: HTMLElement, blockType: string) {
  let elementToFocus: string;

  switch (blockType) {
    case 'point':
    case 'custom':
      elementToFocus = 'input[name=title]';
      break;
    case 'illustration':
      elementToFocus = 'input';
      break;
    case 'application':
    case 'quote':
      elementToFocus = '.fr-element';
      break;
    case 'media':
    case 'bible':
      elementToFocus = 'input[type=radio]';
    default:
      null;
  }

  elementToFocus && targetElement?.querySelector<HTMLElement>(elementToFocus).focus();
}
