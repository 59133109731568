/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Link, Grid, useDisclosure, Slide, Button } from '@chakra-ui/react';

import { ActionsIcon, ArchiveIcon, SermonsIcon, TemplatesIcon, IdeasIcon, ResourcesIcon } from '@sermonary/icons';
import { memo, ReactNode, useRef } from 'react';
import { Logo } from '.';
import { articles, openArticle, openBeacon } from '../../analytics/HelpScout';

function BottombarLink({
  to,
  location,
  title,
  children,
}: {
  to: string;
  title?: string;
  children: (props: { isActive: boolean }) => ReactNode;
  location: LocationState;
}) {
  const isActive = to === location?.pathname;

  return (
    <Link
      as={RouterLink}
      to={to}
      transition="color 0.3s ease-in-out"
      _hover={{
        svg: {
          color: 'blue.500',
        },
      }}
      _after={{
        display: 'block',
        content: `"${title}"`,
        fontWeight: 'semibold',
        height: '1px',
        color: 'transparent',
        overflow: 'hidden',
        visibility: 'hidden',
      }}
      sx={
        isActive
          ? {
              color: 'gray.900',
              fontWeight: 'semibold',
            }
          : { color: 'gray.900' }
      }
    >
      {children({ isActive })}
    </Link>
  );
}

function BottomDrawer({
  onSignout,
  isOpen,
  onClose,
  onToggle,
  externalLinks,
}: {
  isOpen: boolean;
  onSignout: () => void;
  onClose: () => void;
  onToggle: () => void;
  externalLinks: Record<string, string>;
}) {
  return (
    <Box>
      <Box alignItems="center" display="flex" flexDirection="column" onClick={onToggle} zIndex={10}>
        <ActionsIcon color={isOpen ? 'blue.500' : 'gray.900'} />
        More
      </Box>
      <Slide
        in={isOpen}
        direction="bottom"
        style={{
          boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
          zIndex: -1,
          bottom: isOpen ? 64 : 0,
        }}
      >
        <Box as="section" bg="white" textAlign="left" display="grid" flexDirection="column">
          <Box as="header" borderBottomWidth="1px" p="6">
            <Box
              as={RouterLink}
              to="/sermons"
              onClick={() => onClose()}
              py="1"
              paddingLeft="4"
              outline="none"
              display="block"
              textAlign="left"
            >
              <Logo />
            </Box>
          </Box>

          <Link
            as={RouterLink}
            py="6"
            paddingLeft="10"
            width="100%"
            to="/archive"
            onClick={onClose}
            sx={{ color: 'gray.900' }}
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <ArchiveIcon height={6} width={6} mb={1} mr={3} color="gray.900" />
            Archive
          </Link>

          <Box bg="white" h="100%" px="10" py="4" position="relative" transition="all 0.3s ease-in-out" zIndex="10">
            <Box alignItems="flex-start" display="flex" flexDirection="column">
              <Link
                onClick={() => {
                  onClose();
                  openBeacon();
                }}
                sx={{ color: 'gray.900' }}
              >
                Support
              </Link>
              <Link
                onClick={() => {
                  onClose();
                  openArticle(articles.training);
                }}
                mt={7}
                sx={{ color: 'gray.900' }}
              >
                Training
              </Link>
              <Link as={RouterLink} mt={7} to="/account" onClick={onClose} sx={{ color: 'gray.900' }}>
                Account
              </Link>
              <Link
                mt={7}
                mb={4}
                onClick={() => {
                  onClose();
                  return onSignout();
                }}
                sx={{ color: 'gray.900' }}
              >
                Log out
              </Link>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Box>
  );
}

interface LocationState {
  pathname: string;
}

type BottombarProps = {
  handleSignOut: () => Promise<void>;
  renderSubscribeButton: () => ReactNode;
  externalLinks: Record<string, string>;
  isCanceledUser: boolean;
  isFreeUser: boolean;
  subscriptionInfoFetched: boolean;
  hasSubscription: boolean;
};

export const Bottombar = memo(
  ({
    handleSignOut,
    renderSubscribeButton,
    externalLinks,
    isCanceledUser,
    isFreeUser,
    subscriptionInfoFetched,
    hasSubscription,
  }: BottombarProps) => {
    const location = useLocation();
    const { isOpen, onToggle, onClose } = useDisclosure();
    const containerRef = useRef<HTMLDivElement>(null);

    return (
      <Box
        ref={containerRef}
        position="fixed"
        width="100vw"
        height={16}
        bottom={0}
        zIndex={101}
        boxShadow="0 0 3px rgba(0, 0, 0, 0.2)"
      >
        <Grid
          templateColumns={isCanceledUser || isFreeUser ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr'}
          alignItems="center"
          bg="white"
          gap="4"
          p={2}
          height="100%"
          fontSize="sm"
          lineHeight="6"
          textAlign="center"
          transition="all 0.3s ease-in-out"
        >
          {subscriptionInfoFetched &&
            (isCanceledUser ? (
              <Button as={RouterLink} to="/reactivate" fontSize="md" colorScheme="blue">
                Reactivate
              </Button>
            ) : !hasSubscription ? (
              renderSubscribeButton?.()
            ) : isFreeUser ? (
              <Button as={RouterLink} to="/tier-upgrade" fontSize="md" colorScheme="blue">
                Subscribe
              </Button>
            ) : (
              <>
                <BottombarLink to="/sermons" location={location} title="Sermons">
                  {({ isActive }) => (
                    <Box alignItems="center" display="flex" flexDirection="column">
                      <SermonsIcon color={isActive ? 'blue.500' : 'inherit'} transition="all 0.3s ease-in-out" />
                      Sermons
                    </Box>
                  )}
                </BottombarLink>
                <BottombarLink to="/templates" location={location} title="Templates">
                  {({ isActive }) => (
                    <Box alignItems="center" display="flex" flexDirection="column">
                      <TemplatesIcon color={isActive ? 'blue.500' : 'inherit'} transition="all 0.3s ease-in-out" />
                      Templates
                    </Box>
                  )}
                </BottombarLink>
                <BottombarLink to="/ideas" location={location} title="Ideas">
                  {({ isActive }) => (
                    <Box alignItems="center" display="flex" flexDirection="column">
                      <IdeasIcon color={isActive ? 'blue.500' : 'inherit'} transition="all 0.3s ease-in-out" />
                      Ideas
                    </Box>
                  )}
                </BottombarLink>
                <BottombarLink to="/resources" location={location} title="Resources">
                  {({ isActive }) => (
                    <Box alignItems="center" display="flex" flexDirection="column">
                      <ResourcesIcon color={isActive ? 'blue.500' : 'inherit'} transition="all 0.3s ease-in-out" />
                      Resources
                    </Box>
                  )}
                </BottombarLink>
              </>
            ))}
          <BottomDrawer
            isOpen={isOpen}
            onSignout={handleSignOut}
            onClose={onClose}
            onToggle={onToggle}
            externalLinks={externalLinks}
          />
        </Grid>
      </Box>
    );
  },
);
