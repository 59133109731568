import { Icon, IconProps } from '@chakra-ui/react';

interface Props extends IconProps {
  primaryFill?: string;
}

export function LogoMark({ primaryFill = '#168eea', ...rest }: Props) {
  return (
    <Icon height="6" width="6" viewBox="0 0 24 24" fill="none" {...rest}>
      <g id="prefix__Layer_2_1_">
        <g id="prefix__Layer_1-2">
          <path fill={primaryFill} className="prefix__st0" d="M24 0H0v18.2h2.9V2.9H24zM21.1 5.8v15.3H0V24h24V5.8z" />
          <path
            fill={primaryFill}
            className="prefix__st0"
            d="M5.8 5.8h12.4v2.4H5.8V5.8zM5.8 15.8h12.4v2.4H5.8v-2.4zM5.8 10.8h12.4v2.4H5.8v-2.4z"
          />
        </g>
      </g>
    </Icon>
  );
}
