import { ReactNode } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Block } from '@sermonary/types';
import { QuoteIcon } from '@sermonary/icons';
import { BibleBooks, BibleTranslations } from '@sermonary/utils';
import { SermonBlockTypeMap, Heading, StoredImage, BibleReferenceDisplay } from '.';

function getPointNumber(block: Block, pointBlocks: Block[]) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  return block?.number || pointBlocks?.findIndex((x) => x?.id === block?.id) + 1;
}

interface SermonBlockDisplayProps {
  block: Block;
  pointBlocks: Block[];
  renderActions: (block: Block) => ReactNode;
  renderIndicators: () => ReactNode;
}

export function SermonBlockDisplay({ block, pointBlocks, renderActions, renderIndicators }: SermonBlockDisplayProps) {
  const type = block?.type ?? '';
  const label = SermonBlockTypeMap?.[type]?.label;

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        {type === 'point' ? (
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              bg={SermonBlockTypeMap?.[type]?.color}
              borderRadius="md"
              color="white"
              height="16"
              fontSize="3xl"
              fontWeight="bold"
              justifyContent="center"
              mr="4"
              width="16"
            >
              <span>{getPointNumber(block, pointBlocks)}</span>
            </Flex>
            <Heading title={block?.title || ''} fontSize="2xl" textTransform="unset" />
          </Flex>
        ) : (
          <Box mb="6">
            <Heading title={type === 'custom' && block?.title ? block?.title : label} />
          </Box>
        )}
        {renderIndicators()}
        {renderActions?.(block)}
      </Flex>

      {type === 'application' || type === 'illustration' || type === 'custom' ? (
        <Box className="fr-box">
          {block?.content ? (
            <Text className="fr-wrapper" dangerouslySetInnerHTML={{ __html: block?.content }} />
          ) : (
            <Box />
          )}
        </Box>
      ) : null}

      {type === 'quote' ? (
        <Box className="fr-box">
          <Box position="relative">
            <QuoteIcon color="#6AB616" position="absolute" />
            <Box ml="8">
              {block?.content ? (
                <Text className="fr-wrapper" dangerouslySetInnerHTML={{ __html: block?.content }} />
              ) : (
                <Box />
              )}
            </Box>
          </Box>
          <Flex color="gray.900" justifyContent="flex-end" fontSize="sm">
            <Text>
              {block?.sourceName}
              {block?.sourceTitle ? `, ${block?.sourceTitle}` : null}
            </Text>
          </Flex>
        </Box>
      ) : null}

      {type === 'bible' ? (
        <Box className="fr-box">
          {block?.content ? (
            typeof block?.content !== 'string' ? (
              <BibleReferenceDisplay
                title={`${BibleBooks?.find((b) => b.value === block?.book)?.label} ${block?.chapter}:${
                  block?.verses
                } (${BibleTranslations?.find((t) => t.value === block?.translation)?.label})`}
                verses={block?.content}
              />
            ) : (
              <Text className="fr-wrapper" dangerouslySetInnerHTML={{ __html: block?.content }} />
            )
          ) : (
            <Box />
          )}
        </Box>
      ) : null}

      {type === 'media' ? (
        <div>
          {block?.imageKey || block?.legacyImage ? (
            <Box>
              <StoredImage
                legacyImage={block?.legacyImage}
                imageKey={block?.imageKey ?? ''}
                alt={block?.caption}
                crossOrigin="anonymous"
              />
              {block?.caption && (
                <Text
                  color="gray.600"
                  fontSize="xs"
                  fontStyle="italic"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  mt="2"
                  pb="2"
                >
                  {block?.caption}
                </Text>
              )}
            </Box>
          ) : (
            <Box>
              <Heading title={block?.videoTitle || ''} />
              <Text color="gray.700" mt="2">
                {block?.videoSrc}
              </Text>
            </Box>
          )}
        </div>
      ) : null}
    </Box>
  );
}
