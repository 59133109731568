import { useCallback } from 'react';
import { track } from '../analytics/Analytics';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  ModalProps,
  Button,
  Spacer,
  Divider,
  Grid,
  ModalBody,
} from '@chakra-ui/react';
import { BaseResourceItem } from '@sermonary/components';

import { print } from 'graphql';
import { useAppContext } from '../contexts/AppContext';

import { Attachment, GetDownloadLink } from '../graphql/mpfederation';

function ResourceDownloadModal({
  isOpen,
  onClose,
  resource,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { resource: BaseResourceItem }) {
  const { client } = useAppContext();

  async function handleDownloadLink(title: string, fileId: string) {
    const win = window.open('about:blank') as Window;
    try {
      const response = await client?.request({
        data: {
          query: print(GetDownloadLink),
          variables: {
            fileId,
          },
        },
      });

      await track('Download Resource Media', { mediaType: title });

      const data = response?.data?.data?.getDownloadLink;

      if (data.url) {
        win.location = data.url;
      }

      return null;
    } catch (err) {
      throw new Error(err as string);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent p={12}>
        <ModalBody px="0">
          <ModalHeader p="0">Download Media</ModalHeader>
          <ModalCloseButton top="10" right="8" />
          <Spacer mt={8} />
          <Divider size="heavy" colorScheme="gray.300" />
          <Spacer mt={8} />
          <Grid gap={4} templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}>
            {resource?.attachments
              ?.filter((att: Attachment) => att?.attachable?.attachableType === 'file')
              .map((att: Attachment, index: number) => (
                <Button
                  key={`button_${index + 1}`}
                  type="submit"
                  backgroundColor="gray.700"
                  color="white"
                  fontSize="md"
                  width="100%"
                  onClick={() => handleDownloadLink(att?.title ?? '', att?.attachable?.id ?? '')}
                >
                  Download {att.title}
                </Button>
              ))}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function useResourceDownloadModal({ resource }: { resource: BaseResourceItem }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    isOpen,
    handleOnOpen: onOpen,
    handleOnClose: onClose,
    ResourceDownloadModal: useCallback(
      () => <ResourceDownloadModal isOpen={isOpen} onClose={onClose} resource={resource} />,
      [isOpen, onClose, resource],
    ),
  };
}
