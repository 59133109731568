import { useRef, useState, useEffect, MutableRefObject } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataStore } from 'aws-amplify';
import { atom, useAtom } from 'jotai';
import { Box, Button, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { CenteredLayout, SermonBlockDisplay, SermonBlockTypeMap } from '@sermonary/components';
import { Block } from '@sermonary/types';
import { SettingsIcon, PlayIcon, PauseIcon } from '@sermonary/icons';
import Countdown, { zeroPad } from 'react-countdown';
import { track } from '../../analytics/Analytics';
import { Sermon } from '../../models';
import { cognitoUserAtom } from '../../App';
import {
  isPodiumSettingsModalOpenAtom,
  openPodiumSettingsModalAtom,
} from '../../components/modals/PodiumSettingsModal';
import { transformBlocksToArray } from '../../components/sermons/SermonBlockEditor';
import Error404 from '../Error404';
import { sermonAtom } from './SingleSermon';
import { Loading } from '../Loading';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useHandlePodiumFontSize } from 'src/hooks/useHandlePodiumFontSize';

type PodiumCountdownSettingsProps = {
  countdownOption: string;
  countdownValue: number;
  dateNow: number;
  counterPaused: boolean;
  reset: boolean;
};

const defaultPodiumCountdownSettings = {
  countdownOption: 'currentTime',
  countdownValue: 45,
  dateNow: Date.now(),
  counterPaused: true,
  reset: false,
};

export const podiumCountdownSettingsAtom = atom<PodiumCountdownSettingsProps>(defaultPodiumCountdownSettings);

function SinglePodiumView() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const counterRef = useRef<Countdown>(null);

  const [sermonId, setSermonId] = useState('');
  const [show404, setShow404] = useState(false);
  const [timerComplete, setTimerComplete] = useState(false);
  const [date, setDate] = useState(new Date());

  const [user] = useAtom(cognitoUserAtom);
  const [sermon, setSermon] = useAtom(sermonAtom);
  const [podiumCountdownSettings, setPodiumCountdownSettings] = useAtom(podiumCountdownSettingsAtom);
  const [isPodiumSettingsModalOpen] = useAtom(isPodiumSettingsModalOpenAtom);

  useHandlePodiumFontSize({ sermonId, fetchedId: sermon?.id });

  function refreshClock() {
    setDate(new Date());
  }

  const currentTime = date
    .toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
    })
    .replace('AM', '')
    .replace('PM', '');

  const printRef = useRef<HTMLDivElement>(null);

  const blocks = transformBlocksToArray(sermon?.blocks as string | Block[])?.filter(
    (b): b is Block => !b?.hideInPodium,
  );
  const pointBlocks = blocks
    ?.map((block) => (block?.type === 'point' ? block : undefined))
    ?.filter((b): b is Block => !!b);

  const slideBlocks = transformBlocksToArray(sermon?.blocks as string | Block[])?.filter(
    (b): b is Block => b?.markForSlide ?? false,
  );

  const [, openModal] = useAtom(openPodiumSettingsModalAtom);

  const handleGoBack = () => {
    navigate({
      pathname: `/sermons/${id}`,
    });
  };

  const toggleCountdownPause = () => {
    setPodiumCountdownSettings({
      ...podiumCountdownSettings,
      counterPaused: !podiumCountdownSettings?.counterPaused,
    });
  };

  function handleOnTimerComplete() {
    !isPodiumSettingsModalOpen && setTimerComplete(true);
  }

  useEffect(() => {
    if (counterRef?.current) {
      podiumCountdownSettings?.counterPaused ? counterRef.current.pause() : counterRef.current.start();

      if (podiumCountdownSettings?.reset) {
        counterRef.current.stop();
        setPodiumCountdownSettings({ ...podiumCountdownSettings, reset: false });
        setTimerComplete(false);
      }
    }
  }, [podiumCountdownSettings]);

  useEffect(() => {
    if (id) {
      setSermonId(id);
    }
  }, [id, setSermonId]);

  useEffect(() => {
    async function fetchSermon() {
      const dsSermon = await DataStore.query(Sermon, sermonId);
      dsSermon === undefined && setShow404(true);
      setSermon(dsSermon || ({} as Sermon));
    }

    if (sermonId) {
      fetchSermon();
    }

    const subscription = DataStore.observe(Sermon, sermonId).subscribe((msg) => {
      if (msg?.element?.updatedAt) {
        setSermon(msg?.element);
      }
    });

    return () => subscription.unsubscribe();
  }, [sermonId, setSermon]);

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    const fifteenMinutes = 1000 * 60 * 15;
    const podiumTimeTrack = setTimeout(() => {
      track('Podium Mode Time 15 Min', { sermonId: sermon?.id });
    }, fifteenMinutes);

    track('Open Podium Mode');

    document.body.classList.add('hideBeacon');

    return () => {
      track('Close Podium Mode').finally(() => {
        clearInterval(timerId);
        clearTimeout(podiumTimeTrack);
        setPodiumCountdownSettings(defaultPodiumCountdownSettings);
      });

      document.body.classList.remove('hideBeacon');
    };
  }, []);

  if (show404) {
    return (
      <CenteredLayout>
        <Error404 />
      </CenteredLayout>
    );
  }

  return sermonId === sermon.id ? (
    <Box bg="gray.100" pb={20} id="podium-container-id">
      {Object.keys(user || {}).length ? (
        <Flex
          as="header"
          bg="white"
          justifyContent="space-between"
          alignItems="center"
          p={5}
          position="sticky"
          top="0"
          boxShadow="sm"
          zIndex="100"
        >
          <Button onClick={handleGoBack}>Back</Button>
          <Text color={timerComplete ? 'red.500' : 'inherit'}>
            {podiumCountdownSettings?.countdownOption === 'countdown' ? (
              <>
                <Countdown
                  overtime
                  ref={counterRef}
                  autoStart={false}
                  date={
                    Number(podiumCountdownSettings?.dateNow) +
                    Number(podiumCountdownSettings?.countdownValue) * 1000 * 60
                  }
                  renderer={({ minutes, seconds }) => `${zeroPad(minutes)}:${zeroPad(seconds)}`}
                  onComplete={handleOnTimerComplete}
                />
                <Button variant="shadow" cursor="pointer" p="4" onClick={toggleCountdownPause}>
                  {podiumCountdownSettings?.counterPaused ? <PlayIcon /> : <PauseIcon />}
                </Button>
              </>
            ) : (
              currentTime
            )}
          </Text>
          <Button
            variant="shadow"
            onClick={() => {
              openModal({
                printRef: printRef as MutableRefObject<HTMLDivElement>,
                podiumContainerId: 'podium-container-id',
                slidesContainerId: 'slides-container-id',
                sermonTitle: sermon?.title ?? 'Sermon',
              });
            }}
          >
            <SettingsIcon />
          </Button>
        </Flex>
      ) : null}

      <Container ref={printRef} maxW="container.lg">
        <Box borderBottom="1px solid" borderColor="gray.300" py={12} mb={12} id="header">
          <Heading as="h1" mb={5}>
            {sermon?.title}
          </Heading>
          <Heading as="h3" fontSize="xl" mb={5}>
            {sermon?.bigIdea}
          </Heading>
          <Text as="p" fontStyle="italic">
            {sermon?.referenceVerses}
          </Text>
        </Box>

        <Grid className="sermon-print" gap={5}>
          {blocks?.map((block, index) => (
            <Box
              id="block"
              key={`block_${index + 1}`}
              bg="white"
              border="1px solid"
              borderColor="gray.100"
              borderBottom="4px solid"
              borderBottomColor={block?.color || SermonBlockTypeMap?.[block?.type]?.color}
              borderRadius="md"
              p="8"
              minW="4"
            >
              <SermonBlockDisplay
                block={block}
                pointBlocks={pointBlocks}
                renderActions={() => null}
                renderIndicators={() => null}
              />
            </Box>
          ))}
        </Grid>
      </Container>
      <Box id="slides-container-id" position={'absolute'} right={'100%'} bottom={'100%'} maxWidth="container.lg">
        <Box borderBottom="1px solid" borderColor="gray.300" py={12} mb={12} id="header">
          <Heading as="h1" mb={5}>
            {sermon?.title}
          </Heading>
          <Heading as="h3" fontSize="xl" mb={5}>
            {sermon?.bigIdea}
          </Heading>
          <Text as="p" fontStyle="italic">
            {sermon?.referenceVerses}
          </Text>
        </Box>

        <Grid gap={5}>
          {slideBlocks?.map((block, index) => (
            <Box
              id="block"
              key={`slideBlock_${index + 1}`}
              bg="white"
              border="1px solid"
              borderColor="gray.100"
              borderBottom="4px solid"
              borderBottomColor={block?.color || SermonBlockTypeMap?.[block?.type]?.color}
              borderRadius="md"
              p="8"
            >
              <SermonBlockDisplay
                block={block}
                pointBlocks={pointBlocks}
                renderActions={() => null}
                renderIndicators={() => null}
              />
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  ) : (
    <Loading />
  );
}

export default SinglePodiumView;
