import { useEffect } from 'react';

import deepEqual from 'fast-deep-equal';

import { FieldValues } from 'react-hook-form';
import { Block } from '@sermonary/types';
import { usePrevious } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { hasNewUpdateAtom } from '../pages/sermons/SingleSermon';

type UseUpdateBlockFormProps<T extends FieldValues> = {
  block: Block;
  handleReset: () => void;
};

export function useUpdateBlockForm<T extends FieldValues>({ block, handleReset }: UseUpdateBlockFormProps<T>) {
  const prevBlock = usePrevious(block);
  const [hasNewUpdate, setHasNewUpdate] = useAtom(hasNewUpdateAtom);

  useEffect(() => {
    if (prevBlock && !deepEqual(block, prevBlock) && hasNewUpdate) {
      handleReset();
      setHasNewUpdate(false);
    }
  }, [block, hasNewUpdate]);
}
