import { ReactNode, memo } from 'react';
import { atom, useAtom } from 'jotai';
import {
  Divider,
  Modal as BaseModal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Avatar,
  Text,
  Button,
  Spacer,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { Logo } from '@sermonary/components';
import { CheckMarkIcon } from '@sermonary/icons';
import { track } from '../../analytics/Analytics';

import { SubscriptionData, useSubscriptionInformation } from '../../hooks/useSubscriptionInformation';

type ModalData = {
  isSermonaryTier?: boolean;
  isSermonaryPlusTier?: boolean;
  content?: ReactNode | null;
  upgradeFeature?: string;
};

const modalContentAtom = atom<ModalData>({} as ModalData);
const isUpgradeModalOpenAtom = atom<boolean>(false);
export const openUpgradeModalAtom = atom<null, ModalData>(null, (_get, set, args) => {
  set(isUpgradeModalOpenAtom, true);
  set(modalContentAtom, args);
});
const closeUpgradeModalAtom = atom<null, boolean>(null, (_get, set) => set(isUpgradeModalOpenAtom, false));

const benefitsText = [
  'Podium Mode Countdown Timer',
  'Sermon & Event Templates',
  'Export to Slides',
  'Illustrations Library',
  '66 Commentaries',
  '66 Bible Book Summaries',
  '12 Sermon Series',
  '1 New Sermon Series Every Month',
];

const ministryPassBenefits = [
  '700+ Sermon Series',
  'Outlines',
  'Big Ideas',
  'Topic Lists',
  'Illustration Ideas',
  'Talking Points',
  'Small Group Q&A',
  'Biblical Research',
];

function SermonaryTitle() {
  return (
    <ModalHeader px="0" pb="0" flex="1">
      <Logo fontSize="lg" />
      <Spacer mt={2} />
    </ModalHeader>
  );
}

function UpgradeCheckList({
  upgradeFeature,
  upgradeUrl,
  isLoading,
  upgradeAsterisk,
}: {
  upgradeFeature: string;
  upgradeUrl: string;
  isLoading: boolean;
  upgradeAsterisk: string;
}) {
  const handleUpgradeClick = () => {
    track('Upgrade Button per Feature', { feature: upgradeFeature }).finally(() => window.location.replace(upgradeUrl));
  };

  return (
    <ModalBody p="0" w="100%">
      <Spacer mt={2} />
      <Text fontSize="sm" fontWeight="bold" textTransform="uppercase">
        Upgrade for these benefits
      </Text>
      <Spacer mt={4} />
      {benefitsText.map((text) => (
        <Flex alignItems="center" justifyContent="flex-start" mt={2}>
          <CheckMarkIcon />
          <Text fontSize="md" ml="3">
            {text}
          </Text>
        </Flex>
      ))}
      <Spacer mt={4} />
      <Button colorScheme="blue" isFullWidth isLoading={isLoading} onClick={handleUpgradeClick}>
        Upgrade{upgradeAsterisk}
      </Button>
    </ModalBody>
  );
}

function MinistryPassCheckList({
  upgradeFeature,
  upgradeUrl,
  isLoading,
  upgradeAsterisk,
}: {
  upgradeFeature: string;
  upgradeUrl: string;
  isLoading: boolean;
  upgradeAsterisk: string;
}) {
  const handleUpgradeClick = () => {
    track('Upgrade Button per Feature', { feature: upgradeFeature }).then(() => window.location.replace(upgradeUrl));
  };

  return (
    <ModalBody p="0" w="100%">
      <Spacer mt={2} />
      <Text fontSize="sm" fontWeight="bold" textTransform="uppercase">
        Upgrade for these benefits
      </Text>
      <Spacer mt={4} />
      {ministryPassBenefits.map((text) => (
        <Flex alignItems="center" justifyContent="flex-start" mt={2}>
          <CheckMarkIcon />
          <Text fontSize="md" ml="3">
            {text}
          </Text>
        </Flex>
      ))}
      <Spacer mt={4} />
      <Button colorScheme="blue" isFullWidth isLoading={isLoading} onClick={handleUpgradeClick}>
        Upgrade{upgradeAsterisk}
      </Button>
    </ModalBody>
  );
}

function Testimonials() {
  return (
    <ModalBody p="0" w="100%">
      <Text textAlign="center" alignSelf="flex-start" fontSize="sm" mt="1">
        1000s of pastors trust Sermonary in their pulpit
      </Text>
      <Spacer mt={4} />
      <Divider size="heavy" />
      <Spacer mt={4} />
      <Text alignSelf="flex-start" fontSize="md" mt="1">
        I put this to work already. It’s amazing. Podium Mode with a timer was a stroke of genius.
      </Text>
      <Spacer mt={4} />
      <Flex>
        <Avatar name="Chad Whitley" src="#" />
        <Flex flexDirection="column" ml={4} mt={1}>
          <Text fontWeight="bold" textTransform="uppercase" fontSize="sm">
            Chad Whitley
          </Text>
          <Text fontSize="sm">Pastor of Poplar Springs Baptist Church</Text>
        </Flex>
      </Flex>
      <Spacer mb={4} />
      <Divider size="heavy" />
      <Spacer mt={4} />
      <Text alignSelf="flex-start" fontSize="md" mt="1">
        I love Sermonary’s ability to keep me organized and on point and these guys are not finished yet. They continue
        to work on adding and adjusting the interface based on user feedback. I cannot recommend Sermonary enough!!
      </Text>
      <Spacer mt={4} />
      <Flex>
        <Avatar name="Jeffrey Sparks" src="#" />
        <Flex flexDirection="column" ml={4} mt={1}>
          <Text fontWeight="bold" textTransform="uppercase" fontSize="sm">
            Jeffrey Sparks
          </Text>
          <Text fontSize="sm">Pastor</Text>
        </Flex>
      </Flex>
    </ModalBody>
  );
}

function DisplayedModalContext({
  modalContent,
  subscriptionInfo,
  isLoading,
}: {
  modalContent: ModalData;
  subscriptionInfo: SubscriptionData | null | undefined;
  isLoading: boolean;
}) {
  const upgradeAsterisk = subscriptionInfo?.product === 'free' ? '*' : '';

  if (modalContent.isSermonaryTier) {
    return (
      <UpgradeCheckList
        upgradeFeature={modalContent?.upgradeFeature ?? ''}
        upgradeUrl={subscriptionInfo?.portalUpgradeURL ?? ''}
        isLoading={isLoading}
        upgradeAsterisk={upgradeAsterisk}
      />
    );
  }
  if (modalContent.isSermonaryPlusTier) {
    return (
      <MinistryPassCheckList
        upgradeFeature={modalContent?.upgradeFeature ?? ''}
        upgradeUrl={subscriptionInfo?.portalUpgradeURL ?? ''}
        isLoading={isLoading}
        upgradeAsterisk={upgradeAsterisk}
      />
    );
  }
  return (
    <>
      <SermonaryTitle />
      <UpgradeCheckList
        upgradeFeature={modalContent?.upgradeFeature ?? ''}
        upgradeUrl={subscriptionInfo?.portalUpgradeURL ?? ''}
        isLoading={isLoading}
        upgradeAsterisk={upgradeAsterisk}
      />
      <Testimonials />
    </>
  );
}

export function UpgradeModal() {
  const [isOpen] = useAtom(isUpgradeModalOpenAtom);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [modalContent] = useAtom(modalContentAtom);
  const [, onClose] = useAtom(closeUpgradeModalAtom);

  const { subscriptionInfo, isLoading } = useSubscriptionInformation({
    shouldQuery: isOpen,
  });

  const freeDisclaimer =
    subscriptionInfo?.product === 'free' ? (
      <>
        <Divider mt="10px" size="heavy" />
        <Text
          as="em"
          mt="10px"
          fontSize="sm"
        >{`* We have discontinued Sermonary's free plan. You've been grandfathered in to a legacy free plan for now. There is no free plan available after upgrading.`}</Text>
      </>
    ) : (
      ''
    );

  return (
    <BaseModal isOpen={isOpen} onClose={() => onClose(true)} size={isMobile ? 'full' : '2xl'}>
      <ModalOverlay />
      <ModalContent w={isMobile ? '100vw' : '360px'} p="4" alignItems="center">
        {isMobile && <ModalCloseButton position="fixed" top="10" right="10" />}
        <DisplayedModalContext modalContent={modalContent} subscriptionInfo={subscriptionInfo} isLoading={isLoading} />
        {freeDisclaimer}
      </ModalContent>
    </BaseModal>
  );
}
