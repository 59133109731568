import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as BaseInput,
  InputGroup,
  InputProps,
  Text,
} from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';

interface Props extends InputProps {
  isRequired?: boolean;
  label?: string | JSX.Element;
  error?: string;
  name?: string;
  id?: string;
  description?: string;
  leftAddon?: () => ReactNode;
  rightAddon?: () => ReactNode;
}

function LabelComponent({
  name = '',
  id = '',
  label,
}: {
  name?: string;
  id?: string;
  label: string | JSX.Element | undefined;
}) {
  if (label) {
    if (typeof label === 'string') {
      return (
        <FormLabel
          fontSize="sm"
          fontWeight="700"
          textTransform="uppercase"
          color="gray.900"
          letterSpacing="1px"
          htmlFor={id || name}
        >
          {label}
        </FormLabel>
      );
    }
    return label;
  }
  return null;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ isRequired, label, error, description, leftAddon, rightAddon, name, id, ...rest }, ref) => (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      <LabelComponent name={name} id={id} label={label} />
      <InputGroup bg="white" borderRadius="md">
        {leftAddon?.()}
        <BaseInput ref={ref} id={id || name} name={name} {...rest} />
        {rightAddon?.()}
      </InputGroup>
      {error ? (
        <FormErrorMessage>{error}</FormErrorMessage>
      ) : description ? (
        <Text fontSize="sm" color="gray.700" mt="2">
          {description}
        </Text>
      ) : null}
    </FormControl>
  ),
);
