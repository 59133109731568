import { Icon, IconProps } from '@chakra-ui/react';

export function LockIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.25 12.494a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1v7.756a1 1 0 0 0 1 1h16.5a1 1 0 0 0 1-1v-7.756Zm-18.5-3.75A2.75 2.75 0 0 0 0 11.494v9.756A2.75 2.75 0 0 0 2.75 24h18.5A2.75 2.75 0 0 0 24 21.25v-9.756a2.75 2.75 0 0 0-2.75-2.75H2.75Z"
          fill="currentColor"
        />
        <path
          d="M5.777 7.603a6.223 6.223 0 1 1 12.446 0"
          stroke="currentColor"
          strokeWidth="2.75"
          strokeLinecap="round"
          fill="none"
        />
        <rect x="4.402" y="5.918" width="2.75" height="4.856" rx="1.375" fill="currentColor" />
        <rect x="16.848" y="5.918" width="2.75" height="4.856" rx="1.375" fill="currentColor" />
        <rect x="10.625" y="14.24" width="2.75" height="4.26" rx="1.375" fill="currentColor" />
      </g>
    </Icon>
  );
}

export default LockIcon;
