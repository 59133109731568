import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { test, enforce, create } from 'vest';
import isEmail from 'validator/lib/isEmail';
import { Alert, AlertIcon, AlertDescription, Button, Flex, Grid, Link, Text, Circle, Collapse } from '@chakra-ui/react';
import { AuthFormInput } from '@sermonary/components';
import { AuthError } from '@sermonary/types';
import { EmailIcon, LockIcon } from '@sermonary/icons';
import { useEffect, useState } from 'react';
import { externalLinks } from '../../../utilities/externalLinks';

export interface RegisterFormValues {
  email: string;
  password: string;
}

interface RegisterFormProps {
  isError: boolean;
  isLoading: boolean;
  error?: AuthError;
  formTitle: string;
  ctaText: string;
  onSubmit: (values: RegisterFormValues) => void;
}

export interface PasswordValidationValues {
  minChars: boolean;
  upperCase: boolean;
  specialChars: boolean;
  number: boolean;
}

enforce.extend({ isEmail });

const validationSuite = create((data: Partial<RegisterFormValues> = {}) => {
  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty();
  });

  test('email', 'Must be a valid email', () => {
    enforce(data.email).isEmail();
  });
});

function BulletListItem({ text, isValid }: { text: string; isValid: boolean }) {
  return (
    <Flex alignItems="center">
      <Circle size="2" bg={isValid ? 'blue.500' : 'blue.200'} mr="2" />
      <Text fontSize="sm" color={isValid ? 'gray.900' : 'gray.700'}>
        {text}
      </Text>
    </Flex>
  );
}

export function PasswordValidation({ minChars, upperCase, specialChars, number }: PasswordValidationValues) {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="2">
      <BulletListItem isValid={minChars} text="8 characters minimum" />
      <BulletListItem isValid={upperCase} text="1 uppercase letter" />
      <BulletListItem isValid={specialChars} text="1 special character" />
      <BulletListItem isValid={number} text="1 number" />
    </Grid>
  );
}

export const containsNumberRegex = /(?=.*\d)/;
export const containsSpecialCharRegex = /(?=.*\W)/;
export const containsUpperCaseRegex = /(?=.*[A-Z])/;

export function RegisterForm({ isError, isLoading, error, formTitle, ctaText, onSubmit }: RegisterFormProps) {
  const { register, handleSubmit, formState, watch, trigger } = useForm<RegisterFormValues>({
    defaultValues: {},
    resolver: vestResolver(validationSuite),
  });

  const { errors } = formState;

  const watchPassword = watch('password');
  const watchEmail = watch('email');

  const [passwordValidation, setPasswordValidation] = useState<PasswordValidationValues>({
    minChars: false,
    upperCase: false,
    specialChars: false,
    number: false,
  });

  const [passwordHasFocus, setPasswordHasFocus] = useState(false);

  const checkPasswordValidation = () => Object.values(passwordValidation).every((value) => value === true);
  const handlePasswordOnFocus = () => setPasswordHasFocus(true);
  const handleEmailOnBlur = () => watchEmail.length && trigger('email');

  useEffect(() => {
    setPasswordValidation({
      minChars: watchPassword?.length > 7,
      upperCase: containsUpperCaseRegex.test(watchPassword),
      specialChars: containsSpecialCharRegex.test(watchPassword),
      number: containsNumberRegex.test(watchPassword),
    });
  }, [watchPassword]);

  return (
    <Grid as="form" onSubmit={handleSubmit(onSubmit)} mt="8" gap="4" templateColumns="1fr" w={['100%', null, '80']}>
      <Text as="h3" mb="2">
        {formTitle}
      </Text>
      <AuthFormInput
        label="Email"
        inputLeftElement={<EmailIcon />}
        type="email"
        registerFieldName="email"
        register={register}
        errors={errors}
        inputId="loginEmail"
        autoFocus
        onBlur={handleEmailOnBlur}
      />
      <AuthFormInput
        label="Password"
        inputLeftElement={<LockIcon />}
        type="password"
        registerFieldName="password"
        register={register}
        errors={errors}
        inputId="loginPassword"
        onFocus={handlePasswordOnFocus}
      />
      <Collapse in={passwordHasFocus} animateOpacity>
        <PasswordValidation {...passwordValidation} />
      </Collapse>
      {isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error?.message}</AlertDescription>
        </Alert>
      ) : null}
      <Flex alignItems="center">
        <Button
          isFullWidth
          type="submit"
          isLoading={isLoading}
          colorScheme="blue"
          disabled={!checkPasswordValidation() || isLoading}
        >
          {ctaText}
        </Button>
      </Flex>
      <Text mt={3} fontSize="sm">
        By clicking &quot;Create Account&quot;, I agree to Sermonary&apos;s{' '}
        <Link href={externalLinks.termsOfService} isExternal fontSize="sm">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href={externalLinks.privacyPolicy} isExternal fontSize="sm">
          Privacy Policy.
        </Link>
      </Text>
    </Grid>
  );
}
