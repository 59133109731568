import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { track } from '../../../analytics/Analytics';
import { enforce, test, create } from 'vest';
import { FormControl, Grid, Radio, RadioGroup, Stack, usePrevious, Flex, Spinner, Text } from '@chakra-ui/react';
import { BibleReferenceDisplay, TextEditor, Input, Select } from '@sermonary/components';
import { BibleBooks, BibleTranslations } from '@sermonary/utils';

export interface BibleBlockFormValues {
  inputType?: string;
  book?: string;
  chapter?: string;
  verses?: string;
  translation?: string;
  content?: string;
  importedVerses?: string[];
}

interface BibleBlockFormValidation {
  book?: { errors: string[] };
  chapter?: { errors: string[] };
  verses?: { errors: string[] };
  translation?: { errors: string[] };
}

const validate = create('select', (data: Partial<BibleBlockFormValues> = {}) => {
  test('book', 'Book is required.', () => {
    enforce(data?.book).isNotEmpty();
  });

  test('chapter', 'Chapter is required.', () => {
    enforce(data?.chapter).isNotEmpty();
  });

  test('verses', 'Verses are required.', () => {
    enforce(data?.verses).isNotEmpty();
  });

  test('translation', 'Translation is required', () => {
    enforce(data?.translation).isNotEmpty();
  });
});

export function BibleBlockForm({
  children,
  control,
  formState,
  register,
  setValue,
  getValues,
}: Pick<UseFormReturn<BibleBlockFormValues>, 'control' | 'register' | 'formState' | 'setValue' | 'getValues'> & {
  children?: ReactNode;
}) {
  const [isImporting, setIsImporting] = useState(false);
  const [translationCopyright, setTranslationCopyright] = useState('');
  const [importedVerses, setImportedVerses] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [errors, setErrors] = useState<BibleBlockFormValidation>({});

  const inputType = useWatch({
    control,
    name: 'inputType',
  });

  const values = useWatch({
    control,
  });

  const prevTranslation = usePrevious(values?.translation);
  const translationChanged = values?.translation !== prevTranslation;

  const prevBook = usePrevious(values?.book);
  const bookChanged = values?.book !== prevBook;

  const prevChapter = usePrevious(values?.chapter);
  const chapterChanged = values?.chapter !== prevChapter;

  const prevVerses = usePrevious(values?.verses);
  const versesChanged = values?.verses !== prevVerses;

  useEffect(() => {
    const content = getValues('content');

    if (inputType === 'manual') {
      setValue('book', '');
      setValue('chapter', '');
      setValue('verses', '');
      setValue('translation', '');
    }

    if (inputType === 'manual' && typeof content !== 'string') {
      setValue('content', '');
    }

    if (typeof content !== 'string') {
      setImportedVerses(content as unknown as { [key: string]: string[] });
    }
  }, [getValues, inputType, setValue]);

  const MemomizedTextEditor = useMemo(
    () => <TextEditor label="Bible Verse Body" name="content" control={control} />,
    [inputType, formState.isDirty],
  );

  const handleOnImportVerse = useDebouncedCallback(
    useCallback(() => {
      validate(values).done(async (result) => {
        if (!result.hasErrors()) {
          setErrors({});
          setIsImporting(true);

          try {
            const abbr = values?.translation.toLocaleLowerCase();
            const url = `https://s3.amazonaws.com/assets.sermonary.com/bible_translations/bible_${abbr}.json`;
            const response = await fetch(url);
            const translation = await response.json();
            const book = translation?.[values?.book];
            const chapter = book?.[`c${values?.chapter}`];
            const [start, end] = values?.verses?.trim().replace(/\s/g, '').split('-') || [];
            const startReference = parseInt(start, 10);
            const endReference = parseInt(end, 10);
            const copyright =
              BibleTranslations[
                BibleTranslations.findIndex((translation) => translation?.value === values?.translation)
              ]?.copyright;

            const verses = Object.entries<string[]>(chapter as any)?.reduce((acc: any, curr) => {
              const [ref, verse] = curr;
              const numberRef = parseInt(ref.replace(/v/g, ''), 10);

              let foundVerse: string[] = [];

              if (endReference) {
                foundVerse = numberRef >= startReference && numberRef <= endReference ? verse : [];
              } else {
                foundVerse = numberRef === startReference ? verse : [];
              }

              return {
                ...acc,
                ...(foundVerse?.length ? { [ref]: foundVerse } : {}),
              };
            }, {});

            setIsImporting(false);
            setImportedVerses(verses);
            setValue('content', verses);
            setTranslationCopyright(copyright);

            await track('Bible Verse Imported', {
              translation: values?.translation,
              book: values?.book,
              chapter: values?.chapter,
              verses: values?.verses,
            });
          } catch (err) {
            setIsImporting(false);
          }
        } else {
          setErrors(result?.tests);
        }
      });
    }, [setValue, values]),
    400,
    { maxWait: 600 },
  );

  useEffect(() => {
    const hasFormValueUpdated = translationChanged || bookChanged || chapterChanged || versesChanged;

    if (hasFormValueUpdated) {
      setImportedVerses(null);
      if (values?.book && values?.chapter && values?.verses && values?.translation) {
        handleOnImportVerse();
      }
    }
  }, [bookChanged, chapterChanged, translationChanged, versesChanged]);

  return (
    <Grid gridTemplateColumns="100%" gap="4">
      <Controller
        control={control}
        name="inputType"
        render={({ field }) => (
          <RadioGroup {...field}>
            <Stack direction="row">
              <Radio value="select" mr="2">
                Passage select
              </Radio>
              <Radio value="manual">Copy/Paste Manual</Radio>
            </Stack>
          </RadioGroup>
        )}
      />
      {inputType === 'manual' ? (
        MemomizedTextEditor
      ) : (
        <FormControl id="verseInput" border="1px solid" borderColor="gray.200" borderRadius="md" p="4">
          <Grid gridGap="4" gridTemplateColumns="repeat(auto-fit, minmax(150px, 1fr))">
            <Controller
              control={control}
              name="book"
              render={({ field }) => (
                <Select {...field} label="Book" placeholder="Select a book" error={errors?.book?.errors?.[0]}>
                  {BibleBooks?.map((book: { value: string; label: string }) => (
                    <option key={book?.value} value={book?.value}>
                      {book?.label}
                    </option>
                  ))}
                </Select>
              )}
            />
            <Input
              {...register('chapter')}
              label="Chapter"
              error={errors?.chapter?.errors?.[0]}
              type="number"
              placeholder="1"
            />
            <Input {...register('verses')} label="Passage(s)" error={errors?.verses?.errors?.[0]} placeholder="1-5" />
            <Controller
              control={control}
              name="translation"
              render={({ field }) => (
                <Select
                  {...field}
                  label="Translation"
                  placeholder="Select a translation"
                  error={errors?.translation?.errors?.[0]}
                >
                  {BibleTranslations?.map((translation: { label: string; value: string }) => (
                    <option key={translation?.value} value={translation?.value}>
                      {translation?.label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </FormControl>
      )}
      {isImporting ? (
        <Flex w="100%" h="100%" justifyContent="center" alignItems="center" padding="2rem">
          <Spinner size="lg" />
        </Flex>
      ) : null}
      {inputType === 'select' && !isImporting ? (
        <>
          <BibleReferenceDisplay
            title={`${BibleBooks?.find((b: { label: string; value: string }) => b.value === values?.book)?.label} ${
              values?.chapter
            }:${values?.verses} (${
              BibleTranslations?.find((t: { label: string; value: string }) => t.value === values?.translation)?.label
            })`}
            verses={importedVerses}
          />
          <Text
            paddingTop={'1rem'}
            borderTop={'1px solid'}
            borderColor={'gray.300'}
            color={'gray.700'}
            fontSize={'sm'}
            dangerouslySetInnerHTML={{ __html: translationCopyright }}
          />
        </>
      ) : null}

      {children}
    </Grid>
  );
}
