import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const funeralSermonScript = {
  id: 'funeralSermonScript',
  bigIdea: 'We can understand how to live our life well by learning from the saints who have gone before us.',
  sermonType: SermonType.SERMON,
  description: 'We can understand how to live our life well by learning from the saints who have gone before us.',
  title: 'Funeral Sermon Script',
  blocks: JSON.stringify({
    '0': {
      chapter: '9',
      wordCount: 61,
      book: '1CO',
      translation: 'NIV',
      inputType: 'select',
      id: uuid(),
      type: 'bible',
      verses: '24-25',
      content: {
        v25: [
          'Everyone who competes in the games goes into strict training. They do it to get a crown that will not last, but we do it to get a crown that will last forever.',
        ],
        v24: [
          'Do you not know that in a race all the runners run, but only one gets the prize? Run in such a way as to get the prize.',
        ],
      },
    },
    '1': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Introduction',
    },
    '2': {
      id: uuid(),
      color: '#000',
      wordCount: 92,
      type: 'custom',
      title: 'Introduction',
      content:
        "<p>The man who wrote this passage, Paul, uses a powerful illustration. Life is like a race. However, it is not a sprint, but a marathon. Marathon's require dedication, endurance, and commitment to longevity.&nbsp;</p><p>And just like we lean from others to accomplish great achievements, we can learn how to run our races well by eating others who have run before us. One day, our race will be over. How should we live today?</p><p><em>Optional: More specifically, we can better live our lives by learning from how [INSERT NAME OF THE DECEASED] lived their life.</em></p>",
    },
    '3': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Live Your Life Like There is a Prize at the End',
    },
    '4': {
      id: uuid(),
      color: '#000',
      wordCount: 45,
      type: 'custom',
      title: 'Explanation',
      content:
        "<p>In these two verses, Paul explains that life doesn't end with death. What we do today matters. Rather than live selfishly, or simply to make our lives comfortable, we must live in pursuit of a prize. We must live like eternity is on the horizon.</p>",
    },
    '5': {
      id: uuid(),
      wordCount: 26,
      type: 'illustration',
      content:
        "<p>Ask the family of the deceased about their family member's life. If possible, share a story about how that person lived their life committed to Christ.</p>",
    },
    '6': {
      id: uuid(),
      wordCount: 38,
      type: 'application',
      content:
        '<p>As you go about your day-to-day business, how often do you think about eternity? Living with eternity in mind will help us to love our family better, love others better, and to serve Christ with our entire being.</p>',
    },
    '7': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Learn to Sacrifice the Temporary for the Eternal',
    },
    '8': {
      id: uuid(),
      color: '#000',
      wordCount: 66,
      type: 'custom',
      title: 'Explanation',
      content:
        "<p>We all live our lives in pursuit of something--wealth, friendship, power. Paul writes that just like a runner exercises self-control in order to train for their race, we too must sacrifice. We must sacrifice some temporary things for eternal things. If we do this, at the end of our life, our trophy won't rust, fall apart, or disappear in the attic. Our trophy will be imperishable.&nbsp;</p>",
    },
    '9': {
      id: uuid(),
      wordCount: 15,
      type: 'illustration',
      content: '<p>If possible, tell a story about how the deceased sacrificed the temporary for the eternal.</p>',
    },
    '10': {
      id: uuid(),
      wordCount: 100,
      type: 'application',
      content:
        '<p>Living your life for the eternal means treating temporary things like they are temporary. We can spend so much of our lives chasing after things that will eventually disappear. Instead of simply accumulating wealth, how can we live generously? Instead of living like more hours at work will fix all of our problems, how can you spend more time serving others? Rather than simply satisfying your selfish cravings, how can you live a life of obedience to God? How can you spend more time with your family, helping them to know God? How can you glorify God with your life?</p>',
    },
    '11': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'We Receive a Prize Because Christ Has Already Won It For Us',
    },
    '12': {
      id: uuid(),
      color: '#000',
      wordCount: 284,
      type: 'custom',
      title: 'Explanation',
      content:
        '<p>The Christian faith tells us that we are all broken individuals. Even those of us considered "good" are only "good" because we compare ourselves to others. We\'ve all hurt the people around us with our actions, our words, and our thoughts.&nbsp;</p><p>Some of us attempt to make up for this on our own. We try to do good in hopes one day we\'ll prove to God that we deserve him. We can look at salvation or eternal life like a staircase, hoping we climb enough stairs to erase our flaws. This however, can lead to one three avenues:&nbsp;</p><ol><li>In the end, this leads to desperation. How do we know when we\'ve made it? How do we know we\'ve reached the "salvation" threshold?&nbsp;</li><li>This can lead to self-righteousness and hypocrisy. In order to make ourselves feel good about our place on the stairs, we compare ourselves to others. We devalue them to elevate ourselves. In turn, we hurt others with our looks, our attitudes and the placement of our hearts.</li><li>Or, it can cause us to put these questions out of our minds entirely. We can distract ourselves so we don\'t give them the time of day. We leave the most important questions in life--"Who are we?" "What are we made for?""How can we know God?"--to be left unanswered&nbsp;</li></ol><p>The story of Jesus, however, paints a different picture. You see, that\'s why the Christian faith focuses so much on Easter. Easter is the story of a perfect King who died in our place, undefeated death. His grace removes our offenses.</p><p><em>Optional: This is what I mean by [NAME OF DECEASED]\'s faith being one that typifies the gospel. Rather than falling into despair or self-righteousness, they led with compassion, care, and sacrifice.</em></p>',
    },
    '13': {
      sourceTitle: 'The Meaning of Marriage: Facing the Complexities of Commitment with the Wisdom of God',
      id: uuid(),
      sourceName: 'Timothy Keller',
      wordCount: 37,
      type: 'quote',
      content:
        '<p>The gospel is this: We are more sinful and flawed in ourselves than we ever dared believe, yet at the very same time we are more loved and accepted in Jesus Christ than we ever dared hope.</p>',
    },
    '14': {
      id: uuid(),
      wordCount: 15,
      type: 'illustration',
      content: "<p>Tell as story or two about how the deceased accepted and lived out God's grace.&nbsp;</p>",
    },
    '15': {
      id: uuid(),
      color: '#000',
      wordCount: 49,
      type: 'custom',
      title: 'Conclusion',
      content:
        '<p>What can you learn from [NAME OF DECEASED] today? How can examining how they ran their race help you to better run your race?&nbsp;</p><p>While we weep today, we can also rejoice in a life lived well--a life that saw the need to both receive grace and to give grace.</p>',
    },
  }),
};
