import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay, Text, useMediaQuery } from '@chakra-ui/react';
import { DataStore } from 'aws-amplify';
import { atom, useAtom } from 'jotai';

export const showExpiredSessionModalAtom = atom(false);

export function ExpiredSessionModal() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useAtom(showExpiredSessionModalAtom);

  function onClose() {
    setIsOpen(false);
    window.location.replace('/login');
    DataStore.clear();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : '2xl'}>
      <ModalOverlay />
      <ModalContent w={isMobile ? '100vw' : '400px'} alignSelf="center">
        <ModalBody p="0" w="100%">
          <Box p={6}>
            <Text as="h3" mb="2">
              Please Log In Again
            </Text>
            <Text mb="6">Your session expired.</Text>
            <Button colorScheme="blue" onClick={onClose}>
              Log In
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
