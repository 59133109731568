import { init as SentryInit, setUser as setSentryUser } from '@sentry/react';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { CognitoUser } from '@aws-amplify/auth';
import LogRocket from 'logrocket';
import { userBelongsToGroup, UserGroups } from '../utilities/userBelongsToGroup';
import { Rewardful } from './Rewardful';
import { version as appVersion } from '../../package.json';
import { Beacon, initHelpScout } from './HelpScout';

const writeKey = import.meta.env.VITE_SEGMENT_WRITE_KEY || '';
const segment = AnalyticsBrowser.load({ writeKey });

function initSentry() {
  SentryInit({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false })],
    tracesSampleRate: 0.01,
    environment: import.meta.env.VITE_ENV_NAME,
  });
}

function initGoogleTagManager() {
  const gtmId = import.meta.env.VITE_GTM_ID || '';
  TagManager.initialize({ gtmId });
}

function initRewardful() {
  const rewardfulId = import.meta.env.VITE_REWARDFUL_ID || '';
  Rewardful.getInstance().initialize(rewardfulId);
}

export async function identifyUser(user: CognitoUser & { attributes: any }) {
  const userId = user?.attributes?.sub || '';

  if (userId === '') {
    return;
  }

  const groups = ['MinistryPassSubscribers', 'PremiumSubscribers', 'AppUsers'];

  const userTier = groups.reduce((highestTier, group) => {
    if (highestTier !== '') {
      return highestTier;
    }

    if (userBelongsToGroup(user, group as UserGroups)) {
      return group;
    }

    return highestTier;
  }, '');

  setSentryUser({ id: userId });

  const firstName = user?.attributes?.name || '';
  const lastName = user?.attributes?.family_name || '';
  const fullName = `${firstName} ${lastName}`.trim();

  Beacon('identify', {
    name: fullName,
    email: user?.attributes?.email || '',
    sermonaryUserTier: userTier,
    sermonaryUserId: user?.attributes?.sub || '',
    logRocketSessionUrl: LogRocket.sessionURL || '',
  });

  LogRocket.identify(user?.attributes?.sub, {
    name: fullName,
    email: user?.attributes?.email || '',
    userTier,
  });

  await segment.identify(userId, {
    userTier,
    email: user?.attributes?.email || '',
  });
}

function globalProperties() {
  return { appVersion };
}

export async function track(eventName: string, properties?: Record<string, any>, options?: Record<string, any>) {
  const combinedProperties = {
    ...globalProperties(),
    ...(properties || {}),
  };

  await segment.track(eventName, combinedProperties, options);
}

export const Analytics = {
  init: () => {
    initSentry();
    initGoogleTagManager();
    initRewardful();
    initHelpScout();
  },
};
