import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import { Input, TextEditor } from '@sermonary/components';

export interface QuoteBlockFormValues {
  content: string;
  sourceName?: string;
  sourceTitle?: string;
}

export function QuoteBlockForm({
  children,
  control,
  register,
  formState,
}: Pick<UseFormReturn<QuoteBlockFormValues>, 'register' | 'formState' | 'control'> & {
  children?: ReactNode;
}) {
  const { errors } = formState;

  return (
    <Grid gridTemplateColumns="100%" gap="4">
      <TextEditor label="Content" name="content" control={control as any} />
      <Grid gridTemplateColumns="1fr 1fr" gap="4">
        <Input {...register('sourceName')} label="Source name" error={errors?.sourceName?.message} />
        <Input {...register('sourceTitle')} label="Source title" error={errors?.sourceTitle?.message} />
      </Grid>
      {children}
    </Grid>
  );
}
