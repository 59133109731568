declare global {
  interface Window {
    Beacon: any;
  }
}

const helpScoutBeaconId = import.meta.env.VITE_HELPSCOUT_BEACON_ID || '';

export const articles = {
  training: '41',
};

export const Beacon = (method: string, options?, data?) => {
  window.Beacon(method, options, data);
};

export const openBeacon = () => {
  Beacon('open');
};

export const openArticle = (articleId: string) => {
  Beacon('article', articleId, { type: 'modal' });
};

export function initHelpScout() {
  // adapted from Help Scout beacon code
  window.Beacon = (method, options, data) => {
    window.Beacon.readyQueue.push({ method, options, data });
  };

  window.Beacon.readyQueue = [];

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://beacon-v2.helpscout.net';
  script.onload = () => {
    Beacon('init', helpScoutBeaconId);
  };

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode?.insertBefore(script, firstScript);
}
