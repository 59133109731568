import { Icon, IconProps } from '@chakra-ui/react';

export function OfflineIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.36 23.597a1.374 1.374 0 1 1-1.944-1.946l2.925-2.926a5.148 5.148 0 0 1-2.63-2.188 4.89 4.89 0 0 1-.709-2.554A4.89 4.89 0 0 1 .728 11.4a5.101 5.101 0 0 1 2.6-2.155c.256-3.813 3.498-6.775 7.387-6.755A7.399 7.399 0 0 1 16.657 5.4L21.652.403a1.375 1.375 0 1 1 1.945 1.946l-5.476 5.478h.097l.046.001C21.387 7.831 24 10.288 24 13.42c0 3.13-2.613 5.588-5.736 5.59l-.046.002H6.944L2.36 23.596ZM14.684 7.376a4.593 4.593 0 0 0-1.757-1.608 1.382 1.382 0 0 1-.257-.09 4.7 4.7 0 0 0-1.955-.438 4.7 4.7 0 0 0-1.955.438c-.085.04-.172.07-.26.09-1.451.758-2.429 2.247-2.437 3.939 0 .105-.013.207-.036.305a1.376 1.376 0 0 1-1.185 1.678c-.325.037-.65.143-.956.328a2.354 2.354 0 0 0-.858.896c-.18.332-.272.7-.274 1.069a2.278 2.278 0 0 0 .342 1.186c.188.306.453.575.79.779.275.166.568.27.86.314a1.39 1.39 0 0 1 .101-.003h.958l8.879-8.883Zm3.58 8.884c1.695-.003 2.986-1.319 2.986-2.841 0-1.523-1.29-2.838-2.986-2.84H16.77c-.386 0-.735-.159-.984-.415L9.694 16.26H18.264Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default OfflineIcon;
