import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Box, BoxProps, Link, Grid, Icon } from '@chakra-ui/react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { ArchiveIcon, SermonsIcon, TemplatesIcon, IdeasIcon, ResourcesIcon } from '@sermonary/icons';
import { memo, ReactNode } from 'react';
import { Logo, LogoMark } from '.';
import { articles, openArticle, openBeacon } from '../../analytics/HelpScout';

function SidebarDivider({ isExpanded, ...props }: BoxProps & { isExpanded: boolean }) {
  return (
    <Box
      h="2px"
      bg="gray.200"
      borderRadius="sm"
      m="0 auto"
      mx={isExpanded ? 'auto' : '4'}
      minW={isExpanded ? '40' : '6'}
      transition="all 0.3s ease-in-out"
      {...props}
    />
  );
}

function SidebarToggle({ isExpanded }: { isExpanded: boolean }) {
  return !isExpanded ? (
    <Icon width="38px" height="38px" fill="none" position="relative" zIndex="1" viewBox="0 0 38 38">
      <g filter="url(#filter0_d)">
        <circle cx="19" cy="19" r="16" fill="#fff" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.403 11.403a1.375 1.375 0 011.833-.1l.111.1 6.541 6.537a1.378 1.378 0 01.1 1.836l-.118.13-6.523 6.52a1.375 1.375 0 01-2.045-1.833l.1-.112 5.567-5.567-5.566-5.567a1.375 1.375 0 01-.1-1.833l.1-.111z"
        fill="#353D41"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.194182 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </Icon>
  ) : (
    <Icon width="38px" height="38px" fill="none" position="relative" zIndex="1" viewBox="0 0 38 38">
      <g filter="url(#filter0_d)">
        <circle cx="19" cy="19" r="16" fill="#fff" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.888 11.403a1.375 1.375 0 00-1.833-.1l-.111.1-6.541 6.537a1.378 1.378 0 00-.1 1.836l.118.13 6.523 6.52a1.375 1.375 0 002.044-1.833l-.1-.112-5.566-5.567 5.566-5.567a1.375 1.375 0 00.1-1.833l-.1-.111z"
        fill="#353D41"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.194182 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </Icon>
  );
}

function SidebarLink({
  isExpanded,
  to,
  location,
  title,
  children,
}: {
  isExpanded: boolean;
  to: string;
  title?: string;
  children: (props: { isExpanded: boolean; isActive: boolean }) => ReactNode;
  location: LocationState;
}) {
  const isActive = to === location?.pathname;

  return (
    <Link
      as={RouterLink}
      to={to}
      transition="color 0.3s ease-in-out"
      _hover={{
        svg: {
          color: 'blue.500',
        },
      }}
      _after={{
        display: 'block',
        content: `"${title}"`,
        fontWeight: 'semibold',
        height: '1px',
        color: 'transparent',
        overflow: 'hidden',
        visibility: 'hidden',
      }}
      sx={
        isActive
          ? {
              color: 'gray.900',
              fontWeight: 'semibold',
            }
          : { color: 'gray.900' }
      }
    >
      <Box alignItems="center" display="flex" flexDirection={isExpanded ? 'row' : 'column'}>
        {children({ isExpanded, isActive })}
      </Box>
    </Link>
  );
}

function SecondaryMenu({
  isExpanded,
  onSignout,
  externalLinks,
}: {
  isExpanded: boolean;
  onSignout?: () => void;
  externalLinks: Record<string, string>;
}) {
  return (
    <Grid
      templateColumns="1fr"
      gap="8"
      py="10"
      fontSize={isExpanded ? 'md' : 'sm'}
      lineHeight="6"
      textAlign={isExpanded ? 'left' : 'center'}
      transition="all 0.3s ease-in-out"
    >
      <Link onClick={openBeacon} color="gray.900">
        Support
      </Link>
      <Link onClick={() => openArticle(articles.training)} color="gray.900">
        Training
      </Link>
      <Link as={RouterLink} to="/account" color="gray.900">
        Account
      </Link>
      <Button
        color="gray.900"
        justifyContent={isExpanded ? 'flex-start' : 'center'}
        variant="link"
        fontSize={isExpanded ? 'md' : 'sm'}
        lineHeight="6"
        onClick={onSignout}
      >
        Log Out
      </Button>
    </Grid>
  );
}

interface LocationState {
  pathname: string;
}

type SidebarProps = {
  handleSignOut?: () => Promise<void>;
  renderSubscribeButton: () => ReactNode;
  externalLinks: Record<string, string>;
  isCanceledUser: boolean;
  isFreeUser: boolean;
  subscriptionInfoFetched: boolean;
  hasSubscription: boolean;
};

export const Sidebar = memo(
  ({
    handleSignOut,
    renderSubscribeButton,
    externalLinks,
    isCanceledUser,
    isFreeUser,
    subscriptionInfoFetched,
    hasSubscription,
  }: SidebarProps) => {
    const location = useLocation();
    const [isExpanded] = useLocalStorage<boolean>('isSidebarExpanded', true);

    const handleToggleSidebar = () => {
      if (isExpanded) {
        writeStorage('isSidebarExpanded', false);
      } else {
        writeStorage('isSidebarExpanded', true);
      }
    };

    return (
      <Box position="relative" boxShadow="0 0 3px rgba(0, 0, 0, 0.2)" zIndex="102">
        <Box
          bg="white"
          minHeight="100%"
          maxH="100vh"
          overflowY="auto"
          p={isExpanded ? '10' : '4'}
          py="10"
          position="relative"
          transition="all 0.3s ease-in-out"
          zIndex="10"
        >
          <Link as={RouterLink} to="/sermons" outline="none" display="block" textAlign={isExpanded ? 'left' : 'center'}>
            {isExpanded ? <Logo /> : <LogoMark />}
          </Link>
          <SidebarDivider isExpanded={isExpanded} mt="10" />
          <Grid
            templateColumns="1fr"
            gap="10"
            py="10"
            fontSize={isExpanded ? 'md' : 'sm'}
            lineHeight="6"
            textAlign={isExpanded ? 'left' : 'center'}
            transition="all 0.3s ease-in-out"
          >
            {subscriptionInfoFetched &&
              (isCanceledUser ? (
                <Button as={RouterLink} to="/reactivate" fontSize="md" colorScheme="blue">
                  Reactivate
                </Button>
              ) : !hasSubscription ? (
                renderSubscribeButton?.()
              ) : isFreeUser ? (
                <Button as={RouterLink} to="/tier-upgrade" fontSize="md" colorScheme="blue">
                  Subscribe
                </Button>
              ) : (
                <>
                  <SidebarLink isExpanded={isExpanded} to="/sermons" location={location} title="Sermons">
                    {({ isActive, isExpanded: isLinkExpanded }) => (
                      <>
                        <SermonsIcon
                          mb={isLinkExpanded ? 0 : 2}
                          mr={isLinkExpanded ? 6 : 0}
                          color={isActive ? 'blue.500' : 'inherit'}
                          transition="all 0.3s ease-in-out"
                        />
                        Sermons
                      </>
                    )}
                  </SidebarLink>
                  <SidebarLink isExpanded={isExpanded} to="/templates" location={location} title="Templates">
                    {({ isActive, isExpanded: isLinkExpanded }) => (
                      <>
                        <TemplatesIcon
                          mb={isLinkExpanded ? 0 : 2}
                          mr={isLinkExpanded ? 6 : 0}
                          color={isActive ? 'blue.500' : 'inherit'}
                          transition="all 0.3s ease-in-out"
                        />
                        Templates
                      </>
                    )}
                  </SidebarLink>
                  <SidebarLink isExpanded={isExpanded} to="/ideas" location={location} title="Ideas">
                    {({ isActive, isExpanded: isLinkExpanded }) => (
                      <>
                        <IdeasIcon
                          mb={isLinkExpanded ? 0 : 2}
                          mr={isLinkExpanded ? 6 : 0}
                          color={isActive ? 'blue.500' : 'inherit'}
                          transition="all 0.3s ease-in-out"
                        />
                        Ideas
                      </>
                    )}
                  </SidebarLink>
                  <SidebarLink isExpanded={isExpanded} to="/resources" location={location} title="Resources">
                    {({ isActive, isExpanded: isLinkExpanded }) => (
                      <>
                        <ResourcesIcon
                          mb={isLinkExpanded ? 0 : 2}
                          mr={isLinkExpanded ? 6 : 0}
                          color={isActive ? 'blue.500' : 'inherit'}
                          transition="all 0.3s ease-in-out"
                        />
                        Resources
                      </>
                    )}
                  </SidebarLink>
                  <SidebarLink isExpanded={isExpanded} to="/archive" location={location} title="Archive">
                    {({ isActive, isExpanded: isLinkExpanded }) => (
                      <>
                        <ArchiveIcon
                          mb={isLinkExpanded ? 0 : 2}
                          mr={isLinkExpanded ? 6 : 0}
                          color={isActive ? 'blue.500' : 'inherit'}
                          transition="all 0.3s ease-in-out"
                        />
                        Archive
                      </>
                    )}
                  </SidebarLink>
                </>
              ))}
          </Grid>
          <SidebarDivider isExpanded={isExpanded} />
          <SecondaryMenu isExpanded={isExpanded} onSignout={handleSignOut} externalLinks={externalLinks} />
        </Box>
        <Button position="absolute" top="34px" right="-22px" variant="ghost" onClick={handleToggleSidebar}>
          <SidebarToggle isExpanded={isExpanded} />
        </Button>
      </Box>
    );
  },
);
