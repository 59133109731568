export const externalLinks = {
  changelog: 'https://sermonary.canny.io/changelog',
  customTemplateTutorial: 'https://sermonary.com/getting-started-how-to-create-a-custom-template',
  newSupportRequest: 'https://support.sermonary.com/',
  pricingPage: 'https://sermonary.com/pricing',
  privacyPolicy: 'https://sermonary.com/privacy-policy',
  supportSite: 'https://support.sermonary.com',
  termsOfService: 'https://sermonary.com/terms-of-service',
  trainingLink: 'https://sermonary.com/intro-to-sermonary',
};
