/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../types';

const defaultOptions = {} as const;
export type ResourceQueryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ResourceQueryQuery = {
  __typename?: 'Query';
  resource?: {
    __typename?: 'Resource';
    id: string;
    title: string;
    slug: string;
    description?: string | null;
    shortDescription?: string | null;
    whatsIncluded?: Array<string | null> | null;
    content?: string | null;
    resourceType: Types.ResourceType;
    seriesLength?: number | null;
    featuredImage?: any | null;
    groupsWithAccess?: Array<string | null> | null;
    authorized?: boolean | null;
    attachments?: Array<{
      __typename?: 'Attachment';
      id: string;
      title: string;
      attachable?:
        | {
            __typename?: 'File';
            id: string;
            contentType?: string | null;
            filename: string;
            path: string;
            key: string;
            attachableType?: string | null;
          }
        | {
            __typename?: 'Sermon';
            id: string;
            bigIdea?: string | null;
            description?: string | null;
            title?: string | null;
            referenceVerses?: Array<string | null> | null;
            blocks?: any | null;
            attachableType?: string | null;
          }
        | {
            __typename?: 'SermonSeries';
            id: string;
            title?: string | null;
            attachableType?: string | null;
            sermons?: Array<{
              __typename?: 'Sermon';
              id: string;
              bigIdea?: string | null;
              description?: string | null;
              title?: string | null;
              referenceVerses?: Array<string | null> | null;
              blocks?: any | null;
            } | null> | null;
          }
        | null;
    } | null> | null;
    facets?: Array<{
      __typename?: 'Facet';
      id: string;
      name: string;
      facetType: { __typename?: 'FacetType'; id: string; name: string };
    } | null> | null;
  } | null;
};

export const ResourceQueryDocument = gql`
  query resourceQuery($id: ID!) {
    resource(id: $id) {
      id
      title
      slug
      description
      shortDescription
      whatsIncluded
      content
      resourceType
      seriesLength
      featuredImage
      groupsWithAccess
      authorized
      attachments {
        id
        title
        attachable {
          ... on File {
            id
            contentType
            filename
            path
            key
            attachableType
          }
          ... on Sermon {
            id
            bigIdea
            description
            title
            referenceVerses
            blocks
            attachableType
          }
          ... on SermonSeries {
            id
            title
            sermons {
              id
              bigIdea
              description
              title
              referenceVerses
              blocks
            }
            attachableType
          }
        }
      }
      facets {
        id
        name
        facetType {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useResourceQueryQuery__
 *
 * To run a query within a React component, call `useResourceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ResourceQueryQuery, ResourceQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceQueryQuery, ResourceQueryQueryVariables>(ResourceQueryDocument, options);
}
export function useResourceQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResourceQueryQuery, ResourceQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceQueryQuery, ResourceQueryQueryVariables>(ResourceQueryDocument, options);
}
export type ResourceQueryQueryHookResult = ReturnType<typeof useResourceQueryQuery>;
export type ResourceQueryLazyQueryHookResult = ReturnType<typeof useResourceQueryLazyQuery>;
export type ResourceQueryQueryResult = Apollo.QueryResult<ResourceQueryQuery, ResourceQueryQueryVariables>;
