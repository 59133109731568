/* eslint-disable react/no-unstable-nested-components */
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { atom, useAtom } from 'jotai';
import ReactToPrint from 'react-to-print';
import pptxgen from 'pptxgenjs';
import {
  Box,
  Button,
  Divider,
  Grid,
  Input as FormInput,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal as BaseModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  RadioGroup,
  Radio,
  Spacer,
  Spinner,
  Stack,
  Text,
  useClipboard,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
} from '@chakra-ui/react';

import { Label, Input, isOnlineAtom, Tooltip } from '@sermonary/components';
import { track } from '../../analytics/Analytics';
import { toPdf, toWord, toPowerPoint, toProPresenter, getImageBase64 } from '../../utilities/fileDownloads';
import { getSermonWordCount } from '../sermons/SermonBlockEditor';
import { sermonAtom } from '../../pages/sermons/SingleSermon';
import { podiumCountdownSettingsAtom } from '../../pages/sermons/SinglePodiumView';
import { cognitoUserAtom, isFreeUserAtom, subscriptionInfoAtom } from '../../App';
import { podiumSettingsStorageAtom } from 'src/hooks/useHandlePodiumFontSize';
import { chunkWordsToArray } from 'src/utilities/chunkWordsToArray';

type PodiumViewSettingsDialogProps = {
  printRef: MutableRefObject<HTMLDivElement>;
  podiumContainerId: string;
  slidesContainerId: string;
  sermonTitle: string;
};

function UpgradePopover({ upgradeFeature, upgradeUrl, children }: any) {
  const initialFocusRef = useRef(null);
  const handleUpgradeClick = async () => {
    await track('Upgrade Button per Feature', { feature: upgradeFeature });
    window.location.replace(upgradeUrl);
  };

  return (
    <Popover initialFocusRef={initialFocusRef} placement="top-start" closeOnBlur={false}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent bg="white">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          You found a paid feature!
          <Spacer mt={4} />
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>Upgrade to access this feature</PopoverBody>
        <PopoverFooter border="0" d="flex" alignItems="center" justifyContent="space-between" pb={4}>
          <Button colorScheme="blue" isFullWidth onClick={handleUpgradeClick}>
            Upgrade
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

const modalContentAtom = atom<PodiumViewSettingsDialogProps>({} as PodiumViewSettingsDialogProps);
export const isPodiumSettingsModalOpenAtom = atom<boolean>(false);
export const openPodiumSettingsModalAtom = atom<boolean, PodiumViewSettingsDialogProps>(false, (_get, set, args) => {
  set(isPodiumSettingsModalOpenAtom, true);
  set(modalContentAtom, args);
});

const closePodiumSettingsModalAtom = atom<boolean, boolean>(false, (_get, set) =>
  set(isPodiumSettingsModalOpenAtom, false),
);

export function PodiumSettings() {
  const location = useLocation();
  const shareUrl = `${window.location.origin}${location?.pathname}/share`;
  const { hasCopied, onCopy } = useClipboard(shareUrl);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [pptx, setPptx] = useState(new pptxgen());
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [sermonWordCount, setSermonWordCount] = useState(0);

  const counterInputRef = useRef(null);

  const [sermon] = useAtom(sermonAtom);
  const [user] = useAtom(cognitoUserAtom);
  const [isOnline] = useAtom(isOnlineAtom);
  const [podiumCountdownSettings, setPodiumCountdownSettings] = useAtom(podiumCountdownSettingsAtom);
  const [modalContent] = useAtom(modalContentAtom);
  const [isOpen, setIsOpen] = useAtom(isPodiumSettingsModalOpenAtom);
  const [, onClose] = useAtom(closePodiumSettingsModalAtom);
  const [subscriptionInfo] = useAtom(subscriptionInfoAtom);
  const [isFreeUser] = useAtom(isFreeUserAtom);
  const [{ fontSizeAdjustment, ...podiumSettingsStorage }, setPodiumSettingsStorage] =
    useAtom(podiumSettingsStorageAtom);

  const isAuthenticated = user && !!Object.keys(user).length;

  async function generatePowerPointSlides(element: Element & any) {
    const id = element.getAttribute('id');
    let slide: pptxgen.Slide;

    switch (id) {
      case 'header':
        slide = pptx.addSlide();

        slide.addText(element.innerText, {
          x: 1,
          w: '80%',
          h: '90%',
          fontSize: 30,
          align: 'center',
          color: pptx.SchemeColor.text1,
        });
        break;
      case 'block':
        const hasImage = element.getElementsByTagName('img');

        if (hasImage.length) {
          const image: HTMLImageElement = hasImage[0];
          const imgBase64 = await getImageBase64(image);
          const imageWidthInches = image.width / 100;
          const imageHeightInches = image.height / 100;
          const defaultSlideWidthInches = 10;
          const defaultSlideHeightInches = 5.625;

          slide = pptx.addSlide();
          slide.background = { color: pptx.SchemeColor.background2 };
          slide.addImage({
            data: imgBase64,
            w: imageWidthInches,
            h: imageHeightInches,
            x: 0.5,
            sizing: {
              type: 'contain',
              w: defaultSlideWidthInches - 1,
              h: defaultSlideHeightInches - 1,
            },
          });
          image?.alt &&
            slide.addText(image.alt, {
              w: '80%',
              x: '10%',
              y: defaultSlideHeightInches - 0.5,
              fontSize: 12,
              align: 'center',
              color: pptx.SchemeColor.text1,
            });
        } else {
          const chunks = chunkWordsToArray(element.innerText, 40);
          chunks.map((chunk) => {
            slide = pptx.addSlide();
            slide.background = { color: pptx.SchemeColor.background2 };

            slide.addText(chunk, {
              x: 1,
              w: '80%',
              h: '90%',
              fontSize: 30,
              align: 'center',
              color: pptx.SchemeColor.text1,
            });
          });
        }
        break;
      default:
        break;
    }
  }

  async function iterateChildren(element: (Element & any) | null, callback: (element: Element) => Promise<void>) {
    if (!element) return;
    await callback(element);

    if (!element.children || element.children.length === 0) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const child of element.children) {
      await iterateChildren(child, callback);
    }
  }

  async function handleOnDownload(type: 'pdf' | 'word' | 'propresenter' | 'powerpoint') {
    const target = modalContent.slidesContainerId;
    const element: Element = document.getElementById(target) || ({} as Element);
    const isOwner = user?.username ? user.username === sermon?.owner : false;
    setIsLoadingState(true);

    switch (type) {
      case 'pdf':
        await toPdf(modalContent.printRef, modalContent.sermonTitle);
        await track('Export to PDF', { sermonId: sermon.id, isOwner, isLoggedIn: isAuthenticated });
        break;
      case 'word':
        await toWord(isAuthenticated, modalContent.printRef, modalContent.sermonTitle);
        await track('Export to Word', { sermonId: sermon.id, isOwner, isLoggedIn: isAuthenticated });
        break;
      case 'propresenter':
        await iterateChildren(element, generatePowerPointSlides);
        await toProPresenter(isAuthenticated, pptx, modalContent.printRef, modalContent.sermonTitle);
        await track('Export to ProPresenter', { sermonId: sermon.id, isOwner, isLoggedIn: isAuthenticated });
        setPptx(new pptxgen());
        break;
      case 'powerpoint':
        await iterateChildren(element, generatePowerPointSlides);
        await toPowerPoint(pptx, modalContent.sermonTitle);
        await track('Export to PowerPoint', { sermonId: sermon.id, isOwner, isLoggedIn: isAuthenticated });
        setPptx(new pptxgen());
        break;
      default:
        break;
    }
    setIsLoadingState(false);
  }

  const handleOnSave = () => {
    setIsOpen(false);
  };

  const handleChangeCounterSettings = (nextValue: string) => {
    setPodiumCountdownSettings({
      ...podiumCountdownSettings,
      dateNow: Date.now(),
      countdownOption: nextValue,
    });
    nextValue === 'countdown' && track('Countdown Timer Set', { timerSettings: nextValue });
  };

  const handleDecreaseFontSize = () => {
    setPodiumSettingsStorage({
      ...podiumSettingsStorage,
      fontSizeAdjustment: fontSizeAdjustment - 10,
    });
    track('Decrease Font Size');
  };

  const handleIncreaseFontSize = () => {
    setPodiumSettingsStorage({
      ...podiumSettingsStorage,
      fontSizeAdjustment: fontSizeAdjustment + 10,
    });
    track('Increase Font Size');
  };

  const handleCopyUrl = () => {
    onCopy();
    track('Share Podium Sermon', { pathname: window.location.pathname });
  };

  const handleAfterPrint = () => track('Print Sermon');

  function handleResetCountDown() {
    setPodiumCountdownSettings({
      ...podiumCountdownSettings,
      reset: true,
      counterPaused: true,
      dateNow: Date.now(),
      countdownValue: Number(counterInputRef.current.value),
    });
  }

  useEffect(() => {
    if (Object.values(sermon).length) {
      setSermonWordCount(getSermonWordCount(sermon));
    }
  }, [sermon]);

  return (
    <BaseModal isOpen={isOpen} size={isMobile ? 'full' : 'xl'} onClose={() => onClose(true)}>
      <ModalOverlay />
      <ModalContent px="10" py="6">
        {
          <>
            <ModalHeader px="0">Podium mode settings</ModalHeader>
            <ModalCloseButton top="10" right="8" />
            <Spacer mt={4} />
            <Divider size="heavy" colorScheme="gray.300" />
            <Spacer mt={4} />
            <ModalBody px="0">
              <Grid gap={5}>
                {isAuthenticated ? (
                  <Grid gap={4} templateColumns="repeat(2, 1fr)">
                    <Box>
                      <Label>Counter Settings</Label>
                      <RadioGroup
                        onChange={handleChangeCounterSettings}
                        value={podiumCountdownSettings?.countdownOption}
                      >
                        <Stack>
                          <Radio value="currentTime">Show current time</Radio>
                          <Radio value="countdown">Countdown time limit</Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                    <Box>
                      <Label>Word Count</Label>
                      <Text color="gray.700" fontSize="2xl">
                        {sermonWordCount}
                      </Text>
                    </Box>
                  </Grid>
                ) : null}
                {podiumCountdownSettings?.countdownOption === 'countdown' ? (
                  <Box display="flex" alignItems="end">
                    <Input
                      isDisabled={!podiumCountdownSettings?.counterPaused}
                      label="Countdown minutes"
                      type="number"
                      defaultValue={podiumCountdownSettings?.countdownValue}
                      ref={counterInputRef}
                      onChange={handleResetCountDown}
                    />
                    <Button onClick={handleResetCountDown}>Reset</Button>
                  </Box>
                ) : null}
                <Box>
                  <Label>View Settings</Label>
                  <Grid gap={4} templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} mt={4}>
                    <Button
                      backgroundColor="gray.200"
                      disabled={fontSizeAdjustment <= 100}
                      onClick={handleDecreaseFontSize}
                    >
                      Decrease font size -
                    </Button>
                    <Button
                      backgroundColor="gray.300"
                      disabled={fontSizeAdjustment >= 250}
                      onClick={handleIncreaseFontSize}
                      _hover={{
                        backgroundColor: 'gray.400',
                      }}
                    >
                      Increase font size +
                    </Button>
                  </Grid>
                </Box>
                {isAuthenticated ? (
                  <Box>
                    <Label htmlFor="shareUrl">Share Settings</Label>
                    <Text mb={4} mt={-2} ml={1} alignSelf="flex-start" color="gray.700" fontSize="sm">
                      Share this URL to allow anyone to view your sermon in Podium Mode.
                    </Text>
                    <InputGroup>
                      <FormInput
                        backgroundColor="gray.200"
                        color="gray.900"
                        id="shareUrl"
                        value={shareUrl}
                        isReadOnly
                      />
                      <InputRightElement width="4.5rem">
                        <Button colorScheme="blue" h="1.75rem" size="sm" onClick={handleCopyUrl}>
                          {hasCopied ? 'Copied' : 'Copy'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                ) : null}
                <Box>
                  <Label>Export Settings</Label>
                  <Grid gap={4} templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}>
                    <Menu>
                      {isFreeUser ? (
                        <UpgradePopover
                          upgradeFeature="Podium Settings: Download Presentation"
                          upgradeUrl={subscriptionInfo?.portalUpgradeURL}
                        >
                          <Button
                            backgroundColor="gray.600"
                            color="white"
                            fontSize="md"
                            as={Button}
                            w="100%"
                            _hover={{
                              backgroundColor: 'gray.700',
                            }}
                          >
                            Download Presentation...
                          </Button>
                        </UpgradePopover>
                      ) : (
                        <Tooltip shouldWrapChildren isDisabled={isOnline} label="Not Available Offline">
                          <MenuButton
                            backgroundColor="gray.600"
                            color="white"
                            fontSize="md"
                            as={Button}
                            w="100%"
                            disabled={!isOnline}
                            _hover={{
                              backgroundColor: 'gray.700',
                            }}
                          >
                            Download Presentation...
                          </MenuButton>
                        </Tooltip>
                      )}

                      <MenuList>
                        <MenuItem onClick={() => handleOnDownload('propresenter')}>Download ProPresenter</MenuItem>

                        <MenuItem onClick={() => handleOnDownload('powerpoint')}>Download PowerPoint</MenuItem>
                      </MenuList>
                    </Menu>
                    <Menu>
                      <Tooltip shouldWrapChildren isDisabled={isOnline} label="Not Available Offline">
                        <MenuButton
                          backgroundColor="gray.600"
                          color="white"
                          fontSize="md"
                          as={Button}
                          w="100%"
                          disabled={!isOnline}
                          _hover={{
                            backgroundColor: 'gray.700',
                          }}
                        >
                          Download Document...
                        </MenuButton>
                      </Tooltip>
                      <MenuList>
                        <MenuItem onClick={() => handleOnDownload('pdf')}>Download PDF</MenuItem>
                        <MenuItem onClick={() => handleOnDownload('word')}>Download Word</MenuItem>
                      </MenuList>
                    </Menu>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          backgroundColor="gray.600"
                          color="white"
                          fontSize="md"
                          w="100%"
                          _hover={{
                            backgroundColor: 'gray.700',
                          }}
                        >
                          Print
                        </Button>
                      )}
                      content={() => modalContent.printRef.current}
                      pageStyle="@media print { .sermon-print { display: block !important; } #block { margin-bottom: 40px;} }"
                      onAfterPrint={handleAfterPrint}
                    />
                  </Grid>
                </Box>
              </Grid>
            </ModalBody>
            <ModalFooter justifyContent="left" p="0" mt="8" mb="4">
              {isAuthenticated ? (
                <Button onClick={handleOnSave} colorScheme="blue">
                  Save Changes
                </Button>
              ) : null}
              {isLoadingState && <Spinner position="absolute" left="50%" />}
            </ModalFooter>
          </>
        }
      </ModalContent>
    </BaseModal>
  );
}
