import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { AuthError } from '@sermonary/types';
import { useSignin } from './use-signin';

enum State {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

interface UseSignupOptions {
  onSuccess?: () => void;
  onError?: (error: AuthError) => void;
}

export function useSignup({ onSuccess, onError }: UseSignupOptions) {
  const [state, setState] = useState<State>(State.Idle);
  const [error, setError] = useState<AuthError>();

  const { handleSignin, ...rest } = useSignin({
    onSuccess: onSuccess?.(),
  });

  async function handleSignup({ email, password }: { email: string; password: string }) {
    try {
      setState(State.Loading);
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });

      if (user) {
        handleSignin({ email, password });
      }
    } catch (err) {
      setState(State.Error);
      setError(err as AuthError);
      onError?.(err as AuthError);
    }
  }

  return {
    state,
    error,
    isLoading: state === State.Loading,
    isError: state === State.Error,
    isSuccess: state === State.Success,
    handleSignup,
  };
}
