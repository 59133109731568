import { DataStore } from 'aws-amplify';
import { Sermon } from '../models';

// The default generated type definitions don't include the hidden amplify fields,
// so we have to make sure to tell typescript they exist
export interface ExtendedSermon extends Sermon {
  _lastChangedAt: number | undefined;
  _version: number | undefined;
}

// unsynced sermons will have 'undefined' for their _version attribute.
// ALL synced sermons must have a number value.
export const getUnsyncedSermons = async function () {
  const sermons = (await DataStore.query(Sermon)) as Array<ExtendedSermon>;
  // eslint-disable-next-line no-underscore-dangle
  return sermons.filter((s) => s?._version === undefined);
};
