import { useCallback, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerContent, Box, Link, Text } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@sermonary/icons';
import { Label } from '@sermonary/components';
import { DataStore } from '@aws-amplify/datastore';
import { useMutation } from '@sermonary/hooks';
import { useNavigate } from 'react-router-dom';
import { Sermon, SermonType } from '../models';
import { createSermonDrawerStateAtom } from '.';
import { track } from '../analytics/Analytics';
import { sermonAtom, isSermonSettingsDrawerOpenAtom } from '../pages/sermons/SingleSermon';
import { externalLinks } from '../utilities/externalLinks';
import { customSermonTemplatesAtom } from 'src/libs/atoms';

const customTemplateDrawerStateAtom = atom<'open' | 'closed'>('closed');

interface CustomTemplateSelectionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

function CustomTemplateSelectionDrawer({ isOpen, onClose }: CustomTemplateSelectionDrawerProps) {
  const navigate = useNavigate();
  const [, setSermon] = useAtom(sermonAtom);
  const [, setCustomDrawerState] = useAtom(customTemplateDrawerStateAtom);
  const [, setSermonDrawerState] = useAtom(createSermonDrawerStateAtom);
  const [, setIsSermonSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);
  const [customSermonTemplates, setCustomSermonTemplates] = useAtom(customSermonTemplatesAtom);

  const { handler } = useMutation<Sermon>(async (template: Sermon) => {
    try {
      const sermon = await DataStore.save(new Sermon(template));
      setSermon(sermon);
      return sermon;
    } catch (err) {
      console.log({ err });
      return null;
    }
  });

  async function createNewSermon(template: Sermon) {
    const { id: sermonId } = await handler({
      bigIdea: template?.bigIdea,
      blocks: template?.blocks,
      sermonType: 'SERMON',
      wordCount: template?.wordCount,
      title: template?.title,
    });

    setCustomDrawerState('closed');
    setSermonDrawerState('closed');

    await track('Create from Custom Template', { templateTitle: template?.title });

    navigate(`/sermons/${sermonId}`);
    setIsSermonSettingsDrawerOpen(true);
  }

  useEffect(() => {
    const subscription = DataStore.observeQuery(Sermon, (c) => c?.sermonType.eq(SermonType.TEMPLATE)).subscribe(
      ({ isSynced, items }) => {
        isSynced && setCustomSermonTemplates(items);
      },
    );

    return () => subscription.unsubscribe();
  }, [setCustomSermonTemplates]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerContent p={4}>
        <DrawerHeader>
          <Button backgroundColor="transparent" onClick={onClose} textTransform="uppercase" color="gray.700">
            <ArrowLeftIcon mr="1" height={4} width={4} />
            <Text fontSize="md">Back</Text>
          </Button>
        </DrawerHeader>

        <DrawerBody>
          <Box>
            <Label mb={0}>Custom Templates</Label>
            {customSermonTemplates.length ? (
              customSermonTemplates.map((template, index) => (
                <Button
                  key={`template_${index + 1}`}
                  mt="6"
                  w="100%"
                  backgroundColor="gray.300"
                  color="gray.900"
                  textAlign="left"
                  justifyContent="left"
                  onClick={() => createNewSermon(template)}
                  whiteSpace="normal"
                  height="auto"
                  p={4}
                  _hover={{ backgroundColor: 'gray.400' }}
                >
                  {template.title}
                </Button>
              ))
            ) : (
              <Text>
                Your custom templates will appear here. Learn how to create a custom preaching template with this quick{' '}
                <Link href={externalLinks.customTemplateTutorial} isExternal>
                  tutorial
                </Link>
                .
              </Text>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function useCustomTemplateSelectionDrawer() {
  const [state, setState] = useAtom(customTemplateDrawerStateAtom);

  const isOpen = state === 'open';

  const handleOnClose = useCallback(() => {
    setState('closed');
  }, [setState]);

  return {
    isOpen,
    handleOnOpen: () => {
      setState('open');
    },
    handleOnClose,
    handleOnToggle: () => {
      if (state === 'closed') {
        setState('open');
      } else {
        setState('closed');
      }
    },

    CustomTemplateSelectionDrawer: useCallback(
      () => (isOpen ? <CustomTemplateSelectionDrawer isOpen={isOpen} onClose={handleOnClose} /> : null),
      [handleOnClose, isOpen],
    ),
  };
}
