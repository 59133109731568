import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  xs: '0px',
  sm: '428px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
});

export const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      a: {
        color: 'blue.500',
        _hover: {
          textDecoration: 'none',
        },
      },
      h1: {
        fontSize: ['28px', '28px', '46px'],
        fontWeight: 700,
        lineHeight: ['32px', '32px', '52px'],
      },
      h2: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '39px',
      },
      h3: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
        color: 'gray.900',
      },
      h4: {
        lineHeight: '44px',
        fontWeight: 500,
        fontSize: 32,
      },
      '.hideBeacon #beacon-container': {
        display: 'none !important',
      },
      /*
        Hides blue focus outline if and only if the element receives focus
        via mouse click (blue focus outline still shows up on keyboard focus).
      */
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
      },
      '.fr-box': {
        '.fr-toolbar': {
          backgroundColor: '#dfe5e7',
          borderRadius: '0.375rem 0.375rem 0 0',
        },
        '.fr-second-toolbar': {
          borderRadius: '0 0 0.375rem 0.375rem',
        },
        '.fr-wrapper, .fr-toolbar, .fr-second-toolbar': {
          borderColor: '#dfe5e7 !important',
        },
        '.fr-wrapper': {
          '.fr-element': {
            fontSize: '16px',
          },

          'h1, h2, h3, h4': {
            fontWeight: 'unset',
            lineHeight: '1.5em',
          },
          h1: {
            fontSize: '39px',
          },
          h2: {
            fontSize: '31px',
          },
          h3: {
            fontSize: '25px',
          },
          h4: {
            fontSize: '20px',
          },
          'ol, ul': {
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineend: '0px',
            paddingInlineStart: '40px',
          },
          blockquote: {
            display: 'block',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '40px',
            marginInlineEnd: '40px',
          },
          p: {
            marginBottom: '4',
          },
        },
      },
    },
  },
  colors: {
    blue: {
      '100': '#E8F3FD',
      '300': '#E8F4FD',
      '500': '#168eea',
      '600': '#1378C6',
    },
    green: {
      '100': 'rgba(106,182,22,0.15)',
      '500': '#42A644',
      '600': '#6AB616',
    },
    gray: {
      '100': '#F7F7F7',
      '200': '#EDF0F2',
      '300': '#D6DFE6',
      '400': '#AEBFC9',
      '500': '#9BAFBB',
      '600': '#7D8E99',
      '700': '#687B88',
      '900': '#353D41',
    },
    grayButton: {
      '500': '#D6DFE6',
      '600': '#AEBFC9',
    },
  },
  fonts: {
    body: 'proxima-nova, sans-serif',
    heading: 'proxima-nova, sans-serif',
  },
  fontSizes: {
    xs: '0.8125em',
    sm: '0.875em',
    md: '1em',
    lg: '1.125em',
    xl: '1.25em',
    '2xl': '1.5em',
    '3xl': '1.875em',
    '4xl': '2.25em',
    '5xl': '3em',
    '6xl': '3.75em',
    '7xl': '4.5em',
    '8xl': '6em',
    '9xl': '8em',
  },
  textStyles: {
    body1: {
      fontFamily: 'proxima-nova',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '26px',
      color: 'gray.700',
    },
  },
  shadows: {
    sm: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    md: '0px 0px 20px #C4C4C4',
  },
  components: {
    Menu: {
      parts: ['item'],
      baseStyle: {
        item: {
          textTransform: 'capitalize',
          color: 'gray.900',
          fontWeight: 600,
          _hover: {
            backgroundColor: 'blue.300',
            color: 'blue.500',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'proxima-nova',
        fontWeight: 'medium',
        fontStyle: 'normal',
        color: 'gray.900',
      },
      variants: {
        h1: {
          fontSize: ['28px', '28px', '46px'],
          fontWeight: 700,
          lineHeight: ['32px', '32px', '52px'],
        },
        h2: {
          fontWeight: 600,
          fontSize: '32px',
          lineHeight: '39px',
        },
        h3: {
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '29px',
          color: 'gray.900',
        },
        h4: {
          lineHeight: '44px',
          fontWeight: 500,
          fontSize: 32,
        },
        h6: {
          fontWeight: 600,
          fontSize: 20,
          color: 'gray.900',
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'blue.500',
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        small: {
          fontWeight: 'semibold',
          fontSize: '12px',
        },
      },
    },
    List: {
      baseStyle: {
        borderRadius: 3,
      },
    },
    Button: {
      baseStyle: {
        textTransform: 'capitalize',
      },
      variants: {
        link: {
          fontWeight: 500,
          _hover: {
            textDecoration: 'none',
          },
        },
        outline: {
          border: '2px',
          color: 'blue.500',
        },
        ghost: {
          textTransform: 'uppercase',
          fontWeight: '700',
          fontSize: '12px',
          color: '#7D8E99',
          size: 'sm',
          padding: '0',
        },
        frost: {
          background: 'transparent',
          color: '#fff',
          _hover: {
            background: 'rgba(255, 255, 255, 20%)',
          },
        },
        'frost-outline': {
          background: 'transparent',
          color: '#fff',
          border: '1px solid #fff',
          _hover: {
            background: 'rgba(255, 255, 255, 20%)',
          },
        },
        resourceMenuButton: {
          background: 'transparent',
          color: 'gray.900',
          fontSize: '18px',
          fontWeight: '600',
          _hover: {
            background: 'gray.700',
            color: 'white',
          },
        },
        resourceMenuButtonActive: {
          background: 'gray.700',
          color: '#fff',
          fontSize: '18px',
          fontWeight: '600',
        },
      },
    },
    Divider: {
      sizes: {
        regular: {
          borderBottomWidth: 1,
        },
        heavy: {
          borderBottomWidth: 2,
        },
      },
    },
    Input: {
      variants: {
        legacy: {
          field: {
            border: '1px solid transparent',
            padding: '12px 16px',
            backgroundColor: '#E4E7E8',
            borderRadius: '2px',
            borderColor: '#E4E7E8',
            fontWeight: '500',
            textColor: 'gray.900',
            _placeholder: {
              color: 'gray.700',
              fontWeight: '600',
            },
            _focus: {
              border: '1px solid #d6dfe6',
            },
            _disabled: {
              backgroundColor: '#eef1f3',
              borderColor: '#eef1f3',
            },
          },
        },
      },
    },
    Text: {
      parts: ['ol', 'ul', 'blockquote'],
      baseStyle: {
        'ol, ul': {
          marginBlockStart: '1em',
          marginBlockEnd: '1em',
          marginInlineStart: '0px',
          marginInlineend: '0px',
          paddingInlineStart: '40px',
        },
        blockquote: {
          display: 'block',
          marginBlockStart: '1em',
          marginBlockEnd: '1em',
          marginInlineStart: '40px',
          marginInlineEnd: '40px',
          borderLeft: 'solid 2px #5E35B1',
          paddingLeft: '5px',
          color: '#5E35B1',

          blockquote: {
            borderColor: '#00BCD4',
            color: '#00BCD4',

            blockquote: {
              borderColor: '#43A047',
              color: '#43A047',
            },
          },
        },
      },
    },
    Badge: {
      baseStyle: {
        px: '4',
        py: '3',
        borderRadius: '5px',
      },
      variants: {
        primary: {
          backgroundColor: 'green.100',
          color: 'green.500',
        },
        secondary: {
          backgroundColor: 'gray.200',
          color: 'gray.900',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        textTransform: 'uppercase',
        color: 'gray.900',
        fontWeight: 'semibold',
        fontSize: 'sm',
      },
    },
    Modal: {
      parts: ['header'],
      baseStyle: {
        header: {
          textTransform: 'capitalize',
        },
      },
    },
    Radio: {
      parts: ['control'],
      baseStyle: {
        control: {
          borderColor: 'gray.600',
        },
      },
    },
    Checkbox: {
      parts: ['control'],
      baseStyle: {
        control: {
          borderColor: 'gray.600',
        },
      },
    },
  },
});
