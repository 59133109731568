import { Icon, IconProps } from '@chakra-ui/react';

export function ArchiveIcon({ height = '6', width = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fill="currentColor"
        d="M21.25 0A2.75 2.75 0 0 1 24 2.75V5.5c0 1.26-.846 2.32-2 2.647v13.139c0 1.5-1.231 2.714-2.75 2.714H4.75C3.231 24 2 22.785 2 21.286V8.147A2.751 2.751 0 0 1 0 5.5V2.75A2.75 2.75 0 0 1 2.75 0h18.5ZM4.75 5.5h15.5a1 1 0 0 0 1-1v-.75a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1v.75a1 1 0 0 0 1 1h1Zm0 2.75v12.036a1 1 0 0 0 1 1h12.5a1 1 0 0 0 1-1V8.25H4.75Zm2.75 4.125c0-.76.616-1.375 1.375-1.375h6.25a1.375 1.375 0 1 1 0 2.75h-6.25c-.76 0-1.375-.616-1.375-1.375Z"
      />
    </Icon>
  );
}

export default ArchiveIcon;
