import { useEffect, useMemo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { Button, Text, useToast } from '@chakra-ui/react';
import { useVerifyEmail } from '@sermonary/hooks';
import { ThinTopBanner } from '.';

interface ConfirmEmailBannerProps {
  emailWasVerified: boolean;
  isMobile: boolean;
  accountCreatedAt: number;
}

const confirmationWasResentAtom = atomWithStorage<boolean>(
  'confirmationWasResent',
  false,
  createJSONStorage<boolean>(() => sessionStorage),
);

export default function ConfirmEmailBanner({ emailWasVerified, isMobile, accountCreatedAt }: ConfirmEmailBannerProps) {
  const timerRef: any = useRef(null);
  const accountAgeToShowBanner = 1000 * 60 * 60; // 1 hour in milliseconds
  const toast = useToast();

  const [showConfirmEmailBanner, setShowConfirmEmailBanner] = useState(false);
  const [resendCompleted, setResendCompleted] = useState(false);
  const [confirmationWasResent, setConfirmationWasResent] = useAtom(confirmationWasResentAtom);
  const [showSpinner, setShowSpinner] = useState(false);
  const accountOldEnough = useMemo(() => Date.now() - accountCreatedAt > accountAgeToShowBanner, [accountCreatedAt]);

  useEffect(() => {
    setShowConfirmEmailBanner(accountOldEnough && !(emailWasVerified || confirmationWasResent));
  }, [emailWasVerified, confirmationWasResent, accountOldEnough]);

  const handleResendSuccess = () => {
    setShowSpinner(false);
    setResendCompleted(true);
    timerRef.current = setTimeout(() => {
      setConfirmationWasResent(true);
    }, 2000);
  };

  const handleResendError = () => {
    setShowSpinner(false);
    toast({
      title: 'An error occurred.',
      description: 'Unable to send verification email. Please try again.',
      status: 'error',
      duration: 8000,
      isClosable: true,
    });
  };

  const { handleSendVerifyEmail, ...rest } = useVerifyEmail({
    onLoading: () => setShowSpinner(true),
    onSuccess: handleResendSuccess,
    onError: handleResendError,
  });

  const mobileResponsiveText = 'Tap confirm email link in your inbox.';
  const desktopResponsiveText = 'Please confirm your email by clicking the link sent to your inbox.';

  const resendButton = (
    <Button
      size="sm"
      width="hug"
      height="hug"
      padding="2px 8px"
      mx="4px"
      variant="ghost"
      color="blue.500"
      fontSize="13px"
      fontWeight="600"
      lineHeight="19.5px"
      isLoading={showSpinner}
      _active={{
        bg: 'blue.200',
        color: 'blue.700',
      }}
      _hover={{ bg: 'blue.100', color: 'blue.600' }}
      _focus={{
        color: 'blue.500',
      }}
      onClick={handleSendVerifyEmail}
      textTransform="capitalize"
    >
      Resend Email
    </Button>
  );

  const resendConfirmation = (
    <Text
      size="sm"
      width="hug"
      height="hug"
      padding="2px 8px"
      mx="4px"
      fontSize="13px"
      fontWeight="600"
      lineHeight="19.5px"
    >
      Email Sent!
    </Text>
  );

  return (
    (showConfirmEmailBanner && (
      <ThinTopBanner>
        <Text height="100%" fontSize="sm" color="gray.900" display="flex" alignItems="center">
          {isMobile ? mobileResponsiveText : desktopResponsiveText}
        </Text>
        {resendCompleted ? resendConfirmation : resendButton}
      </ThinTopBanner>
    )) ||
    null
  );
}
