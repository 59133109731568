import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const christmasMessageScript = {
  id: 'christmasMessageScript',
  bigIdea: 'Christmas cheer is best when it is shared with others.',
  sermonType: SermonType.SERMON,
  description: 'Christmas cheer is best when it is shared with others.',
  title: 'Christmas Message Script',
  blocks: JSON.stringify({
    '0': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Introduction',
    },
    '1': {
      id: uuid(),
      color: '#000',
      wordCount: 114,
      type: 'custom',
      title: 'illustration',
      content:
        '<p>According to a 2015 article, 77 percent of shoppers in the 2014 holiday season bought an average of $126 in gifts for themselves (<span id="isPasted" style=" color: rgb(5, 99, 193);">http://www.starnewsonline.com/news/20151125/2015-holiday-season-by-the-numbers- --25-stats-from-spending-to-celebrating</span>). If we aren’t careful, we can get distracted from the real meaning of Christmas by focusing on ourselves, on getting stuff, and on our Christmas lists. Most of us have probably found out by now that the joy of new stuff wears off pretty quickly—usually as soon as it isn’t new anymore. But there is a kind of Christmas cheer that lasts. It is the real joy we experience when we celebrate what God did in giving his Son to us and share that with people who need it.</p>',
    },
    '2': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Christmas Is For Everyone',
    },
    '3': {
      id: uuid(),
      color: '#000',
      wordCount: 84,
      type: 'custom',
      title: 'Explanation',
      content:
        '<p>There’s lots of sharing going on in this passage. The angels appeared to the shepherds to share the good news of Jesus’s birth (vv. 9–10). Mary and Joseph shared their joy with the shepherds who came to visit him (vv. 15–16). The shepherds shared the wonder of what they had seen with everyone (vv. 17-18). And God shared his “good news that will cause great joy for all the people” (v. 10, emphasis added), by sharing his son with the world as the Savior.</p>',
    },
    '4': {
      id: uuid(),
      wordCount: 111,
      type: 'illustration',
      content:
        '<p>One of the famous lines from the Christmas movie Elf starring Will Farrell is “The best way to spread Christmas cheer is singing loud for all to hear.” Show a clip (about 1:25:30 to 1:29:30) from this movie where Jovie learns the truth of this statement and leads others in singing in order to get Santa’s sleigh running again. Like Jovie discovered, the best way to enjoy Christmas is to share it. We need to share with others the real reason behind the Christmas story so they can know God’s love for them. (Note to the Preacher: Before showing portions of this film, be sure to receive proper permission. See http://us.cvli.com/about/.)</p>',
    },
    '5': {
      id: uuid(),
      wordCount: 52,
      type: 'application',
      content:
        '<p>Real Christmas cheer is about Jesus coming as the Savior of the world, and this news is to be shared with every single person. You might not feel like you are “good” enough to come to Christ, but Christ has come for all of us. We are never too far from God.</p>',
    },
    '6': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Christmas Is An Invitation',
    },
    '7': {
      id: uuid(),
      color: '#000',
      wordCount: 45,
      type: 'custom',
      title: 'Explanation',
      content:
        '<p>The angels’ announcement of God’s plan of redemption coming into action with Jesus’s birth was followed by an invitation: “You will find” (v. 12). The shepherds responded to that invitation with a yes in their hearts and immediately said, “Let’s go ... and see” (v. 15).</p>',
    },
    '8': {
      id: uuid(),
      wordCount: 106,
      type: 'illustration',
      content:
        '<p><em>[Note to the Preacher: Talk about a time in your life, perhaps as a young person, when you received an invitation to an event you were really excited to attend. Perhaps it was an invitation to a party or to a dance with a special someone. How did you react when you received this special invitation? Did you call up all your friends to share the news? Buy a special outfit? Get your hair done? Jump up and down with excitement? That’s how God wants us to feel about his invitation to come and see Jesus too. And he wants to make sure everyone is invited.]</em></p>',
    },
    '9': {
      id: uuid(),
      wordCount: 58,
      type: 'application',
      content:
        '<p>Today, you are receiving an invitation. An invitation to follow Christ. It doesn’t matter where you are, God is pursuing you in hopes that you would let his grace wash over you.<em>&nbsp;[Note to the Pastor: Leave room in your message response time for congregants to be quiet, prayerfully listen, and write down any names that come to mind.]</em></p>',
    },
    '10': {
      id: uuid(),
      color: '#000',
      wordCount: 137,
      type: 'custom',
      title: 'Conclusion',
      content:
        '<p id="isPasted">Christmas is about giving. But we can only give because Christ first gave to us. <span style=" color: rgb(38, 38, 38);">“Look at what God has done to get you to know Him personally. If the Son would come all this way to become a real person to you, don’t you think the Holy Spirit will do&nbsp;</span><span style=" color: rgb(38, 38, 38);">anything in His power to make Jesus a real person to you in your heart? Christmas is an invitation to know Christ personally. Christmas is an invitation by God to say:&nbsp;</span><span style=" color: rgb(38, 38, 38);">Look what I’ve done to come near to you. Now draw near to Me. I don’t want to be a concept; I want to be a friend</span><span style=" color: rgb(38, 38, 38);">” (Tim Keller, “Why Christmas Matters,”&nbsp;</span><span style=" color: rgb(38, 38, 38);">Relevant Magazine</span><span style=" color: rgb(38, 38, 38);">, December 10, 2011,&nbsp;</span><span style=" color: rgb(5, 99, 193);">http://www.relevantmagazine.com/god/worldview/why-christmas- matters-0</span><span style=" color: rgb(38, 38, 38);">).</span></p><p><em>[Note to the Pastor:&nbsp;</em><span style=" color: rgb(38, 38, 38);"><em>Give an invitation for those who need to respond to the gospel for themselves for the first time.</em></span><span style=" color: rgb(38, 38, 38);"><em>]</em></span></p>',
    },
  }),
};
