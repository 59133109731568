import { useState } from 'react';
import { Auth, DataStore } from 'aws-amplify';
import { AuthError } from '@sermonary/types';

enum State {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

interface UseSigninOptions {
  onSuccess?: any;
  onError?: (error: AuthError) => void;
}

export function useSignin({ onSuccess, onError }: UseSigninOptions) {
  const [state, setState] = useState<State>(State.Idle);
  const [error, setError] = useState<AuthError>();

  async function handleSignin({ email, password }: { email: string; password: string }) {
    try {
      setState(State.Loading);
      const user = await Auth.signIn(email, password);

      if (user) {
        await DataStore.clear();
        setState(State.Success);
        onSuccess?.();
      }
    } catch (err) {
      setState(State.Error);
      setError(err as AuthError);
      onError?.(err as AuthError);
    }
  }

  return {
    state,
    error,
    isLoading: state === State.Loading,
    isError: state === State.Error,
    isSuccess: state === State.Success,
    handleSignin,
  };
}
