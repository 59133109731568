import { Storage } from 'aws-amplify';
import { ReactNode, useCallback, useState, useRef } from 'react';
import { Controller, useWatch, UseFormReturn } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Flex, FormLabel, Grid, Heading, Progress, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { Input, StoredImage } from '@sermonary/components';
import { UploadIcon } from '@sermonary/icons';
import { fetchCognitoUserAtom } from 'src/App';
import { useAtom } from 'jotai';
import { v4 as uuid } from 'uuid';

export interface MediaBlockFormValues {
  inputType?: 'image' | 'video';
  imageKey?: string;
  caption?: string;
  videoTitle?: string;
  videoSrc?: string;
  legacyImage?: string;
}

export function MediaBlockForm({
  children,
  control,
  formState,
  register,
  setValue,
  getValues,
}: Pick<
  UseFormReturn<MediaBlockFormValues>,
  'control' | 'register' | 'setValue' | 'formState' | 'control' | 'getValues'
> & {
  children?: ReactNode;
}) {
  const legacyImage = getValues('legacyImage');
  const imageKey = getValues('imageKey');
  const uploadPromiseRef = useRef<Promise<any> | null>(null);
  const [imagePreviewKey, setImagePreviewKey] = useState(imageKey || '');
  const [progress, setProgress] = useState<{
    loaded: number;
    total: number;
  } | null>(null);

  const [cognitoUser] = useAtom(fetchCognitoUserAtom);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileType = file.type.split('/').pop();
    const fileName = `${cognitoUser?.attributes?.sub}_${uuid()}.${fileType}`;
    try {
      const promise = Storage.put(fileName, file, {
        metadata: { IdentityID: cognitoUser.identityId },
        progressCallback(progressCb: { loaded: number; total: number }) {
          setProgress(progressCb);
        },
      });
      uploadPromiseRef.current = promise;

      const result = (await promise) as { key: string };

      setImagePreviewKey(result?.key);
      setValue('imageKey', result?.key, { shouldDirty: true });
    } catch (err) {
      if (Storage.isCancelError(err)) {
        setProgress(null);
        uploadPromiseRef.current = null;
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/*'],
    onDrop,
  });

  const { errors } = formState;

  const inputType = useWatch({
    control,
    name: 'inputType',
  });

  const handleOnCancelUpload = () => {
    Storage.cancel(uploadPromiseRef?.current as Promise<any>, 'Cancelling upload.');
  };

  return (
    <Grid gridTemplateColumns="1fr" gap="4">
      <Controller
        control={control}
        name="inputType"
        render={({ field }) => (
          <RadioGroup {...field}>
            <Stack direction="row">
              <Radio value="image" mr="2">
                Image
              </Radio>
              <Radio value="video">Video</Radio>
            </Stack>
          </RadioGroup>
        )}
      />
      {inputType === 'image' ? (
        <Flex>
          <Box flex="1">
            <Flex
              {...(getRootProps() as any)}
              alignItems="center"
              border="2px dashed"
              borderColor={isDragActive ? 'blue.500' : 'gray.200'}
              borderRadius="md"
              justifyContent="center"
              textAlign="center"
              py="12"
              px="4"
              mb="4"
            >
              <input {...(getInputProps() as any)} />
              {isDragActive ? (
                <Flex flexDirection="column">
                  <Box mb="4" color="blue.500">
                    <UploadIcon height="8" width="8" />
                  </Box>
                  <Heading as="h4" fontSize="md">
                    Drop the files here ...
                  </Heading>
                </Flex>
              ) : (
                <Flex flexDirection="column">
                  <Box mb="4" color="blue.500">
                    <UploadIcon height="8" width="8" />
                  </Box>
                  <Heading as="h4" fontSize="md">
                    Drag an image or click to upload.
                  </Heading>
                </Flex>
              )}
            </Flex>
            <Input {...register('caption')} label="Caption" />
          </Box>
          <Box flex="1" px="4">
            {progress && progress?.loaded !== progress?.total ? (
              <>
                <FormLabel mb="2" fontWeight="semibold">
                  Uploading...
                </FormLabel>
                <Progress isAnimated hasStripe min={0} max={progress?.total} value={progress?.loaded} />
                <Button onClick={handleOnCancelUpload} mt="2">
                  Cancel Upload
                </Button>
              </>
            ) : (
              <StoredImage legacyImage={legacyImage} imageKey={imagePreviewKey} alt={getValues('caption')} />
            )}
          </Box>
        </Flex>
      ) : (
        <>
          <Input {...register('videoTitle')} label="Video title" error={errors?.videoTitle?.message} />
          <Input
            {...register('videoSrc')}
            label="Video source (URL)"
            error={errors?.videoSrc?.message}
            description="This is for your reference only. Video does not embed."
          />
        </>
      )}
      {children}
    </Grid>
  );
}
