import { DataStore } from 'aws-amplify';
import { Account } from '../models';

// this utility is necessary because of this issue: https://github.com/aws-amplify/amplify-codegen/issues/396
// The codegen does not generate the correct types for the flags field on the Account model,
// so we use this utility to interact with the flags field so typescript doesn't complain

export function getAccountFlag(account: Account, flagName: string) {
  const flags = account?.flags as unknown as Record<string, any>;

  return flags?.[flagName];
}
export async function setAccountFlag(
  account: Account,
  flagName: string,
  flagValue: any,
  callback?: (account: Account) => void,
) {
  const newAccount = await DataStore.save(
    Account.copyOf(account, (updated) => {
      if (!updated.flags) {
        Object.assign(updated, { flags: {} });
      }

      Object.assign(updated.flags, { [flagName]: flagValue });
      return updated;
    }),
  );

  if (callback) {
    callback(newAccount);
  }

  return newAccount;
}
