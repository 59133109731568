import { ExpandedIndex } from '@chakra-ui/accordion';
import { atom } from 'jotai';
import { Sermon } from 'src/models';
import * as providedSermonTemplates from '../../utilities/providedSermonTemplates';

export const providedSermonTemplatesAtom = atom({ ...providedSermonTemplates });

export const providedSermonTemplateGroupsAtom = atom({
  general: {
    title: 'General',
    templates: [
      'traditionalThreePointSermon',
      'theMeWeGodYouWeMethod',
      'verseByVerseRunningCommentary',
      'theDefendersOutline',
    ],
  },
  wedding: {
    title: 'Wedding',
    templates: [
      'weddingCeremonyScript',
      'simpleWeddingOutline',
      'traditionalWeddingOutlineEphesians',
      'modernWeddingOutline',
      'traditionalWeddingOutlineCorinthians',
      'vowRenewalOutline',
    ],
  },
  funeral: {
    title: 'Funeral',
    templates: [
      'funeralSermonScript',
      'funeralOfAChild',
      'funeralFollowingIllness',
      'funeralFollowingTragedy',
      'funeralOfANonBeliever',
      'funeralOfABeliever',
    ],
  },
  other: {
    title: 'Life Events',
    templates: ['babyDedicationScript', 'graduationPresentationScript', 'vowRenewalOutline'],
  },
  holiday: {
    title: 'Holiday',
    templates: ['christmasMessageScript', 'easterSundayScript'],
  },
  youth: {
    title: 'Youth',
    templates: ['theYouthPastor'],
  },
  children: {
    title: 'Children',
    templates: ['theChildrensLeader'],
  },
});

export const customSermonTemplatesAtom = atom<Sermon[]>([]);
export const favoriteSermonTemplatesAtom = atom<Sermon[]>([]);
export const accordionIndexAtom = atom<ExpandedIndex>([]);
