import { Icon, IconProps } from '@chakra-ui/react';

export function SearchIcon({ height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon height={6} width={6} viewBox="0 0 16 16" {...props}>
      <path
        d="M6.167 0c3.405 0 6.166 2.645 6.166 5.908a5.72 5.72 0 0 1-1.205 3.51l4.604 4.41a.852.852 0 0 1 0 1.242.945.945 0 0 1-1.297 0l-4.604-4.41a6.319 6.319 0 0 1-3.664 1.155C2.76 11.816 0 9.171 0 5.909S2.76 0 6.167 0Zm0 1.756c-2.394 0-4.334 1.859-4.334 4.152s1.94 4.151 4.334 4.151a4.44 4.44 0 0 0 2.413-.702l.16-.11.525-.371.389-.503a4.012 4.012 0 0 0 .841-2.273l.005-.192c0-2.293-1.94-4.152-4.333-4.152Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default SearchIcon;
