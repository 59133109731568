import { memo, ReactNode } from 'react';
import { useWatch, UseFormReturn } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import { enforce, test, create } from 'vest';
import { Input, Textarea, Select } from '@sermonary/components';

export interface SermonFormValues {
  title: string;
  referenceVerses?: string;
  bigIdea?: string;
  series?: string;
  newSeriesTitle?: string;
}

export const sermonFormValidationSuite = create((data: Partial<SermonFormValues> = {}) => {
  test('title', 'Title is required.', () => {
    enforce(data.title).isNotEmpty();
  });
});

export const SermonInfoForm = memo(
  <T extends { id: string; title: string }>({
    children,
    register,
    formState,
    control,
    series,
  }: Pick<UseFormReturn<SermonFormValues>, 'register' | 'formState' | 'control'> & {
    children?: ReactNode;
    series: T[];
  }) => {
    const { errors } = formState;

    const sermonSeries = useWatch({
      control,
      name: 'series',
    });

    return (
      <Grid gridTemplateColumns="1fr" gap="4">
        <Input {...register('referenceVerses')} label="Bible passage(s)" error={errors?.referenceVerses?.message} />
        <Textarea {...register('bigIdea')} label="Big idea of message" error={errors?.bigIdea?.message} />
        <Select
          {...register('series')}
          label="Sermon series"
          placeholder="Select a series"
          error={errors?.series?.message}
        >
          {series?.map((item) => (
            <option key={item?.id} value={item?.id}>
              {item?.title}
            </option>
          ))}
          <option value="create">Add new series</option>
        </Select>
        {sermonSeries === 'create' ? (
          <Input {...register('newSeriesTitle')} label="New series title" error={errors?.newSeriesTitle?.message} />
        ) : null}
        {children}
      </Grid>
    );
  },
);
