import { AuthLayout } from '@sermonary/components';
import { useSignup } from '@sermonary/hooks';
import { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { RegistrationConfig } from '@sermonary/types';
import { track } from '../../analytics/Analytics';
import { RegisterForm, RegisterFormValues } from '../../components/forms';
import { useGetRegistrationCheckout } from '../../hooks';

export interface RegistrationConfigStorage extends RegistrationConfig {
  expiry?: number;
}

const defaultRegistrationConfig = {
  code: 'monthly',
} as RegistrationConfig;

const storage = createJSONStorage<RegistrationConfigStorage>(() => localStorage);
export const registrationConfigAtom = atomWithStorage<RegistrationConfigStorage>(
  'registrationConfig',
  {} as RegistrationConfigStorage,
  storage,
);

export default function Register() {
  const [registrationConfig, setRegistrationConfig] = useAtom(registrationConfigAtom);
  const [queryState, setQueryState] = useState({ shouldQuery: false, registrationCode: '' });

  function handleSetDefaultConfig() {
    Object.keys(registrationConfig || {}).length === 0 && setRegistrationConfig({ ...defaultRegistrationConfig });
  }

  const { isLoading, isError, registrationCheckoutUrl } = useGetRegistrationCheckout({ ...queryState });

  useEffect(() => {
    handleSetDefaultConfig();
  }, []);

  function handleGetCheckout() {
    setQueryState({ shouldQuery: true, registrationCode: registrationConfig.code });
  }

  const { handleSignup, state, ...rest } = useSignup({
    onSuccess: () => handleGetCheckout,
  });

  const onSubmit = (values: RegisterFormValues) => {
    handleSignup(values);
  };

  useEffect(() => {
    if (state === 'SUCCESS') {
      track('User Registered', { registrationCode: registrationConfig?.code });
    }
  }, [state]);

  useEffect(() => {
    if (registrationCheckoutUrl) {
      window.location.replace(registrationCheckoutUrl);
    }
  }, [registrationCheckoutUrl]);

  return (
    <AuthLayout
      navButton={
        <Button as={RouterLink} to="/login" colorScheme="blue" variant="outline">
          Log In
        </Button>
      }
    >
      <RegisterForm
        formTitle={registrationConfig?.registerForm?.headingText || 'Try Sermonary Free 30 Days'}
        ctaText={registrationConfig?.registerForm?.ctaText || 'Sign Up for Sermonary Free Trial'}
        onSubmit={onSubmit}
        {...rest}
      />
    </AuthLayout>
  );
}
