import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as JotaiProvider } from 'jotai';
import { ChakraProvider } from '@chakra-ui/react';
import { Amplify, AuthModeStrategyType, syncExpression } from 'aws-amplify';
import { theme } from './libs/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';
import 'focus-visible';
import { App } from './App';
import { Loading } from './pages/Loading';
import { ErrorFallback } from './components/ErrorFallback';
import loadDevTools from './tools/load';
import { Analytics } from './analytics/Analytics';
import { conflictHandler } from './utilities/conflictHandler';
import { SermonBackup } from './models';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID ?? '',
  });

  Analytics.init();

  Amplify.configure({
    ...awsExports,
    errorHandler: (error: any) => {
      console.warn('Unrecoverable error', { error });
    },
    DataStore: {
      authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
      conflictHandler,
      syncExpressions: [
        // hack until they allow a 'None' predicate to prevent all syncing for this model
        syncExpression(SermonBackup, () => (sermonBackup) => sermonBackup.id.eq('NOT_EXIST')),
      ],
    },
  });

  const queryClient = new QueryClient();

  loadDevTools(() => {
    ReactDOM.render(
      <React.StrictMode>
        <JotaiProvider>
          <ChakraProvider resetCSS theme={theme}>
            <Suspense fallback={<Loading />}>
              <QueryClientProvider client={queryClient}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <BrowserRouter>
                    <LDProvider>
                      <App />
                    </LDProvider>
                  </BrowserRouter>
                </ErrorBoundary>
              </QueryClientProvider>
            </Suspense>
          </ChakraProvider>
        </JotaiProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
