import { CognitoUser } from '@aws-amplify/auth';

export type UserGroups = 'AppUsers' | 'PremiumSubscribers' | 'MinistryPassSubscribers';

export function userBelongsToGroup(
  user: CognitoUser & { signInUserSession?: { accessToken: { payload: any } } },
  group: UserGroups,
) {
  return user?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes(group);
}
