import { forwardRef, memo, useRef } from 'react';
import { Button } from '@chakra-ui/react';
import { SettingsIcon, PodiumIcon, RestoreIcon } from '@sermonary/icons';
import { Link as RouterLink } from 'react-router-dom';
import { Sermon } from '../../models';

interface SermonActionsProps {
  handleOnAddBlock: () => void;
  handleOnOpenSettings: () => void;
  handleOnRestoreArchive: () => void;
  sermon: Sermon;
}

const SermonActionsForwardRef = forwardRef<HTMLButtonElement, SermonActionsProps>(
  ({ handleOnAddBlock, handleOnOpenSettings, handleOnRestoreArchive, sermon }, ref) => {
    const settingsTriggerRef = useRef<HTMLButtonElement>(null);

    return (
      <>
        {sermon?.isArchived && (
          <Button
            onClick={handleOnRestoreArchive}
            variant="ghost"
            display="flex"
            flexDirection="column"
            fontSize="x-small"
            mx="1"
            color="gray.700"
            _hover={{
              color: 'gray.600',
            }}
          >
            <RestoreIcon mb="1" height="6" width="6" />
            Restore
          </Button>
        )}
        <Button
          as={RouterLink}
          to={`/sermons/${sermon?.id}/podium`}
          variant="ghost"
          display="flex"
          flexDirection="column"
          fontSize="x-small"
          mx="1"
          color="gray.700"
          _hover={{
            color: 'gray.600',
          }}
        >
          <PodiumIcon mb="1" height="6" width="6" />
          Podium
        </Button>
        <Button
          ref={settingsTriggerRef}
          onClick={handleOnOpenSettings}
          variant="ghost"
          display="flex"
          flexDirection="column"
          fontSize="x-small"
          ml="1"
          mr="3"
          color="gray.700"
          _hover={{
            color: 'gray.600',
          }}
        >
          <SettingsIcon mb="1" height="6" width="6" />
          Settings
        </Button>
        <Button type="button" colorScheme="blue" flexShrink={0} onClick={handleOnAddBlock}>
          Add block
        </Button>
      </>
    );
  },
);

export const SermonActions = memo(SermonActionsForwardRef);
