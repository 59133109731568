import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Logo } from './logo';

export function AuthLayout({ navButton, children }: { navButton?: ReactNode; children: ReactNode }) {
  return (
    <Box m={['0 -16px', null, '0']}>
      <Flex justifyContent="space-between" alignItems="center" p={['0px', '0px', '2rem']}>
        <Logo width={['50%', '50%', '188px']} height="auto" />
        {navButton}
      </Flex>
      <Flex justifyContent="center">{children}</Flex>
    </Box>
  );
}
