import { useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { Block } from '@sermonary/types';
import { useAutoSave } from '@sermonary/hooks';
import { useMemo } from 'react';
import { BibleBlockForm, BibleBlockFormValues } from '../forms';
import AlertDialogPrompt from '../forms/AlertDialogPrompt';

import { useUpdateBlockForm } from '../../hooks/useUpdateBlockForm';

export function BibleBlock({
  isActive,
  block,
  onSave,
  onDelete,
  onClose,
}: {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: BibleBlockFormValues) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: BibleBlockFormValues) => void;
}) {
  const { control, handleSubmit, reset, ...methods } = useForm<BibleBlockFormValues>({
    mode: 'onChange',
    // Memoizes all of the user's most recently entered values for each
    // BibleBlockForm input, preventing the user from having to re-enter a value
    // for EVERY input, especially if they only want to update one value (i.e. -
    // user wants to keep the same 'Book' value, but wants to update the 'Passage(s)'
    // value from '1-5' to '1-9').
    defaultValues: useMemo(
      () => ({
        inputType: block?.content && !block?.book ? 'manual' : 'select',
        book: block?.book,
        chapter: block?.chapter,
        verses: block?.verses,
        translation: block?.translation,
        content: block?.content,
      }),
      [block],
    ),
  });

  function onSubmit(values: BibleBlockFormValues) {
    if (methods.formState.isDirty) {
      onSave(block?.id, values);
    }
    onClose(block?.id, values);
  }

  useAutoSave<BibleBlockFormValues>({
    control,
    isDirty: methods.formState.isDirty,
    onSave: (values: BibleBlockFormValues) => {
      onSave(block?.id, values);
      reset(values);
    },
  });

  useUpdateBlockForm<BibleBlockFormValues>({
    block,
    handleReset: () => {
      reset({
        inputType: methods.getValues('inputType'),
        book: block?.book,
        chapter: block?.chapter,
        verses: block?.verses,
        translation: block?.translation,
        content: block?.content,
      });
    },
  });

  return isActive ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDialogPrompt isDirty={methods.formState.isDirty} />
      <BibleBlockForm control={control} {...methods}>
        <Flex justifyContent="flex-end">
          <Button type="button" mr="2" onClick={() => onDelete(block)}>
            Delete
          </Button>
          <Button type="submit" colorScheme="blue">
            Done
          </Button>
        </Flex>
      </BibleBlockForm>
    </form>
  ) : null;
}
