import { Text } from '@chakra-ui/react';

export function FormLabel({ label, inputId }: { label: string; inputId: string }) {
  return (
    <Text
      as="label"
      htmlFor={inputId}
      color="gray.900"
      fontSize="xs"
      fontWeight="bold"
      textTransform="uppercase"
      letterSpacing="1px"
    >
      {label}
    </Text>
  );
}
