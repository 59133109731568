declare global {
  interface Window {
    _rwq: any;
    rewardful: any;
    Rewardful: any;
  }
}

export class Rewardful {
  private static instance: Rewardful;

  private isReady = false;

  private addEvent: (eventName: string, eventProps: any) => void;

  private api: any;

  public static getInstance(): Rewardful {
    if (!Rewardful.instance) {
      Rewardful.instance = new Rewardful();
    }

    return Rewardful.instance;
  }

  constructor() {
    // adapted from the snippet provided by Rewardful:
    // (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');

    // eslint-disable-next-line no-underscore-dangle
    window._rwq = 'rewardful';
    window.rewardful =
      window.rewardful ||
      function () {
        window.rewardful.q = window.rewardful.q || [];
        // eslint-disable-next-line prefer-rest-params
        window.rewardful.q.push(arguments);
      };

    this.addEvent = window.rewardful;
  }

  public initialize(rewardfulId: string) {
    // adapted from the snippet provided by Rewardful:
    // <script async src='https://r.wdfl.co/rw.js' data-rewardful='{rewardfulId}'></script>

    const script = document.createElement('script');
    script.src = 'https://r.wdfl.co/rw.js';
    script.async = true;
    script.setAttribute('data-rewardful', rewardfulId);

    this.onReady(() => {
      this.api = window.Rewardful;
      this.isReady = true;
    });

    document.head.appendChild(script);
  }

  onReady(callback: () => void) {
    this.addEvent('ready', callback);
  }

  setSource(source: string) {
    this.addEvent('source', source);
  }

  private apiProp(prop: string) {
    if (!this.isReady || !this.api) {
      console.log('Rewardful is not ready yet');
      return undefined;
    }

    return this.api[prop];
  }

  referral = () => this.apiProp('referral');

  affiliate = () => this.apiProp('affiliate');

  campaign = () => this.apiProp('campaign');

  coupon = () => this.apiProp('coupon');

  debugCookie = () => this.apiProp('_cookie');
}
