import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useEffect } from 'react';

interface PodiumSettingsStorage {
  fontSizeAdjustment: number;
}

const defaultPodiumSettingsStorage = {
  fontSizeAdjustment: 100,
} as PodiumSettingsStorage;

const storage = createJSONStorage<PodiumSettingsStorage>(() => localStorage);
export const podiumSettingsStorageAtom = atomWithStorage('podiumSettings', {} as PodiumSettingsStorage, storage);

export function useHandlePodiumFontSize({ sermonId, fetchedId }: { sermonId: string; fetchedId: string }) {
  const [podiumSettingsStorage, setPodiumSettingsStorage] = useAtom(podiumSettingsStorageAtom);

  function handleSetDefaultPodiumSettings() {
    Object.keys(podiumSettingsStorage || {}).length === 0 &&
      setPodiumSettingsStorage({ ...defaultPodiumSettingsStorage });
  }

  useEffect(() => {
    const target = 'podium-container-id';

    const element = document.getElementById(target);

    if (!element) {
      return;
    }
    element.style.setProperty('font-size', `${podiumSettingsStorage.fontSizeAdjustment}%`);
  }, [podiumSettingsStorage, sermonId, fetchedId]);

  useEffect(() => {
    handleSetDefaultPodiumSettings();
  }, []);
}
