import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const simpleWeddingOutline = {
  id: 'simpleWeddingOutline',
  bigIdea: 'The Wedding of (Bride) & (Groom)',
  sermonType: SermonType.SERMON,
  description: 'This dependable outline can jumpstart any wedding prep.',
  title: 'Simple Wedding Outline',
  blocks: JSON.stringify({
    '0': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'PROCESSION',
    },
    '1': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Order of Entrance',
      content:
        '<em><span style="font-weight: 400;">Pastor, groom, and groomsmen enter. Bridal party, ring bearer and flower girl enter. Then, all stand, and the bride is escorted by her father.</span></em>',
    },
    '2': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'WELCOME',
    },
    '3': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To The Audience',
      content:
        '<span style="font-weight: 400;">Family and friends, we gather today to celebrate the wedding of <strong>(Bride - First &amp; Last)</strong> and <strong>(Groom - First &amp; Last)</strong>.</span>',
    },
    '4': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'PRAYER',
    },
    '5': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Opening Prayer',
      content:
        '<span style="font-weight: 400;">Let’s begin with prayer:</span>\r\n\r\n<span style="font-weight: 400;">Father, we thank you today for bringing <strong>(Bride)</strong> and <strong>(Groom)</strong> together. In your goodness, you gave us marriage so that we could learn to love another as you love us. We ask today that you would bless <strong>(Groom)</strong> and <strong>(Bride)</strong> from this day forward as they seek to honor you in their marriage together. Amen.</span>\r\n\r\n<span style="font-weight: 400;">Please be seated.</span>',
    },
    '6': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'SERMON',
    },
    '7': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Audience, Bride, and Groom',
      content:
        '<span style="font-weight: 400;">When Jesus was asked about marriage, he quoted the Bible, as he often did.</span>\r\n\r\nIn Mark 10:6-9, he says: "at the beginning of creation God ‘made them male and female.’ ‘For this reason a man will leave his father and mother and be united to his wife,  and the two will become one flesh.’ So they are no longer two, but one flesh.  Therefore what God has joined together, let no one separate.” (Mark 10:6-9 NIV)\r\n\r\n<span style="font-weight: 400;">Marriage was God’s plan from the beginning. The world is difficult, and God knew that it was not best to go at it alone. So God made both man and woman. </span>\r\n\r\n<span style="font-weight: 400;">So when people say that <strong>(Groom)</strong> and <strong>(Bride)</strong> were made for each other, they may not realize how profoundly true that statement really is. Indeed, God made you two for one another. </span>\r\n\r\n<span style="font-weight: 400;">And God intended that one day <strong>(Groom)</strong> would meet <strong>(Bride)</strong>, the two would fall head-over-heels in love with one another, and they would leave the home of their father and mother and be united together as one.</span>\r\n\r\n<span style="font-weight: 400;">Marriage is not a commitment to be entered into lightly. It is a binding promise between a man and woman to become one, taking everything the other person brings with them and loving them as if they were now a part of them.</span>',
    },
    '8': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'DECLARATION OF INTENT',
    },
    '9': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To the Bride and Groom',
      content:
        '<span style="font-weight: 400;">So <strong>(Groom)</strong> and <strong>(Bride)</strong>, I will ask you now to declare your intentions.</span>\r\n\r\n<span style="font-weight: 400;"><strong>(Bride)</strong>, do you take <strong>(Groom)</strong> to be your husband? Do you promise to love him, comfort him, respect him, and be devoted to him for all of your days? If so, please say, "I do."</span>\r\n\r\n<b><i>*(Bride):  I do.*</i></b>\r\n\r\n<span style="font-weight: 400;"><strong>(Groom)</strong>, do you take <strong>(Bride)</strong> to be your wife? Do you promise to love her, comfort her, respect her, and be devoted to her for all of your days? If so, please say, "I do."</span>\r\n\r\n<b><i>*(Groom): I do.*</i></b>',
    },
    '10': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'VOWS',
    },
    '11': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Option 1:',
      content:
        '<h2><b>Traditional Vows</b></h2>\r\n<span style="font-weight: 400;">Please face each other and join hands. We will start with you <strong>(Groom)</strong>. Repeat after me.</span>\r\n\r\n<em><strong>*(Groom): I (Groom) – take you (Bride) –  to be my wife – To have and to hold – From this day forward – For better, or worse – For richer, or poorer – In sickness, and in health – To love and to cherish –  All the days of my life*</strong></em>\r\n\r\n<span style="font-weight: 400;"><strong>(Bride)</strong> please repeat after me.</span>\r\n\r\n<em><strong>*(Bride): I (Bride) – take you (Groom) –  to be my husband – To have and to hold – From this day forward – For better, or worse – For richer, or poorer – In sickness, and in health – To love and to cherish –  All the days of my life*</strong></em>',
    },
    '12': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Option 2:',
      content:
        '<h2><b>Written Vows</b></h2>\r\n<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong> have written their own vows of marriage allowing us to see their heart for one another.</span>\r\n\r\n<span style="font-weight: 400;">Would you turn and face one another.  We will start with you <strong>(Groom)</strong>. </span>\r\n\r\n<em><strong>*(Groom says vows)*</strong></em>\r\n\r\n<span style="font-weight: 400;">Thank you <strong>(Groom)</strong>.  And now <strong>(Bride)</strong> would you share your vows of marriage for <strong>(Groom)</strong>. </span>\r\n\r\n<em><strong>*(Bride says vows)*</strong></em>',
    },
    '13': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'RING EXCHANGE',
    },
    '14': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To The Bride, Groom, and Audience',
      content:
        '<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong> will now exchange rings as a symbol of their commitment to each other. May I have the rings, please.</span>\r\n\r\n<i><span style="font-weight: 400;">Best man hands rings to pastor</span></i>\r\n\r\n<span style="font-weight: 400;">These rings are to be a reminder of the promise you have made today. Notice the circle of the ring has no beginning and no end. It is an eternal circle meant to represent your life-long vow to love each other. The ring is also made from purified precious metals, symbolic of the purity of your promise to remain faithful to each other.</span>\r\n\r\n<span style="font-weight: 400;"><strong>(Bride)</strong> please put this ring on <strong>(Groom\'s)</strong> finger and repeat after me:</span>\r\n\r\n<em><strong>*With this ring – I marry you — and bind my life to yours – with all of my heart — and all of my soul.*</strong></em>\r\n\r\n<span style="font-weight: 400;"><strong>(Groom)</strong> please put this ring on <strong>(Bride\'s)</strong> finger and repeat after me:</span>\r\n\r\n<em><strong>*With this ring – I marry you — and bind my life to yours – with all of my heart — and all of my soul.*</strong></em>',
    },
    '15': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'PRONOUNCEMENT',
    },
    '16': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To The Bride, Groom, And Audience',
      content:
        '<span style="font-weight: 400;"><strong>(Groom)</strong> and <strong>(Bride)</strong>, as you have exchanged vows and exchanged rings, I now by the power vested in me, pronounce you as husband and wife.</span>\r\n\r\nYou may kiss the Bride!\r\n\r\n<em><strong>*Bride &amp; Groom kiss*</strong></em>\r\n\r\n<span style="font-weight: 400;">Ladies and Gentlemen, it’s my honor to be the first to present to you:</span>\r\n\r\nMr. &amp; Mrs.<b> (Groom) &amp; (Bride) (Last Name)!</b>',
    },
    '17': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'RECESSIONAL',
    },
    '18': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'Order of Exit',
      content:
        '<em><span style="font-weight: 400;">The Bride and Groom exit first, followed by the wedding party.</span></em>',
    },
    '19': {
      number: '',
      id: uuid(),
      type: 'point',
      title: 'CLOSING COMMENTS',
    },
    '20': {
      id: uuid(),
      color: '',
      type: 'custom',
      title: 'To The Audience',
      content:
        '<span style="font-weight: 400;">On behalf of both families, I would like to thank you for coming, and invite you to celebrate with all us…<strong>(details or directions to reception)</strong>. </span>\r\n\r\n<span style="font-weight: 400;">Thank you.</span>',
    },
  }),
};
