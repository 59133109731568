import { API } from 'aws-amplify';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { algoliaSermonarySecuredKeyAtom } from 'src/libs/atoms/algoliaAtoms';

const getAlgoliaSecuredKey = /* GraphQL */ `
  query getAlgoliaSecuredKey($algoliaSecuredKey: String!) {
    getAlgoliaSecuredKey(algoliaSecuredKey: $algoliaSecuredKey)
  }
`;

export function useGetAlgoliaSecuredKey(shouldCheckKey: boolean) {
  const [algoliaSecuredKey, setAlgoliaSecuredKey] = useAtom(algoliaSermonarySecuredKeyAtom);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: 'useGetAlgoliaSecuredKey',
    queryFn: async () => {
      const response = await API.graphql<any>({
        query: getAlgoliaSecuredKey,
        variables: { algoliaSecuredKey: algoliaSecuredKey || '' },
      });

      return response.data.getAlgoliaSecuredKey;
    },
    enabled: shouldCheckKey,
  });

  useEffect(() => {
    data && setAlgoliaSecuredKey(data);
  }, [data]);
}
