import { Tooltip as BaseTooltip, TooltipProps } from '@chakra-ui/react';

export function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <BaseTooltip
      fontSize="sm"
      bg="white"
      color="gray.900"
      borderRadius="5px"
      boxShadow="0px 2px 4px rgba(53, 61, 65, 0.15)"
      py="3"
      px="4"
      placement="top"
      {...props}
    >
      {children}
    </BaseTooltip>
  );
}
