import { useState, useEffect, useCallback, ContextType, useContext, useRef, RefObject, LegacyRef } from 'react';
import {
  useNavigate,
  useLocation,
  UNSAFE_NavigationContext as NavigationContext,
  Navigator as BaseNavigator,
} from 'react-router-dom';
import type { Blocker, History, Transition } from 'history';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';

interface Navigator extends BaseNavigator {
  block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

// Custom useBlocker hook since react-router-dom removed it
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return function () {
        return null;
      };
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

function useCallbackPrompt(when: boolean): [boolean, () => void, () => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location.pathname],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}

type AlertProps = {
  isDirty: boolean;
};

function AlertDialogPrompt({ isDirty }: AlertProps) {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);

  const cancelRef = useRef<FocusableElement>();

  return (
    <AlertDialog
      isOpen={showPrompt}
      motionPreset="slideInBottom"
      isCentered
      leastDestructiveRef={cancelRef as RefObject<FocusableElement>}
      onClose={cancelNavigation}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Leave Page
        </AlertDialogHeader>

        <AlertDialogBody>
          Are you sure that you want to leave the current page? The changes that you made won&apos;t be saved.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button
            ref={cancelRef as LegacyRef<HTMLButtonElement>}
            onClick={cancelNavigation}
            w="80px"
            variant="ghost"
            mr="2"
            autoFocus
          >
            Cancel
          </Button>
          <Button onClick={confirmNavigation} colorScheme="blue">
            Leave
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default AlertDialogPrompt;
