import { Icon, IconProps } from '@chakra-ui/react';

export function ResourcesIcon({ height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon height={6} width={6} {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.25 0A2.75 2.75 0 0124 2.75v18.5A2.75 2.75 0 0121.25 24H4.75A2.75 2.75 0 012 21.25v-1.375h-.625a1.375 1.375 0 010-2.75H2v-3.751l-.625.001a1.375 1.375 0 010-2.75H2v-3.75h-.625a1.375 1.375 0 010-2.75H2V2.75A2.75 2.75 0 014.75 0h16.5zm-1 2.75H5.75a1 1 0 00-1 1v.375h.625a1.375 1.375 0 010 2.75H4.75v3.75h.625a1.375 1.375 0 010 2.75l-.625-.001v3.751h.625a1.375 1.375 0 010 2.75H4.75v.375a1 1 0 001 1h14.5a1 1 0 001-1V3.75a1 1 0 00-1-1zm-4.625 13a1.375 1.375 0 010 2.75h-5.25a1.375 1.375 0 010-2.75h5.25zm0-5.125a1.375 1.375 0 010 2.75h-5.25a1.375 1.375 0 010-2.75h5.25zm0-5.125a1.375 1.375 0 010 2.75h-5.25a1.375 1.375 0 010-2.75h5.25z"
      />
    </Icon>
  );
}

export default ResourcesIcon;
