import { ReactNode } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

interface CenteredLayoutProps extends FlexProps {
  children: ReactNode;
}

export function CenteredLayout({ children, ...rest }: CenteredLayoutProps) {
  return (
    <Flex alignItems="center" bg="gray.100" justifyContent="center" h="100vh" w="100%" {...rest}>
      {children}
    </Flex>
  );
}
