import { Icon, IconProps } from '@chakra-ui/react';

export function ShareIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.875 0a4.125 4.125 0 1 1-2.87 7.088L8.216 11.47a4.163 4.163 0 0 1 .002 1.047l8.796 4.386a4.125 4.125 0 1 1-1.233 2.458l-8.798-4.386a4.125 4.125 0 1 1-.01-5.958l8.807-4.391A4.125 4.125 0 0 1 19.875 0Zm0 2.75a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75ZM18.5 19.875a1.375 1.375 0 1 1 2.75 0 1.375 1.375 0 0 1-2.75 0Zm-14.375-9.25a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default ShareIcon;
