import { forwardRef } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Textarea as BaseTextarea,
  TextareaProps as TextareaPropsType,
} from '@chakra-ui/react';

interface TextareaProps extends TextareaPropsType {
  label: string;
  name: string;
  error?: string;
  leftAddon?: () => React.ReactElement;
  rightAddon?: () => React.ReactElement;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, error, leftAddon, rightAddon, name, ...rest }, ref) => (
    <FormControl isInvalid={!!error}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <InputGroup bg="white" borderRadius="md">
        {leftAddon?.()}
        <BaseTextarea ref={ref} id={name} name={name} {...rest} />
        {rightAddon?.()}
      </InputGroup>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  ),
);
