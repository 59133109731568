import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import { enforce, test, create } from 'vest';
import { Input, Textarea } from '@sermonary/components';

export interface SermonTemplateFormValues {
  title: string;
  description?: string;
}

export const sermonTemplateFormValidationSuite = create((data: Partial<SermonTemplateFormValues> = {}) => {
  test('title', 'Title is required.', () => {
    enforce(data.title).isNotEmpty();
  });
});

export function SermonTemplateForm({
  children,
  register,
  formState,
}: Pick<UseFormReturn<SermonTemplateFormValues>, 'register' | 'formState' | 'control'> & {
  children?: ReactNode;
}) {
  const { errors } = formState;

  return (
    <Grid gridTemplateColumns="1fr" gap="4">
      <Input {...register('title')} label="Title" error={errors?.title?.message} />
      <Textarea {...register('description')} label="Description" error={errors?.description?.message} />
      {children}
    </Grid>
  );
}
