import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@sermonary/icons';

type BreadcrumbProps = {
  title: string;
  to: string;
  toLabel: string;
};

export function Breadcrumbs({ title, to, toLabel }: BreadcrumbProps) {
  return (
    <Flex
      color="gray.500"
      fontSize="sm"
      sx={{
        '> *': {
          mr: 2,
        },
      }}
    >
      <Link as={RouterLink} to={to} color="gray.500">
        <Flex alignItems="center">
          <Box mr="2" color="gray.700">
            <ArrowLeftIcon display="block" />
          </Box>
          {toLabel}
        </Flex>
      </Link>
      <Text as="span">/</Text>
      <Text as="span" color="gray.700">
        {title}
      </Text>
    </Flex>
  );
}
