import { useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { Block } from '@sermonary/types';
import { useAutoSave } from '@sermonary/hooks';
import { useMemo } from 'react';
import { MediaBlockForm, MediaBlockFormValues } from '../forms';
import AlertDialogPrompt from '../forms/AlertDialogPrompt';
import { useUpdateBlockForm } from '../../hooks/useUpdateBlockForm';

export function MediaBlock({
  isActive,
  block,
  onSave,
  onDelete,
  onClose,
}: {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: MediaBlockFormValues) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: MediaBlockFormValues) => void;
}) {
  const { control, handleSubmit, reset, ...methods } = useForm<MediaBlockFormValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        inputType: block?.videoTitle && !block?.imageKey ? 'video' : 'image',
        imageKey: block?.imageKey,
        caption: block?.caption,
        videoTitle: block?.videoTitle,
        videoSrc: block?.videoSrc,
        legacyImage: block?.legacyImage,
      }),
      [block],
    ),
  });

  function onSubmit(values: MediaBlockFormValues) {
    if (methods.formState.isDirty) {
      onSave(block?.id, values);
    }
    onClose(block?.id, values);
  }

  useAutoSave<MediaBlockFormValues>({
    control,
    isDirty: methods.formState.isDirty,
    onSave: async (values: MediaBlockFormValues) => {
      onSave(block?.id, values);
      reset(values);
    },
  });

  useUpdateBlockForm<MediaBlockFormValues>({
    block,
    handleReset: () => {
      reset({
        inputType: methods.getValues('inputType'),
        imageKey: block?.imageKey,
        caption: block?.caption,
        videoTitle: block?.videoTitle,
        videoSrc: block?.videoSrc,
        legacyImage: block?.legacyImage,
      });
    },
  });

  return isActive ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDialogPrompt isDirty={methods.formState.isDirty} />
      <MediaBlockForm control={control} {...methods}>
        <Flex justifyContent="flex-end">
          <Button type="button" mr="2" onClick={() => onDelete(block)}>
            Delete
          </Button>
          <Button type="submit" colorScheme="blue">
            Done
          </Button>
        </Flex>
      </MediaBlockForm>
    </form>
  ) : null;
}
