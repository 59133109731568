import { Box, useMediaQuery } from '@chakra-ui/react';
import { OfflineIcon } from '@sermonary/icons';

export function OfflineBanner() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Box
      bg="gray.700"
      color="white"
      p="3"
      textAlign="center"
      position={isMobile ? 'relative' : 'sticky'}
      top="0"
      zIndex="101"
    >
      <OfflineIcon mr="5" width="5" height="5" />
      Offline: All changes will be saved on your device and automatically synced when you reconnect to the internet.
    </Box>
  );
}
