import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { Sermon } from 'src/models';

const algoliaUserReIndex = /* GraphQL */ `
  query algoliaUserReIndex($sermons: String) {
    algoliaUserReIndex(sermons: $sermons) {
      success
    }
  }
`;

export function useAlgoliaUserReIndex(shouldCheckForReIndex: boolean, sermons: Sermon[]) {
  const sermonsJSON = JSON.stringify({ sermons });

  const { isLoading, isError, data, error } = useQuery({
    queryKey: 'useAlgoliaUserReIndex',
    queryFn: async () => {
      const response = await API.graphql<any>({
        query: algoliaUserReIndex,
        variables: { sermons: sermonsJSON },
      });

      console.log('ReIndexed Algolia');

      return response.data.algoliaUserReIndex;
    },
    enabled: shouldCheckForReIndex && sermons.length > 0,
  });

  return {
    isLoading,
    success: data?.success || false,
  };
}
