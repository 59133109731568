import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, VisuallyHidden } from '@chakra-ui/react';
import { ActionsIcon, HideInPodiumIcon, CheckInBoxIcon } from '@sermonary/icons';
import { useDeleteSermonBlockDialog } from '@sermonary/hooks';
import { SermonBlockTypeMap, SermonBlockInlineAdd, SermonBlockDisplay, Tooltip } from '@sermonary/components';
import { Block } from '@sermonary/types';
import {
  PointBlock,
  ContentBlock,
  QuoteBlock,
  CustomBlock,
  BibleBlock,
  MediaBlock,
  IllustrationBlock,
} from '../blocks';

interface BlockProps {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: any) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: any) => void;
}

const blocks = {
  point: (props: BlockProps) => <PointBlock {...props} />,
  illustration: (props: BlockProps) => <IllustrationBlock {...props} />,
  application: (props: BlockProps) => <ContentBlock {...props} />,
  quote: (props: BlockProps) => <QuoteBlock {...props} />,
  custom: (props: BlockProps) => <CustomBlock {...props} />,
  bible: (props: BlockProps) => <BibleBlock {...props} />,
  media: (props: BlockProps) => <MediaBlock {...props} />,
};

interface SermonBlockProps {
  index: number;
  isDragging: boolean;
  block: Block;
  pointBlocks: Block[];
  lastAddedBlockId: string;
  onRemove: (block: Block) => void;
  onAdd: (index: number) => void;
  onSave: (id: string, values: Partial<Block>) => void;
}

export function SermonBlock({
  index,
  isDragging,
  block,
  pointBlocks,
  lastAddedBlockId,
  onRemove,
  onAdd,
  onSave,
}: SermonBlockProps) {
  const ref = useRef<HTMLDivElement>();
  const [isActive, setIsActive] = useState(false);
  const [hideInPodium, setHideInPodium] = useState(false);
  const [markForSlide, setMarkForSlide] = useState(false);

  const type = block?.type;

  function handleOnSave(id: string, values: any) {
    onSave(id, values);
  }

  function handleOnClose(id: string, values: any) {
    setIsActive(false);
  }

  function handleOnDelete(b: Block) {
    setIsActive(false);
    onRemove(b);
  }

  function handleHideInPodium() {
    handleOnSave(block?.id, { hideInPodium: !hideInPodium });
  }

  function handleMarkForSlide() {
    handleOnSave(block?.id, { markForSlide: !markForSlide });
  }

  useEffect(() => {
    setHideInPodium(block?.hideInPodium || false);
    setMarkForSlide(block?.markForSlide || false);
  }, [block]);

  const { handleOpenDialog, DeleteSermonBlockDialog } = useDeleteSermonBlockDialog({
    onDelete: () => {
      handleOnDelete(block);
    },
  });

  useEffect(() => {
    if (lastAddedBlockId && lastAddedBlockId === block?.id) {
      setIsActive(true);
    }
  }, [block?.id, lastAddedBlockId]);

  return (
    <>
      <SermonBlockInlineAdd isDragging={isDragging} onAdd={() => onAdd(index)} />
      <Flex
        ref={ref as MutableRefObject<HTMLDivElement>}
        alignItems="center"
        bg="white"
        borderRadius="5px"
        borderBottom="5px solid"
        borderBottomColor={block?.color || SermonBlockTypeMap?.[type]?.color}
        flexDirection={['column', 'row']}
        p="8"
        onClick={() => setIsActive(true)}
        cursor={isActive ? 'auto' : 'grab'}
      >
        <Box width="full">
          {!isActive ? (
            <SermonBlockDisplay
              block={block}
              pointBlocks={pointBlocks}
              renderIndicators={() =>
                (hideInPodium || markForSlide) && (
                  <Box ml="auto">
                    {hideInPodium && (
                      <Tooltip label="Block does not show in podium mode">
                        <Box as="span" mr="4">
                          <HideInPodiumIcon height="5" width="5" color="gray.700" />
                        </Box>
                      </Tooltip>
                    )}
                    {markForSlide && (
                      <Tooltip label="Block will be included in slides">
                        <Box as="span" mr="4">
                          <CheckInBoxIcon height="5" width="5" color="gray.700" />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )
              }
              renderActions={() => (
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    onClick={(e) => e.stopPropagation()}
                    _hover={{
                      background: 'gray.100',
                      svg: {
                        color: 'gray.600',
                      },
                    }}
                    _active={{
                      background: 'gray.200',
                      svg: {
                        color: 'gray.600',
                      },
                    }}
                  >
                    <ActionsIcon />
                    <VisuallyHidden>Sermon block actions</VisuallyHidden>
                  </MenuButton>
                  <MenuList zIndex="2">
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsActive(true);
                      }}
                    >
                      Edit
                    </MenuItem>
                    {/* <MenuItem onClick={(e) => e.stopPropagation()}>
                      Move up
                    </MenuItem>
                    <MenuItem onClick={(e) => e.stopPropagation()}>
                      Move down
                    </MenuItem> */}
                    {!hideInPodium ? (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHideInPodium();
                        }}
                      >
                        Hide in podium mode
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHideInPodium();
                        }}
                      >
                        Show in podium mode
                      </MenuItem>
                    )}
                    {!markForSlide ? (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMarkForSlide();
                        }}
                      >
                        Mark for slide
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMarkForSlide();
                        }}
                      >
                        Remove from slide
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            />
          ) : (
            blocks?.[type]?.({
              isActive,
              block,
              onSave: handleOnSave,
              onClose: handleOnClose,
              onDelete: handleOpenDialog,
            })
          )}
        </Box>
      </Flex>
      <DeleteSermonBlockDialog />
    </>
  );
}
