import { UseFormReturn } from 'react-hook-form';
import { enforce, test, create } from 'vest';
import { Box, Grid } from '@chakra-ui/react';
import { Input, Tooltip } from '@sermonary/components';

export interface UserSettingsFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export const userSettingsValidationSuite = create((data: Partial<UserSettingsFormValues> = {}) => {
  test('email', 'Email is required.', () => {
    enforce(data.email).isNotEmpty();
  });
});

export function UserSettingsForm({
  formState,
  register,
}: Pick<UseFormReturn<UserSettingsFormValues> & { user: any }, 'formState' | 'register'>) {
  const { errors } = formState;

  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="4">
      <Input {...register('firstName')} label="First name" error={errors?.firstName?.message} />
      <Input {...register('lastName')} label="Last name" error={errors?.lastName?.message} />
      <Tooltip
        placement="bottom"
        openDelay={500}
        closeOnClick={false}
        label="Log In Email cannot be updated. Contact Support to update."
      >
        <Box>
          <Input isDisabled {...register('email')} label="Login Email" error={errors?.email?.message} />
        </Box>
      </Tooltip>
    </Grid>
  );
}
