import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Logo } from '@sermonary/components';
import { LoadingGif } from '@sermonary/icons';
import { useAtom } from 'jotai';
import { modelsSyncedAtom } from 'src/App';
import { Progress } from '@chakra-ui/react';
import { useEffect } from 'react';
import { openBeacon } from '../analytics/HelpScout';
import { useHandleSignOut } from 'src/hooks/useHandleSignout';

const SyncProgressBar = () => {
  const [modelsSynced, setModelsSynced] = useAtom(modelsSyncedAtom);

  useEffect(() => {
    return () => setModelsSynced([]);
  }, []);

  return <Progress bgColor={'gray.300'} value={modelsSynced.length * 11.11} />;
};

export function InitialSyncLoading() {
  const { handleSignOut } = useHandleSignOut();

  return (
    <Box w={'100vw'} h={'100vh'} p={'10'} bg={'gray.100'} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      <Logo />
      <Flex flexGrow={1} justifyContent="center" alignItems="center">
        <Box>
          <Box py={'50%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <LoadingGif />
            <Box textAlign={'center'}>
              <Text mt={'5'} mb={'3'} fontSize={'x-large'}>
                Syncing...
              </Text>
              <SyncProgressBar />
              <Text fontSize={'sm'} color={'gray.700'} mb={'5'}>
                It'll just be a moment*
              </Text>
              <Link color={'gray.800'} onClick={handleSignOut}>
                Log Out
              </Link>
              <Text mt={'25%'} fontSize={'sm'} color={'gray.700'}>
                *If it takes longer than a minute,{' '}
                <Link onClick={openBeacon} color="gray.900">
                  contact support.
                </Link>
              </Text>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
