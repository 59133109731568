import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthError } from '@sermonary/types';

enum State {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Blocked = 'BLOCKED',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

interface UseVerifyEmailOptions {
  onSuccess?: () => void;
  onLoading?: () => void;
  onBlocked?: () => void;
  onError?: (error: AuthError) => void;
}

export function useVerifyEmail({ onSuccess, onLoading, onBlocked, onError }: UseVerifyEmailOptions) {
  const location = useLocation();
  const code = location.search.split('=')[1];

  const [state, setState] = useState<State>(State.Idle);
  const [error, setError] = useState<AuthError>();

  async function handleSendVerifyEmail() {
    try {
      setState(State.Loading);
      onLoading?.();
      await Auth.verifyCurrentUserAttribute('email');
      setState(State.Success);
      onSuccess?.();
    } catch (err: any) {
      setState(State.Error);
      setError(err as AuthError);
      onError?.(err as AuthError);
      console.error(`Unable to resend verification email: ${err}`);
    }
  }

  async function handleVerifyEmailConfirm() {
    try {
      setState(State.Loading);
      onLoading?.();

      if (code) {
        await Auth.verifyCurrentUserAttributeSubmit('email', code);
        setState(State.Success);
        onSuccess?.();
      } else {
        setState(State.Error);
        onBlocked?.();
        console.error('Unable to verify email. Confirmation code was not provided in URL params.');
      }
    } catch (err: any) {
      setState(State.Error);
      setError(err as AuthError);
      onError?.(err as AuthError);
    }
  }

  return {
    state,
    error,
    isLoading: state === State.Loading,
    isError: state === State.Error,
    isSuccess: state === State.Success,
    handleSendVerifyEmail,
    handleVerifyEmailConfirm,
  };
}
