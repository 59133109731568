import { Icon, IconProps } from '@chakra-ui/react';

export function PauseIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2.74c-.76 0-1.375.615-1.375 1.374v15.771a1.375 1.375 0 0 0 2.75 0V4.115c0-.76-.616-1.376-1.375-1.376Zm11 0c-.76 0-1.375.615-1.375 1.374v15.771a1.375 1.375 0 0 0 2.75 0V4.115c0-.76-.616-1.376-1.375-1.376Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default PauseIcon;
