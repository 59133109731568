import { useState, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Box, BoxProps, Flex, Grid, Heading, Link, Text } from '@chakra-ui/react';
import { PodiumIcon, ShareIcon } from '@sermonary/icons';

const Motion = motion<BoxProps>(Box);

export interface BaseSermon {
  id: string;
  title: string;
  referenceVerses?: string | null;
  bigIdea?: string | null;
  seriesId?: string | null;
}

interface SermonCardProps<T extends BaseSermon> {
  children: ReactNode;
  sermon: T;
  onExport: (sermon: T) => void;
  onShare: (sermon: T) => void;
}

export function SermonCard<T extends BaseSermon>({ children, sermon, onExport, onShare }: SermonCardProps<T>) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box
      bg="white"
      borderRadius="md"
      minHeight="268px"
      p={['4', '10']}
      maxWidth="calc(100vw - 2em)"
      position="relative"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <Grid gap="8" gridTemplateColumns="100%">
        <Heading as="h4" fontSize="2xl" fontWeight="normal" color="blue.500">
          {sermon?.title !== '' ? sermon?.title : 'Untitled'}
        </Heading>
        {sermon?.referenceVerses ? (
          <Box>
            <Text as="span" color="gray.700" fontFamily="serif" fontStyle="italic" fontWeight="bold">
              {sermon?.referenceVerses}
            </Text>
          </Box>
        ) : null}
        {sermon?.bigIdea ? (
          <Box>
            <Text as="p" color="gray.900" noOfLines={3}>
              {sermon?.bigIdea}
            </Text>
          </Box>
        ) : null}
      </Grid>
      <AnimatePresence>
        {isVisible && (
          <Motion
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            backgroundColor="rgba(22, 142, 234, 0.83)"
            borderRadius="md"
            position="absolute"
            top="0"
            left="0"
            bottom="0"
            right="0"
          >
            <Link
              as={RouterLink}
              to={`/sermons/${sermon?.id}`}
              color="white"
              position="absolute"
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="outline" color="white" borderColor="white" _hover={{ background: 'transparent' }}>
                Edit Sermon
              </Button>
            </Link>
            <Flex direction="column" justifyContent="space-between" height="100%">
              <Flex pt="5" px="6" justifyContent="space-between">
                <Box />
                {children}
              </Flex>
              <Flex color="white" justifyContent="center" alignItems="center" mb="8">
                <Button
                  as={RouterLink}
                  to={`/sermons/${sermon?.id}/podium`}
                  color="white"
                  variant="shadow"
                  fontSize="sm"
                  textTransform="uppercase"
                >
                  <PodiumIcon mr="2" width="4" height="4" />
                  Podium
                </Button>
                {/* <Button
                  variant="shadow"
                  fontSize="sm"
                  textTransform="uppercase"
                  onClick={() => onExport(sermon)}
                >
                  <ExportIcon mr="2" width="4" height="4" />
                  Export
                </Button> */}
                <Button variant="shadow" fontSize="sm" textTransform="uppercase" onClick={() => onShare(sermon)}>
                  <ShareIcon mr="2" width="4" height="4" />
                  Share
                </Button>
              </Flex>
            </Flex>
          </Motion>
        )}
      </AnimatePresence>
    </Box>
  );
}
