import { Box, FormControl, FormErrorMessage, FormLabel, TextareaProps as TextareaPropsType } from '@chakra-ui/react';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/css/plugins/fullscreen.min.css';
import { Controller, UseFormReturn } from 'react-hook-form';
import { track } from '../../analytics/Analytics';
import { sermonAtom } from 'src/pages/sermons/SingleSermon';
import { useAtom } from 'jotai';

type TextEditorProps = TextareaPropsType &
  Pick<UseFormReturn, 'control'> & {
    label: string;
    error?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    editorConfig?: Object;
  };

export function TextEditor({ label, error, name, control, editorConfig }: TextEditorProps) {
  const [sermon] = useAtom(sermonAtom);
  return (
    <FormControl isInvalid={!!error}>
      {label ? (
        <FormLabel hidden htmlFor={name}>
          {label}
        </FormLabel>
      ) : null}
      <Controller
        control={control}
        name={name as 'content'}
        render={({ field: { onChange, value, ref } }) => (
          <Box>
            <FroalaEditor
              config={{
                events: {
                  'commands.after': function (cmd: any) {
                    if (cmd === 'paragraphFormat') {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      // eslint-disable-next-line react/no-this-in-sfc
                      this.commands.clearFormatting();
                    }
                  },
                  'paste.after': async function () {
                    await track('Pasting into Froala Editor', { sermonId: sermon?.id });
                  },
                },
                key: 'YNB3fJ3C10B7D6D6E2A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2C4D6B3C2==',
                heightMin: '300',
                toolbarButtons: {
                  moreText: {
                    buttons: [
                      'paragraphFormat',
                      'fontSize',
                      'bold',
                      'italic',
                      'underline',
                      'outdent',
                      'indent',
                      'textColor',
                      'backgroundColor',
                      'insertLink',
                      'align',
                      'formatOL',
                      'formatUL',
                      'insertHR',
                      'quote',
                      'clearFormatting',
                      'undo',
                      'redo',
                      'fullscreen',
                    ],
                    buttonsVisible: 17,
                  },
                },
                colorsBackground: [
                  'REMOVE',
                  '#168eea',
                  '#353d41',
                  '#535f66',
                  '#7f909b',
                  '#ed3d3d',
                  '#6ab616',
                  '#e5d506',
                  '#e69f06',
                  '#a661e7',
                  '#ca77d4',
                  '#000000',
                ],
                colorsStep: 6,
                colorsText: [
                  'REMOVE',
                  '#168eea',
                  '#353d41',
                  '#535f66',
                  '#7f909b',
                  '#ed3d3d',
                  '#6ab616',
                  '#e5d506',
                  '#e69f06',
                  '#a661e7',
                  '#ca77d4',
                  '#ffffff',
                ],
                theme: 'gray',
                attribution: false,
                tabSpaces: 4,
                fontSizeSelection: true,
                fontSizeDefaultSelection: '16',
                fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
                paragraphFormat: {
                  N: 'Paragraph',
                  H1: 'Heading 1',
                  H2: 'Heading 2',
                  H3: 'Heading 3',
                  H4: 'Heading 4',
                },
                paragraphFormatSelection: true,
                pasteAllowedStyleProps: ['color'],
                htmlAllowedTags: [
                  'p',
                  'h1',
                  'h2',
                  'h3',
                  'h4',
                  'h5',
                  'h6',
                  'span',
                  'strong',
                  'em',
                  'u',
                  'a',
                  'ol',
                  'ul',
                  'li',
                  'hr',
                  'blockquote',
                  'br',
                  'sup',
                ],
                toolbarSticky: false,
                ...editorConfig,
              }}
              tag="textarea"
              model={value}
              onModelChange={onChange}
              ref={ref}
            />
          </Box>
        )}
      />
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
}
