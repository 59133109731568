import { UseFormReturn } from 'react-hook-form';
import { enforce, test, create } from 'vest';
import { Grid } from '@chakra-ui/react';
import { Input } from '@sermonary/components';

export interface UserChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
}

export const userChangePasswordValidationSuite = create((data: Partial<UserChangePasswordFormValues> = {}) => {
  test('oldPassword', 'Current password is required.', () => {
    enforce(data.oldPassword).isNotEmpty();
  });

  test('newPassword', 'New password is required.', () => {
    enforce(data.newPassword).isNotEmpty();
  });
});

export function UserChangePasswordForm({
  formState,
  register,
}: Pick<UseFormReturn<UserChangePasswordFormValues>, 'register' | 'formState'>) {
  const { errors } = formState;

  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="4">
      <Input
        {...register('oldPassword')}
        label="Current password"
        type="password"
        error={errors?.oldPassword?.message}
      />
      <Input {...register('newPassword')} label="New password" type="password" error={errors?.newPassword?.message} />
    </Grid>
  );
}
