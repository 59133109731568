import { Button, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ThinTopBanner } from '.';

export function FreeUserUpgradeBanner() {
  return (
    <ThinTopBanner>
      <Text textAlign={'center'} fontSize="sm" color="gray.900" mx={4}>
        We no longer offer a free plan. Experience Sermonary like never before with an exclusive promotional offer!
      </Text>
      <Button
        as={RouterLink}
        to={'/tier-upgrade'}
        colorScheme="blue"
        fontSize={'xs'}
        paddingX={2}
        height={6}
        minW={'unset'}
      >
        Subscribe
      </Button>
    </ThinTopBanner>
  );
}
