import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  ModalProps,
  Text,
  Box,
  InputRightElement,
  InputGroup,
  useClipboard,
  Input,
  useMediaQuery,
} from '@chakra-ui/react';
import { SetStateAction, useCallback, useState } from 'react';
import { track } from '../../analytics/Analytics';

interface SermonShareProps {
  sermon: any;
}

function SermonShareModal({ isOpen, onClose, sermon }: Pick<ModalProps, 'isOpen' | 'onClose'> & SermonShareProps) {
  const shareUrl = `${window.location.origin}/sermons/${sermon?.id}/podium/share`;
  const { hasCopied, onCopy } = useClipboard(shareUrl);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const handleCopyUrl = async () => {
    onCopy();
    await track('Share Podium Sermon', { pathname: window.location.pathname });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : 'md'}>
      <ModalOverlay />
      <ModalContent p="4" pb="8">
        <ModalHeader>Share Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text mb={4} mt={-2} ml={1} alignSelf="flex-start" color="gray.700" fontSize="sm">
              Share this URL to allow anyone to view your sermon in Podium Mode.
            </Text>
            <InputGroup>
              <Input backgroundColor="gray.200" color="gray.900" id="shareUrl" value={shareUrl} isReadOnly />
              <InputRightElement width="4.5rem">
                <Button colorScheme="blue" h="1.75rem" size="sm" onClick={handleCopyUrl}>
                  {hasCopied ? 'Copied' : 'Copy'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function useSermonShareModal() {
  const [sermon, setSermon] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    handleOpenModal: (s: SetStateAction<any>) => {
      setSermon(s);
      onOpen();
    },
    handleCloseModal: onClose,
    SermonShareModal: useCallback(
      () => <SermonShareModal isOpen={isOpen} onClose={onClose} sermon={sermon} />,
      [sermon, isOpen, onClose],
    ),
  };
}
