import { useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { Block, CustomBlock as CustomBlockType } from '@sermonary/types';

import { useAutoSave } from '@sermonary/hooks';
import { useMemo } from 'react';
import AlertDialogPrompt from '../forms/AlertDialogPrompt';
import { CustomBlockForm } from '../forms';
import { useUpdateBlockForm } from '../../hooks/useUpdateBlockForm';

export type CustomBlockFormValues = Pick<CustomBlockType, 'title' | 'content' | 'color'>;

export function CustomBlock({
  isActive,
  block,
  onSave,
  onDelete,
  onClose,
}: {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: CustomBlockFormValues) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: CustomBlockFormValues) => void;
}) {
  const { control, handleSubmit, reset, ...methods } = useForm<CustomBlockFormValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        title: block?.title,
        content: block?.content,
        color: block?.color || '#000',
      }),
      [block],
    ),
  });

  function onSubmit(values: CustomBlockFormValues) {
    if (methods.formState.isDirty) {
      onSave(block?.id, values);
    }
    onClose(block?.id, values);
  }

  useAutoSave<CustomBlockFormValues>({
    control,
    isDirty: methods.formState.isDirty,
    onSave: async (values: CustomBlockFormValues) => {
      onSave(block?.id, values);
      reset(values);
    },
  });

  useUpdateBlockForm<CustomBlockFormValues>({
    block,
    handleReset: () => {
      reset({
        title: block?.title,
        content: block?.content,
        color: block?.color ?? '#000',
      });
    },
  });

  return isActive ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDialogPrompt isDirty={methods.formState.isDirty} />
      <CustomBlockForm control={control} {...methods}>
        <Flex justifyContent="flex-end">
          <Button type="button" mr="2" onClick={() => onDelete(block)}>
            Delete
          </Button>
          <Button type="submit" colorScheme="blue">
            Done
          </Button>
        </Flex>
      </CustomBlockForm>
    </form>
  ) : null;
}
