import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const easterSundayScript = {
  id: 'easterSundayScript',
  bigIdea:
    'Just as Lazarus was raised from the dead, so we, too, can move from death to life when we place our hope in Jesus’s resurrection.',
  sermonType: SermonType.SERMON,
  description:
    'Just as Lazarus was raised from the dead, so we, too, can move from death to life when we place our hope in Jesus’s resurrection.',
  title: 'Easter Sunday Script',
  blocks: JSON.stringify({
    '0': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Introduction',
    },
    '1': {
      id: uuid(),
      color: '#000',
      wordCount: 147,
      type: 'custom',
      title: 'What is Easter about?',
      content:
        "<p>What is Easter about? Egg hunts and chocolate bunnies? The change of seasons from wither to spring? No, but that last one is closer: Easter is about death being ousted by new life. Easter is the celebration of Jesus's atoning sacrifice, his dying on the cross to pay for our sins, and his victory over sin and death when he came alive again that first Easter Sunday. Jesus's resurrection is the guarantee of eternal life for any who believe. This morning we are going to look at another resurrection story, a story that reminds us of the new life Jesus gives to all who believe in him. Let's turn to John 11 where Jesus raises his friend Lazarus from death to life. Just as Lazarus was raised from the dead, so we, too, can move from death to life when we place our hope in Jesus's resurrection.&nbsp;</p>",
    },
    '2': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Point 1: Jesus is the Resurrection and the Life (John 11:1-27)',
    },
    '3': {
      chapter: '11',
      wordCount: 465,
      book: 'JHN',
      translation: 'NIV',
      inputType: 'select',
      id: uuid(),
      type: 'bible',
      verses: '1-27',
      content: {
        v6: ['So when he heard that Lazarus was sick, he stayed where he was two more days,'],
        v7: ['and then he said to his disciples,', '“Let us go back to Judea.”'],
        v8: [
          '“But Rabbi,” they said, “a short while ago the Jews there tried to stone you, and yet you are going back?”',
        ],
        v9: [
          'Jesus answered,',
          '“Are there not twelve hours of daylight? Anyone who walks in the daytime will not stumble, for they see by this world’s light.',
        ],
        v10: ['It is when a person walks at night that they stumble, for they have no light.”'],
        v12: ['His disciples replied, “Lord, if he sleeps, he will get better.”'],
        v11: [
          'After he had said this, he went on to tell them,',
          '“Our friend Lazarus has fallen asleep; but I am going there to wake him up.”',
        ],
        v14: ['So then he told them plainly,', '“Lazarus is dead,'],
        v13: ['Jesus had been speaking of his death, but his disciples thought he meant natural sleep.'],
        v16: [
          'Then Thomas (also known as Didymus',
          ') said to the rest of the disciples, “Let us also go, that we may die with him.”',
        ],
        v15: ['and for your sake I am glad I was not there, so that you may believe. But let us go to him.”'],
        v18: ['Now Bethany was less than two miles', 'from Jerusalem,'],
        v17: ['On his arrival, Jesus found that Lazarus had already been in the tomb for four days.'],
        v19: ['and many Jews had come to Martha and Mary to comfort them in the loss of their brother.'],
        v21: ['“Lord,” Martha said to Jesus, “if you had been here, my brother would not have died.'],
        v20: ['When Martha heard that Jesus was coming, she went out to meet him, but Mary stayed at home.'],
        v23: ['Jesus said to her,', '“Your brother will rise again.”'],
        v22: ['But I know that even now God will give you whatever you ask.”'],
        v25: [
          'Jesus said to her,',
          '“I am the resurrection and the life. The one who believes in me will live, even though they die;',
        ],
        v24: ['Martha answered, “I know he will rise again in the resurrection at the last day.”'],
        v27: [
          '“Yes, Lord,” she replied, “I believe that you are the Messiah, the Son of God, who is to come into the world.”',
        ],
        v1: ['Now a man named Lazarus was sick. He was from Bethany, the village of Mary and her sister Martha.'],
        v26: ['and whoever lives by believing in me will never die. Do you believe this?”'],
        v2: [
          '(This Mary, whose brother Lazarus now lay sick, was the same one who poured perfume on the Lord and wiped his feet with her hair.)',
        ],
        v3: ['So the sisters sent word to Jesus, “Lord, the one you love is sick.”'],
        v4: [
          'When he heard this, Jesus said,',
          '“This sickness will not end in death. No, it is for God’s glory so that God’s Son may be glorified through it.”',
        ],
        v5: ['Now Jesus loved Martha and her sister and Lazarus.'],
      },
    },
    '4': {
      id: uuid(),
      color: '#000',
      wordCount: 146,
      type: 'custom',
      title: 'Explanation',
      content:
        '<p>Jesus had a special relationship with this group of siblings: Martha, Mary, and Lazarus. His love for them is mentioned several times (vv. 2-3, 5). Yet when they send word to Jesus that Lazarus is deathly ill, Jesus delays coming and Lazarus dies (vv. 6-14). Jesus hints to his disciples what he plans to do, but they don\'t understand (vv.11-15). When he finally arrives at their home in Bethany, the sisters are grieving and upset. They know Jesus <em>could have have&nbsp;</em>healed their brother (vv. 21, 32). Jesus tells Martha what he is going to do--raise Lazarus from the dead--but she thinks he means only in the last day (vv.23-24). Jesus assures her that he is "the resurrection and the life" and whoever believes in him "will live, even though they die" (v. 25). She reiterates her faith in him as the Son of God (v. 27).</p>',
    },
    '5': {
      id: uuid(),
      wordCount: 241,
      type: 'illustration',
      content:
        '<p>Musician David Bowie died January 10, 2016. Seeming to cheat death, he released a music video of a song called "Lazarus" only a few days before his death. It began to circulate widely after his death. his producer for the album <em>Blackstar,&nbsp;</em>Tony Visconti, wrote on Facebook &lt;https://www.facebook.com/tony.visconti1/posts/10208522003550232&gt;, "He always did what he wanted to do. And he wanted to do it this way and he wanted to do it the best way. His death was no different from his life - a work of Art. He made Blackstar for us, his parting gift...He was an extraordinary man, full of love and life. He will always be with us. For now, it is appropriate to cr." This had a powerful effect on his fans, who seemed to feel almost as if Bowie was reaching back through he grave through the matter was that Bowie, brilliant musician though he was, in fact, dead. Only God knows the state of his faith, but regardless of whether Bowie will be raised again with all believers, he cannot make the claim that Jesus makes here. Jesus says, "I am the resurrection and the life. Like Lazarus, Bowie can only come alive again through an act of God. Just a s God once breathed the breath of life into Adam and Eve, so God will do with the ashes and remains of our dead bodies. He can animate dead matter because He IS the resurrection and the life.&nbsp;</p>',
    },
    '6': {
      id: uuid(),
      wordCount: 52,
      type: 'application',
      content:
        "<p>Eternal life starts not in heaven but in the moment when we believe Jesus is God's only Son and believe in what he accomplished for us through his death and resurrection. To become born again involves a transformation of our inward person that is then reflected over time in our outward lives.</p>",
    },
    '7': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Point 2: Pain Will Test Our Trust in Jesus (John 11:28-37)',
    },
    '8': {
      chapter: '11',
      wordCount: 191,
      book: 'JHN',
      translation: 'NIV',
      inputType: 'select',
      id: uuid(),
      type: 'bible',
      verses: '28-37',
      content: {
        v30: ['Now Jesus had not yet entered the village, but was still at the place where Martha had met him.'],
        v32: [
          'When Mary reached the place where Jesus was and saw him, she fell at his feet and said, “Lord, if you had been here, my brother would not have died.”',
        ],
        v31: [
          'When the Jews who had been with Mary in the house, comforting her, noticed how quickly she got up and went out, they followed her, supposing she was going to the tomb to mourn there.',
        ],
        v34: ['“Where have you laid him?”', 'he asked.', '“Come and see, Lord,” they replied.'],
        v33: [
          'When Jesus saw her weeping, and the Jews who had come along with her also weeping, he was deeply moved in spirit and troubled.',
        ],
        v36: ['Then the Jews said, “See how he loved him!”'],
        v35: ['Jesus wept.'],
        v37: [
          'But some of them said, “Could not he who opened the eyes of the blind man have kept this man from dying?”',
        ],
        v29: ['When Mary heard this, she got up quickly and went to him.'],
        v28: [
          'After she had said this, she went back and called her sister Mary aside. “The Teacher is here,” she said, “and is asking for you.”',
        ],
      },
    },
    '9': {
      id: uuid(),
      color: '#000',
      wordCount: 63,
      type: 'custom',
      title: 'Explanation',
      content:
        '<p>Openly emotional and upset, Mary comes to where Jesus is waiting for her (vv. 28-33). She is the same woman who had poured out her precious perfume on his feet and wiped his feet with her hair (v. 2). They were close and she was a believer. Yet her darkest moment of disappointment and pain tested everything she believed about her precious Savior.</p>',
    },
    '10': {
      id: uuid(),
      wordCount: 92,
      type: 'illustration',
      content:
        "<p>Consider sharing about how the seemingly finality of the death of a loved one has challenged your own faith. Or share about your experiences leading funeral services. Most congregants will never experience such a close experience with death and grief as a pastor does. Heave you ever found it hard to trust in Jesus as the resurrection and the life as you stand over a particularly tragic grave? What words or Scriptures do you always speak over the mourners at the graveside? How does Jesus's promise sustain in your work of ministry?</p>",
    },
    '11': {
      id: uuid(),
      wordCount: 103,
      type: 'application',
      content:
        '<p>When Mary faces her dark night of the soul, in the midst of her doubt and grief, Jesus says to her, "I am the resurrection and the life. The one who believes in me will live, even though they die; and whoever lives by believing in me will never die. Do you believe this?" (vv. 25-26). Today, Jesus promises the same to each of us who face our own doubt, grief, and fear. He confronts each of us with his identity as they resurrection and the life. He asks us to recommit to believing his promise. Can we say today, "Lord, I believe"?</p>',
    },
    '12': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Point 3: Because of Jesus, We Too Can Lose Our Grave Clothes (John 11:38-44)',
    },
    '13': {
      chapter: '11',
      wordCount: 165,
      book: 'JHN',
      translation: 'NIV',
      inputType: 'select',
      id: uuid(),
      type: 'bible',
      verses: '38-44',
      content: {
        v41: [
          'So they took away the stone. Then Jesus looked up and said,',
          '“Father, I thank you that you have heard me.',
        ],
        v40: ['Then Jesus said,', '“Did I not tell you that if you believe, you will see the glory of God?”'],
        v43: ['When he had said this, Jesus called in a loud voice,', '“Lazarus, come out!”'],
        v42: [
          'I knew that you always hear me, but I said this for the benefit of the people standing here, that they may believe that you sent me.”',
        ],
        v44: [
          'The dead man came out, his hands and feet wrapped with strips of linen, and a cloth around his face.',
          'Jesus said to them,',
          '“Take off the grave clothes and let him go.”',
        ],
        v38: ['Jesus, once more deeply moved, came to the tomb. It was a cave with a stone laid across the entrance.'],
        v39: [
          '“Take away the stone,”',
          'he said.',
          '“But, Lord,” said Martha, the sister of the dead man, “by this time there is a bad odor, for he has been there four days.”',
        ],
      },
    },
    '14': {
      id: uuid(),
      color: '#000',
      wordCount: 161,
      type: 'custom',
      title: 'Explanation',
      content:
        '<p>In this passage, Jesus confronts the stark reality of death. Like he did a few moments before, Jesus again weeps as he stands at Lazarus\'s tomb (vv.35, 38). The very Resurrection and Life personified is grieved by the horror of death. A cold, heavy stone blocks the entrance to the grave, serving to reinforce the finality of death (v. 39). The scent of a decomposing body is near (v. 39), and then Jesus asks the gathered crowd to "take away the stone" (v. 39). Everyone is afraid of drawing nearer to death, but they obey Jesus (vv. 39-41). And Lazarus emerges from the grave, alive! When Jesus says, "Take off the grave clothes" at Lazarus\'s miraculous resurrection (v. 44), he is speaking to all the witnesses, the sisters, and their family and friends. This event is a precursor to the Easter story. Jesus too would die. Yet he would be raised. Not to die agin, but to ensure our new life.&nbsp;</p>',
    },
    '15': {
      id: uuid(),
      wordCount: 135,
      type: 'illustration',
      content:
        '<p><em>[Note to the Pastor: To illustrate "grave clothes" issues that need to come off, take a roll of toilet paper and, with sheets still intact, write in large letters common sin patters that are destructive (for example: forgotten, addicted to pornography, cursing, hateful, unforgiveness, gossip, angry outbursts, lying, alcoholism, immorality, impurity, condemning myself, fear of humans, etc.). Then wrap a person in this roll of paper like a mummy and have them waiting a bit out of sight until this point in the message. Have the mummy come out unwrap him/her. Call out the words as. you take them off and replace them with the opposite, based on who we are in Christ ("You are no longer hateful. In Christ, your re called beloved. You are no longer forgotten. In Christ, you are sought out.").]</em></p>',
    },
    '16': {
      id: uuid(),
      wordCount: 62,
      type: 'application',
      content:
        "<p>As believers, we have a responsibility, a ministry of reconciliation, to help other people be free from the sin that so easily entangles, to help them remove the old grave clothes, and walk in new life.<em>&nbsp;[A Note to the Pastor: This is a good opportunity to mention your church's community groups and clear directions on how someone can get connected to one.]</em></p>",
    },
    '17': {
      id: uuid(),
      color: '#000',
      wordCount: 123,
      type: 'custom',
      title: 'Conclusion',
      content:
        '<p><em>[Note to the Pastor: Give an invitation for people to exchange old life for new life in Christ. Because he died and rose again, all who are gathered can have new life. Jesus IS the resurrection and the life. He can breathe life into our ashes, into our dead bodies and lives. The beginnings of this are springing up even now. Provide and opportunity for response and personal prayer for those who are struggling in the midst of pain or doubt, as well as those who long to know he Resurrection and the Life, Jesus, for the first time. Be sure to also offer personal prayer for those who are struggling to remove their grave clothes despite the resurrection Christ has given them.]</em></p>',
    },
  }),
};
