import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Select as BaseSelect,
  SelectProps as SelectPropsType,
} from '@chakra-ui/react';
import { forwardRef, ReactNodeArray } from 'react';

interface SelectProps extends SelectPropsType {
  isRequired?: boolean;
  label?: string;
  error?: string;
  name?: string;
  id?: string;
  children: ReactNodeArray;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ isRequired, label, error, name, id, children, ...rest }, ref) => (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? <FormLabel htmlFor={id || name}>{label}</FormLabel> : null}
      <InputGroup bg="white" borderRadius="md">
        <BaseSelect ref={ref} id={id || name} name={name} {...rest}>
          {children}
        </BaseSelect>
      </InputGroup>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  ),
);
