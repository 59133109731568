import { Icon, IconProps } from '@chakra-ui/react';

export function ArrowLeftIcon({ ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 6 9" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.247.251a.857.857 0 010 1.212L2.424 4.286l2.823 2.822A.857.857 0 014.035 8.32L0 4.286 4.035.25a.857.857 0 011.212 0z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default ArrowLeftIcon;
