import { track } from '../../analytics/Analytics';
import { Button, Menu, MenuButton, MenuList, MenuItem, SimpleGrid, VisuallyHidden } from '@chakra-ui/react';

import { useSermonShareModal } from '@sermonary/hooks';
import { ActionsIcon } from '@sermonary/icons';
import { BaseSermon, SermonCard } from '.';

interface SermonCardGridProps<T extends BaseSermon> {
  sermons: T[];
  onDuplicateSermon?: (sermon: T) => void;
  onArchiveSermon?: (sermon: T) => void;
  onDeleteSermon?: (sermon: T) => void;
}

export function SermonCardGrid<T extends BaseSermon>({
  sermons,
  onDuplicateSermon,
  onArchiveSermon,
  onDeleteSermon,
}: SermonCardGridProps<T>) {
  const { handleOpenModal, SermonShareModal } = useSermonShareModal();

  const handleOnExport = (sermon: T) => {};

  const handleArchive = async (sermon: T) => {
    onArchiveSermon?.(sermon);
    await track('Archive Sermon');
  };

  return (
    <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} spacing="4" mt="10">
      {sermons?.map((sermon: T) => (
        <SermonCard<T> key={sermon?.id} sermon={sermon} onExport={handleOnExport} onShare={handleOpenModal}>
          <Menu>
            <MenuButton as={Button} variant="shadow" h="auto" p="0" minW="none">
              <ActionsIcon color="white" />
              <VisuallyHidden>Sermon block actions</VisuallyHidden>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => onDuplicateSermon?.(sermon)}>Duplicate</MenuItem>
              <MenuItem onClick={() => handleArchive(sermon)}>Archive</MenuItem>
              {/* <MenuItem onClick={() => handleOnSaveAsTemplate(sermon)}>
                Save as Template
              </MenuItem> */}
              <MenuItem onClick={() => onDeleteSermon?.(sermon)}>Delete</MenuItem>
            </MenuList>
          </Menu>
        </SermonCard>
      ))}
      <SermonShareModal />
    </SimpleGrid>
  );
}
