import { useState, FocusEvent, useEffect } from 'react';
import { Box, BoxProps, Flex, Input } from '@chakra-ui/react';
import { FieldValues, useForm, SubmitHandler } from 'react-hook-form';
import { WriteOrEditIcon } from '@sermonary/icons';

type FormValues = FieldValues & { title: string };

interface HeadingProps extends FormValues, BoxProps {
  title: string;
  isEditable?: boolean;
  modelId?: string;
  onHeadingBlur?: (modelId: string, title: string) => void;
}

export function Heading({ title, isEditable, modelId, onHeadingBlur, ...props }: HeadingProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const { register, handleSubmit, formState, reset, getValues } = useForm<FormValues>({
    defaultValues: {
      title,
    },
  });

  const formValues = getValues();

  const { isDirty } = formState;

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
    setHasFocus(true);
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (isDirty && data.title) {
      onHeadingBlur?.(modelId || '', data.title);
      reset({ title: data.title });
    }
    setHasFocus(false);
  };

  useEffect(() => {
    if (formValues?.title !== title) {
      reset({ title });
    }
  }, [title]);

  return (
    <Box
      w="100%"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      fontSize="sm"
      fontWeight="700"
      textTransform="uppercase"
      color="gray.900"
      letterSpacing="1px"
      {...props}
    >
      {isEditable ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex w="100%" alignItems="center" position="relative">
            {(isVisible || hasFocus) && (
              <WriteOrEditIcon position="absolute" right="100%" height="4" width="4" mr="2" />
            )}
            <Input
              {...register?.('title' as any)}
              onFocus={handleOnFocus}
              onBlur={handleSubmit(onSubmit)}
              defaultValue={title}
              variant="unstyled"
              width="100%"
              border="1px solid"
              borderColor={hasFocus ? 'blue.500' : 'transparent'}
              cursor={hasFocus ? 'curor' : 'pointer'}
              p="1 2 1 2"
              mr="2"
              fontSize="inherit"
              fontWeight="inherit"
              textTransform="inherit"
              color="inherit"
              letterSpacing="inherit"
            />
          </Flex>
        </form>
      ) : (
        <span>{title}</span>
      )}
    </Box>
  );
}
