import { Icon, IconProps } from '@chakra-ui/react';

export function MagnifyingGlassIcon({ height = '4', width = '4', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        d="M6.16667 0C9.57242 0 12.3333 2.76107 12.3333 6.16701C12.3333 7.53899 11.8853 8.80632 11.1277 9.8307L15.7315 14.4351C16.0895 14.7931 16.0895 15.3735 15.7315 15.7315C15.3735 16.0895 14.7931 16.0895 14.4352 15.7315L9.83147 11.1273C8.80692 11.8856 7.53915 12.334 6.16667 12.334C2.76091 12.334 0 9.57296 0 6.16701C0 2.76107 2.76091 0 6.16667 0ZM6.16667 1.83344C3.77343 1.83344 1.83333 3.77364 1.83333 6.16701C1.83333 8.56038 3.77343 10.5006 6.16667 10.5006C7.04152 10.5006 7.87318 10.2425 8.57998 9.76713L8.74083 9.65359L9.26467 9.26518L9.65374 8.74039C10.1619 8.05341 10.456 7.23544 10.4954 6.36802L10.5 6.16701C10.5 3.77364 8.5599 1.83344 6.16667 1.83344Z"
        fill="#353D41"
      />
    </Icon>
  );
}

export default MagnifyingGlassIcon;
