import { Button, Text, Flex, VisuallyHidden } from '@chakra-ui/react';
import { PlusInCircleIcon } from '@sermonary/icons';
import { useState } from 'react';

interface SermonBlockInlineAddProps {
  isDragging: boolean;
  onAdd: () => void;
  noBlocks?: boolean;
}

export function SermonBlockInlineAdd({ isDragging, onAdd, noBlocks }: SermonBlockInlineAddProps) {
  const [isHover, setIsHover] = useState(false);

  return (
    <div>
      {noBlocks ? (
        <Button
          variant="shadow"
          onClick={onAdd}
          outline="none"
          width="100%"
          height="auto"
          border="2px dashed"
          borderColor="gray.300"
          backgroundColor="white"
          transition="background-color 0.2s ease-in-out"
          p="10"
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            backgroundColor: 'gray.200',
          }}
        >
          <Flex alignItems="center">
            <PlusInCircleIcon color="blue.500" />
            <Text pl="2" color="gray.900" fontWeight="normal">
              Add block
            </Text>
          </Flex>
        </Button>
      ) : (
        <Button
          variant="shadow"
          onClick={onAdd}
          outline="none"
          opacity={isDragging ? 0 : 1}
          position="relative"
          transition="opacity 0.2s ease-in-out"
          width="100%"
          _focus={{
            boxShadow: 'none',
          }}
          _after={{
            borderTop: '2px dashed',
            borderColor: isHover ? 'gray.400' : 'gray.300',
            content: '""',
            width: '100%',
            transition: 'border-color 0.2s ease-in-out',
          }}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <PlusInCircleIcon
            backgroundColor="gray.100"
            color={isHover ? 'gray.400' : 'gray.300'}
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
            transition="color 0.2s ease-in-out"
          />
          <VisuallyHidden>Add block</VisuallyHidden>
        </Button>
      )}
    </div>
  );
}
