// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SermonType = {
  "SERMON": "SERMON",
  "DEFAULTSERMON": "DEFAULTSERMON",
  "RESOURCESERMON": "RESOURCESERMON",
  "TEMPLATE": "TEMPLATE",
  "SYSTEMTEMPLATE": "SYSTEMTEMPLATE"
};

const { Account, SermonSeries, Sermon, SermonBackup, Idea, MPFederationToken, SubscriptionInfo, SermonPodiumDataResponse, ExportToDocxResponse, GetRegistrationCheckoutResponse, HandleCheckoutResponse, HandleUpsellResponse, AlgoliaReIndexResponse } = initSchema(schema);

export {
  Account,
  SermonSeries,
  Sermon,
  SermonBackup,
  Idea,
  SermonType,
  MPFederationToken,
  SubscriptionInfo,
  SermonPodiumDataResponse,
  ExportToDocxResponse,
  GetRegistrationCheckoutResponse,
  HandleCheckoutResponse,
  HandleUpsellResponse,
  AlgoliaReIndexResponse
};