import { UpgradeModal } from './UpgradeModal';
import { PodiumSettings } from './PodiumSettingsModal';
import { WelcomeModal } from './WelcomeModal';
import { CanceledUserSermons } from './CanceledUserSermons';
import { ExpiredSessionModal } from './ExpiredSessionModal';

export function ModalProvider() {
  return (
    <>
      <UpgradeModal />
      <PodiumSettings />
      <WelcomeModal />
      <CanceledUserSermons />
      <ExpiredSessionModal />
    </>
  );
}
