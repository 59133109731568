/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type TokenProvider = () => Promise<string>;

export interface MPFederationClientConfig {
  apiUrl: string;
  tokenProvider: TokenProvider;
}

interface Token {
  jwt: string;
  claims: {
    exp: number;
  };
}

export class MPFederationClient {
  config: MPFederationClientConfig;

  invalidateToken = false;

  token: Token | undefined;

  constructor(config: MPFederationClientConfig) {
    this.config = config;
  }

  private async fetchToken() {
    try {
      const jwt = await this.config.tokenProvider();
      const claims = this.extractClaims(jwt);
      this.token = { jwt, claims };
      this.invalidateToken = false;
    } catch (e) {
      console.log('Error fetching token');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private extractClaims(jwt: string) {
    const encodedClaims = jwt.split('.')[1];
    return JSON.parse(atob(encodedClaims));
  }

  private tokenIsExpired() {
    const expiration = 1000 * (this.token?.claims?.exp ?? 0);
    return Date.now() - expiration >= 0;
  }

  private shouldFetchToken() {
    return this.invalidateToken || !this.token || this.tokenIsExpired();
  }

  invalidateCredentials = () => {
    this.invalidateToken = true;
  };

  request = async (config: AxiosRequestConfig): Promise<AxiosResponse> => {
    if (this.shouldFetchToken()) {
      await this.fetchToken();
    }

    config.url = config.url || this.config.apiUrl;
    config.method = config.method || 'post';
    config.headers = config.headers || {};

    Object.assign(config.headers, {
      Authorization: `Bearer ${this?.token?.jwt}`,
    });

    return axios(config);
  };
}
