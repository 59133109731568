import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { atom, useAtom } from 'jotai';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { Box, Button, Heading as CHeading, Flex, Text, useToast, Grid, Spinner } from '@chakra-ui/react';
import { Container, Header, Heading, SermonCardGrid, BaseResourceItem } from '@sermonary/components';
import { useMutation, useDeleteSermonDialog } from '@sermonary/hooks';
import { print } from 'graphql';
import { track } from '../analytics/Analytics';
import { Sermon, SermonSeries } from '../models';
import {
  useCreateSermonDrawer,
  useCustomTemplateSelectionDrawer,
  useFavoriteTemplateSelectionDrawer,
  usePreBuiltTemplateSelectionDrawer,
  useResourceDownloadModal,
} from '../hooks';

import { useAppContext } from '../contexts/AppContext';
import { ResourceQuery } from '../graphql/mpfederation';
import { CustomSearchBox } from './SearchResults';
import { getAccountFlag, setAccountFlag } from '../utilities/accountFlagger';
import { shouldCheckForReIndexAtom, userAccountAtom } from 'src/App';
import { useAlgoliaUserReIndex } from 'src/hooks/useAlgoliaUserReIndex';

export const sermonsAtom = atom<Sermon[]>([]);
export const sermonSeriesAtom = atom<SermonSeries[]>([]);
export const sermonGroupsAtom = atom(
  (get) =>
    get(sermonsAtom)
      ?.filter((s) => !s?.isArchived)
      ?.reduce(
        (acc, curr) => {
          const key = curr?.seriesId ?? 'individual';
          acc[key] = acc?.[key] || [];
          acc[key].push(curr);

          return acc;
        },
        {} as {
          [key: string]: any;
        },
      ) || {},
);
export const orderedSermonGroupsAtom = atom((get) => Object.keys(get(sermonGroupsAtom)));

export function Sermons() {
  const toast = useToast();
  const sermonToDeleteRef = useRef<Sermon | null>(null);

  const [sermons, setSermons] = useAtom(sermonsAtom);
  const [sermonSeries, setSermonSeries] = useAtom(sermonSeriesAtom);
  const [groups] = useAtom(sermonGroupsAtom);
  const [orderedSermonGroups] = useAtom(orderedSermonGroupsAtom);

  const [resource, setResource] = useState<BaseResourceItem>({} as BaseResourceItem);
  const [resourceIsLoading, setResourceIsLoading] = useState(false);
  const [account, setAccount] = useAtom(userAccountAtom);

  const { client } = useAppContext();

  const [handleReIndex, setHandleReIndex] = useState(false);
  const [shouldCheckForReIndex, setShouldCheckForReIndex] = useAtom(shouldCheckForReIndexAtom);

  const { success: algoliaIndexSuccess } = useAlgoliaUserReIndex(handleReIndex, sermons);

  useEffect(() => {
    if (algoliaIndexSuccess) {
      setHandleReIndex(false);
      setAccountFlag(account, 'userHasInitialIndex', true, setAccount);
    }
  }, [algoliaIndexSuccess]);

  async function fetchSermons() {
    const fetchedSermons = await DataStore.query(Sermon, (c) => c?.sermonType.eq('SERMON'), {
      sort: (s) => s.updatedAt(SortDirection.DESCENDING),
    });
    setSermons(fetchedSermons);
  }

  async function fetchSermonSeries() {
    const fetchedSermonSeries = await DataStore.query(SermonSeries);
    setSermonSeries(fetchedSermonSeries);
  }

  const { handler: archiveSermon } = useMutation(
    async (sermon: Sermon) => {
      try {
        await DataStore.save(
          Sermon.copyOf(sermon, (updated) => {
            updated.isArchived = true;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    },
    { deps: [groups] },
  );

  const { handler: archiveSermonSeries } = useMutation(
    async (sermonSeriesId: string) => {
      groups?.[sermonSeriesId].forEach((sermon: Sermon) => {
        archiveSermon(sermon);
      });
    },
    { deps: [groups] },
  );

  useEffect(() => {
    const userHasInitialIndex = !!getAccountFlag(account, 'userHasInitialIndex');

    if (shouldCheckForReIndex && Object.keys(account).length && !userHasInitialIndex) {
      setShouldCheckForReIndex(false);
      setHandleReIndex(true);
    }
  }, [shouldCheckForReIndex, account]);

  useEffect(() => {
    fetchSermons();
    DataStore.observe(Sermon).subscribe(fetchSermons);

    fetchSermonSeries();
    DataStore.observe(SermonSeries).subscribe(fetchSermonSeries);

    //TODO: missing cleanup function for subscriptions
  }, []);

  const { CreateSermonDrawer, handleOnOpen } = useCreateSermonDrawer();
  const { CustomTemplateSelectionDrawer } = useCustomTemplateSelectionDrawer();
  const { FavoriteTemplateSelectionDrawer } = useFavoriteTemplateSelectionDrawer();
  const { PreBuiltTemplateSelectionDrawer } = usePreBuiltTemplateSelectionDrawer();

  const { handler: duplicateSermon } = useMutation(
    async (sermon: Sermon) => {
      function stripTitle(title: string) {
        return title?.replace(/\(([[0-9])\)/, '').trim();
      }

      const duplicates = sermons?.filter((x) => stripTitle(x?.title) === stripTitle(sermon?.title));

      try {
        await DataStore.save(
          new Sermon({
            bigIdea: sermon?.bigIdea,
            blocks: sermon?.blocks,
            sermonType: sermon?.sermonType,
            wordCount: sermon?.wordCount,
            title: `${stripTitle(sermon?.title)} (${duplicates?.length ? Number(duplicates?.length) + 1 : 1})`,
            ...(sermon?.seriesId && { seriesId: sermon?.seriesId }),
          }),
        );

        await track('Duplicate Sermon');
      } catch (err) {
        console.log(err);
      }
    },
    { deps: [sermons] },
  );

  const {
    isError,
    isLoading,
    error,
    handler: deleteSermon,
  } = useMutation(async (sermon: Sermon) => {
    try {
      await DataStore.delete(Sermon, sermon?.id);
    } catch (err) {
      console.log(err);
    }
  });

  const { handleOpenDialog, DeleteSermonDialog } = useDeleteSermonDialog({
    isError,
    isLoading,
    error,
    onDelete: async () => deleteSermon(sermonToDeleteRef?.current),
    onSuccess: () => {
      toast({
        title: 'Sermon deleted.',
        status: 'success',
        isClosable: true,
      });
      sermonToDeleteRef.current = null;
    },
  });

  const handleOnDelete = async (sermon: Sermon) => {
    sermonToDeleteRef.current = sermon;
    handleOpenDialog();
  };

  const handleHeadingOnBlur = async (sermonSeriesKey: string, newTitle: string) => {
    try {
      const sermonSeriesModel = await DataStore.query(SermonSeries, sermonSeriesKey);

      await DataStore.save(
        SermonSeries.copyOf(sermonSeriesModel as SermonSeries, (updated) => {
          updated.title = newTitle;
        }),
      );

      toast({
        title: `Series Title '${newTitle}' Saved!`,
        status: 'success',
        isClosable: true,
      });
    } catch (err) {
      throw new Error(err as any);
    }
  };

  const { handleOnOpen: handleResourceOnOpen, ResourceDownloadModal } = useResourceDownloadModal({
    resource,
  });

  const handleDownloadMedia = async (resourceId: string) => {
    try {
      setResourceIsLoading(true);
      const resourceReq = async () => {
        const response = await client?.request({
          data: {
            query: print(ResourceQuery),
            variables: {
              id: resourceId,
            },
          },
        });

        return response?.data?.data?.resource ?? [];
      };

      const data = await resourceReq();

      setResource(data);
      setResourceIsLoading(false);
      handleResourceOnOpen();
    } catch (err) {
      throw new Error(err as any);
    }
  };

  const handleDownloadSeriesMedia = async (key: string) => {
    handleDownloadMedia(groups?.[key]?.[0]?.resourceId);
    await track('Download Series Media from Sermons');
  };

  const handleArchiveSermonSeries = async (key: string) => {
    archiveSermonSeries?.(key);
    await track('Archive Series');
  };

  return (
    <>
      <Header title="Sermons">
        <CHeading as="h1">Sermons</CHeading>
        <>
          <CustomSearchBox mr={6} />
          <Button colorScheme="blue" onClick={handleOnOpen}>
            Write New
          </Button>
        </>
      </Header>
      <Container>
        <Grid gap={12}>
          {orderedSermonGroups.map((key: string, index: number) => (
            <Box key={`sermonSeries+${index + 1}`}>
              <Flex justifyContent="space-between">
                {key === 'individual' ? (
                  <Heading title="Individual Sermons" />
                ) : (
                  <Text as="span" fontSize="sm" fontWeight="semibold" textTransform="uppercase" flex="1">
                    <Heading
                      title={sermonSeries?.find((series) => series?.id === key)?.title as string}
                      isEditable
                      modelId={key}
                      onHeadingBlur={handleHeadingOnBlur}
                    />
                  </Text>
                )}
              </Flex>
              <SermonCardGrid
                sermons={groups?.[key]?.slice(0, 4)}
                onDuplicateSermon={duplicateSermon}
                onDeleteSermon={handleOnDelete}
                onArchiveSermon={archiveSermon}
              />
              {key === 'individual' ? (
                <Flex justifyContent="end" mt="4">
                  <Button fontSize="sm" as={RouterLink} to="/sermons/all">
                    View All Individual Sermons
                  </Button>
                </Flex>
              ) : (
                <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']} flexWrap="wrap" mt="4">
                  <Button
                    px="2"
                    color="blue.500"
                    variant="shadow"
                    fontSize="sm"
                    as={RouterLink}
                    to={`/sermon-series/${key}`}
                  >
                    View All Sermons
                  </Button>
                  <Flex alignItems="center" color="gray.600">
                    /
                  </Flex>
                  {groups?.[key]?.[0]?.resourceId && (
                    <>
                      <Button
                        px="2"
                        color="blue.500"
                        variant="shadow"
                        fontSize="sm"
                        onClick={() => handleDownloadSeriesMedia(key)}
                      >
                        Download Series Media
                        {resourceIsLoading && (
                          <Flex alignItems="center" justifyContent="center">
                            <Spinner size="lg" />
                          </Flex>
                        )}
                      </Button>
                      <Flex alignItems="center" color="gray.600">
                        /
                      </Flex>
                    </>
                  )}
                  <Button
                    px="2"
                    color="blue.500"
                    variant="shadow"
                    fontSize="sm"
                    onClick={() => handleArchiveSermonSeries(key)}
                  >
                    Archive
                  </Button>
                </Flex>
              )}
            </Box>
          ))}
        </Grid>
      </Container>
      <CreateSermonDrawer />
      <CustomTemplateSelectionDrawer />
      <FavoriteTemplateSelectionDrawer />
      <PreBuiltTemplateSelectionDrawer />
      <DeleteSermonDialog />
      <ResourceDownloadModal />
    </>
  );
}

export default Sermons;
