import { Icon, IconProps } from '@chakra-ui/react';

export function TemplatesIcon({ height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fill="currentColor"
        d="M10.5 10.75a2.75 2.75 0 012.75 2.75v7.75A2.75 2.75 0 0110.5 24H2.75A2.75 2.75 0 010 21.25V13.5a2.75 2.75 0 012.75-2.75h7.75zm10.75 0A2.75 2.75 0 0124 13.5v7.75A2.75 2.75 0 0121.25 24h-2.5A2.75 2.75 0 0116 21.25V13.5a2.75 2.75 0 012.75-2.75h2.5zM9.5 13.5H3.75a1 1 0 00-1 1v5.75a1 1 0 001 1H9.5a1 1 0 001-1V14.5a1 1 0 00-1-1zm10.75 0h-.5a1 1 0 00-1 1v5.75a1 1 0 001 1h.5a1 1 0 001-1V14.5a1 1 0 00-1-1zm1-13.5A2.75 2.75 0 0124 2.75v2.5A2.75 2.75 0 0121.25 8H2.75A2.75 2.75 0 010 5.25v-2.5A2.75 2.75 0 012.75 0h18.5zm-1 2.75H3.75a1 1 0 00-1 1v.5a1 1 0 001 1h16.5a1 1 0 001-1v-.5a1 1 0 00-1-1z"
      />
    </Icon>
  );
}

export default TemplatesIcon;
