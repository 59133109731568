import { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataStore } from 'aws-amplify';
import { atom, useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { track } from '../../analytics/Analytics';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Flex,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react';
import { Header } from '@sermonary/components';
import { Block } from '@sermonary/types';
import { SettingsIcon } from '@sermonary/icons';
import { Sermon, SermonSeries } from '../../models';
import { SermonActions } from '../../components/sermons/SermonActions';
import { blocksAtom, SermonBlockEditor, transformBlocksToArray } from '../../components/sermons/SermonBlockEditor';
import { SermonSettings } from '../../components/sermons/SermonSettings';
import { useSermonTemplateDialog } from '../../hooks';
import { SermonForm } from './SermonForm';
import { sermonSeriesAtom } from '../Sermons';
import Error404 from '../Error404';
import { Loading } from '../Loading';
import { backupSermon } from '../../utilities/backupSermon';
import { useMutation } from '@sermonary/hooks';

export const isSermonBlockDrawerOpenAtom = atom(false);
export const isSermonSettingsDrawerOpenAtom = atom(false);
export const sermonSavedAtom = atom(false);
export const sermonSavedIdAtom = atom('');
export const hasNewUpdateAtom = atom(false);
export const sermonAtom = atom<Sermon>({} as Sermon);

const sermonVersionAtom = atom<number>(0);
const sermonSaveTrackedAtom = atom(false);

const SingleSermon = memo(
  forwardRef<HTMLButtonElement>((props, ref) => {
    const { id } = useParams<{ id: string }>();

    const setTimeoutRef = useRef<NodeJS.Timeout>();

    const [sermonId, setSermonId] = useState('');
    const [show404, setShow404] = useState(false);

    const [sermon, setSermon] = useAtom(sermonAtom);
    const [, setHasNewUpdate] = useAtom(hasNewUpdateAtom);
    const [sermonSeries, setSermonSeries] = useAtom(sermonSeriesAtom);
    const [sermonSaved, setSermonSaved] = useAtom(sermonSavedAtom);
    const [sermonSavedId, setSermonSavedId] = useAtom(sermonSavedIdAtom);
    const [sermonVersion, setSermonVersion] = useAtom(sermonVersionAtom);
    const [sermonSaveTracked, setSermonSaveTracked] = useAtom(sermonSaveTrackedAtom);

    const setIsSermonBlockDrawerOpen = useUpdateAtom(isSermonBlockDrawerOpenAtom);
    const setIsSettingsDrawerOpen = useUpdateAtom(isSermonSettingsDrawerOpenAtom);
    const updateBlocks = useUpdateAtom(blocksAtom);

    const { handler: restoreArchivedSermon } = useMutation(async (sermon: Sermon) => {
      try {
        await DataStore.save(
          Sermon.copyOf(sermon, (updated) => {
            updated.isArchived = false;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    });

    const handleTrackSermonSaved = async () => {
      try {
        await DataStore.save(
          Sermon.copyOf(sermon, (updated) => {
            updated.sermonSaveTracked = true;
          }),
        );
        setSermonSaveTracked(true);
        await track('Sermon Saved', { sermonId: sermon.id });
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
      sermonId === sermonSavedId && setSermonSaved(true);
    }, [sermonId, sermonSavedId]);

    useEffect(() => {
      if (id) {
        setSermonId(id);
      }
    }, [id, setSermonId]);

    useEffect(() => {
      async function fetchSermon() {
        const dsSermon = await DataStore.query(Sermon, sermonId);
        dsSermon === undefined && setShow404(true);

        dsSermon &&
          backupSermon({
            sermon: dsSermon,
            source: 'Backup on Sermon Load',
          });

        setSermonSaved(false);
        setSermon(dsSermon || ({} as Sermon));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        setSermonVersion(dsSermon?._version === undefined ? 0 : dsSermon?._version);
        setSermonSaveTracked(dsSermon?.sermonSaveTracked || false);
        updateBlocks(transformBlocksToArray(dsSermon?.blocks || ([] as Block[])));
      }
      if (sermonId) {
        fetchSermon();
      }
    }, [sermonId, setSermon, setSermonSaved, setSermonVersion, setIsSermonBlockDrawerOpen, updateBlocks]);

    useEffect(() => {
      async function fetchSermonSeries() {
        const series = await DataStore.query(SermonSeries);

        setSermonSeries(series || []);
      }

      if (sermonSeries?.length === 0) {
        fetchSermonSeries();
      }

      const backupSermonInterval = setInterval(
        () => Object.keys(sermon).length && backupSermon({ sermon, source: 'Sermon Backup Interval (5min)' }),
        1000 * 60 * 5,
      );

      return () => {
        clearInterval(backupSermonInterval);
      };
    }, [sermon]);

    useEffect(() => {
      const subscription = DataStore.observe(Sermon, sermonId).subscribe((msg) => {
        if (msg?.element?.updatedAt) {
          setHasNewUpdate(true);
          setSermon(msg?.element);
        }
      });

      return () => subscription.unsubscribe();
    }, [sermonId, setSermon]);

    useEffect(() => {
      if (sermonSaved) {
        setSermonVersion(sermonVersion + 1);
        if (sermonVersion > 50 && !sermonSaveTracked) {
          handleTrackSermonSaved();
        }
        setTimeoutRef.current = setTimeout(() => {
          setSermonSaved(false);
          setSermonSavedId('');
        }, 5000);
      }
    }, [sermonSaved, setSermonSaved]);

    if (show404) {
      return <Error404 />;
    }

    return sermonId === sermon?.id ? (
      <Box pb="150">
        <Header data-testid="Sermon Title" title={sermon?.title} isFixed>
          <Box width={'100%'}>
            <Flex>
              <Heading
                isTruncated
                textOverflow="inherit"
                overflowX="auto"
                fontSize={['20px', '20px', '30px']}
                css={{
                  /* Chrome, Safari, Edge */
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  /* FireFox */
                  scrollbarWidth: 'none',
                  /* IE10+*/
                  MsOverflowStyle: '-ms-autohiding-scrollbar',
                }}
              >
                {sermon?.title}
              </Heading>
              {sermon?.isArchived && (
                <Text
                  ml={4}
                  px={1}
                  bgColor={'blue.100'}
                  borderRadius={'5px'}
                  fontWeight={'semibold'}
                  color={'blue.500'}
                  alignSelf={'center'}
                >
                  Archived
                </Text>
              )}
            </Flex>
            {sermonSeries ? (
              <Text fontSize="md" color="gray.700">
                {sermonSeries?.find((series) => series?.id === sermon?.seriesId)?.title}
              </Text>
            ) : null}
          </Box>
          <Flex alignItems="center" color="gray.500">
            <Text color="gray.500" px="4" visibility={sermonSaved ? 'visible' : 'hidden'}>
              Saved!
            </Text>
            <SermonActions
              ref={ref}
              sermon={sermon}
              handleOnAddBlock={() => setIsSermonBlockDrawerOpen(true)}
              handleOnOpenSettings={() => setIsSettingsDrawerOpen(true)}
              handleOnRestoreArchive={() => restoreArchivedSermon(sermon)}
            />
          </Flex>
        </Header>
        <Text fontSize="xl" mb="6">
          {sermon?.bigIdea}
        </Text>
        <Text as="i" color="gray.700" fontSize="md">
          {sermon?.referenceVerses}
        </Text>
        <SermonBlockEditor />
      </Box>
    ) : (
      <Loading />
    );
  }),
);

function SingleSermonWithDrawers() {
  const btnRef = useRef<HTMLButtonElement>(null);
  const settingsBtnRef = useRef<HTMLButtonElement>(null);

  const [sermon] = useAtom(sermonAtom);

  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);

  const { handleOnOpen: handleOnSaveTemplateDialogOpen, SermonTemplateDialog } = useSermonTemplateDialog({
    sermon,
  });

  return (
    <>
      <SingleSermon ref={btnRef} />

      <Drawer
        isOpen={isSettingsDrawerOpen}
        placement="right"
        onClose={() => setIsSettingsDrawerOpen(false)}
        finalFocusRef={settingsBtnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="gray.900">
            <SettingsIcon mr="4" />
            Sermon Settings
            <Divider border="none" height="2px" background="gray.300" marginTop="4" />
          </DrawerHeader>

          <DrawerBody pt="none">
            <SermonForm />
            <Divider border="none" height="2px" background="gray.300" my="4" />
            <SermonSettings onSaveAsTemplate={handleOnSaveTemplateDialogOpen} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <SermonTemplateDialog />
    </>
  );
}

export default SingleSermonWithDrawers;
