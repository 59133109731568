import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const babyDedicationScript = {
  id: 'babyDedicationScript',
  title: 'Baby Dedication Script',
  bigIdea:
    'During this short ceremony, children are dedicated to God. Both the congregation and the parents commit to doing their part to instruct the children in the ways of the Lord.',
  sermonType: SermonType.SERMON,
  description:
    'During this short ceremony, children are dedicated to God. Both the congregation and the parents commit to doing their part to instruct the children in the ways of the Lord.',
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      color: '#fcb900',
      wordCount: 135,
      type: 'custom',
      title: 'Introduction: Opening the dedication service',
      content:
        '<p id="isPasted">Today, we have set aside part of this service to dedicate children in our congregation to God. While many Christian denominations celebrate and act out this ritual in a number of different ways, we can all agree that God loves children and he desires for them to be raised to love him and love others.&nbsp;</p><p>Today, we are going to do two things:&nbsp;</p><ol><li>We are going to ask that God would give these children(s) wisdom, understanding, and knowledge. We are going to ask that God would protect these children and lead them to know and love him.</li><li>We are also going to ask that God would give their parents (as well as all of us) wisdom, understanding, and knowledge. May we create an environment where these children can learn about God and serve him with their entire beings.</li></ol>',
    },
    '1': {
      id: uuid(),
      color: '#000',
      wordCount: 15,
      type: 'custom',
      title: 'Names of Children and Parents',
      content:
        '<p>Today, we are dedicating: </p><ul><li>[LIST NAMES OF CHILDREN. FOLLOW WITH THE NAMES OF THEIR PARENTS].</li></ul>',
    },
    '2': {
      chapter: '6',
      wordCount: 80,
      book: 'DEU',
      translation: 'ESV',
      inputType: 'select',
      id: uuid(),
      type: 'bible',
      verses: '4-7',
      content: {
        v6: ['And these words that I command you today shall be on your heart.'],
        v7: [
          'You shall teach them diligently to your children, and shall talk of them when you sit in your house, and when you walk by the way, and when you lie down, and when you rise.',
        ],
        v4: [
          'Hear, O Israel: The <span class="divine-name">Lord</span> our God, the <span class="divine-name">Lord</span> is one.',
        ],
        v5: [
          'You shall love the <span class="divine-name">Lord</span> your God with all your heart and with all your soul and with all your might.',
        ],
      },
    },
    '3': {
      id: uuid(),
      wordCount: 224,
      type: 'application',
      content:
        '<p id="isPasted">The application of this text is clear: parents must raise their children in the ways of the Lord.</p><p>[ADDRESS PARENTS DIRECTLY]: Parents, while the world is busy, and our schedules are packed full, we must not forget to talk to our children about the things of God. We must be wiling to answer their difficult questions. We must read the Bible to them. We must ask them about what they learn in church. Like Jesus, we must even use everyday items—such as seeds, stories, and parables—to help our children better understand God. But, just as much, we must also model as an example what it means to follow Jesus. We must live out what we hope to see in the lives of our children.</p><p>[ADDRESS CONGREGATION DIRECTLY]: Church, we too have a responsibility to these children. We must create a safe place for them to learn about God. We must be willing to answer their difficult questions. We must offer grace, love, and forgiveness. We must also support [PARENTS NAMES]. As a church, we should ensure that these children never go without the basic necessities of life. We should offer babysitting, protection, and gentleness. For those who have already raised children, you must be the listening ear you wished you had when you first had children. Everyone has a role; everyone has a part to play.</p>',
    },
    '4': {
      id: uuid(),
      color: '#9900ef',
      wordCount: 15,
      type: 'custom',
      title: 'Reading of Letters',
      content: '<p><em>At this time, a letter should be publicly read to each child from their parents.</em></p>',
    },
    '5': {
      id: uuid(),
      color: '#f78da7',
      wordCount: 139,
      type: 'custom',
      title: 'Parent Commitments',
      content:
        '<p id="isPasted"><em>For each set of parents, ask them to publicly pledge to raise their child in the ways of God.</em></p><p>[NAMES OF PARENTS], do you publicly promise, before God and your local church, to raise [NAME OF CHILD] in the ways of the Lord? To teach them the gospel, to help them to understand the Bible, and to share with them the love of Jesus? If so, say “We do.”</p><p id="isPasted"><span style=" color: rgb(22, 142, 234);"><strong><em>Parents: We do.</em></strong></span></p><p>Do you also commit to fulfilling your responsibilities as a parent, doing your best to protect, instruct, and (when needed) to discipline them for the sake of their personal development?</p><p><span style=" color: rgb(22, 142, 234);"><strong><em>Parents: We do.</em></strong></span></p><p>And, just as important, do you promise to model Christ to them, sacrificing for them, as well as offering grace and forgiveness? And, when the time comes, apologizing</p><p>when you act selfishly, in sinful anger, or in pride?</p><p><span style=" color: rgb(22, 142, 234);"><strong><em>Parents: We do.</em></strong></span></p>',
    },
    '6': {
      id: uuid(),
      color: '#f78da7',
      wordCount: 48,
      type: 'custom',
      title: 'Congregation Commitments',
      content:
        '<p id="isPasted"><em>Next, address your congregation:</em></p><p>Congregation, do you promise to offer love, grace, support, prayers, and service to these parents? Do you also promise to pray for these children and to help teach them the ways of the Lord, as best you can? If so, say “We do.”</p><p><span style=" color: rgb(22, 142, 234);"><strong><em>Congregation: We do.</em></strong></span></p>',
    },
    '7': {
      id: uuid(),
      color: '#0693e3',
      wordCount: 52,
      type: 'custom',
      title: 'Dedication Prayer',
      content:
        '<p id="isPasted"><em>Offer a prayer of dedication and blessing for the children. One by one, conclude by saying:</em></p><p>[CHILD’S NAME], may the Lord bless you and keep you; may the Lord make his face to shine upon you and be gracious to you; may the Lord turn his face toward you and give you peace.</p>',
    },
    '8': {
      id: uuid(),
      color: '#00d084',
      wordCount: 13,
      type: 'custom',
      title: 'Offer Gift',
      content: '<p>After the prayer, offer parents a certificate of dedication and a small gift.</p>',
    },
  }),
};
