export function chunkWordsToArray(string: string, chunkSize: number): string[] {
  const words = string.split(' ');
  const res = [];
  for (let i = 0; i < words.length; i += chunkSize) {
    const chunk = words.slice(i, i + chunkSize).join(' ');
    res.push(chunk);
  }

  return res;
}
