import { Icon, IconProps } from '@chakra-ui/react';

export function CheckMarkIcon({ width = '4', height = '4', color = '#168EEA', ...props }: IconProps) {
  return (
    <Icon height={height} width={width} color={color} viewBox="0 0 16 16" {...props}>
      <path
        d="M15.2437 2.26849C15.5794 2.62647 15.5794 3.20688 15.2437 3.56486L6.28073 13.1229C5.94503 13.4809 5.40076 13.4809 5.06506 13.1229L5.05414 13.1101L0.745936 8.51615C0.410239 8.15816 0.410239 7.57776 0.745936 7.21977C1.08163 6.86179 1.62591 6.86179 1.9616 7.21977L5.67306 11.1774L14.0281 2.26849C14.3638 1.9105 14.908 1.9105 15.2437 2.26849Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default CheckMarkIcon;
