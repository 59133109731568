import { useForm } from 'react-hook-form';
import { Alert, AlertIcon, AlertDescription, Button, Flex, Grid, Text, Collapse } from '@chakra-ui/react';
import { AuthFormInput } from '@sermonary/components';
import { AuthError } from '@sermonary/types';
import { Link as RouterLink } from 'react-router-dom';
import { vestResolver } from '@hookform/resolvers/vest';
import { test, enforce, create } from 'vest';
import { LockIcon } from '@sermonary/icons';
import { useEffect, useState } from 'react';
import { useURLParams } from '../../../hooks';
import {
  containsNumberRegex,
  containsSpecialCharRegex,
  containsUpperCaseRegex,
  PasswordValidation,
  PasswordValidationValues,
} from './RegisterForm';

export interface ResetPasswordConfirmFormValues {
  code: string;
  password: string;
}

interface ResetPasswordConfirmFormProps {
  isError: boolean;
  isLoading: boolean;
  error?: AuthError;
  formTitle: string;
  ctaText: string;
  onSubmit: (values: ResetPasswordConfirmFormValues) => void;
}

const validationSuite = create((data: Partial<ResetPasswordConfirmFormValues> = {}) => {
  test('code', 'Code is required', () => {
    enforce(data.code).isNotEmpty();
  });
});

export function ResetPasswordConfirmForm({
  isError,
  isLoading,
  error,
  formTitle,
  ctaText,
  onSubmit,
}: ResetPasswordConfirmFormProps) {
  const urlParams = useURLParams();
  const codeParam = urlParams.get('code');

  const { register, handleSubmit, formState, watch } = useForm<ResetPasswordConfirmFormValues>({
    defaultValues: { code: codeParam || '' },
    resolver: vestResolver(validationSuite),
  });

  const { errors } = formState;

  const watchPassword = watch('password');

  const [passwordValidation, setPasswordValidation] = useState<PasswordValidationValues>({
    minChars: false,
    upperCase: false,
    specialChars: false,
    number: false,
  });

  const [passwordHasFocus, setPasswordHasFocus] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState('');

  const checkPasswordValidation = () => Object.values(passwordValidation).every((value) => value === true);
  const handlePasswordOnFocus = () => setPasswordHasFocus(true);

  useEffect(() => {
    const data = localStorage.getItem('passwordResetEmailValue');

    if (data) {
      setPasswordResetEmail(data);
    }
  }, []);

  useEffect(() => {
    setPasswordValidation({
      minChars: watchPassword?.length > 7,
      upperCase: containsUpperCaseRegex.test(watchPassword),
      specialChars: containsSpecialCharRegex.test(watchPassword),
      number: containsNumberRegex.test(watchPassword),
    });
  }, [watchPassword]);

  return (
    <Grid as="form" onSubmit={handleSubmit(onSubmit)} mt="8" gap="4" templateColumns="1fr" w={['100%', null, '80']}>
      {codeParam && !isError ? (
        <>
          <Text as="h3" mb="2">
            {formTitle}
          </Text>
          <Text fontSize="md" mb="2">
            Email:{' '}
            <Text as="span" display="inline" fontWeight="700">
              {passwordResetEmail}
            </Text>
          </Text>
          {errors?.code && <Text>{errors?.code?.message}</Text>}
          <AuthFormInput
            label="New Password"
            inputLeftElement={<LockIcon />}
            type="password"
            registerFieldName="password"
            register={register}
            errors={errors}
            inputId="loginPassword"
            onFocus={handlePasswordOnFocus}
          />
          <Collapse in={passwordHasFocus} animateOpacity>
            <PasswordValidation {...passwordValidation} />
          </Collapse>
          {isError ? (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{error?.message}</AlertDescription>
            </Alert>
          ) : null}
          <Flex alignItems="center">
            <Button
              isFullWidth
              type="submit"
              isLoading={isLoading}
              colorScheme="blue"
              disabled={!checkPasswordValidation()}
            >
              {ctaText}
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Text as="h3" mb="2">
            Something Went Wrong!
          </Text>
          <Text fontSize="md" mb="2">
            The password reset doesn’t seem to be working. Please use the button below to send another password reset
            email.
          </Text>
          <Flex alignItems="center">
            <Button isFullWidth as={RouterLink} to="/reset-password" colorScheme="blue">
              Resend the Password Reset Email
            </Button>
          </Flex>
        </>
      )}
    </Grid>
  );
}
