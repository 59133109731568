import { Icon, IconProps } from '@chakra-ui/react';

export function CloseEyeIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon height={height} width={width} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5953 2.34919C24.1323 1.81222 24.1323 0.941622 23.5953 0.404651C23.0584 -0.13232 22.1878 -0.13232 21.6508 0.404651L18.1519 3.90359C16.4357 2.75885 14.5521 1.99999 12 1.99999C8.7 1.99999 6.35682 3.3963 4.37236 4.99999C1.74458 7.12356 0 9.80528 0 12C0 14.0564 1.53612 16.2226 3.62598 18.289L3.69742 18.358L0.404661 21.6508C-0.13231 22.1878 -0.13231 23.0584 0.404661 23.5953C0.941632 24.1323 1.81223 24.1323 2.3492 23.5953L5.81248 20.132C7.5114 21.2564 9.62477 22 12 22C15.1596 22 17.48 20.73 19.63 19C22.2623 16.8819 24 14.2624 24 12C24 10.6353 22.5648 7.72436 20.3312 5.61329L23.5953 2.34919ZM16.1588 5.89663C14.8137 5.08864 13.576 4.74999 12 4.74999C9.82722 4.74999 8.13204 5.49742 6.10084 7.13888C4.00546 8.8322 2.75 10.8193 2.75 12C2.75 12.9836 3.71824 14.5256 5.64166 16.4138L7.76595 14.2895C7.39701 13.6086 7.1875 12.8288 7.1875 12C7.1875 9.34212 9.34213 7.18749 12 7.18749C12.8288 7.18749 13.6087 7.397 14.2895 7.76594L16.1588 5.89663ZM16.2341 9.71048L18.3842 7.5603C19.285 8.40687 20.0873 9.49515 20.6122 10.4076L20.8135 10.7747C21.0902 11.3069 21.25 11.7488 21.25 12C21.25 13.2258 20.0285 15.1496 17.906 16.8575C15.8408 18.5192 14.138 19.25 12 19.25C10.4924 19.25 9.05831 18.8496 7.80913 18.1354L9.71049 16.234C10.3913 16.603 11.1712 16.8125 12 16.8125C14.6579 16.8125 16.8125 14.6579 16.8125 12C16.8125 11.1712 16.603 10.3913 16.2341 9.71048ZM12.1148 9.94063C12.0768 9.93855 12.0385 9.93749 12 9.93749C10.8609 9.93749 9.9375 10.8609 9.9375 12C9.9375 12.0385 9.93856 12.0768 9.94064 12.1148L12.1148 9.94063ZM11.8852 14.0593L14.0594 11.8852C14.0614 11.9232 14.0625 11.9615 14.0625 12C14.0625 13.1391 13.1391 14.0625 12 14.0625C11.9615 14.0625 11.9232 14.0614 11.8852 14.0593Z"
        fill="#168EEA"
      />
    </Icon>
  );
}

export default CloseEyeIcon;
