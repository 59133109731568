import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Divider, Flex, useMediaQuery } from '@chakra-ui/react';
import { isOnlineAtom } from '.';
import { useAtom } from 'jotai';

interface HeaderProps {
  title: string;
  isFixed?: boolean;
  children: [ReactNode, ReactNode];
  renderBreadcrumbs?: (props: { title: string }) => ReactNode;
}

export function Header({
  title: titleProp,
  isFixed,
  children = [null, null],
  renderBreadcrumbs: renderBreadcrumbsProp,
}: HeaderProps) {
  function renderBreadcrumbs() {
    return renderBreadcrumbsProp?.({ title: titleProp });
  }

  const [title, actions] = children;

  const [online] = useAtom(isOnlineAtom);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Helmet>
        <title>{`${titleProp} | Sermonary`}</title>
      </Helmet>
      <Box
        bg="gray.100"
        pt={['2', '8']}
        w="full"
        top={online || isMobile ? '0' : '20px'}
        zIndex="100"
        sx={{
          position: isFixed ? 'sticky' : 'relative',
          py: renderBreadcrumbsProp ? 0 : 8,
          pt: '4',
          pb: '0',
        }}
      >
        {renderBreadcrumbs()}
        <Flex direction={['column', 'column', 'column', 'column', 'row']} justify="space-between">
          <Flex mb={['4', '4', '4', '0']} overflow="hidden">
            {title}
          </Flex>
          <Flex alignSelf="flex-end" direction="row">
            {actions}
          </Flex>
        </Flex>
        <Divider colorScheme="gray" orientation="horizontal" size="heavy" my="6" />
      </Box>
    </>
  );
}
