import { Link } from 'react-router-dom';
import { Box, Flex, Heading } from '@chakra-ui/react';

export default function Error404() {
  return (
    <Box pb={20} id="podium-container-id" paddingTop="2rem">
      <Flex justifyContent="flex-start">
        <Heading as="h1" mb={5}>
          Sorry, that page does not exist.
        </Heading>
      </Flex>
      <Flex justifyContent="flex-start">
        <Heading as="h2" fontSize="xl">
          Try going to the <Link to="/">Sermons</Link> page instead.
        </Heading>
      </Flex>
    </Box>
  );
}
