import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { Rewardful } from '../analytics/Rewardful';

const getRegistrationCheckout = /* GraphQL */ `
  query getRegistrationCheckout($input: GetRegistrationCheckoutInput!) {
    getRegistrationCheckout(input: $input) {
      checkoutUrl
    }
  }
`;

export function useGetRegistrationCheckout({
  shouldQuery = false,
  registrationCode,
}: {
  shouldQuery: boolean;
  registrationCode: string;
}) {
  const {
    data: registrationCheckoutUrl,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getRegistrationCheckout', registrationCode],
    queryFn: async () => {
      const referral = Rewardful.getInstance().referral() ? { referral: Rewardful.getInstance().referral() } : {};
      const response = await API.graphql<any>(
        graphqlOperation(getRegistrationCheckout, {
          input: {
            registrationCode,
            appBaseUrl: window.location.origin,
            returnUrl: window.location.href,
            ...referral,
          },
        }),
      );

      return response.data.getRegistrationCheckout.checkoutUrl;
    },
    enabled: shouldQuery,
  });

  return {
    isLoading,
    isError,
    registrationCheckoutUrl,
  };
}
