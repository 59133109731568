import { useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { Block } from '@sermonary/types';
import { useAutoSave } from '@sermonary/hooks';
import { useMemo } from 'react';
import AlertDialogPrompt from '../forms/AlertDialogPrompt';
import { QuoteBlockForm, QuoteBlockFormValues } from '../forms';

import { useUpdateBlockForm } from '../../hooks/useUpdateBlockForm';

export function QuoteBlock({
  isActive,
  block,
  onSave,
  onDelete,
  onClose,
}: {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: QuoteBlockFormValues) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: QuoteBlockFormValues) => void;
}) {
  const { control, handleSubmit, reset, ...methods } = useForm<QuoteBlockFormValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        content: block?.content,
        sourceName: block?.sourceName,
        sourceTitle: block?.sourceTitle,
      }),
      [block],
    ),
  });

  function onSubmit(values: QuoteBlockFormValues) {
    if (methods.formState.isDirty) {
      onSave(block?.id, values);
    }
    onClose(block?.id, values);
  }

  useAutoSave<QuoteBlockFormValues>({
    control,
    isDirty: methods.formState.isDirty,
    onSave: async (values: QuoteBlockFormValues) => {
      onSave(block?.id, values);
      reset(values);
    },
  });

  useUpdateBlockForm<QuoteBlockFormValues>({
    block,
    handleReset: () => {
      reset({
        content: block?.content,
        sourceName: block?.sourceName,
        sourceTitle: block?.sourceTitle,
      });
    },
  });

  return isActive ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDialogPrompt isDirty={methods.formState.isDirty} />
      <QuoteBlockForm control={control} {...methods}>
        <Flex justifyContent="flex-end">
          <Button type="button" mr="2" onClick={() => onDelete(block)}>
            Delete
          </Button>
          <Button type="submit" colorScheme="blue">
            Done
          </Button>
        </Flex>
      </QuoteBlockForm>
    </form>
  ) : null;
}
