import { ReactNode, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { Box, Button, FormLabel, Grid, VisuallyHidden } from '@chakra-ui/react';
import { TwitterPicker } from 'react-color';
import { Input, TextEditor } from '@sermonary/components';

export function CustomBlockForm({
  children,
  register,
  control,
  formState,
  setValue,
}: Pick<UseFormReturn, 'register' | 'formState' | 'control' | 'setValue'> & {
  children?: ReactNode;
}) {
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const { errors } = formState;

  const color = useWatch({
    control,
    name: 'color',
  });

  const handleOnChangeComplete = (nColor: any) => {
    setValue('color', nColor?.hex, { shouldDirty: true });
  };

  return (
    <Grid gridTemplateColumns="100%" gap="4">
      <Input {...register('title')} label="Title" error={errors?.title?.message} />
      <TextEditor label="Content" name="content" control={control} />
      <Box>
        <Box
          onClick={(e) => {
            setIsColorPickerVisible(false);
          }}
        >
          <FormLabel fontSize="sm" fontWeight="semibold">
            Color
          </FormLabel>
          <Button
            type="button"
            variant="shadow"
            onClick={(e) => {
              e.stopPropagation();
              setIsColorPickerVisible(!isColorPickerVisible);
            }}
            sx={{
              background: 'white',
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: 'sm',
              height: 'auto',
              p: 1,
              px: 0.5,
            }}
          >
            <Box h="4" w="8" background={color} borderRadius="sm" />
            <VisuallyHidden>Pick color</VisuallyHidden>
          </Button>
        </Box>
        {isColorPickerVisible ? <TwitterPicker color={color} onChangeComplete={handleOnChangeComplete} /> : null}
      </Box>
      {children}
    </Grid>
  );
}
