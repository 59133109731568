import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from '@chakra-ui/react';
import { DataStore, SortDirection } from 'aws-amplify';
import { Link as RouterLink } from 'react-router-dom';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { CaretRightIcon, PodiumIcon } from '@sermonary/icons';
import { Sermon, SermonSeries } from '../../models';
import { orderedSermonGroupsAtom, sermonGroupsAtom, sermonsAtom, sermonSeriesAtom } from '../../pages/Sermons';
import { cognitoUserAtom } from 'src/App';

export const canceledSermonsModalOpenAtom = atom(false);

export function CanceledUserSermons() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [isOpen, setIsOpen] = useAtom(canceledSermonsModalOpenAtom);

  const [, setSermons] = useAtom(sermonsAtom);
  const [sermonSeries, setSermonSeries] = useAtom(sermonSeriesAtom);
  const [groups] = useAtom(sermonGroupsAtom);
  const [orderedSermonGroups] = useAtom(orderedSermonGroupsAtom);
  const [user] = useAtom(cognitoUserAtom);

  async function fetchSermons() {
    const fetchedSermons = await DataStore.query(Sermon, (c) => c?.sermonType.eq('SERMON'), {
      sort: (s) => s.updatedAt(SortDirection.DESCENDING),
    });
    setSermons(fetchedSermons);
  }

  async function fetchSermonSeries() {
    const fetchedSermonSeries = await DataStore.query(SermonSeries);
    setSermonSeries(fetchedSermonSeries);
  }

  useEffect(() => {
    if (Object.keys(user).length) {
      fetchSermons();
      fetchSermonSeries();
    }
  }, [user]);

  return (
    <Modal
      closeOnOverlayClick
      isOpen={isOpen}
      size={isMobile ? 'full' : 'xl'}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent w={isMobile ? '100vw' : '360px'} py="6" px="4">
        <ModalHeader p="0" color="gray.900">
          Your Sermons
        </ModalHeader>
        <ModalCloseButton top="6" right="4" />
        <ModalBody p="0">
          <Accordion allowMultiple>
            {orderedSermonGroups.map((key: string, index: number) => (
              <AccordionItem border="none" key={key}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton px="0" pb="4" _hover={{ background: 'none' }} fontWeight="medium">
                      <CaretRightIcon mr="3" transform={isExpanded ? 'rotate(90deg)' : 'none'} />
                      {key === 'individual' ? (
                        <span>Individual Sermons</span>
                      ) : (
                        <span>{sermonSeries?.find((series) => series?.id === key)?.title as string}</span>
                      )}
                    </AccordionButton>
                    <AccordionPanel p="0">
                      {groups?.[key].map((sermon: Sermon) => (
                        <Button
                          as={RouterLink}
                          to={`/sermons/${sermon?.id}/podium/share`}
                          variant="shadow"
                          fontSize="xs"
                          onClick={() => setIsOpen(false)}
                          p="0"
                          h="unset"
                          mb="5"
                          key={sermon?.id}
                        >
                          <PodiumIcon width="3" height="3" mr="2" />
                          {sermon?.title}
                        </Button>
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
