import { Icon, IconProps } from '@chakra-ui/react';

export function WriteOrEditIcon({ height = '6', width = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fill="currentColor"
        d="M9.248 2.625c.714 0 1.301.549 1.368 1.25l.006.133a1.38 1.38 0 0 1-1.375 1.383H3.75c-.552 0-1 .45-1 1.006v13.831c0 .556.448 1.006 1 1.006h13.745c.513 0 .936-.388.994-.889l.006-.117v-5.532c0-.72.545-1.31 1.243-1.377l.132-.006c.76 0 1.375.619 1.375 1.383v6.538A2.758 2.758 0 0 1 18.495 24H2.75A2.758 2.758 0 0 1 0 21.234V5.39a2.758 2.758 0 0 1 2.75-2.766h6.498ZM21.422.755l1.823 1.82a2.573 2.573 0 0 1 0 3.642L11.788 17.663a2.742 2.742 0 0 1-1.938.804H5.516v-4.312c0-.737.292-1.443.812-1.962L17.778.754a2.58 2.58 0 0 1 3.644 0Zm-2.527 2.524L8.53 13.601c-.194.193-.3.458-.295.732l.024 1.36 1.368.024c.27.005.53-.1.723-.292L20.708 5.11a1.01 1.01 0 0 0 .088-1.328l-.088-.1-.404-.403a.996.996 0 0 0-1.409 0Z"
      />
    </Icon>
  );
}

export default WriteOrEditIcon;
