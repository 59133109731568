import { Button } from '@chakra-ui/react';
import { BlockType } from '@sermonary/types';

export const SermonBlockTypeMap: {
  [key: string]: {
    label: string;
    color: string;
  };
} = {
  bible: {
    label: 'Bible Passage',
    color: '#ED3D3D',
  },
  point: {
    label: 'Point',
    color: '#E69F06',
  },
  illustration: {
    label: 'Illustration',
    color: '#168eea',
  },
  application: {
    label: 'Application',
    color: '#7f909b',
  },
  quote: {
    label: 'Quote',
    color: '#6AB616',
  },
  custom: {
    label: 'Custom',
    color: '#353d41',
  },
  media: {
    label: 'Media',
    color: '#7a00ff',
  },
};

interface SermonBlockCardProps {
  type: BlockType;
  onAddBlock: (type: BlockType) => void;
}

export function SermonBlockCard({ type, onAddBlock }: SermonBlockCardProps) {
  return (
    <Button
      borderRadius="3px"
      borderBottom="3px solid"
      borderBottomColor={SermonBlockTypeMap?.[type]?.color}
      p="4"
      py="6"
      w="100%"
      justifyContent="flex-start"
      onClick={() => onAddBlock(type)}
    >
      {SermonBlockTypeMap?.[type]?.label}
    </Button>
  );
}
