import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const verseByVerseRunningCommentary = {
  id: 'verseByVerseRunningCommentary',
  title: 'Verse-by-Verse Running Commentary',
  description:
    'This template is designed to help pastors work verse-by-verse through their text. Included are blocks for illustrations and application.',
  sermonType: SermonType.SERMON,
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      type: 'illustration',
      title: 'INTRODUCTION',
      content: '',
    },
    '1': {
      id: uuid(),
      type: 'custom',
      title: 'PASSAGE BACKGROUND AND CONTEXT INFOMATION',
      content: '',
    },
    '2': {
      id: uuid(),
      type: 'point',
      title: 'First Set of Verses',
      number: '1',
    },
    '3': {
      id: uuid(),
      type: 'custom',
      title: 'EXPLAIN THE TEXT',
      content: '',
    },
    '4': {
      id: uuid(),
      type: 'illustration',
      title: 'ILLUSTRATE THE TEXT',
      content: '',
    },
    '5': {
      id: uuid(),
      type: 'point',
      title: 'Second Set of Verses',
      number: '2',
    },
    '6': {
      id: uuid(),
      type: 'custom',
      title: 'EXPLAIN THE TEXT',
      content: '',
    },
    '7': {
      id: uuid(),
      type: 'illustration',
      title: 'ILLUSTRATE THE TEXT',
      content: '',
    },
    '8': {
      id: uuid(),
      type: 'point',
      title: 'Third Set of Verses',
      number: '3',
    },
    '9': {
      id: uuid(),
      type: 'custom',
      title: 'EXPLAIN THE TEXT',
      content: '',
    },
    '10': {
      id: uuid(),
      type: 'illustration',
      title: 'ILLUSTRATE THE TEXT',
      content: '',
    },
    '11': {
      id: uuid(),
      type: 'application',
      title: 'APPLY THE PASSAGE',
      content: '',
    },
    '12': {
      id: uuid(),
      type: 'custom',
      title: 'Conclusion',
      content: '',
    },
    '13': {
      id: uuid(),
      type: 'custom',
      color: 'transparent',
      title: 'COPYRIGHT',
      content: 'Copyright © 2017 Sermonary',
    },
  }),
};
