import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { test, enforce, create } from 'vest';
import { Alert, AlertIcon, AlertDescription, Button, Flex, Grid, Link, Text } from '@chakra-ui/react';
import { AuthError } from '@sermonary/types';
import { EmailIcon } from '@sermonary/icons';
import { AuthFormInput } from '@sermonary/components';

export interface ResetPasswordFormValues {
  email: string;
}

export interface ResetPasswordFormProps {
  isError: boolean;
  isLoading: boolean;
  error?: AuthError;
  onSubmit: (values: ResetPasswordFormValues) => void;
}

const validationSuite = create((data: Partial<ResetPasswordFormValues> = {}) => {
  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty();
  });
});

export function ResetPasswordForm({ isLoading, isError, error, onSubmit }: ResetPasswordFormProps) {
  const { register, handleSubmit, formState } = useForm<ResetPasswordFormValues>({
    resolver: vestResolver(validationSuite),
  });

  const { errors } = formState;

  return (
    <Grid as="form" onSubmit={handleSubmit(onSubmit)} mt="8" gap="4" templateColumns="1fr" w={['100%', null, '80']}>
      <Text as="h3" mb="2">
        Forgot Your Password?
      </Text>
      <Text fontSize="md">Enter the email you use for Sermonary.</Text>
      <AuthFormInput
        label="Email"
        inputLeftElement={<EmailIcon />}
        type="email"
        registerFieldName="email"
        register={register}
        errors={errors}
        inputId="loginEmail"
        autoFocus
      />

      {isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error?.message}</AlertDescription>
        </Alert>
      ) : null}
      <Flex alignItems="center">
        <Button isFullWidth type="submit" isLoading={isLoading} colorScheme="blue">
          Send Password Reset Email
        </Button>
      </Flex>
      <Text fontSize="sm">
        <Link as={RouterLink} to="/login">
          Back to Log In
        </Link>
      </Text>
    </Grid>
  );
}
