import { DataStore } from '@aws-amplify/datastore';
import moment from 'moment/moment';
import { track } from '../analytics/Analytics';
import { Sermon, SermonBackup } from '../models';

export interface BackupSermonInput {
  sermon: Sermon;
  source: string;
  meta?: Record<string, any>;
  daysToKeep?: number;
}

export async function backupSermon(input: BackupSermonInput) {
  const { sermon, source, meta } = input;
  const daysToKeep = input.daysToKeep ?? 30;

  const backup = await DataStore.save(
    new SermonBackup({
      sermon: JSON.stringify(sermon),
      _ttl: parseInt(moment().add(daysToKeep, 'days').format('X'), 10),
      meta: JSON.stringify({
        ...meta,
        source,
      }),
    }),
  );

  await track('Sermon Backup Created', {
    source,
    sermonId: sermon.id,
    backupId: backup.id,
  });

  return backup;
}
