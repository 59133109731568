import { DependencyList, useCallback, useState } from 'react';

interface UseMutationOptions {
  deps?: DependencyList;
}

type State = 'idle' | 'loading' | 'error' | 'success';

export function useMutation<T>(
  fn: any,
  {
    deps = [],
    onSuccess,
    onError,
  }: UseMutationOptions & {
    onSuccess?: () => void;
    onError?: (error: any) => void;
  } = {},
): {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  state: State;
  error: any;
  handler: (args: any) => Promise<T>;
} {
  const [state, setState] = useState<State>('idle');
  const [error, setError] = useState<any>();

  const isLoading = state === 'loading';
  const isError = state === 'error';
  const isSuccess = state === 'success';

  const callback = useCallback((...args: any): any => fn(...args), deps);

  const handler = useCallback(
    async (args: any) => {
      setState('loading');

      try {
        const result = await callback(args);
        setState('success');
        onSuccess?.();

        return result;
      } catch (err) {
        setState('error');
        setError(err);
        onError?.(error);
        return null;
      }
    },
    [deps],
  );

  return { isLoading, isError, isSuccess, state, error, handler };
}
