import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerContent, Box, Link, Text } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@sermonary/icons';
import { Label } from '@sermonary/components';
import { DataStore } from '@aws-amplify/datastore';
import { useMutation } from '@sermonary/hooks';
import { useNavigate } from 'react-router-dom';
import { Sermon } from '../models';
import { createSermonDrawerStateAtom, useFetchFavoriteTemplates } from '.';
import { track } from '../analytics/Analytics';
import { sermonAtom, isSermonSettingsDrawerOpenAtom } from '../pages/sermons/SingleSermon';
import { externalLinks } from '../utilities/externalLinks';
import { favoriteSermonTemplatesAtom } from '../libs/atoms';

const favoriteTemplateDrawerStateAtom = atom<'open' | 'closed'>('closed');

interface FavoriteTemplateSelectionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

function FavoriteTemplateSelectionDrawer({ isOpen, onClose }: FavoriteTemplateSelectionDrawerProps) {
  const navigate = useNavigate();
  const [, setSermon] = useAtom(sermonAtom);
  const [, setFavoriteDrawerState] = useAtom(favoriteTemplateDrawerStateAtom);
  const [, setSermonDrawerState] = useAtom(createSermonDrawerStateAtom);
  const [, setIsSermonSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);
  const [favoriteSermonTemplates] = useAtom(favoriteSermonTemplatesAtom);

  const { handler } = useMutation<Sermon>(async (template: Sermon) => {
    try {
      const sermon = await DataStore.save(new Sermon(template));
      setSermon(sermon);
      return sermon;
    } catch (err) {
      console.log({ err });
      return null;
    }
  });

  async function createNewSermon(template: Sermon) {
    const { id: sermonId } = await handler({
      bigIdea: template?.bigIdea,
      blocks: template?.blocks,
      sermonType: 'SERMON',
      wordCount: template?.wordCount,
      title: template?.title,
    });

    setFavoriteDrawerState('closed');
    setSermonDrawerState('closed');

    await track('Create Sermon from Favorite Template', { templateTitle: template?.title, templateId: template?.id });

    navigate(`/sermons/${sermonId}`);
    setIsSermonSettingsDrawerOpen(true);
  }

  useFetchFavoriteTemplates();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerContent p={4}>
        <DrawerHeader>
          <Button backgroundColor="transparent" onClick={onClose} textTransform="uppercase" color="gray.700">
            <ArrowLeftIcon mr="1" height={4} width={4} />
            <Text fontSize="md">Back</Text>
          </Button>
        </DrawerHeader>

        <DrawerBody>
          <Box>
            <Label mb={0}>Favorite Templates</Label>
            {favoriteSermonTemplates.length ? (
              favoriteSermonTemplates.map((template, index) => (
                <Button
                  key={`template_${index + 1}`}
                  mt="6"
                  w="100%"
                  backgroundColor="gray.300"
                  color="gray.900"
                  textAlign="left"
                  justifyContent="left"
                  onClick={() => createNewSermon(template)}
                  whiteSpace="normal"
                  height="auto"
                  p={4}
                  _hover={{ backgroundColor: 'gray.400' }}
                >
                  {template.title}
                </Button>
              ))
            ) : (
              <Text>Your favorite templates will appear here.</Text>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function useFavoriteTemplateSelectionDrawer() {
  const [state, setState] = useAtom(favoriteTemplateDrawerStateAtom);

  const isOpen = state === 'open';

  const handleOnClose = useCallback(() => {
    setState('closed');
  }, [setState]);

  return {
    isOpen,
    handleOnOpen: () => {
      setState('open');
    },
    handleOnClose,
    handleOnToggle: () => {
      if (state === 'closed') {
        setState('open');
      } else {
        setState('closed');
      }
    },

    FavoriteTemplateSelectionDrawer: useCallback(
      () => (isOpen ? <FavoriteTemplateSelectionDrawer isOpen={isOpen} onClose={handleOnClose} /> : null),
      [handleOnClose, isOpen],
    ),
  };
}
