import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  usePrevious,
} from '@chakra-ui/react';
import { SearchIcon, CaretRightIcon, ArrowLeftIcon } from '@sermonary/icons';
import { Block } from '@sermonary/types';

import { useMemo, useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useGetCurrentUser, useAutoSave } from '@sermonary/hooks';
import { useAtom } from 'jotai';
import { track } from '../../analytics/Analytics';
import { useAppContext } from '../../contexts/AppContext';
import { Illustration } from '../../graphql/mpfederation';
import { openUpgradeModalAtom } from '../modals/UpgradeModal';
import AlertDialogPrompt from '../forms/AlertDialogPrompt';

import { ContentBlockForm } from '../forms';

import { useUpdateBlockForm } from '../../hooks/useUpdateBlockForm';

interface IllustrationBlockFormValues {
  content: string;
}

export function IllustrationBlock({
  isActive,
  block,
  onSave,
  onDelete,
  onClose,
}: {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: IllustrationBlockFormValues) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: IllustrationBlockFormValues) => void;
}) {
  const { client } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [isManual, setIsManual] = useState(!!block.content);
  const [inputValue, setInputValue] = useState('');
  const [illustrations, setIllustrations] = useState<Illustration[]>([]);
  const [illustrationsCount, setIllustrationsCount] = useState<number | null>(null);
  const [selectedIllustration, setSelectedIllustration] = useState<Illustration | null>(null);

  const [debouncedInputValue] = useDebounce(inputValue, 300);
  const prevInputValue = usePrevious(inputValue);
  const [, openUpgradeModal] = useAtom(openUpgradeModalAtom);

  const { isFreeUser } = useGetCurrentUser();

  const { control, handleSubmit, reset, ...methods } = useForm<IllustrationBlockFormValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        content: block?.content,
      }),
      [block],
    ),
  });

  useEffect(() => {
    async function fetchIllustrations() {
      setIsLoading(true);
      try {
        const response = await client?.request({
          data: {
            query: `
          query GetIllustrations($input: IllustrationInput) {
            illustrations(input: $input) {
              illustrationCount
              illustrationList {
                id
                title
                content
                source
                facets {
                  id
                  name
                  facetType {
                    id
                    name
                    description
                  }
                  description
                  featured
                  slug
                }
              }
            }
          }
        `,
            variables: {
              input: {
                search: debouncedInputValue,
                take: 5,
              },
            },
          },
        });

        setIllustrations(response?.data?.data?.illustrations?.illustrationList);
        setIllustrationsCount(response?.data?.data?.illustrations?.illustrationCount);
        setIsLoading(false);
        await track('Enhanced Illustration Search', { query: debouncedInputValue });
      } catch (err: any) {
        setError(err.message);
        setIsLoading(false);
      }
    }

    if (debouncedInputValue) {
      fetchIllustrations();
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    if (prevInputValue && inputValue === '') {
      setIllustrations([]);
      setIllustrationsCount(null);
    }
  }, [inputValue, prevInputValue]);

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    try {
      const response = await client?.request({
        data: {
          query: `
          query GetIllustrations($input: IllustrationInput) {
            illustrations(input: $input) {
              illustrationCount
              illustrationList {
                id
                title
                content
                source
                facets {
                  id
                  name
                  facetType {
                    id
                    name
                    description
                  }
                  description
                  featured
                  slug
                }
              }
            }
          }
        `,
          variables: {
            input: {
              search: debouncedInputValue,
              take: 5,
              cursor: illustrations?.[Number(illustrations?.length) - 1]?.id,
            },
          },
        },
      });

      const illustrationList = response?.data?.data?.illustrations?.illustrationList ?? {};

      setIllustrations([...illustrations, ...illustrationList]);
      setIsLoadingMore(false);
    } catch (err: any) {
      setError(err.message);
      setIsLoadingMore(false);
    }
  };

  function onSubmit(values: IllustrationBlockFormValues) {
    if (methods.formState.isDirty) {
      onSave(block?.id, values);
    }
    onClose(block?.id, values);
  }

  const handleClickAddToSermon = () => {
    methods.setValue('content', `<h3>${selectedIllustration?.title}</h3>${selectedIllustration?.content}`);
    onSave(block?.id, methods.getValues());
    setIsManual(true);
    setSelectedIllustration(null);
    track('Illustration Imported', { illustrationTitle: selectedIllustration?.title });
  };

  useAutoSave<IllustrationBlockFormValues>({
    control,
    isDirty: methods.formState.isDirty,
    onSave: (values: IllustrationBlockFormValues) => {
      onSave(block?.id, values);
      reset(values);
    },
  });

  useUpdateBlockForm<IllustrationBlockFormValues>({
    block,
    handleReset: () => {
      reset({
        content: block?.content,
      });
    },
  });

  if (!isActive) {
    return null;
  }

  if (selectedIllustration) {
    return (
      <Box>
        <Box borderBottom="4px solid" borderColor="gray.200" pb={8} mb={8}>
          <Button
            variant="unstyled"
            alignItems="center"
            cursor="pointer"
            display="flex"
            height="auto"
            onClick={() => setSelectedIllustration(null)}
          >
            <ArrowLeftIcon h="12px" color="gray.700" mr={2} />
            <Text as="span" color="gray.600">
              Illustrations
            </Text>
            <Text as="span" mx={2} color="gray.600">
              /
            </Text>
            <Text as="span" color="gray.700">
              {selectedIllustration?.title}
            </Text>
          </Button>
        </Box>
        <Heading as="h5" color="gray.700" fontSize="3xl" mb={5}>
          {selectedIllustration?.title}
        </Heading>
        <Text
          as="p"
          dangerouslySetInnerHTML={{
            __html: selectedIllustration?.content || '',
          }}
        />
        {selectedIllustration?.facets?.filter((facet) => facet?.facetType?.name === 'Topic')?.splice(0, 7)?.length ? (
          <Box mt={6}>
            <Text as="span" display="block" mb={2} fontWeight="semibold">
              Topics
            </Text>
            <HStack mb={8} spacing={10}>
              {selectedIllustration?.facets
                ?.filter((facet) => facet?.facetType?.name === 'Topic')
                ?.splice(0, 7)
                ?.map((facet) => (
                  <Text key={facet?.id} color="gray.700">
                    {facet?.name}
                  </Text>
                ))}
            </HStack>
          </Box>
        ) : null}
        {selectedIllustration?.facets?.filter((facet) => facet?.facetType?.name === 'Bible Verse')?.length ? (
          <Box>
            <Text as="span" display="block" mb={2} fontWeight="semibold">
              References
            </Text>
            <HStack spacing={10}>
              {selectedIllustration?.facets
                ?.filter((facet) => facet?.facetType?.name === 'Bible Verse')
                ?.map((facet) => (
                  <Text key={facet?.id} color="gray.700">
                    {facet?.name}
                  </Text>
                ))}
            </HStack>
          </Box>
        ) : null}
        <Box mt={6}>
          <Button type="button" colorScheme="blue" onClick={handleClickAddToSermon}>
            Add to sermon
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Flex justifyContent="flex-end" mb="5">
        {isManual ? (
          <Button
            colorScheme="blue"
            fontSize="sm"
            fontWeight="semibold"
            textTransform="uppercase"
            variant="link"
            onClick={() => setIsManual(false)}
            rightIcon={<CaretRightIcon />}
          >
            Search illustrations
          </Button>
        ) : (
          <Button
            colorScheme="blue"
            fontSize="sm"
            fontWeight="semibold"
            textTransform="uppercase"
            variant="link"
            onClick={() => setIsManual(true)}
            rightIcon={<CaretRightIcon />}
          >
            Add your own illustration
          </Button>
        )}
      </Flex>
      {isManual ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogPrompt isDirty={methods.formState.isDirty} />
          <ContentBlockForm control={control} {...methods}>
            <Flex justifyContent="flex-end">
              <Button type="button" mr="2" onClick={() => onDelete(block)}>
                Delete
              </Button>
              <Button type="submit" colorScheme="blue">
                Done
              </Button>
            </Flex>
          </ContentBlockForm>
        </form>
      ) : (
        <>
          <InputGroup>
            <Input
              placeholder="Search Sermonary's library of 500+ illustrations"
              value={inputValue}
              onChange={(e) => {
                if (isFreeUser) {
                  openUpgradeModal({
                    isSermonaryTier: true,
                    upgradeFeature: 'Enhanced Illustration Search',
                  });
                  return;
                }
                setInputValue(e?.target?.value);
              }}
            />
            <InputRightElement children={<SearchIcon color="gray.500" height={4} width={4} />} />
          </InputGroup>
          {illustrationsCount ? (
            <Box borderBottom="4px solid" borderColor="gray.200" pb={5}>
              <Text color="gray.700" mt={6}>
                {illustrationsCount} Illustrations
              </Text>
            </Box>
          ) : null}
          <Box mt={8}>
            {isLoading ? (
              <Flex alignItems="center" justifyContent="center">
                <Spinner size="lg" />
              </Flex>
            ) : (illustrationsCount !== null && illustrations?.length === 0) || error ? (
              <Box mt={12} mb={4}>
                <Heading as="h4" fontSize="3xl" color="gray.700">
                  Please try searching again.
                </Heading>
              </Box>
            ) : (
              illustrations?.map((illustration) => (
                <Box
                  key={illustration?.id}
                  borderBottom="4px solid"
                  borderColor="gray.200"
                  cursor="pointer"
                  mb={8}
                  p={8}
                  transition="all 0.2s ease-in-out"
                  _hover={{
                    borderColor: 'transparent',
                    boxShadow: '0px 9px 19px rgba(0, 0, 0, 0.15)',
                  }}
                  onClick={() => setSelectedIllustration(illustration)}
                >
                  <Heading as="h5" color="gray.700" fontSize="3xl" mb={5}>
                    {illustration?.title}
                  </Heading>
                  <Text
                    as="p"
                    noOfLines={4}
                    dangerouslySetInnerHTML={{
                      __html: illustration?.content || '',
                    }}
                  />
                  {illustration?.facets?.filter((facet) => facet?.facetType?.name === 'Bible Verse')?.splice(0, 3)
                    ?.length ? (
                    <HStack mt={8} spacing={10}>
                      {illustration?.facets
                        ?.filter((facet) => facet?.facetType?.name === 'Bible Verse')
                        ?.splice(0, 3)
                        ?.map((facet) => (
                          <Text key={facet?.id} fontStyle="italic">
                            {facet?.name}
                          </Text>
                        ))}
                    </HStack>
                  ) : null}
                </Box>
              ))
            )}
          </Box>
          {isLoadingMore ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner size="lg" />
            </Flex>
          ) : !isLoading && illustrations?.length > 0 ? (
            <Flex alignItems="center" justifyContent="center">
              <Button variant="outline" onClick={handleLoadMore}>
                Load more
              </Button>
            </Flex>
          ) : null}
        </>
      )}
    </Box>
  );
}
