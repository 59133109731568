import { Icon, IconProps } from '@chakra-ui/react';

export function PlayIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.726 16.186a1 1 0 0 0 1.462.886l7.979-4.155a1 1 0 0 0 0-1.774L8.188 6.987a1 1 0 0 0-1.462.887v8.312Zm12.375-1.793c1.912-.995 1.912-3.73 0-4.727L7.957 3.863c-1.774-.924-3.895.363-3.895 2.363v11.608c0 2 2.121 3.287 3.895 2.363l11.144-5.804Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default PlayIcon;
