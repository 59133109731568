import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { test, enforce, create } from 'vest';
import { Alert, AlertIcon, AlertDescription, Button, Flex, Grid, Link, Text } from '@chakra-ui/react';
import { AuthError } from '@sermonary/types';
import { EmailIcon, LockIcon } from '@sermonary/icons';
import { AuthFormInput } from '@sermonary/components';
import { useEffect } from 'react';

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormProps {
  defaultEmail?: string;
  isError: boolean;
  isLoading: boolean;
  error?: AuthError;
  onSubmit: (values: LoginFormValues) => void;
}

const validationSuite = create((data: Partial<LoginFormValues> = {}) => {
  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty();
  });

  test('password', 'Password is required', () => {
    enforce(data.password).isNotEmpty();
  });
});

export function LoginForm({ defaultEmail, isError, isLoading, error, onSubmit }: LoginFormProps) {
  const { register, handleSubmit, formState } = useForm<LoginFormValues>({
    ...(defaultEmail ? { defaultValues: { email: defaultEmail } } : {}),
    resolver: vestResolver(validationSuite),
  });

  const { errors } = formState;

  return (
    <Grid as="form" onSubmit={handleSubmit(onSubmit)} mt="8" gap="4" templateColumns="1fr" w={['100%', null, '80']}>
      <Text as="h3" mb="2">
        Log In
      </Text>
      <AuthFormInput
        label="Email"
        inputLeftElement={<EmailIcon />}
        type="email"
        registerFieldName="email"
        register={register}
        errors={errors}
        inputId="loginEmail"
        autoFocus
      />
      <AuthFormInput
        label="Password"
        inputLeftElement={<LockIcon />}
        type="password"
        registerFieldName="password"
        register={register}
        errors={errors}
        inputId="loginPassword"
      />
      <Text fontSize="sm">
        <Link as={RouterLink} to="/reset-password">
          I forgot my password
        </Link>
      </Text>

      {isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error?.message || error}</AlertDescription>
        </Alert>
      ) : null}
      <Flex alignItems="center">
        <Button isFullWidth type="submit" isLoading={isLoading} colorScheme="blue">
          Log In
        </Button>
      </Flex>
    </Grid>
  );
}
