import { createContext, ReactNode, useContext, useMemo } from 'react';
import { MPFederationClient } from '@ministry-pass/federation-client';

interface AppContextValue {
  client: MPFederationClient | null;
}

export const AppContext = createContext<AppContextValue>({
  client: null,
});

export const useAppContext = () => useContext(AppContext);

export function AppProvider({ children, client }: { children: ReactNode; client: MPFederationClient }) {
  const clientObj = useMemo(() => ({ client }), [client]);
  return <AppContext.Provider value={clientObj}>{children}</AppContext.Provider>;
}
