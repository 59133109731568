import { Flex } from '@chakra-ui/react';
import { LoadingGif } from '@sermonary/icons';

export function Loading() {
  return (
    <Flex bg={'gray.100'} w="100%" h="100vh" justifyContent="center" alignItems="center">
      <LoadingGif />
    </Flex>
  );
}
