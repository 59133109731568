import { Icon, IconProps } from '@chakra-ui/react';

export function QuoteIcon({ height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <g clipPath="url(#clip0_303:9)">
        <path fill="white" d="M0 0h24v24H0z" />
        <path
          d="M16.536 18.802c-.79.365-1.367-.076-1.733-.867-.258-.56-.826-1.034-.982-1.61-1.327-4.917 1.039-10.217 5.818-12.422a1.58 1.58 0 0 1 2.095.772c.365.79.02 1.727-.77 2.092a7.486 7.486 0 0 0-3.658 9.943c.366.79.021 1.727-.77 2.092Z"
          fill="currentColor"
        />
        <path
          d="M13.674 15.08a5.166 5.166 0 0 1 5.169-5.163 5.166 5.166 0 0 1 5.169 5.163 5.166 5.166 0 0 1-5.17 5.162 5.166 5.166 0 0 1-5.168-5.162Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.843 12.667a2.414 2.414 0 0 0-2.416 2.413 2.414 2.414 0 0 0 2.416 2.412 2.414 2.414 0 0 0 2.415-2.412 2.414 2.414 0 0 0-2.415-2.413Zm0-2.75a5.166 5.166 0 0 0-5.17 5.163 5.166 5.166 0 0 0 5.17 5.162 5.166 5.166 0 0 0 5.169-5.162 5.166 5.166 0 0 0-5.17-5.163Z"
          fill="currentColor"
        />
        <path
          d="M3.092 18.802c-.791.365-1.367-.076-1.733-.867-.259-.56-.826-1.034-.982-1.61C-.95 11.408 1.415 6.108 6.195 3.903a1.58 1.58 0 0 1 2.094.772c.366.79.021 1.727-.77 2.092a7.486 7.486 0 0 0-3.657 9.943c.365.79.02 1.727-.77 2.092Z"
          fill="currentColor"
        />
        <path
          d="M.23 15.08a5.166 5.166 0 0 1 5.168-5.163 5.166 5.166 0 0 1 5.17 5.163 5.166 5.166 0 0 1-5.17 5.162A5.166 5.166 0 0 1 .23 15.08Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.398 12.667a2.414 2.414 0 0 0-2.415 2.413 2.414 2.414 0 0 0 2.415 2.412 2.414 2.414 0 0 0 2.416-2.412 2.414 2.414 0 0 0-2.416-2.413Zm0-2.75A5.166 5.166 0 0 0 .23 15.08a5.166 5.166 0 0 0 5.168 5.162 5.166 5.166 0 0 0 5.17-5.162 5.166 5.166 0 0 0-5.17-5.163Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_303:9">
          <path fill="white" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default QuoteIcon;
