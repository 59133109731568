import { Icon, IconProps } from '@chakra-ui/react';

export function ActionsIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} color="gray.300" {...props}>
      <path
        fill="currentColor"
        d="M12 9.25a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5zm8.25 0a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5zm-16.5 0a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5z"
      />
    </Icon>
  );
}

export default ActionsIcon;
