import { Icon, IconProps } from '@chakra-ui/react';

export function PodiumIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.25 1.881C20.986.786 19.858 0 18.55 0H5.45c-1.308 0-2.436.786-2.7 1.881L.057 9.167A2.029 2.029 0 0 0 0 9.643C0 10.945 1.234 12 2.757 12h7.868v9.25h-3.25a1.375 1.375 0 1 0 0 2.75h9.25a1.375 1.375 0 1 0 0-2.75h-3.25V12h7.868c.187 0 .373-.016.556-.049 1.492-.262 2.451-1.51 2.144-2.784L21.25 1.88Zm-2.7.869.058.003.034.006 2.399 6.491H2.958l2.399-6.491.063-.008 13.13-.001Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default PodiumIcon;
