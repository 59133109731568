import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

export function useGetCurrentUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({} as CognitoUser);
  const [error, setError] = useState(null);

  async function fetchCurrentUser() {
    setIsLoading(true);
    try {
      const authUser = await Auth.currentAuthenticatedUser();

      setUser(authUser);
      setIsLoading(false);
    } catch (err) {
      setError(err as any);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  function handleRefetch() {
    fetchCurrentUser();
  }

  return {
    isLoading,
    isError: !!error,
    user,
    error,
    refetch: handleRefetch,
    isFreeUser:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !user?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes('PremiumSubscribers'),
  };
}
