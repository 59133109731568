import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Text, useMediaQuery } from '@chakra-ui/react';
import { RegistrationConfig } from '@sermonary/types';
import { atom, useAtom } from 'jotai';
import ReactPlayer from 'react-player/wistia';
import { useLocation } from 'react-router-dom';
import { registrationConfigAtom } from '../../pages/auth/Register';

export const showWelcomeModalAtom = atom(false);

export function WelcomeModal() {
  const currentLocation = useLocation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useAtom(showWelcomeModalAtom);
  const [, setRegistrationConfig] = useAtom(registrationConfigAtom);

  function onClose() {
    setIsOpen(false);
    setRegistrationConfig({} as RegistrationConfig);
  }

  if (currentLocation.pathname === '/upgrade') {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} size={isMobile ? 'full' : '2xl'}>
      <ModalOverlay />
      <ModalContent w={isMobile ? '100vw' : '400px'} alignSelf="center">
        <ModalBody p="0" w="100%">
          <Box p={6}>
            <Text as="h3" mb="2">
              Welcome!
            </Text>
            <Text>We&apos;re glad you&apos;re here!</Text>
            <Text>Check out this 60 second video to get started.</Text>
          </Box>
          <Box position="relative" width="100%" pb="56.25%">
            <ReactPlayer
              config={{
                options: {
                  popover: true,
                },
              }}
              controls
              style={{ position: 'absolute' }}
              width="100%"
              height="100%"
              url="https://ministrypass.wistia.com/medias/o4o40322xy"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
