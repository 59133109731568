import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const theYouthPastor = {
  id: 'theYouthPastor',
  title: 'The Youth Pastor',
  description:
    "This template is designed to help youth leaders communicate the Bible in a way that's both creative and applicable for teenagers.",
  sermonType: SermonType.SERMON,
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      type: 'point',
      title: 'CAPTURE',
      number: '1',
    },
    '1': {
      id: uuid(),
      type: 'custom',
      title: 'GRAB THEIR ATTENTION',
      content:
        'At the beginning of the sermon, include an illustration that will capture your students’ attention and direct them to the Big Idea of the Message. Illustrations include stories, video clips, pictures, songs, and object lessons.',
    },
    '2': {
      id: uuid(),
      type: 'illustration',
      title: 'Illustration',
      content: '',
    },
    '3': {
      id: uuid(),
      type: 'point',
      title: 'CONNECT',
      number: '2',
    },
    '4': {
      id: uuid(),
      type: 'custom',
      title: `DIRECT THEM TO GOD'S WORD`,
      content:
        'During this section, explore your Bible passage. What was the author of this text trying to teach their original audience?',
    },
    '5': {
      id: uuid(),
      type: 'bible',
      inputType: 'manual',
      book: '',
      chapter: '',
      verses: '',
      translation: '',
      content: '',
    },
    '6': {
      id: uuid(),
      type: 'point',
      title: 'CONSIDER',
      number: '3',
    },
    '7': {
      id: uuid(),
      type: 'custom',
      title: 'WHAT DOES THIS MEAN TODAY?',
      content: 'Here, you’ll connect your passage to the world of your students. What does this mean for them today?',
    },
    '8': {
      id: uuid(),
      type: 'illustration',
      title: 'Illustration',
      content: '',
    },
    '9': {
      id: uuid(),
      type: 'point',
      title: 'COLLIDE',
      number: '4',
    },
    '10': {
      id: uuid(),
      type: 'custom',
      title: `WHERE GOD'S WORD MEETS OUR EVERYDAY LIFE`,
      content:
        'In this section, give specific examples to your students on how they can apply this message to their life.',
    },
    '11': {
      id: uuid(),
      type: 'application',
      title: 'Application',
      content: '',
    },
    '12': {
      id: uuid(),
      type: 'point',
      title: 'CALL',
      number: '5',
    },
    '13': {
      id: uuid(),
      type: 'custom',
      title: `RESPOND TO THE HOLY SPIRIT`,
      content:
        'What in-service response do you want the students to take? Altar call? Communion? Something else? Place that here.',
    },
    '14': {
      id: uuid(),
      type: 'custom',
      color: 'transparent',
      title: 'COPYRIGHT',
      content: 'Copyright © 2017 Sermonary',
    },
  }),
};
