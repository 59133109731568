import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { DataStore } from 'aws-amplify';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalProps,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@sermonary/hooks';
import { SermonTemplateForm, sermonTemplateFormValidationSuite, SermonTemplateFormValues } from '../components/forms';
import { Sermon, SermonType } from '../models';

function SermonTemplateDialog({
  isOpen,
  onClose,
  sermon,
  editTemplate,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { sermon: Sermon; editTemplate: boolean }) {
  const toast = useToast();
  const { handleSubmit, control, register, reset, formState, ...methods } = useForm<SermonTemplateFormValues>({
    resolver: vestResolver(sermonTemplateFormValidationSuite),
  });

  function handleOnSuccess() {
    toast({
      title: 'Sermon template saved.',
      status: 'success',
      isClosable: true,
    });

    onClose();
  }

  const { isLoading, handler } = useMutation<Sermon>(
    async (args: SermonTemplateFormValues) => {
      if (editTemplate) {
        await DataStore.save(
          Sermon.copyOf(sermon, (updated) => {
            updated.title = args.title.trim();
            updated.description = args.description;
          }),
        );
      } else {
        const { referenceVerses, bigIdea, blocks } = sermon;
        await DataStore.save(
          new Sermon({
            title: args?.title,
            description: args?.description,
            referenceVerses,
            bigIdea,
            blocks,
            sermonType: SermonType.TEMPLATE,
          }),
        );
      }
    },
    {
      deps: [sermon],
      onSuccess: handleOnSuccess,
    },
  );

  async function onSubmit(values: SermonTemplateFormValues) {
    try {
      await handler(values);
    } catch (err) {
      // TODO: Handle error
    }
  }

  useEffect(() => {
    if (Object.keys(sermon).length && editTemplate) {
      reset({
        title: sermon?.title,
        description: sermon?.description,
      });
    }
  }, [sermon, reset, editTemplate]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Template</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <SermonTemplateForm
              {...methods}
              control={control as any}
              formState={formState}
              register={register as any}
            />
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function useSermonTemplateDialog({ sermon, editTemplate }: { sermon: Sermon; editTemplate?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    isOpen,
    handleOnOpen: onOpen,
    handleOnClose: onClose,
    SermonTemplateDialog: useCallback(
      () => <SermonTemplateDialog isOpen={isOpen} onClose={onClose} sermon={sermon} editTemplate={editTemplate} />,
      [isOpen, onClose, sermon],
    ),
  };
}
