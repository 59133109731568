import { HubCapsule } from '@aws-amplify/core';
import { Hub } from 'aws-amplify';
import { useAtom } from 'jotai';
import { dataStoreIsSyncedAtom, isAuthenticatedAtom, modelsSyncedAtom } from 'src/App';
import { sermonSavedIdAtom } from '../pages/sermons/SingleSermon';

const blockingModels = ['Account', 'Sermon', 'SermonSeries', 'Idea'];

export function useAmplifyHubListeners() {
  const [, setSermonSavedId] = useAtom(sermonSavedIdAtom);
  const [, setDataStoreIsSynced] = useAtom(dataStoreIsSyncedAtom);
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [modelsSynced, setModelsSynced] = useAtom(modelsSyncedAtom);

  // Datastore Hub
  const hubListener = Hub.listen('datastore', (capsule: HubCapsule) => {
    const {
      payload: { event, data },
    } = capsule;

    let unsyncedBlockingModels: Array<string> = [];

    switch (event) {
      case 'outboxMutationProcessed':
        data?.model?.name === 'Sermon' && setSermonSavedId(data?.element?.id);
        break;

      case 'syncQueriesStarted':
        setDataStoreIsSynced(false);
        unsyncedBlockingModels = blockingModels;
        break;

      case 'modelSynced':
        const blockingIndex = unsyncedBlockingModels.indexOf(data.model.name);
        const modelIndex = modelsSynced.indexOf(data.model.name);

        if (blockingIndex >= 0) {
          unsyncedBlockingModels.splice(blockingIndex, 1);

          if (unsyncedBlockingModels.length === 0) {
            setDataStoreIsSynced(true);
          }
        }

        if (modelIndex === -1) {
          modelsSynced.push(data.model.name);
          setModelsSynced([...modelsSynced, data.model.name]);
        }
        break;

      case 'syncQueriesReady':
        // leave this here as a failsafe, in case something goes wrong with the early sync
        setDataStoreIsSynced(true);
        break;

      default:
    }
  });

  if (isAuthenticated) {
    hubListener();
  }
}
