import { memo, useState, useEffect } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useGetCurrentUser } from '@sermonary/hooks';
import { useAtom } from 'jotai';
import { isSermonSettingsDrawerOpenAtom, sermonAtom } from '../../pages/sermons/SingleSermon';
import { getSermonWordCount } from './SermonBlockEditor';

import { openUpgradeModalAtom } from '../modals/UpgradeModal';

interface SermonSettingsProps {
  onSaveAsTemplate?: () => void;
}

export const SermonSettings = memo(({ onSaveAsTemplate }: SermonSettingsProps) => {
  const [sermon] = useAtom(sermonAtom);
  const [sermonWordCount, setSermonWordCount] = useState(0);
  const [, setIsSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);
  const [, openUpgradeModal] = useAtom(openUpgradeModalAtom);

  const { isFreeUser } = useGetCurrentUser();

  const handleOnSaveAsTemplate = () => {
    if (isFreeUser) {
      openUpgradeModal({
        isSermonaryTier: true,
        upgradeFeature: 'Sermon Settings Save As Template',
      });
      return;
    }
    onSaveAsTemplate?.();
    setIsSettingsDrawerOpen(false);
  };

  useEffect(() => {
    if (Object.values(sermon).length) {
      setSermonWordCount(getSermonWordCount(sermon));
    }
  }, [sermon]);

  return (
    <>
      <Box mt="8">
        <Text fontSize="sm" fontWeight="700" textTransform="uppercase" color="gray.900" letterSpacing="1px">
          Word Count
        </Text>
        <Text as="h3" mt="2" fontWeight="normal" color="gray.600">
          {sermonWordCount}
        </Text>
      </Box>
      <Box mt="6">
        <Button
          variant="link"
          colorScheme="blue"
          onClick={handleOnSaveAsTemplate}
          fontSize="sm"
          fontWeight="700"
          textTransform="uppercase"
          letterSpacing="1px"
          textDecoration="underline"
        >
          Create Template From Sermon
        </Button>
      </Box>
    </>
  );
});
