import { Icon, IconProps } from '@chakra-ui/react';

export function RestoreIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.113 23.2732C22.3321 21.0071 25.5387 14.1228 23.2752 7.89663C21.5843 3.24594 17.2466 0.158951 12.3881 0.00590229C10.8776 -0.0416823 9.36475 0.198452 7.91586 0.726397C5.86837 1.47247 4.10153 2.73863 2.75644 4.37973L2.75478 3.97234L2.7545 3.40742L2.74642 3.25725C2.67168 2.56673 2.08747 2.02922 1.37785 2.02922C0.617552 2.02922 0.00120518 2.64626 0.00120518 3.40742L0.00109863 7.36495C0.0159807 7.69665 0.149919 8.02398 0.402915 8.27726L0.418743 8.29267L0.552163 8.40686L0.662396 8.4811L0.753989 8.53252C0.941826 8.62873 1.15465 8.683 1.38013 8.683H5.27084C6.03114 8.683 6.64749 8.06596 6.64749 7.3048C6.64749 6.54364 6.03114 5.9266 5.27084 5.9266L5.05534 5.92625C6.06752 4.76117 7.36417 3.86072 8.85755 3.31657C9.97589 2.90907 11.1396 2.72434 12.3015 2.76094C16.044 2.87883 19.3854 5.25676 20.6879 8.83937C22.4314 13.635 19.9615 18.9376 15.1713 20.6831C10.3811 22.4285 5.08446 19.9559 3.34096 15.1603C3.08093 14.445 2.29095 14.0762 1.5765 14.3365C0.862046 14.5969 0.493672 15.3877 0.753711 16.103C3.01729 22.3291 9.89387 25.5394 16.113 23.2732ZM11.0687 5.90219C11.829 5.90219 12.4454 6.51923 12.4454 7.28039V12.2287L15.936 15.7233C16.4736 16.2615 16.4736 17.1341 15.936 17.6723C15.3984 18.2106 14.5267 18.2106 13.9891 17.6723L10.0953 13.7741C9.82564 13.5042 9.69124 13.1501 9.69206 12.7964C9.69205 12.7953 9.69205 12.7943 9.69205 12.7933V7.28039C9.69205 6.51923 10.3084 5.90219 11.0687 5.90219Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default RestoreIcon;
