import { useAtom } from 'jotai';
import { memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { DataStore } from 'aws-amplify';
import { Box, Button, Grid } from '@chakra-ui/react';
import { Input, Textarea, Select } from '@sermonary/components';

import { sermonAtom, isSermonSettingsDrawerOpenAtom } from './SingleSermon';
import { Sermon, SermonSeries } from '../../models';
import { sermonSeriesAtom } from '../Sermons';
import AlertDialogPrompt from '../../components/forms/AlertDialogPrompt';

type SermonFormValues = {
  title: string;
  referenceVerses?: string | null;
  bigIdea?: string | null;
  sermonSeries?: string | null;
  newSermonSeries: string;
};

export const SermonForm = memo(() => {
  const [sermon] = useAtom(sermonAtom);
  const [series, setSermonSeries] = useAtom(sermonSeriesAtom);
  const [, setIsSermonSettingsDrawerOpen] = useAtom(isSermonSettingsDrawerOpenAtom);

  const { control, formState, handleSubmit, register, reset } = useForm<SermonFormValues>();

  const { isDirty } = formState;

  const sermonSeries = useWatch({ control, name: 'sermonSeries' });

  useEffect(() => {
    async function fetchSermonSeries() {
      const dsSeries = await DataStore.query(SermonSeries);

      setSermonSeries(dsSeries || []);
    }

    if (sermonSeries?.length === 0) {
      fetchSermonSeries();
    }
  }, [sermon]);

  useEffect(() => {
    if (sermon) {
      reset({
        title: sermon?.title,
        referenceVerses: sermon?.referenceVerses,
        bigIdea: sermon?.bigIdea,
        sermonSeries: sermon?.seriesId,
      });
    }
  }, [reset, sermon]);

  async function onSubmit(values: SermonFormValues) {
    let seriesId: string | null | undefined;
    try {
      if (values?.newSermonSeries) {
        const newSeries = await DataStore.save(
          new SermonSeries({
            title: values?.newSermonSeries,
          }),
        );

        seriesId = newSeries?.id;
      } else {
        seriesId = values?.sermonSeries;
      }

      await DataStore.save(
        Sermon.copyOf(sermon, (updated) => {
          updated.title = values.title || 'Untitled';
          updated.referenceVerses = values.referenceVerses;
          updated.bigIdea = values.bigIdea;
          if (seriesId) {
            updated.seriesId = seriesId;
          }
        }),
      );

      setIsSermonSettingsDrawerOpen(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="Sermon Form">
      <AlertDialogPrompt isDirty={isDirty} />
      <Grid
        gridTemplateColumns="1fr"
        gap="4"
        fontSize="sm"
        fontWeight="700"
        textTransform="uppercase"
        color="gray.900"
        letterSpacing="1px"
        css={{ label: { fontWeight: '700' } }}
      >
        <Input {...register('title')} label="Sermon Title" />
        <Select {...register('sermonSeries')} label="Sermon series" placeholder="Select a series">
          {series?.map((item) => (
            <option key={item?.id} value={item?.id}>
              {item?.title}
            </option>
          ))}
          <option value="create">Add new series</option>
        </Select>
        {sermonSeries === 'create' ? <Input {...register('newSermonSeries')} label="New series title" /> : null}
        <Input {...register('referenceVerses')} label="Bible passage(s)" />
        <Textarea {...register('bigIdea')} label="Big idea of message" />
        <Box>
          <Button
            colorScheme={isDirty ? 'blue' : 'grayButton'}
            color={isDirty ? 'white' : 'gray.900'}
            w="100%"
            type="submit"
            isDisabled={!isDirty}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </form>
  );
});
