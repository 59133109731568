import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import { enforce, test, create } from 'vest';
import { TextEditor } from '@sermonary/components';

export interface IdeaFormValues {
  title: string;
  content?: string | null;
}

export const ideaFormValidationSuite = create((data: Partial<IdeaFormValues> = {}) => {
  test('title', 'Title is required.', () => {
    enforce(data.title).isNotEmpty();
  });
});

export function IdeaForm({
  children,
  control,
}: Pick<UseFormReturn<IdeaFormValues>, 'control'> & {
  children?: ReactNode;
}) {
  return (
    <Grid gridTemplateColumns="100%" gap="4">
      <TextEditor
        label="Content"
        name="content"
        control={control as any}
        editorConfig={{
          toolbarButtons: {
            moreText: {
              buttons: ['bold', 'italic', 'underline', 'outdent', 'indent', 'clearFormatting', 'undo', 'redo'],
              buttonsVisible: 6,
            },
          },
        }}
      />
      {children}
    </Grid>
  );
}
