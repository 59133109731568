import { HTMLInputTypeAttribute, useState } from 'react';
import { UseFormReturn, FormState } from 'react-hook-form';
import { Box, Flex, Text, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { CloseEyeIcon, OpenEyeIcon } from '@sermonary/icons';
import { FormLabel } from './FormLabel';
import { Input } from './input';

interface AuthFormInputProps {
  label: string;
  inputLeftElement: JSX.Element;
  type: HTMLInputTypeAttribute | undefined;
  registerFieldName: 'email' | 'password';
  register: UseFormReturn<any>['register'];
  errors: FormState<any>['errors'];
  inputId: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export function AuthFormInput({
  label,
  inputLeftElement,
  type = undefined,
  registerFieldName,
  register,
  errors,
  inputId,
  autoFocus = false,
  onFocus,
  onBlur,
}: AuthFormInputProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Box>
      {type === 'password' ? (
        <Flex alignItems="center" justifyContent="space-between">
          <FormLabel inputId={inputId} label={label} />
          {showPassword ? (
            <Text
              alignItems="center"
              color="blue.500"
              display="flex"
              onClick={() => setShowPassword(false)}
              justifyContent="space-around"
              fontSize="xs"
              textTransform="uppercase"
              fontWeight="bold"
              cursor="pointer"
            >
              Hide
              <CloseEyeIcon width="4" height="4" ml="2" />
            </Text>
          ) : (
            <Text
              alignItems="center"
              color="blue.500"
              display="flex"
              onClick={() => setShowPassword(true)}
              justifyContent="space-around"
              fontSize="xs"
              textTransform="uppercase"
              fontWeight="bold"
              cursor="pointer"
            >
              Show
              <OpenEyeIcon width="4" height="4" ml="2" />
            </Text>
          )}
        </Flex>
      ) : (
        <FormLabel inputId={inputId} label={label} />
      )}
      <InputGroup mt="2">
        <InputLeftElement
          color="gray.900"
          width="auto"
          left="4"
          height="12"
          pointerEvents="none"
          children={inputLeftElement}
        />
        <Input
          {...register(registerFieldName)}
          id={inputId}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          error={errors?.[registerFieldName]?.message}
          aria-required
          height="12"
          paddingInlineStart="12"
          autoFocus={autoFocus}
          border="1px solid"
          borderColor="gray.700"
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </InputGroup>
    </Box>
  );
}
