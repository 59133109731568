import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const theChildrensLeader = {
  id: 'theChildrensLeader',
  title: "The Children's Leader",
  description:
    "This template, designed for children's leaders, will help kids understand and apply the teachings of Scripture.",
  sermonType: SermonType.SERMON,
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      type: 'point',
      title: 'Introduction',
      number: '1',
    },
    '1': {
      id: uuid(),
      type: 'illustration',
      title: 'OPENING HOOK',
      content: 'Begin your lesson with an attention-grabbing illustration.',
    },
    '2': {
      id: uuid(),
      type: 'custom',
      title: 'BIG IDEA OF THE LESSON',
      content: 'In one memorable sentence, state the big idea of your lesson.',
    },
    '3': {
      id: uuid(),
      type: 'point',
      title: 'Word of the Day',
      number: '2',
    },
    '4': {
      id: uuid(),
      type: 'quote',
      content: 'State one word from the text that encapsulates the entire lesson.',
    },
    '5': {
      id: uuid(),
      type: 'custom',
      title: 'DEFINITION',
      content: 'Describe unfamiliar terms from the lesson in language children can understand.',
    },
    '6': {
      id: uuid(),
      type: 'point',
      title: 'Bible Story',
      number: '3',
    },
    '7': {
      id: uuid(),
      type: 'bible',
      inputType: 'manual',
      book: '',
      chapter: '',
      verses: '',
      translation: '',
      content: '',
    },
    '8': {
      id: uuid(),
      type: 'custom',
      title: 'EXPLANATION OF STORY OR PASSAGE',
      content: `Explain this week's Bible story.`,
    },
    '9': {
      id: uuid(),
      type: 'point',
      title: 'Interpretation and Application',
      number: '4',
    },
    '10': {
      id: uuid(),
      type: 'illustration',
      title: 'ILLUSTRATION',
      content: 'Use an object lesson, story, or skit to help children interpret and apply this passage to their life.',
    },
    '11': {
      id: uuid(),
      type: 'application',
      title: 'APPLICATION',
      content: 'Give children specific ideas on how to apply the Bible story to their life.',
    },
    '12': {
      id: uuid(),
      type: 'point',
      title: 'Reflect - Small Groups',
      number: '5',
    },
    '13': {
      id: uuid(),
      type: 'custom',
      title: 'SMALL GROUP QUESTIONS',
      content: 'Insert small group questions here.',
    },
    '14': {
      id: uuid(),
      type: 'point',
      title: 'Review - Game',
      number: '6',
    },
    '15': {
      id: uuid(),
      type: 'custom',
      title: 'GAME',
      content: 'Use a game to review lesson material.',
    },
    '16': {
      id: uuid(),
      type: 'custom',
      color: 'transparent',
      title: 'COPYRIGHT',
      content: 'Copyright © 2017 Sermonary',
    },
  }),
};
