import { ConflictHandler } from '@aws-amplify/datastore';
import { backupSermon } from './backupSermon';
import { Sermon } from '../API';

export const conflictHandler: ConflictHandler = async (conflict) => {
  const { localModel, modelConstructor, remoteModel } = conflict;
  const { _version } = remoteModel;

  if (modelConstructor.name === 'Sermon') {
    await backupSermon({
      sermon: remoteModel as Sermon,
      source: 'Conflict Handler',
      meta: {
        localId: localModel.id,
        localVersion: localModel._version,
      },
    });
  }

  // now required to remove null properties from the localModel
  const newModel = Object.keys(localModel)
    .filter((key) => localModel[key] !== null)
    .reduce((model, key) => Object.assign(model, { [key]: localModel[key] }), {});

  return { ...newModel, _version };
};
