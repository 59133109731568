import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  ModalProps,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

interface DeleteSermonDialogProps {
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
  onDelete: () => void;
  onSuccess?: () => void;
}

function DeleteSermonDialog({
  isOpen,
  isLoading,
  isError,
  error,
  onClose,
  onDelete,
  onSuccess,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & DeleteSermonDialogProps) {
  const handleOnDelete = () => {
    onDelete();
    onClose();
    onSuccess?.();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Deleting will remove sermon(s) forever.</ModalBody>
        {isError ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Whoops!</AlertTitle>
            <AlertDescription>{error?.message}</AlertDescription>
          </Alert>
        ) : null}
        <ModalFooter display="flex" flexDirection="column">
          <Button colorScheme="blue" mb={2} w="100%" onClick={onClose} textTransform="unset">
            Never Mind. Keep My Sermon(s).
          </Button>
          <Button colorScheme="blue" variant="outline" w="100%" onClick={handleOnDelete} isLoading={isLoading}>
            Yes, delete forever.
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function useDeleteSermonDialog(props: DeleteSermonDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    handleOpenDialog: onOpen,
    handleCloseDialog: onClose,
    DeleteSermonDialog: () => (isOpen ? <DeleteSermonDialog isOpen={isOpen} onClose={onClose} {...props} /> : null),
  };
}
