import { useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { Block, ContentBlock as ContentBlockType } from '@sermonary/types';

import { useAutoSave } from '@sermonary/hooks';
import { useMemo } from 'react';
import AlertDialogPrompt from '../forms/AlertDialogPrompt';
import { ContentBlockForm } from '../forms';
import { useUpdateBlockForm } from '../../hooks/useUpdateBlockForm';

type ContentBlockFormValues = Pick<ContentBlockType, 'content'>;

// Listed as an 'Application' block in the "Add a block" menu
export function ContentBlock({
  isActive,
  block,
  onSave,
  onDelete,
  onClose,
}: {
  isActive: boolean;
  block: Block;
  onSave: (id: string, values: ContentBlockFormValues) => void;
  onDelete: (block: Block) => void;
  onClose: (id: string, values: ContentBlockFormValues) => void;
}) {
  const { control, handleSubmit, reset, ...methods } = useForm<ContentBlockFormValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        content: block?.content,
      }),
      [block],
    ),
  });

  function onSubmit(values: ContentBlockFormValues) {
    if (methods.formState.isDirty) {
      onSave(block?.id, values);
    }
    onClose(block?.id, values);
  }

  useAutoSave<ContentBlockFormValues>({
    control,
    isDirty: methods.formState.isDirty,
    onSave: async (values: ContentBlockFormValues) => {
      onSave(block?.id, values);
      reset(values);
    },
  });

  useUpdateBlockForm<ContentBlockFormValues>({
    block,
    handleReset: () => {
      reset({
        content: block?.content,
      });
    },
  });

  return isActive ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDialogPrompt isDirty={methods.formState.isDirty} />
      <ContentBlockForm control={control} {...methods}>
        <Flex justifyContent="flex-end">
          <Button type="button" mr="2" onClick={() => onDelete(block)}>
            Delete
          </Button>
          <Button type="submit" colorScheme="blue">
            Done
          </Button>
        </Flex>
      </ContentBlockForm>
    </form>
  ) : null;
}
