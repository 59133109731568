import { Icon, IconProps } from '@chakra-ui/react';

export function UploadIcon({ height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.25,10.4182772 C22.7687831,10.4182772 24,11.6494942 24,13.1682772 L24,21.25 C24,22.7687831 22.7687831,24 21.25,24 L2.75,24 C1.28746816,24 0.0915957212,22.8582954 0.00501879394,21.4175223 L-1.71003167e-14,13.1682772 C-1.70107624e-14,11.7057454 1.1417046,10.509873 2.58247767,10.423296 L6.555,10.4182772 C7.31439153,10.4182772 7.93,11.0338857 7.93,11.7932772 C7.93,12.5079987 7.31,13.1619829 6.55,13.1619829 L3.75,13.1682772 C3.23716416,13.1682772 2.81449284,13.5543174 2.75672773,14.0516561 L2.75,20.25 C2.75,20.7628358 3.13604019,21.1855072 3.63337887,21.2432723 L20.25,21.25 C20.8022847,21.25 21.25,20.8022847 21.25,20.25 L21.25,14.1682772 C21.25,13.6159925 20.8022847,13.1682772 20.25,13.1682772 L17.445,13.1682772 C16.6856085,13.1682772 16.07,12.5526688 16.07,11.7932772 C16.07,11.0338857 16.6856085,10.4182772 17.445,10.4182772 L21.25,10.4182772 Z M12.7492143,0.243586783 L12.9564827,0.434440349 L13.015493,0.499880847 L18.6362975,6.12063049 C19.1119642,6.59629721 19.0622674,7.4172022 18.5252965,7.95417311 C17.9883256,8.49114401 17.1674206,8.54084086 16.6917538,8.06517414 L13.3442976,4.7177728 L13.2985279,15.8291386 C13.2985279,16.5885302 12.6829194,17.2091386 11.9235279,17.2091386 C11.1641364,17.2091386 10.5485279,16.5885302 10.5485279,15.8291386 L10.5929452,4.74252153 L7.38434735,7.95111934 C6.84737645,8.48809024 5.9767746,8.48809024 5.4398037,7.95111934 C4.9028328,7.41414844 4.9028328,6.5435466 5.4398037,6.00657569 L11.011939,0.434440349 L11.1126072,0.342665519 C11.6113615,-0.0737582316 12.297396,-0.115290148 12.7492143,0.243586783 Z"
      />
    </Icon>
  );
}

export default UploadIcon;
