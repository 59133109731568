import { Icon, IconProps } from '@chakra-ui/react';

export function CheckInBoxIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        d="M21.25 0A2.75 2.75 0 0 1 24 2.75v18.5A2.75 2.75 0 0 1 21.25 24H2.75A2.75 2.75 0 0 1 0 21.25V2.75A2.75 2.75 0 0 1 2.75 0h18.5Zm-1 2.75H3.75a1 1 0 0 0-1 1v16.5a1 1 0 0 0 1 1h16.5a1 1 0 0 0 1-1V3.75a1 1 0 0 0-1-1ZM18.1 7.278a1.375 1.375 0 0 1 0 1.944l-7.502 7.502a1.37 1.37 0 0 1-.902.401h-.147a1.37 1.37 0 0 1-.902-.4L5.904 13.98a1.375 1.375 0 0 1 1.945-1.945l1.773 1.774 6.533-6.532a1.375 1.375 0 0 1 1.945 0Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default CheckInBoxIcon;
