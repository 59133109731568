import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const theDefendersOutline = {
  id: 'theDefendersOutline',
  title: "The Defender's Outline",
  description: 'Work through this template when using apologetics to teach or defend a tenet of the Christian faith.',
  sermonType: SermonType.SERMON,
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      type: 'illustration',
      title: 'INTRODUCTION',
      content: '',
    },
    '1': {
      id: uuid(),
      type: 'point',
      title: 'Explain the Biblical Principle',
      number: '1',
    },
    '2': {
      id: uuid(),
      type: 'custom',
      title: 'WHAT DOES THE BIBLE TEACH?',
      content: '',
    },
    '3': {
      id: uuid(),
      type: 'point',
      title: 'Consider Objections',
      number: '2',
    },
    '4': {
      id: uuid(),
      type: 'custom',
      title: 'WHAT ARGUMENTS DO PEOPLE WHO DISAGREE WITH THE BIBLE OFFER?',
      content: '',
    },
    '5': {
      id: uuid(),
      type: 'point',
      title: 'Offer a Defense',
      number: '3',
    },
    '6': {
      id: uuid(),
      type: 'custom',
      title: 'WHAT REASONS DO WE HAVE TO BELIEVE THE BIBLE?',
      content: '',
    },
    '7': {
      id: uuid(),
      type: 'point',
      title: 'Provide Application',
      number: '4',
    },
    '8': {
      id: uuid(),
      type: 'application',
      title: 'HOW SHOULD THIS BIBLICAL TRUTH CHANGE THE WAY WE LIVE?',
      content: '',
    },
    '9': {
      id: uuid(),
      type: 'custom',
      color: 'transparent',
      title: 'COPYRIGHT',
      content: 'Copyright © 2017 Sermonary',
    },
  }),
};
