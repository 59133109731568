import { lazy, useEffect } from 'react';
import { atom, PrimitiveAtom, useAtom } from 'jotai';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { MPFederationClient } from '@ministry-pass/federation-client';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { AppProvider } from './contexts/AppContext';
import { Loading } from './pages/Loading';
import { ModalProvider } from './components/modals';
import { Account } from './models';
import { identifyUser } from './analytics/Analytics';
import { useAmplifyHubListeners } from './hooks/useAmplifyHubListeners';
import { InstantSearch } from 'react-instantsearch';
import { registerSW } from 'virtual:pwa-register';
import { isOnlineAtom } from './libs/components/layout';
import { SubscriptionData } from './hooks/useSubscriptionInformation';
import { algoliaSermonarySecuredKeyAtom } from './libs/atoms/algoliaAtoms';
import algoliasearch from 'algoliasearch';
import { InitialSyncLoading } from './pages/InitialSyncLoading';
import { useLocation } from 'react-router-dom';

type CognitoUserExtraInfo = {
  identityId: string;
  attributes: any;
  signInUserSession: { accessToken: { payload: any } };
  username: string;
};

registerSW({
  onRegistered(r) {
    r &&
      setInterval(() => {
        r.update();
      }, 1000);
  },
});

const fetchMPFederationToken = async () => {
  const response = await API.graphql(
    graphqlOperation(`
      query mpFederationToken {
        mpFederationToken{
          jwt
        }
      }
    `),
  );

  return (response as any)?.data?.mpFederationToken?.jwt;
};

const client = new MPFederationClient({
  apiUrl: import.meta.env.VITE_MP_FED_API_URL ?? 'https://api.ministrypass.com',
  tokenProvider: fetchMPFederationToken,
});

const Routes = lazy(() => import('./Routes'));

export const showInitialLoadingPromptAtom = atom<boolean>(false);
export const userAccountAtom = atom<Account>({} as Account);
export const podiumModeAtom = atom(false);
export const shouldCheckForResourcesAtom = atom(true);
export const shouldCheckForReIndexAtom = atom(true);
export const cognitoUserAtom = atom<(CognitoUser & CognitoUserExtraInfo) | null>(null) as PrimitiveAtom<
  (CognitoUser & CognitoUserExtraInfo) | null
>;
export const dataStoreIsSyncedAtom = atom<boolean | null>(null) as PrimitiveAtom<boolean | null>;
export const isAuthenticatedAtom = atom<boolean | null>(null) as PrimitiveAtom<boolean | null>;
export const subscriptionInfoFetchedAtom = atom<boolean>(false);
export const isCanceledUserAtom = atom<boolean>(false);
export const isFreeUserAtom = atom<boolean>(false);
export const hasSubscriptionAtom = atom<boolean>(false);
export const subscriptionInfoAtom = atom({} as SubscriptionData);
export const modelsSyncedAtom = atom([]);

export const fetchCognitoUserAtom = atom(
  (get) => get(cognitoUserAtom),
  async (_get, set, bypassCache: boolean | undefined = false) => {
    try {
      const user: CognitoUser & CognitoUserExtraInfo = await Auth.currentAuthenticatedUser({ bypassCache });
      const { identityId } = await Auth.currentUserCredentials();
      user.identityId = identityId;

      await identifyUser(user);

      set(cognitoUserAtom, user);
    } catch (err) {
      set(cognitoUserAtom, {} as CognitoUser & CognitoUserExtraInfo);
    }
  },
);

export function App() {
  const [cognitoUser, fetchCognitoUser] = useAtom(fetchCognitoUserAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
  const [isOnline, setIsOnline] = useAtom(isOnlineAtom);
  const [dataStoreIsSynced] = useAtom(dataStoreIsSyncedAtom);
  const [algoliaSecuredKey] = useAtom(algoliaSermonarySecuredKeyAtom);
  const ldClient = useLDClient();
  const darklyFlags = useFlags();
  const logRocketKey = import.meta.env.VITE_LOGROCKET_KEY || '';
  const currentLocation = useLocation();
  const finalPath = currentLocation.pathname.slice(currentLocation.pathname.lastIndexOf('/'));

  const searchClient = algoliasearch(import.meta.env.VITE_ALGOLIA_SERMONARY_APP_ID, algoliaSecuredKey);
  // Prevents empty queries
  const customSearchClient = {
    ...searchClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
      }

      return searchClient.search(requests);
    },
  };

  useAmplifyHubListeners();

  function handleOfflineStatus() {
    setIsOnline(false);
  }

  function handleOnlineStatus() {
    setIsOnline(true);
  }

  // LaunchDarkly identification
  useEffect(() => {
    if (cognitoUser !== null) {
      if (Object.keys(cognitoUser).length) {
        setIsAuthenticated(true);
        if (cognitoUser?.attributes?.sub) {
          const firstName = cognitoUser?.attributes?.name || '';
          const lastName = cognitoUser?.attributes?.family_name || '';
          const name = `${firstName} ${lastName}`.trim();

          ldClient.identify({
            kind: 'user',
            key: cognitoUser?.attributes?.sub,
            email: cognitoUser?.attributes?.email,
            ...(name.length > 0 ? { name: name } : {}),
          });
        }
      } else {
        setIsAuthenticated(false);
      }
    }
  }, [cognitoUser]);

  if (darklyFlags.logRocket && logRocketKey !== '') {
    LogRocket.init(logRocketKey);
    LogRocket.getSessionURL(async (sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
      await identifyUser(cognitoUser);
    });
  }

  useEffect(() => {
    fetchCognitoUser(true).catch(() => console.error("Can't fetch cognito user"));

    window.addEventListener('offline', handleOfflineStatus);
    window.addEventListener('online', handleOnlineStatus);

    return () => {
      window.removeEventListener('offline', handleOfflineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  return isAuthenticated === null && dataStoreIsSynced === null ? (
    <Loading />
  ) : // Prevent sync loading component on podium routes
  // Podium component would remount and scroll to top during a sermon
  finalPath !== '/podium' && finalPath !== '/share' && isAuthenticated && dataStoreIsSynced === false ? (
    <InitialSyncLoading />
  ) : (
    <InstantSearch searchClient={customSearchClient} indexName={import.meta.env.VITE_ALGOLIA_SERMONARY_ENV_INDEX}>
      <AppProvider client={client}>
        <Routes />
        <ModalProvider />
      </AppProvider>
    </InstantSearch>
  );
}
