import { Image, ImageProps } from '@chakra-ui/react';

type LoadingGifProps = {
  boxSize?: '25px' | '50px' | '75px' | '100px';
};

export const LoadingGif = ({ boxSize = '100px' }: LoadingGifProps) => {
  return (
    <Image
      boxSize={boxSize}
      objectFit="cover"
      src="https://s3.amazonaws.com/assets.sermonary.com/Loading.gif"
      alt="Loading..."
    />
  );
};
