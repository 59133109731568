import { Icon, IconProps } from '@chakra-ui/react';

export function EmailIcon({ width = '6', height = '6', ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 4.507a2.875 2.875 0 0 0-.423-1.229 2.665 2.665 0 0 0-1.12-1A2.739 2.739 0 0 0 21.25 2H2.75c-.434 0-.844.1-1.209.28a2.665 2.665 0 0 0-1.118.998A2.876 2.876 0 0 0 0 5.088V18.25A2.75 2.75 0 0 0 2.75 21h18.5A2.75 2.75 0 0 0 24 18.25V5.09c.02-.194.02-.39 0-.583Zm-2.749.243H2.747a.133.133 0 0 0-.007.02L12 10.73l9.26-5.96a.132.132 0 0 0-.007-.019l-.002-.001Zm-10.74 8.292a2.75 2.75 0 0 0 2.977 0l7.762-4.996V18.25H2.75V8.046l7.762 4.996Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default EmailIcon;
