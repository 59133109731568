import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const graduationPresentationScript = {
  id: 'graduationPresentationScript',
  bigIdea:
    'This script is designed to be used during a church service where all of the high school and college graduates in the congregation are honored.',
  sermonType: SermonType.SERMON,
  description:
    'This script is designed to be used during a church service where all of the high school and college graduates in the congregation are honored.',
  title: 'Graduation Presentation Script',
  blocks: JSON.stringify({
    '0': {
      number: '',
      id: uuid(),
      wordCount: 0,
      type: 'point',
      title: 'Introduction',
    },
    '1': {
      id: uuid(),
      color: '#000',
      wordCount: 59,
      type: 'custom',
      title: 'Introduction',
      content:
        "<p>At this point in the service, we are going to take a few moments to honor our graduates. At this time, I'd like for [GRADUATE NAMES] to come to the stage. While they are coming forward, I want to direct your attention to the screens where a short slideshow will play.&nbsp;</p><p><em>Graduates line up on the stage as slideshow plays.</em></p>",
    },
    '2': {
      id: uuid(),
      color: '#000',
      wordCount: 17,
      type: 'custom',
      title: 'Slideshow',
      content:
        '<p><i>While the graduates are coming forward, a short slideshow plays with images and names of the graduates.&nbsp;</i></p>',
    },
    '3': {
      chapter: '16',
      wordCount: 95,
      book: 'PSA',
      translation: 'NIV',
      inputType: 'select',
      id: uuid(),
      type: 'bible',
      verses: '7-11',
      content: {
        v7: ['I will praise the', 'Lord', ', who counsels me;', 'even at night my heart instructs me.'],
        v8: ['I keep my eyes always on the', 'Lord', '.', 'With him at my right hand, I will not be shaken.'],
        v9: ['Therefore my heart is glad and my tongue rejoices;', 'my body also will rest secure,'],
        v10: [
          'because you will not abandon me to the realm of the dead,',
          'nor will you let your faithful',
          'one see decay.',
        ],
        v11: [
          'You make known to me the path of life;',
          'you will fill me with joy in your presence,',
          'with eternal pleasures at your right hand.',
        ],
      },
    },
    '4': {
      id: uuid(),
      wordCount: 193,
      type: 'application',
      content:
        "<p>Graduates, we are so proud of you. Your hard work, as well as the love and support from those close to you, has gotten you to where you are today. Congratulations! What you have done I a reason to celebrate! We are also so thankful for all of the parents, leaders, and teachers woh have walked alongside you as you have worked to reach this very important milestone.&nbsp;</p><p>I'd like to talk to you a moment about the Bible passage I just read.</p><p>Psalm 16:7-11 describes God's ever faithfulness. He will not forsake or abandon us. It's important that we trust in this truth. We must also, the writer says, \"set the Lord always before\" our hearts.&nbsp;</p><p>Upon graduation, you are embarking on a new journey. Some of you are moving away to college. Others are exploring other options, or attending university, closer to home. Where the next few years take you, it is important to always follow God's voice. You must continue to grow in your faith, stay plugged into a local church--wherever that may be--and look to honor God in education, occupation, and future relationships.&nbsp;</p><p>This is where we will find our fulfillment in life.&nbsp;</p>",
    },
    '5': {
      id: uuid(),
      wordCount: 165,
      type: 'illustration',
      content:
        '<p>Writer Amy Simpson told a story a lot of us can relate to when she declared, "Blessed are the Unsatisfied." She explained that for most of her childhood, her family was living in poverty, depending on food pantries, public assistance, and expired food. Her mother was ill, and Amy and her siblings had to shoulder a lot more responsibility for the family than most children do. When she grew up, got a college education, a good job, and a happy family, she thought she would feel satisfied. When she wasn\'t she was confronted with the truth that we were not made to be satisfied with a fallen and broken world. We will always hunger for more, and this hunger is what pushes us to place our hope in the future redemption of the world (Amy Simpson, "Blessed are the Unsatisfied," Christianity Today, February 8, 2018).</p><p>Today, as you embark on your future, commit to being satisfied not in money or possessions, but in building God\'s kingdom.</p>',
    },
    '6': {
      id: uuid(),
      color: '#000',
      wordCount: 17,
      type: 'custom',
      title: 'Prayer',
      content: '<p><em>Have the elders in your church lay hands on the graduates and say a prayer over them. </em></p>',
    },
    '7': {
      id: uuid(),
      color: '#000',
      wordCount: 33,
      type: 'custom',
      title: 'Conclusion',
      content:
        "<p>At this time, we'd like to present all of you with a small gift on behalf of the congregation.&nbsp;</p><p><em>Present gift and then have the congregation offer a round of applause for the graduates.</em></p>",
    },
  }),
};
