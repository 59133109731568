import { Icon, IconProps } from '@chakra-ui/react';

export function SermonsIcon({ height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="M22.625 5.5c.76 0 1.375.62 1.375 1.38v14.37A2.75 2.75 0 0121.25 24H1.375a1.375 1.375 0 010-2.75H20c.69 0 1.25-.56 1.25-1.25V6.88c0-.76.616-1.38 1.375-1.38zm0-5.5a1.375 1.375 0 010 2.75H4c-.69 0-1.25.56-1.25 1.25v13.13c0 .76-.616 1.37-1.375 1.37A1.37 1.37 0 010 17.13V2.75A2.75 2.75 0 012.75 0h19.875z"
      />
    </Icon>
  );
}

export default SermonsIcon;
