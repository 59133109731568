import { Icon, IconProps } from '@chakra-ui/react';

export function PlusInCircleIcon({ color = 'inherit', height = 6, width = 6, ...props }: IconProps) {
  return (
    <Icon color={color} height={height} width={height} {...props}>
      <path
        fillRule="evenodd"
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zm0 3.625c.76 0 1.375.616 1.375 1.375v2.875h2.875a1.375 1.375 0 010 2.75h-2.875v2.875a1.375 1.375 0 01-2.75 0v-2.875H7.75a1.375 1.375 0 010-2.75h2.875V7.75c0-.76.616-1.375 1.375-1.375z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default PlusInCircleIcon;
