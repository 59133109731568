import { useAtom } from 'jotai';
import { validate as isUuid } from 'uuid';
import { userAccountAtom } from 'src/App';
import { Sermon } from 'src/models';
import { customSermonTemplatesAtom, favoriteSermonTemplatesAtom, providedSermonTemplatesAtom } from 'src/libs/atoms';
import { useEffect, useState } from 'react';
import { DataStore, SortDirection } from 'aws-amplify';

export function useFetchFavoriteTemplates() {
  const [userAccount] = useAtom(userAccountAtom);
  const [customSermonTemplates, setCustomSermonTemplates] = useAtom(customSermonTemplatesAtom);
  const [providedSermonTemplates] = useAtom(providedSermonTemplatesAtom);
  const [, setFavoriteSermonTemplates] = useAtom(favoriteSermonTemplatesAtom);
  const [customTemplatesFetched, setCustomTemplatesFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchCustomTemplates() {
    const fetchedCustomTemplates = await DataStore.query(Sermon, (c) => c?.sermonType.eq('TEMPLATE'), {
      sort: (s) => s.updatedAt(SortDirection.DESCENDING),
    });
    setCustomSermonTemplates(fetchedCustomTemplates);
    setCustomTemplatesFetched(true);
  }

  async function fetchFavoriteTemplates() {
    const favoriteTemplateIds = userAccount?.favoriteTemplates?.split(',').filter(Boolean) ?? [];
    let mixedFavoriteTemplates = [];

    favoriteTemplateIds.map((id) => {
      if (isUuid(id)) {
        // get custom template
        const template: Sermon = customSermonTemplates.find((template: Sermon) => template.id === id);
        mixedFavoriteTemplates.push(template);
      } else {
        // get provided template
        const template: Sermon = providedSermonTemplates[id];
        mixedFavoriteTemplates.push(template);
      }
    });

    setFavoriteSermonTemplates(mixedFavoriteTemplates);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchCustomTemplates();
    DataStore.observe(Sermon).subscribe(fetchCustomTemplates);
  }, []);

  useEffect(() => {
    customTemplatesFetched && fetchFavoriteTemplates();
  }, [userAccount.favoriteTemplates, providedSermonTemplates, customTemplatesFetched, customSermonTemplates]);

  return {
    isLoading: isLoading,
  };
}
