import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import { Input } from '@sermonary/components';

export interface PointBlockFormValues {
  title?: string;
  number?: number;
}

export function PointBlockForm({
  children,
  register,
  formState,
}: Pick<UseFormReturn<PointBlockFormValues>, 'register' | 'formState' | 'control'> & {
  children?: ReactNode;
}) {
  const { errors } = formState;

  return (
    <Grid gridTemplateColumns="1fr" gap="4">
      <Input
        {...register('number')}
        label="Number"
        placeholder="Leave blank to auto increment"
        error={errors?.number?.message}
      />
      <Input {...register('title')} label="Title" error={errors?.title?.message} />
      {children}
    </Grid>
  );
}
