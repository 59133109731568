import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

export type SubscriptionData = {
  billingFrequency: string;
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: number;
  portalManageURL: string;
  portalUpgradeURL: string;
  product: string;
  status: string;
  trialEnd: number;
};

export function useSubscriptionInformation({ shouldQuery = false }: { shouldQuery?: boolean }) {
  const location = useLocation();

  const getSubscriptionInfo = `
    query getSubscriptionInfo($returnUrl: String!) {
      getSubscriptionInfo(returnUrl: $returnUrl) {
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        trialEnd
        product
        billingFrequency
        portalManageURL
        portalUpgradeURL
      }
    }
  `;

  const {
    data: subscriptionInfo,
    isLoading,
    isFetching,
    isFetched,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['getSubscriptionInfo', shouldQuery],
    queryFn: async () => {
      const response = await API.graphql<any>(
        graphqlOperation(getSubscriptionInfo, {
          returnUrl: `${window.location.origin}${location?.pathname}?triggerSync=1`,
        }),
      );

      return response.data.getSubscriptionInfo as SubscriptionData;
    },
    enabled: shouldQuery,
  });

  return {
    refetch,
    isLoading,
    isFetching,
    isFetched,
    isError,
    isSuccess,
    subscriptionInfo,
    isFreeUser: subscriptionInfo?.product === 'free',
  };
}
