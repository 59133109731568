import { v4 as uuid } from 'uuid';
import { SermonType } from '../../models';

export const traditionalThreePointSermon = {
  id: 'traditionalThreePointSermon',
  title: 'Traditional 3-Point Sermon Template',
  description:
    'This is a traditional three-point sermon template. Each point includes explanation, illustration, and application blocks.',
  sermonType: SermonType.SERMON,
  blocks: JSON.stringify({
    '0': {
      id: uuid(),
      type: 'custom',
      title: 'INTRODUCTION',
      content: 'Insert your message introduction, here.',
    },
    '1': {
      id: uuid(),
      type: 'point',
      title: 'POINT #1',
      number: '1',
    },
    '2': {
      id: uuid(),
      type: 'custom',
      title: 'Explanation',
      content: 'Explain Point #1.',
    },
    '3': {
      id: uuid(),
      type: 'illustration',
      title: 'Illustration',
      content: 'Illustrate your point.',
    },
    '4': {
      id: uuid(),
      type: 'application',
      title: 'Application',
      content: 'Apply this point to your audience.',
    },
    '5': {
      id: uuid(),
      type: 'point',
      title: 'POINT #2',
      number: '2',
    },
    '6': {
      id: uuid(),
      type: 'custom',
      title: 'Explanation',
      content: 'Explain Point #2.',
    },
    '7': {
      id: uuid(),
      type: 'illustration',
      title: 'Illustration',
      content: 'Illustrate your point.',
    },
    '8': {
      id: uuid(),
      type: 'application',
      title: 'Application',
      content: 'Apply this point to your audience.',
    },
    '9': {
      id: uuid(),
      type: 'point',
      title: 'POINT #3',
      number: '3',
    },
    '10': {
      id: uuid(),
      type: 'custom',
      title: 'Explanation',
      content: 'Explain Point #3.',
    },
    '11': {
      id: uuid(),
      type: 'illustration',
      title: 'Illustration',
      content: 'Illustrate your point.',
    },
    '12': {
      id: uuid(),
      type: 'application',
      title: 'Application',
      content: 'Apply this point to your audience.',
    },
    '13': {
      id: uuid(),
      type: 'custom',
      title: 'Conclusion',
      content: '',
    },
  }),
};
